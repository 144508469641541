import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, CardContent, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import CustomDatePicker from "../formularios/CustomDatePicker";
import DefaultTable from "../table/DefaultTable";

const termos = {
  id: "ID",
  nome: "Nome",
  inicioPreMatricula: "Início Pré-Matrícula",
  fimPreMatricula: "Fim Pré-Matricula",
  vagas: "Vagas",
  interessados: "Interessados",
  situacao: "Situação",
  status: "Status",
  quantidade: "Quantidade",
  cargaHoraria: "Carga Horária",
  modalidade: "Modalidade",
  cnpj: "CNPJ",
};

const tabelaColunas = [
  { field: "modificacao", headerName: "Modificação" },
  { field: "campoAlterado", headerName: "Campo alterado" },
  { field: "informacaoAnterior", headerName: "Informação anterior" },
  { field: "novaInformacao", headerName: "Nova Informação" },
];

const AdminListarHistoricoCurso = ({ initialData, curso }) => {
  const [filter, setFilter] = useState(initialData);
  // const initialData = curso.historico;
  const [filteredData, setFilteredData] = useState(initialData);

  const filterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      const textFilter =
        parceiro.campo.toLowerCase().includes(filter.campoAlterado.toLowerCase()) &&
        (parceiro.anterior.toLowerCase().includes(filter.texto.toLowerCase()) ||
          parceiro.nova.toLowerCase().includes(filter.texto.toLowerCase()));
      const dataFilter =
        !filter.dataDoCadastroInicio ||
        !filter.dataDoCadastroFim ||
        dayjs(parceiro.dataCadastro).isBetween(filter.dataDaModificacaoInicio, filter.dataDaModificacaoFim, "day", "[]");

      return textFilter && dataFilter;
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialData);
    applyFilter();
  };

  return (
    <>
      <Stack spacing={1}>
        <Typography variant="subtitle2">Histórico de alterações do curso: {curso.nomeDoCurso}</Typography>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">Filtros</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data da Modificação</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <CustomDatePicker
                        label="Início"
                        id="dataDaModificacaoInicio"
                        name="dataDaModificacaoInicio"
                        value={filter.dataDaModificacaoInicio}
                        format="DD/MM/YYYY"
                        onChange={valor => setFilter({ ...filter, ["dataDaModificacaoInicio"]: valor })}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <FormGroup>
                        <CustomDatePicker
                          id="dataDaModificacaoFim"
                          name="dataDaModificacaoFim"
                          value={filter.dataDaModificacaoFim}
                          label="Fim"
                          format="DD/MM/YYYY"
                          onChange={valor => setFilter({ ...filter, ["dataDaModificacaoFim"]: valor })}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              alignContent="center"
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  id="campoAlterado"
                  name="campoAlterado"
                  value={filter.campoAlterado}
                  label="Campo alterado"
                  placeholder="Campo alterado"
                  onChange={filterChange}
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid
            container
            mt={2}
          >
            <Grid
              item
              xs={12}
            >
              <FormGroup>
                <TextField
                  id="texto"
                  name="texto"
                  label="Pesquisa"
                  placeholder="Pesquisa"
                  value={filter.texto}
                  onChange={filterChange}
                />
              </FormGroup>
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado histórico!"}
              columns={tabelaColunas}
              termos={termos}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
    </>
  );
};

AdminListarHistoricoCurso.propTypes = {
  setServicosModal: PropTypes.func,
  servicosModal: PropTypes.bool,
  initialData: PropTypes.array,
  curso: PropTypes.object,
};

export default AdminListarHistoricoCurso;

import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminIncluirCursosTrilha from "../../components/formularios/AdminIncluirCursosTrilha";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageIncluirCursosTrilha = () => {
  const { id } = useParams();
  const { isPending, data: trilha } = useQuery({
    queryKey: ["trilha"],
    queryFn: () => adminService.getTrilhaCurso(id),
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/trilhas", text: "Trilhas" },
          { href: "/trilhas", text: "Listar" },
        ]}
        currentPage="Incluir Cursos"
      />

      <TitlePage title={"Incluir cursos"} />

      {isPending && !trilha ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <AdminIncluirCursosTrilha trilha={trilha} />
      )}
    </Container>
  );
};

export default PageIncluirCursosTrilha;

import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import VagaTrabalho from "../../components/formularios/VagaTrabalho";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageVisualizarVagaTrabalho = () => {
  const { id } = useParams();

  const results = useQueries({
    queries: [
      {
        queryKey: ["vaga"],
        queryFn: () => {
          return adminService.getVaga(id, 1);
        }, //todo: não enviar estático
      },
      {
        queryKey: ["empresasParceiras"],
        queryFn: adminService.getEmpresasParceiras,
      },
    ],
  });

  const [vagaQuery, empresasQuery] = results;

  const vaga = vagaQuery.data;
  const empresas = empresasQuery.data;

  const isPending = vagaQuery.isLoading || empresasQuery.isLoading;
  const isError = vagaQuery.isError || empresasQuery.isError;

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Vagas" },
          { href: "/vagas/listar", text: "Listar" },
        ]}
        currentPage="Visualizar"
      />

      <TitlePage title={"Visualizar"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <VagaTrabalho
          initialValues={vaga}
          visualizacao={true}
          isAdmin={true}
          listaEmpresas={empresas}
        />
      )}
    </Container>
  );
};

export default PageVisualizarVagaTrabalho;

import React from "react";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import WarningIcon from "@mui/icons-material/Warning";
import { Container } from "@mui/material";

import WarningBox from "../../components/boxes/WarningBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import RenderCard from "../../components/cards/CardRender";
import FormMicrocreditoMPO from "../../components/formularios/ParceiroCidadaoMicrocreditoMPO";
import TitlePage from "../../components/title-page/TitlePage";

// Dados dos cards como strings
const cardContent1 = {
  icon: <RequestQuoteIcon style={{ color: "grey" }} />,
  title: "O que é Microcrédito Produtivo Orientado (MPO)",
  text: `
    O Microcrédito Produtivo Orientado (MPO) é o crédito concedido por operador de microcrédito, em condições diferenciadas do crédito tradicional, para financiamento de negócios produtivos de empreendedores de baixa renda, com orientação e acompanhamento ofertados pelo operador ao empreendedor durante a utilização do crédito.
    Com os recursos do MPO o empreendedor poderá adquirir bens, contratar serviços e ter capital de giro necessários à manutenção, melhoria ou criação de seu negócio.
    O operador de microcrédito, por exemplo, banco, cooperativa de crédito, agência de fomento, e outros, sempre avalia os objetivos e planejamento do negócio por meio de um atendimento diferenciado ao empreendedor tomador do MPO. Ou seja, há um atendimento realmente personalizado. Por fim, é importante lembrar que cada operador de microcrédito utiliza critérios próprios para avaliar e autorizar ou não o empréstimo, ressaltando que cada operador tem a sua política de concessão de crédito.
    Tecnicamente, o mais correto é falarmos de financiamento de microcrédito produtivo orientado, porém, como já há uma prática de uso pelos operadores e para facilitar o entendimento do cidadão, continuaremos também usando o termo “empréstimo”.
  `,
  subtitle: "Quem pode solicitar?",
  subtext: `
    Todos os empreendedores de baixa renda com negócios formais ou informais, individual ou coletivo, novos ou existentes, sendo a concessão do empréstimo sujeita à avaliação e aprovação cadastral e de crédito do empreendedor realizada pelo operador de microcrédito.
  `,
};

const cardContent2 = {
  icon: <WarningIcon style={{ color: "grey" }} />,
  title: "Atenção",
  text: `
    NÃO É EMPRÉSTIMO DO GOVERNO! ESSE CRÉDITO NÃO TEM APROVAÇÃO GARANTIDA, E O RECURSO TEM QUE SER UTILIZADO EM UM NEGÓCIO PRÓPRIO E NUNCA PARA DESPESAS DA CASA (CONSUMO FAMILIAR).
    A operação de crédito do MPO é um empréstimo cujo dinheiro emprestado tem uso restrito e pré-definido em contrato, e são os parceiros do Acredita (operadores de microcrédito) que decidem, após as avaliações necessárias, se farão ou não o contato com o empreendedor para conceder ou não o empréstimo.
  `,
  subtitle: "Quem são os operadores de microcrédito parceiros do Acredita?",
  subtext: `
    Os operadores de microcrédito parceiros do Acredita podem ser bancos, cooperativas de crédito, agências de fomento, sociedades de crédito ao microempreendedor e a empresas de pequeno porte, organizações da sociedade civil de interesse público, pessoas jurídicas especializadas no apoio, no fomento ou na orientação de microcrédito produtivo orientado, instituições financeiras que realizam operações exclusivamente por meio de site eletrônico ou de aplicativo. Consulte a relação de parceiros do Acredita, veja se há algum atuando em seu Município, quem sabe eles poderão decidir por fazer um contato com você, aguarde.
  `,
};

const cardContent3 = {
  icon: <BusinessCenterIcon style={{ color: "grey" }} />,
  title: "Preciso estar formalizado como microempreendedor individual para ter acesso ao Microcrédito Produtivo Orientado?",
  text: `
    Depende! Alguns parceiros operadores de microcrédito fazem essa exigência, outros não!
    O Microempreendedor Individual (MEI) é quem trabalha ou quer trabalhar por conta própria e decide formalizar seu negócio como empresa para ter acesso a CNPJ, aposentadoria, auxílio-doença, salário-maternidade, pensão por morte e outros direitos. Para ser um MEI, o faturamento do empreendedor pode ser de, no máximo, R$ 81 mil por ano, não pode ter participação em outra empresa como sócio ou titular.
    Para saber mais sobre MEI, clique aqui e acesse o Portal do Empreendedor.
  `,
  subtitle: "Quais são os critérios para concessão do MPO?",
  subtext: `
    Cada parceiro operador de microcrédito tem sua própria política de concessão de crédito, ou seja, os critérios variam de acordo com o operador.
  `,
};

const PageMicrocreditoMPO = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/empreendedorismo", text: "Empreendedorismo" },
    { href: "/empreendedorismo", text: "Microcrédito" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Acesso a Microcrédito Produtivo Orientado (MPO)"
        links={linksBreadCrumb}
      />

      <WarningBox />

      <TitlePage title={"Mobilização Individual – Acesso a Microcrédito Produtivo Orientado (MPO)"} />

      <p>
        Aqui você que está interessado em criar ou melhorar o seu próprio negócio, poderá ter acesso ao Microcrédito Produtivo Orientado a
        ser concedido pelos operadores de microcrédito parceiros do Acredita.
      </p>

      <RenderCard content={cardContent1} />
      <RenderCard content={cardContent2} />
      <RenderCard content={cardContent3} />

      <FormMicrocreditoMPO />
    </Container>
  );
};

export default PageMicrocreditoMPO;

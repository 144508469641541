import React from "react";

import { Container } from "@mui/material";

import WarningBox from "../../components/boxes/WarningBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormAcessoMicrocreditoMPO from "../../components/formularios/ParceiroCidadaoAcessoMicrocreditoMPO";
import TitlePage from "../../components/title-page/TitlePage";

const PageAcessoMicrocreditoMPOAprovado = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Parceiro" },
    { href: "/microcredito", text: "Microcrédito" },
    { href: "/microcredito/mobilizacao-individual", text: "Mobilização" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Acesso a Microcrédito Produtivo Orientado (MPO)"
        links={linksBreadCrumb}
      />

      <WarningBox />

      <TitlePage title={"Mobilização Individual - Acesso a Microcrédito Produtivo Orientado (MPO)"} />

      <FormAcessoMicrocreditoMPO aprovado={true} />
    </Container>
  );
};

export default PageAcessoMicrocreditoMPOAprovado;

import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";

import optionsService from "../../services/options.service";
import CustomDatePicker from "../formularios/CustomDatePicker";
import { tabelaInteressadosMPO } from "../formularios/dadosMockados";
import { SelectCidade } from "../formularios/fields/SelectCidade";
import { SelectUF } from "../formularios/fields/SelectUF";
import TipoDeArquivoModal from "../modals/TipoDeArquivoModal";
import DefaultTable from "../table/DefaultTable";

const termos = {
  id: "ID",
  habilitacao: "Habilitação",
  email: "E-mail",
  uf: "UF",
  municipio: "Município",
  tipo: "Tipo de questionário respondido",
  dataCadastro: "Data de Cadastro",
};

// Dados fictícios para teste da tabela
const initialData = tabelaInteressadosMPO;

// Retornar a pesquisa vazia
const initialFilterState = {
  cpf: "",
  nome: "",
  uf: "",
  municipio: "",
  dataCadastro: null,
  situacao: "",
  tipo: "",
};

const tabelaColunas = [
  { field: "escolaridade", headerName: "Qualificação" },
  { field: "empregabilidade", headerName: "Empregabilidade" },
  { field: "necessidade_financiamento", headerName: "Microcrédito" },
];

//const keysHidden = ["telefone", "email", "uf", "municipio", "tipo", "dataResposta"];

const AdminConsultarJornadaCidadao = () => {
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);
  const [modal, setModal] = useState(false);

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });

    setSelectedUf(value);
    setSelectedMunicipio("");
    setFilter({ ...filter, ["municipio"]: "" });
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    setSelectedMunicipio(event.target.value);
  };

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        (!filter.dataDoCadastroInicio ||
          !filter.dataDoCadastroFim ||
          dayjs(parceiro.dataCadastro).isBetween(filter.dataDoCadastroInicio, filter.dataDoCadastroFim, "day", "[]")) &&
        (!filter.dataDaUltimaModificacaoInicio ||
          !filter.dataDaUltimaModificacaoFim ||
          dayjs(parceiro.dataUltimaModificacao).isBetween(
            filter.dataDaUltimaModificacaoInicio,
            filter.dataDaUltimaModificacaoFim,
            "day",
            "[]",
          ))
      );
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setSelectedUf("");
    setSelectedMunicipio("");
    applyFilter();
  };
  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            {/* CPF e Nome*/}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                fullWidth
                id="cpf"
                name="cpf"
                label="CPF"
                placeholder="CPF"
                value={filter.cpf}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                fullWidth
                id="nome"
                name="nome"
                label="Nome"
                placeholder="Nome"
                value={filter.nome}
                onChange={handleFilterChange}
              />
            </Grid>

            {/* UF e Município */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectUF
                idSelect="uf"
                nameSelect="uf"
                value={filter.uf}
                ufs={ufs}
                uf={selectedUf}
                label="* UF"
                handleSelectUf={event => handleSelectUf(event)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectCidade
                idSelect="municipio"
                nameSelect="municipio"
                value={filter.municipio}
                cidades={municipios}
                cidade={selectedMunicipio}
                label="* Município"
                handleSelectCidade={event => handleSelectMunicipio(event)}
              />
            </Grid>

            {/* Data */}
            <Grid
              item
              xs={4}
            >
              <CustomDatePicker
                label="Data de Cadastro"
                id="dataCadastro"
                name="dataCadastro"
                value={filter.dataCadastro}
                format="DD/MM/YYYY"
                onChange={valor => setFilter({ ...filter, ["dataCadastro"]: valor })}
              />
            </Grid>

            {/* Situação dos Interessados e Tipo de Questionário*/}
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-tipo">Tipo de Questionário Respondido</InputLabel>
                <Select
                  id="tipo"
                  name="tipo"
                  value={filter.tipo || ""}
                  type="text"
                  onChange={handleFilterChange}
                  labelId="label-tipo"
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione uma opção
                  </MenuItem>
                  {initialData.map(usuario => (
                    <MenuItem
                      key={usuario.id + usuario.tipo}
                      value={usuario.tipo}
                    >
                      {usuario.tipo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <TextField
                fullWidth
                id="telefone"
                name="telefone"
                label="Telefone"
                placeholder="Telefone"
                value={filter.telefone}
                onChange={handleFilterChange}
              />
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado interessados!"}
              columns={tabelaColunas}
              termos={termos}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      <div>
        <TipoDeArquivoModal
          showModal={modal}
          handleClose={() => setModal(false)}
          dados={filteredData}
          tipo={"Consulta"}
        />
      </div>
    </>
  );
};

export default AdminConsultarJornadaCidadao;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormInscricaoParceiro from "../../components/formularios/empresaParceira/FormInscricaoParceiro";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ConfirmacaoModal from "../../components/modals/ConfirmacaoModal";
import ErroModal from "../../components/modals/ErroModal";
import InformacoesComplementaresModal from "../../components/modals/FormInformacoesComplementaresModal";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroEmpresaUtils";

const EditarParceiro = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [confirmacaoModal, setConfirmacaoModal] = useState(false);
  const [erroModal, setErroModal] = useState(false);

  // Estado inicial das informações complementares
  const [initialInformacoesComplementares, setInitialInformacoesComplementares] = useState(null);
  const [informacoesComplementares, setInformacoesComplementares] = useState(null);
  const [informacoesComplementaresModal, setInformacoesComplementaresModal] = useState(false);
  const hasInformacoesComplementares = !!informacoesComplementares;

  const conteudoConfirmacaoModal = (
    <>
      <center>
        {
          "Atualização realizada com sucesso e enviada para aprovação da Secretaria de Inclusão Socioeconômica do Ministério do  Desenvolvimento e Assistência Social, Família e Combate à Fome."
        }
      </center>
      <center>
        {"Assim que sua atualização for validada, você será informado da aprovação por e-mail e poderá continuar a oferta de serviços."}
      </center>
    </>
  );

  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresaParceira", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return parceiroService.getEmpresaData(id);
    },
    enabled: !!id,
  });

  // Query só vai rodar caso a empresa tenha valores de informações complementares
  const { data: initialDataComplementar } = useQuery({
    queryKey: ["empresaComplementar", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return parceiroService.getEmpresaComplementar(id);
    },
    // A segunda query só será habilitada quando initialData estiver disponível e empresaComplementar for true
    enabled: !!id && !!initialData?.empresaComplementar,
  });

  useEffect(() => {
    if (initialDataComplementar && informacoesComplementares === null) {
      // console.log("Já tem empresa complementar");
      setInformacoesComplementares(initialDataComplementar);
      setInitialInformacoesComplementares(initialDataComplementar); // Guarda o estado inicial para comparação futura
    }
  }, [initialDataComplementar, informacoesComplementares, initialData]);

  // Função para verificar se houve mudanças
  const hasChangedInformacoesComplementares = () => {
    return JSON.stringify(informacoesComplementares) !== JSON.stringify(initialInformacoesComplementares);
  };

  const handleSubmit = async values => {
    setLoading(true);

    if (
      values.checkVagaEmprego ||
      values.checkVagaEstagio ||
      values.checkVagaJovem ||
      values.checkCursos ||
      values.checkFinanceiro ||
      values.checkMobilidadePublico ||
      values.checkMobilidadeParceiro
    ) {
      const editParceiro = transformaParaBackend(values);
      // console.log("Edit PARCEIRO -> ", editParceiro);
      // console.log("Financeiro? ", values.checkFinanceiro);

      // Verifica se a situação do parceiro é diferente de "Pendente"
      if (initialData?.situacao !== "Pendente") {
        // Pode atualizar a empresa parceira
        if (values.checkFinanceiro) {
          submitInformacoesComplementares(informacoesComplementares, id);
        }
      }

      return parceiroService
        .atualizaEmpresaParceira(id, editParceiro)
        .then(mensagem => {
          console.log("Sucesso:", mensagem);
          setConfirmacaoModal(true);
        })
        .catch(erro => {
          console.error("Erro ao atualizar empresa parceira", erro);
          setErroModal(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      alert("Por favor, selecione pelo um tipo de serviço para o parceiro");
      console.error("Há campos em branco");
    }
  };

  const handleConfirmacaoClose = () => {
    setConfirmacaoModal(false);
    navigate("/empresa/listar");
  };

  const submitInformacoesComplementares = async (values, id) => {
    const dados = {
      empresa_parceira: parseInt(id, 10),
      ...values,
    };

    // Verifica se houve mudanças antes de atualizar
    if (!hasChangedInformacoesComplementares()) {
      console.log("Nenhuma alteração detectada, evitando atualização.");
      return;
    }

    // Checa se já existe empresa complementar ao submeter
    const possuiEmpresaComplementar = !!initialData?.empresaComplementar;

    if (possuiEmpresaComplementar) {
      console.log("Atualizando empresa complementar");
      return parceiroService
        .atualizaEmpresaComplementar(id, dados)
        .then(mensagem => {
          console.log("Sucesso:", mensagem);
          alert("Operação realizada com sucesso!");
        })
        .catch(erro => {
          alert("Erro ao realizar operação!\n", erro);
        });
    } else {
      console.log("Criando empresa complementar");
      return parceiroService
        .postCadastrarEmpresaComplementar(dados)
        .then(mensagem => {
          console.log("Sucesso:", mensagem);
          alert("Operação realizada com sucesso!");
        })
        .catch(erro => {
          alert("Erro ao realizar operação!\n", erro);
        });
    }
  };

  const handleInformacoesComplementaresModalOpen = () => {
    setInformacoesComplementaresModal(true);
  };

  const handleInformacoesComplementaresModalClose = () => {
    setInformacoesComplementaresModal(false);
  };

  // Método para apenas salvar as informações da empresa complementar
  const handleSaveInformacoesComplementares = values => {
    setInformacoesComplementares(values);
    setInformacoesComplementaresModal(false);
  };

  const handleDeleteInformacoesComplementares = () => {
    setInformacoesComplementares(null);
    handleInformacoesComplementaresModalClose();
  };

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Rede" },
          { href: "/empresa/listar", text: "Listar Parceiros" },
        ]}
        currentPage="Editar Parceiro"
      />

      <TitlePage title={"Editar Parceiro"} />

      {isPending || loading ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <FormInscricaoParceiro
          initialValues={initialData}
          onSubmit={handleSubmit}
          hasInformacoesComplementares={hasInformacoesComplementares}
          handleInformacoesComplementares={handleInformacoesComplementaresModalOpen}
        />
      )}

      <InformacoesComplementaresModal
        showModal={informacoesComplementaresModal}
        handleContinuar={handleInformacoesComplementaresModalOpen}
        handleClose={handleInformacoesComplementaresModalClose}
        handleDeletar={handleDeleteInformacoesComplementares}
        handleSubmit={handleSaveInformacoesComplementares}
        initialValues={informacoesComplementares}
      />

      <ConfirmacaoModal
        showModal={confirmacaoModal}
        handleClose={handleConfirmacaoClose}
        conteudo={conteudoConfirmacaoModal}
      />
      <ErroModal
        showModal={erroModal}
        handleClose={() => setErroModal(false)}
      />

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default EditarParceiro;

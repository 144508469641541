import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminUsuario from "../../components/formularios/AdminUsuario";
import { tabelaUsuarios } from "../../components/formularios/dadosMockados";
import TitlePage from "../../components/title-page/TitlePage";

const initialValues = {
  dataUltimaModificacao: null,
  nome: "",
  descricao: "",
  situacao: "none",
};

const PageEditarUsuario = () => {
  const [valores, setValores] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(true);
  const usuarioID = JSON.parse(localStorage.getItem("usuarioID"));

  useEffect(() => {
    if (usuarioID) {
      const usuarioEncontrado = tabelaUsuarios.find(usuario => usuario.id === usuarioID);

      if (usuarioEncontrado) {
        setValores(usuarioEncontrado);
      }
    }
    setIsLoading(false);
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/usuarios", text: "Usuário" }]}
        currentPage="Editar"
      />

      <TitlePage title={"Editar Usuário"} />

      {isLoading ? (
        <div>Carregando...</div>
      ) : valores ? (
        <AdminUsuario
          editar={true}
          valores={valores}
          setValores={setValores}
        />
      ) : (
        <div>Usuário não encontrado</div>
      )}
    </Container>
  );
};

export default PageEditarUsuario;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormInscricaoParceiro from "../../components/formularios/empresaParceira/FormInscricaoParceiro";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ConfirmacaoModal from "../../components/modals/ConfirmacaoModal";
import ErroModal from "../../components/modals/ErroModal";
import InformacoesComplementaresModal from "../../components/modals/FormInformacoesComplementaresModal";
import TitlePage from "../../components/title-page/TitlePage";
import { useAuth } from "../../contexts/AuthContext";
import parceiroService from "../../services/parceiro.service";
import transformDadosEmpresaComplementar from "../../utils/parceiroEmpresaComplementarUtils";
import { transformaParaBackend } from "../../utils/parceiroEmpresaUtils";

const valoresIniciais = {
  email: "",
  cnpj: "",
  razaoSocial: "",
  nomeFantasia: "",
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  uf: "",
  cidade: "",
  telefone: "",
  site: "",
  redesSociais: [""],
  nomeRepresentante: "",
  cpfRepresentante: "",
  telefoneRepresentante: "",
  ufRepresentante: "",
  cidadeRepresentante: "",
  nomePontoFocal: "",
  emailPontoFocal: "",
  telefonePontoFocal: "",
  areaAtuacao: "",
  naturezaJuridica: "",
  naturezaJuridicaCodigo: "",
  tipo: "",
  porte: "",
  checkVagaEmprego: false,
  checkVagaEstagio: false,
  checkVagaJovem: false,
  checkCursos: false,
  checkFinanceiro: false,
  checkMobilidadePublico: false,
  checkMobilidadeParceiro: false,
  toggleCienteNormas: false,
  toggleCienteGratuito: false,
};

const PageNovaInscricaoParceiro = () => {
  const { user, token, getAuthUser } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [confirmacaoModal, setConfirmacaoModal] = useState(false);
  const [erroModal, setErroModal] = useState(false);

  const [informacoesComplementares, setInformacoesComplementares] = useState(null);
  const [informacoesComplementaresModal, setInformacoesComplementaresModal] = useState(false);
  const hasInformacoesComplementares = !!informacoesComplementares;

  const conteudoConfirmacaoModal = (
    <>
      <center>
        {
          "Cadastro realizado com sucesso e enviado para aprovação da Secretaria de Inclusão Socioeconômica do Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome."
        }
      </center>
      <center>
        {"Assim que seu cadastro for validado, você será informado da aprovação por e-mail e poderá iniciar a oferta de serviços."}
      </center>
    </>
  );

  const dadosIniciais = {
    ...valoresIniciais,
    email: user.ds_email,
    nomeRepresentante: user.no_usuario,
    cpfRepresentante: user.nu_cpf,
    telefoneRepresentante: user.telefone,
    ufRepresentante: user.uf,
    cidadeRepresentante: user.cidade,
  };

  const handleSubmit = async values => {
    setLoading(true);
    if (
      values.checkVagaEmprego ||
      values.checkVagaEstagio ||
      values.checkVagaJovem ||
      values.checkCursos ||
      values.checkFinanceiro ||
      values.checkMobilidadePublico ||
      values.checkMobilidadeParceiro
    ) {
      const novoParceiro = transformaParaBackend(values);
      // console.log("NOVO PARCEIRO -> ", novoParceiro);
      // console.log("Financeiro? ", values.checkFinanceiro);

      return parceiroService
        .postCadastrarEmpresa(novoParceiro)
        .then(id => {
          if (values.checkFinanceiro) {
            submitInformacoesComplementares(informacoesComplementares, id);
          }
          setConfirmacaoModal(true);
          getAuthUser(token);
        })
        .catch(erro => {
          console.error("Erro ao cadastrar empresa parceira", erro);
          setErroModal(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      alert("Por favor, selecione pelo um tipo de serviço para o parceiro");
      console.error("Há campos em branco");
    }
  };

  const handleConfirmacaoClose = () => {
    setConfirmacaoModal(false);
    navigate("/empresa/listar");
  };

  const handleInformacoesComplementaresModalOpen = () => {
    setInformacoesComplementaresModal(true);
  };

  const handleInformacoesComplementaresModalClose = () => {
    setInformacoesComplementaresModal(false);
  };

  const submitInformacoesComplementares = async (values, id) => {
    const dados = {
      empresa_parceira: parseInt(id, 10),
      ...values,
    };

    return parceiroService
      .postCadastrarEmpresaComplementar(dados)
      .then(mensagem => {
        console.log("Sucesso:", mensagem);
        // setConfirmacaoModal(true);
        alert("Operação realizada com sucesso!");
      })
      .catch(erro => {
        console.error(" ", erro);
        // setErroModal(true);
        alert("Erro ao realizar operação!");
      })
      .finally(() => {
        // setSubmitting(false);
        // setLoading(false);
      });
  };

  // Método para apenas salvar as informações da empresa complementar
  const handleSaveInformacoesComplementares = values => {
    setInformacoesComplementares(values);
    handleInformacoesComplementaresModalClose();
  };

  const handleDeleteInformacoesComplementares = () => {
    setInformacoesComplementares(null);
    handleInformacoesComplementaresModalClose();
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Rede" },
          { href: "/empresa/listar", text: "Parceiro" },
        ]}
        currentPage="Nova inscrição"
      />

      <TitlePage title={"Nova inscrição"} />

      {loading ? (
        <LoadingComponent
          isLoading={loading}
          fullScreen={false}
        />
      ) : (
        <FormInscricaoParceiro
          initialValues={dadosIniciais}
          onSubmit={handleSubmit}
          hasInformacoesComplementares={hasInformacoesComplementares}
          handleInformacoesComplementares={handleInformacoesComplementaresModalOpen}
        />
      )}

      <InformacoesComplementaresModal
        showModal={informacoesComplementaresModal}
        handleContinuar={handleInformacoesComplementaresModalOpen}
        handleClose={handleInformacoesComplementaresModalClose}
        handleDeletar={handleDeleteInformacoesComplementares}
        handleSubmit={handleSaveInformacoesComplementares}
        initialValues={informacoesComplementares ? transformDadosEmpresaComplementar(informacoesComplementares) : null}
      />

      <ConfirmacaoModal
        showModal={confirmacaoModal}
        handleClose={handleConfirmacaoClose}
        conteudo={conteudoConfirmacaoModal}
      />
      <ErroModal
        showModal={erroModal}
        handleClose={() => setErroModal(false)}
      />
    </Container>
  );
};

export default PageNovaInscricaoParceiro;

import React from "react";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SchoolIcon from "@mui/icons-material/School";
import { Button, Grid } from "@mui/material";

const services = [
  { name: "Cursos", icon: <SchoolIcon /> },
  { name: "Empreendedorismo", icon: <RocketLaunchIcon /> },
  { name: "Microcrédito", icon: <AttachMoneyIcon /> },
  { name: "Trabalho", icon: <BusinessCenterIcon /> },
  { name: "Estágios", icon: <ManageAccountsIcon /> },
  { name: "Jovem Aprendiz", icon: <PersonAddIcon /> },
];

const CardServicos = () => {
  return (
    <Grid
      container
      spacing={1}
      justifyContent="flex-start"
    >
      {services.map((service, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={"auto"}
        >
          <Button
            startIcon={service.icon}
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
          >
            {service.name}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardServicos;

import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, FormGroup, Grid } from "@mui/material";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";
import ButtonSecondary from "../buttons/ButtonSecondary";
import { SelectAtuacaoParceiro } from "./fields/SelectAtuacaoParceiro.js";

const FormMultipleInsertSelect = ({ label, placeHolder, onFieldsChange, list, visualizacao = false, initialValue = [] }) => {
  const defaultFields =
    (initialValue ?? []).length > 0
      ? initialValue.map((value, index) => ({ id: Date.now() + index, value }))
      : [{ id: Date.now(), value: "" }];

  const [fields, setFields] = useState(defaultFields);

  const smDown = useResponsive("down", "sm");

  const handleAddField = () => {
    const newFields = [...fields, { id: Date.now(), value: "" }];
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const handleRemoveField = id => {
    const newFields = fields.filter(field => field.id !== id);
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const handleChange = (id, newValue) => {
    const newFields = fields.map(field => (field.id === id ? { ...field, value: newValue } : field));
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const allFieldsFilled = () => {
    return fields.every(field => field.value.trim() !== "");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, mt: 1 }}>
      <Grid
        container
        spacing={2}
        pl={2}
      >
        <Grid
          container
          spacing={2}
          key={fields[0].id}
        >
          <Grid
            item
            xs={visualizacao ? 12 : 10}
            sx={{ mt: 1 }}
          >
            <FormGroup>
              <SelectAtuacaoParceiro
                idSelect={"id_" + label + "_0"}
                nameSelect={"name_" + label + "_0"}
                handleSelect={event => handleChange(fields[0].id, event.target.value)}
                list={list}
                item={fields[0].value}
                label={label}
                placeholder={placeHolder}
                visualizacao={visualizacao}
              />
            </FormGroup>
          </Grid>

          {!visualizacao && (
            <Grid
              item
              xs={2}
              sx={{ mt: 1 }}
              style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
            >
              {allFieldsFilled() && (
                <ButtonSecondary
                  title={smDown ? "" : "adicionar"}
                  icon={<AddCircleIcon />}
                  onClick={handleAddField}
                />
              )}
            </Grid>
          )}
        </Grid>

        {fields.slice(1).map((field, index) => (
          <Grid
            container
            spacing={2}
            key={field.id}
          >
            <Grid
              item
              xs={visualizacao ? 12 : 10}
              sx={{ mt: 1 }}
            >
              <FormGroup>
                <SelectAtuacaoParceiro
                  idSelect={"id_" + label + "_" + (index + 1)}
                  nameSelect={"name_" + label + "_" + (index + 1)}
                  handleSelect={event => handleChange(field.id, event.target.value)}
                  list={list}
                  item={field.value}
                  label={label}
                  placeholder={placeHolder}
                  visualizacao={visualizacao}
                />
              </FormGroup>
            </Grid>
            {!visualizacao && (
              <Grid
                item
                xs={2}
                sx={{ mt: 1 }}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
              >
                <ButtonSecondary
                  title={smDown ? "" : "remover"}
                  icon={<RemoveCircleIcon />}
                  onClick={() => handleRemoveField(field.id)}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

FormMultipleInsertSelect.propTypes = {
  initialValue: PropTypes.array,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  onFieldsChange: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  visualizacao: PropTypes.bool,
};

export default FormMultipleInsertSelect;

import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";

const CardPrimario = ({ title, content, subContent, imageUrl, imageWidth, imageHeight }) => {
  const theme = useTheme();
  const smUp = useResponsive("up", "sm");

  imageWidth = imageWidth ? imageWidth : smUp ? "300px" : "225px";
  imageHeight = imageHeight ? imageHeight : smUp ? "200px" : "150px";

  return (
    <div style={{ paddingTop: smUp ? "60px" : "1rem", overflowY: "hidden", display: "flex" }}>
      <div
        style={{
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: theme.palette.border.card,
          borderRadius: "0px",
          width: "100%",
          display: "flex",
          flexDirection: smUp ? "Row" : "column",
          alignItems: smUp ? "center" : "flex-start",
          minHeight: "calc( " + imageHeight + " - 60px)",
        }}
      >
        <div style={{ width: smUp ? "calc(100% - " + imageWidth + ")" : "100%", padding: "12px" }}>
          <Typography
            variant="h2"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            {title}
          </Typography>

          <Typography
            variant="body1"
            style={{ transition: "1s" }}
            sx={{
              fontWeight: 600,
              lineHeight: "normal",
              fontFamily: "Rawline Medium",
              color: theme.palette.text.primary,
            }}
          >
            {content}
          </Typography>
          <Typography
            variant="body1"
            style={{ transition: "1s" }}
            sx={{
              fontWeight: 600,
              lineHeight: "normal",
              fontFamily: "Rawline Thin",
              color: theme.palette.text.primary,
            }}
          >
            {subContent}
          </Typography>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img
            alt="Imagem do Card"
            height={imageHeight}
            src={imageUrl}
            style={{
              objectFit: "fill",
              width: imageWidth,
              marginTop: smUp ? "-40px" : "0px",
              overflowY: "hidden",
            }}
          />
        </div>
      </div>
    </div>
  );
};

CardPrimario.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  subContent: PropTypes.string,
};

export default CardPrimario;

import React from "react";
import { useForm } from "react-hook-form";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import ReusableSwitch from "../../components/formularios/fields/ReusableSwitch";
import FormMultipleInsertDouble from "../../components/formularios/FormMultipleInsertDouble";
import { escolaridadeBanco } from "../../services/constants.js";
import { payloadJaSouEmpreendedor } from "../../utils/formularioJaSouEmpreendedorUtils.js";
import InformationBox from "../boxes/InformationBox";
import CustomDatePicker from "./CustomDatePicker";
import { FormFieldsGrid } from "./DynamicForm.js";
import FormAddress from "./FormAddress.js";
import { formatCPF, formatTelefone, validarCPF } from "./utils.js";

const valoresIniciais = {
  atividadeVariacaoNegocio: "",
  interesseVariarAtuacao: false,
  checkDinheiroProprio: false,
  checkFamiliaresAmigos: false,
  checkFaturamentoProprio: false,
  checkNenhum: false,
  controleEntradas: false,
  separaCompras: false,
  checkComercioLocal: false,
  checkInternet: false,
  checkGrandesFornecedores: false,
  checkOutrosMeios: false,
  precisouFinanciamento: false,
  calculaCustoFixo: false,
  fazAlgoReduzirCusto: false,
  radioLucro: false,
  tempoNegocio: "",
  // NOTA: Nao sei porque no backend esse campo esta esperando um booleano
  comoVeNegocio3Anos: false,
  checkBoca: false,
  checkRadio: false,
  checkCartazPanfleto: false,
  checkJornal: false,
  checkWhatsApp: false,
  checkCarroSom: false,
  checkFacebook: false,
  checkOutros: false,
  porqueConsumidorDeveriaComprar: "",
  checkDinheiro: false,
  checkCursoCapacitacao: false,
  checkMaqMoveisEquip: false,
  checkOrientacao: false,
  checkReforma: false,
  checkOutrosCrescer: false,
  radioSituacao: false,
  cnpj: "",
  gostariaReceberOrientacaoAdministracao: false,
  jaPensouDesistir: false,
  checkSemEmprego: false,
  checkComplementarRendar: false,
  checkProprioPatrao: false,
  checkRealizarSonho: false,
  checkProjetoFamiliar: false,
  checkGostoEmpreender: false,
  temAlgumFuncionario: false,
  participouAlgumCurso: false,
  jaParticipouCursoDetalhes: [],
  gostariaParticiparCurso: false,
  motivoParticiparCurso: "",
  gostariaReceberOrientacao: false,
  motivoReceberOrientacao: "",
  necessidadeFinanciamento: false,
  motivoNecessidadeFinanciamento: "",
  conheceEmprestimos: false,
  radioLocalNegocio: false,
  nomeCompleto: "",
  dataNascimento: "",
  rg: "",
  cpf: "",
  generoRadio: "",
  escolaridade: "",
  enderecoCompleto: [
    {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      uf: "",
      cidade: "",
      telefone: "",
    },
  ],
  telefone: "",
  email: "",
};

const FormJaSouEmpreendedor = ({ initialValues = valoresIniciais, visualizacao = false, submit }) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    // setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const handleKeyDown = event => {
    // Impedir que o formularário seja enviado ao pressionar a tecla Enter
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const fieldsAtividadeVariacao = [
    {
      // NOTA: esse campo provavelmente devia ser um select de uma lista de códigos
      name: "atividadeVariacaoNegocio",
      label: "Qual a atividade do seu negócio?",
      validation: {
        maxLength: {
          value: 7,
          message: "O tamanho máximo é 7 caracteres",
        },
      },
      renderComponent: ({ value, onChange }) => (
        <TextField
          label="Qual a atividade do seu negócio?"
          id="atividadeVariacaoNegocio"
          name="atividadeVariacaoNegocio"
          value={value || ""}
          type="text"
          onChange={onChange}
          disabled={visualizacao}
          inputProps={{ maxLength: 7 }}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "interesseVariarAtuacao",
      label: "Você tem interesse em variar a linha de atuação do seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você tem interesse em variar a linha de atuação do seu negócio?</Typography>
          <ReusableSwitch
            id="interesseVariarAtuacao"
            name="interesseVariarAtuacao"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsGestaoFinanceira = [
    {
      name: "checkDinheiroProprio",
      label: "Dinheiro próprio",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">Qual(is) a(s) fonte(s) de recursos para manutenção do seu negócio?</Typography>
          <FormControl error={!!error}>
            <FormControlLabel
              control={
                <Checkbox
                  id="checkDinheiroProprio"
                  name="checkDinheiroProprio"
                  checked={value ?? false}
                  onChange={onChange}
                  style={{ color: theme.palette.text.disabled }}
                  disabled={visualizacao}
                />
              }
              label={<Typography>Dinheiro próprio</Typography>}
            />
          </FormControl>
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkFamiliaresAmigos",
      label: "Dinheiro de Familiares e amigos",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkFamiliaresAmigos"
                name="checkFamiliaresAmigos"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Dinheiro de Familiares e amigos</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkFaturamentoProprio",
      label: "Faturamento do próprio negócio",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkFaturamentoProprio"
                name="checkFaturamentoProprio"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Faturamento do próprio negócio</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkNenhum",
      label: "Nenhuma",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkNenhum"
                name="checkNenhum"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Nenhuma</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "controleEntradas",
      label: "Você tem controle de entradas e saídas de dinheiro do seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você tem controle de entradas e saídas de dinheiro do seu negócio?</Typography>
          <ReusableSwitch
            id="controleEntradas"
            name="controleEntradas"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "separaCompras",
      label: "Você separa as compras do seu negócio das que você faz para sua família?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você separa as compras do seu negócio das que você faz para sua família?</Typography>
          <ReusableSwitch
            id="separaCompras"
            name="separaCompras"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkComercioLocal",
      label: "Compras no comércio local",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">Como você faz a compra de produtos e serviços para seu negócio?</Typography>
          <FormControl error={!!error}>
            <FormControlLabel
              control={
                <Checkbox
                  id="checkComercioLocal"
                  name="checkComercioLocal"
                  checked={value ?? false}
                  onChange={onChange}
                  style={{ color: theme.palette.text.disabled }}
                  disabled={visualizacao}
                />
              }
              label={<Typography>Compras no comércio local</Typography>}
            />
          </FormControl>
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkInternet",
      label: "Compras via internet",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkInternet"
                name="checkInternet"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Compras via internet</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkGrandesFornecedores",
      label: "Compras de grandes fornecedores",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkGrandesFornecedores"
                name="checkGrandesFornecedores"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Compras de grandes fornecedores</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkOutrosMeios",
      label: "Outros meios",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkOutrosMeios"
                name="checkOutrosMeios"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Outros meios</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "precisouFinanciamento",
      label: "Você já precisou de financiamento/empréstimo para melhorar seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você já precisou de financiamento/empréstimo para melhorar seu negócio?</Typography>
          <ReusableSwitch
            id="precisouFinanciamento"
            name="precisouFinanciamento"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "calculaCustoFixo",
      label: "Você calcula o custo fixo e variável por unidade do produto/serviço prestado?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você calcula o custo fixo e variável por unidade do produto/serviço prestado?</Typography>
          <ReusableSwitch
            id="calculaCustoFixo"
            name="calculaCustoFixo"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "fazAlgoReduzirCusto",
      label: "Você faz algo para reduzir custos/despesas?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você faz algo para reduzir custos/despesas?</Typography>
          <ReusableSwitch
            id="fazAlgoReduzirCusto"
            name="fazAlgoReduzirCusto"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "radioLucro",
      label: "Você sabe dizer quanto você produz por mês?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você sabe dizer quanto você produz por mês?</Typography>
          <FormGroup>
            <RadioGroup
              name="radioLucro"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="SCL"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "SCL"}
                    disabled={visualizacao}
                  />
                }
                label="Sim, e meu negócio dá lucro"
              />
              <FormControlLabel
                value="SSL"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "SSL"}
                    disabled={visualizacao}
                  />
                }
                label="Sim, mas não tenho lucro"
              />
              <FormControlLabel
                value="NAO"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "NAO"}
                    disabled={visualizacao}
                  />
                }
                label="Não"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
  ];

  const fieldsTempoNegocio = [
    {
      name: "tempoNegocio",
      label: "Há quanto tempo você tem esse negócio?",
      renderComponent: ({ value, onChange }) => (
        <TextField
          label="Há quanto tempo você tem esse negócio?"
          id="tempoNegocio"
          name="tempoNegocio"
          value={value || ""}
          type="number"
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    {
      // NOTA: Não sei porque no backend esse campo está esperando um booleano
      name: "comoVeNegocio3Anos",
      label: "Como você vê seu negócio daqui a 3 anos?",
      renderComponent: ({ value, onChange }) => (
        // <TextField
        //   label="Como você vê seu negócio daqui a 3 anos?"
        //   id="comoVeNegocio3Anos"
        //   name="comoVeNegocio3Anos"
        //   value={value || ""}
        //   type="text"
        //   onChange={onChange}
        //   disabled={visualizacao}
        //   fullWidth
        // />
        <ReusableSwitch
          id="comoVeNegocio3Anos"
          name="comoVeNegocio3Anos"
          value={value ?? false}
          onChange={onChange}
          disabled={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsMarketingVendas = [
    {
      name: "checkBoca",
      label: "Boca a boca",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">Como você faz a propaganda do seu negócio?</Typography>
          <FormControl error={!!error}>
            <FormControlLabel
              control={
                <Checkbox
                  id="checkBoca"
                  name="checkBoca"
                  checked={value ?? false}
                  onChange={onChange}
                  style={{ color: theme.palette.text.disabled }}
                  disabled={visualizacao}
                />
              }
              label={<Typography>Boca a boca</Typography>}
            />
          </FormControl>
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkRadio",
      label: "Rádio",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkRadio"
                name="checkRadio"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Rádio</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkCartazPanfleto",
      label: "Cartaz / panfleto / cartão",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkCartazPanfleto"
                name="checkCartazPanfleto"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Cartaz / panfleto / cartão</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkJornal",
      label: "Jornal",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkJornal"
                name="checkJornal"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Jornal</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkWhatsApp",
      label: "WhatsApp",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkWhatsApp"
                name="checkWhatsApp"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>WhatsApp</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkCarroSom",
      label: "Carro de som",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkCarroSom"
                name="checkCarroSom"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Carro de som</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkFacebook",
      label: "Facebook",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkFacebook"
                name="checkFacebook"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Facebook</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkOutros",
      label: "Outros",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkOutros"
                name="checkOutros"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Outros</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "porqueConsumidorDeveriaComprar",
      label: "Por que o consumidor deveria comprar seu produto ou serviço?",
      renderComponent: ({ value, onChange }) => (
        <TextField
          label="Por que o consumidor deveria comprar seu produto ou serviço?"
          id="porqueConsumidorDeveriaComprar"
          name="porqueConsumidorDeveriaComprar"
          value={value || ""}
          onChange={onChange}
          disabled={visualizacao}
          rows={3}
          minRows={3}
          maxRows={6}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsCrescimentoDev = [
    {
      name: "checkDinheiro",
      label: "Dinheiro para investir",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">O que seu negócio precisa para crescer?</Typography>
          <FormControl error={!!error}>
            <FormControlLabel
              control={
                <Checkbox
                  id="checkDinheiro"
                  name="checkDinheiro"
                  checked={value ?? false}
                  onChange={onChange}
                  style={{ color: theme.palette.text.disabled }}
                  disabled={visualizacao}
                />
              }
              label={<Typography>Dinheiro para investir</Typography>}
            />
          </FormControl>
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkCursoCapacitacao",
      label: "Cursos / qualificação",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkCursoCapacitacao"
                name="checkCursoCapacitacao"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Cursos / qualificação</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkMaqMoveisEquip",
      label: "Máquinas / móveis / equipamentos",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkMaqMoveisEquip"
                name="checkMaqMoveisEquip"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Máquinas / móveis / equipamentos</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkOrientacao",
      label: "Orientações de especialistas",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkOrientacao"
                name="checkOrientacao"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Orientações de especialistas</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkReforma",
      label: "Reforma / construção",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkReforma"
                name="checkReforma"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Reforma / construção</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkOutrosCrescer",
      label: "Outros",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkOutrosCrescer"
                name="checkOutrosCrescer"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Outros</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "radioSituacao",
      label: "Qual a situação do seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Qual a situação do seu negócio?</Typography>
          <FormGroup>
            <RadioGroup
              name="radioSituacao"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="informal"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "informal"}
                    disabled={visualizacao}
                  />
                }
                label="Informal"
              />
              <FormControlLabel
                value="microempresa"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "microempresa"}
                    disabled={visualizacao}
                  />
                }
                label="Formal – microempresa"
              />
              <FormControlLabel
                value="outros-portes"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "outros-portes"}
                    disabled={visualizacao}
                  />
                }
                label="Formal – Outros portes"
              />
              <FormControlLabel
                value="mei"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "mei"}
                    disabled={visualizacao}
                  />
                }
                label="Formal – Microempreendedor Individual – MEI"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "cnpj",
      label: "CNPJ",
      renderComponent: ({ value, onChange }) => (
        <TextField
          label="CNPJ"
          id="cnpj"
          name="cnpj"
          value={value || ""}
          type="text"
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaReceberOrientacaoAdministracao",
      label: "Você gostaria de receber orientação sobre como administrar seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você gostaria de receber orientação sobre como administrar seu negócio?</Typography>
          <ReusableSwitch
            id="gostariaReceberOrientacaoAdministracao"
            name="gostariaReceberOrientacaoAdministracao"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsAspectos = [
    {
      name: "jaPensouDesistir",
      label: "Já pensou em desistir do negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Já pensou em desistir do negócio?</Typography>
          <ReusableSwitch
            id="jaPensouDesistir"
            name="jaPensouDesistir"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkSemEmprego",
      label: "Não tenho emprego, preciso de alguma atividade que me gere renda",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <Typography variant="subtitle2">Por que você tem o seu próprio negócio?</Typography>
          <FormControl error={!!error}>
            <FormControlLabel
              control={
                <Checkbox
                  id="checkSemEmprego"
                  name="checkSemEmprego"
                  checked={value ?? false}
                  onChange={onChange}
                  style={{ color: theme.palette.text.disabled }}
                  disabled={visualizacao}
                />
              }
              label={<Typography>Não tenho emprego, preciso de alguma atividade que me gere renda</Typography>}
            />
          </FormControl>
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkComplementarRendar",
      label: "Apesar de estar empregado, é um complemento da minha renda",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkComplementarRendar"
                name="checkComplementarRendar"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Apesar de estar empregado, é um complemento da minha renda</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkProprioPatrao",
      label: "Quero ser meu próprio patrão, cansei de estar subordinado a um chefe",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkProprioPatrao"
                name="checkProprioPatrao"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Quero ser meu próprio patrão, cansei de estar subordinado a um chefe</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkRealizarSonho",
      label: "É a realização de um sonho",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkRealizarSonho"
                name="checkRealizarSonho"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>É a realização de um sonho</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkProjetoFamiliar",
      label: "É um projeto familiar, precisam da minha participação",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkProjetoFamiliar"
                name="checkProjetoFamiliar"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>É um projeto familiar, precisam da minha participação</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkGostoEmpreender",
      label: "Gosto da experiência de empreender um negócio",
      renderComponent: ({ value, onChange, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkGostoEmpreender"
                name="checkGostoEmpreender"
                checked={value ?? false}
                onChange={onChange}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={<Typography>Gosto da experiência de empreender um negócio</Typography>}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsRecursosHumanos = [
    {
      name: "temAlgumFuncionario",
      label: "Você tem algum funcionário trabalhando no seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você tem algum funcionário trabalhando no seu negócio?</Typography>
          <ReusableSwitch
            id="temAlgumFuncionario"
            name="temAlgumFuncionario"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "participouAlgumCurso",
      label: "Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
          </Typography>
          <ReusableSwitch
            id="participouAlgumCurso"
            name="participouAlgumCurso"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "jaParticipouCursoDetalhes",
      label: "Cursos participados",
      renderComponent: ({ value, onChange }) => (
        <>
          <FormMultipleInsertDouble
            label1={"Qual(is) Curso(s)?"}
            label2={"Qual a Instituição realizadora?"}
            value1={"curso"}
            value2={"instituicao"}
            disabled={visualizacao}
            values={value}
            onFieldsChange={onChange}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaParticiparCurso",
      label:
        "Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para
            melhor gerir o seu negócio?
          </Typography>
          <ReusableSwitch
            id="gostariaParticiparCurso"
            name="gostariaParticiparCurso"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "motivoParticiparCurso",
      label: "Por que motivo?",
      renderComponent: ({ value, onChange }) => (
        <TextField
          label="Por que motivo?"
          id="motivoParticiparCurso"
          name="motivoParticiparCurso"
          value={value || ""}
          type="text"
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "gostariaReceberOrientacao",
      label: "Você gostaria de receber orientação sobre como administrar seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você gostaria de receber orientação sobre como administrar seu negócio?</Typography>
          <ReusableSwitch
            id="gostariaReceberOrientacao"
            name="gostariaReceberOrientacao"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "motivoReceberOrientacao",
      label: "Por que motivo?",
      renderComponent: ({ value, onChange }) => (
        <TextField
          label="Por que motivo?"
          id="motivoReceberOrientacao"
          name="motivoReceberOrientacao"
          value={value || ""}
          type="text"
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsFinancas = [
    {
      name: "necessidadeFinanciamento",
      label: "Você tem necessidade de um financiamento/empréstimo de microcrédito?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Você tem necessidade de um financiamento/empréstimo de microcrédito?</Typography>
          <ReusableSwitch
            id="necessidadeFinanciamento"
            name="necessidadeFinanciamento"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "motivoNecessidadeFinanciamento",
      label: "Por que motivo?",
      renderComponent: ({ value, onChange }) => (
        <TextField
          label="Por que motivo?"
          id="motivoNecessidadeFinanciamento"
          name="motivoNecessidadeFinanciamento"
          value={value || ""}
          type="text"
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "conheceEmprestimos",
      label:
        "Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento
            do empréstimo?
          </Typography>
          <ReusableSwitch
            id="conheceEmprestimos"
            name="conheceEmprestimos"
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsDadosContato = [
    {
      name: "radioLocalNegocio",
      label: "Em que local funciona o seu negócio?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">Em que local funciona o seu negócio?</Typography>
          <FormGroup>
            <RadioGroup
              name="radioLocalNegocio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="residencia"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "residencia"}
                    disabled={visualizacao}
                  />
                }
                label="Em minha residência"
              />
              <FormControlLabel
                value="comercial-alugad"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "comercial-alugad"}
                    disabled={visualizacao}
                  />
                }
                label="Em espaço comercial alugado"
              />
              <FormControlLabel
                value="comercial-proprio"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "comercial-proprio"}
                    disabled={visualizacao}
                  />
                }
                label="Em espaço comercial próprio"
              />
              <FormControlLabel
                value="comercial-cedido"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "comercial-cedido"}
                    disabled={visualizacao}
                  />
                }
                label="Em espaço comercial cedido (sem custo de aluguel)"
              />
              <FormControlLabel
                value="ambulante"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "ambulante"}
                    disabled={visualizacao}
                  />
                }
                label="Ambulante"
              />
              <FormControlLabel
                value="outros"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "outros"}
                    disabled={visualizacao}
                  />
                }
                label="Outros"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "nomeCompleto",
      label: "Nome Completo",
      size: { xs: 12, sm: 6 },
    },
    {
      name: "dataNascimento",
      label: "Data de Nascimento",
      renderComponent: ({ value, onChange }) => (
        <>
          <CustomDatePicker
            label="Data de Nascimento"
            id="dataNascimento"
            name="dataNascimento"
            value={value ? dayjs(value) : null}
            format="DD/MM/YYYY"
            onChange={onChange}
            disabled={visualizacao}
          />
          {/* {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>} */}
        </>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "rg",
      label: "RG",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="* RG"
            id="rg"
            name="rg"
            value={value}
            placeholder="Insira o RG"
            type="number"
            onChange={onChange}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "cpf",
      label: "CPF",
      validation: {
        validate: {
          // required: "CPF  é obrigatório",
          tamanhoValido: value => {
            if (!value || value.trim() === "") {
              return true;
            }
            if (value.replace(/\D/g, "").length !== 11) {
              return "O CPF deve ter 11 dígitos";
            }
            return true;
          },
          cpfValido: value => {
            if (!value || value.trim() === "") {
              return true;
            }
            if (!validarCPF(value)) {
              return "O CPF informado não é válido";
            }
            return true;
          },
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* CPF"
            id="cpf"
            name="cpf"
            value={formatCPF(value)}
            placeholder="Insira o CPF"
            type="text"
            onChange={onChange}
            inputProps={{ maxLength: 14 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "generoRadio",
      label: "Gênero",
      renderComponent: ({ value, onChange }) => (
        <>
          {/* valores de tp_genero */}
          <Typography variant="subtitle2">Gênero</Typography>
          <FormGroup>
            <RadioGroup
              name="generoRadio"
              onChange={onChange}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="FEM"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "FEM"}
                    disabled={visualizacao}
                  />
                }
                label="Feminino"
              />
              <FormControlLabel
                value="MAS"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "MAS"}
                    disabled={visualizacao}
                  />
                }
                label="Masculino"
              />
              <FormControlLabel
                value="NIN"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "NIN"}
                    disabled={visualizacao}
                  />
                }
                label="Prefiro não informar"
              />
            </RadioGroup>
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "escolaridade",
      label: "Escolaridade",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Escolaridade</InputLabel>
            <Select
              id="escolaridade"
              name="escolaridade"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione uma escolaridade
              </MenuItem>

              {Object.keys(escolaridadeBanco).map(escolaridade => (
                <MenuItem
                  key={escolaridade}
                  value={escolaridade}
                >
                  {escolaridadeBanco[escolaridade]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12 },
    },
    {
      name: "enderecoCompleto",
      label: "Endereço",
      renderComponent: ({ value, onChange }) => (
        <>
          <FormAddress
            name="enderecoCompleto"
            values={value}
            onChange={onChange}
          />
        </>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "telefone",
      label: "* Telefone",
      validation: {
        // required: "Telefone é obrigatório",
        validate: {
          tamanhoValido: value => {
            // Se o valor estiver vazio, retorna true (não valida tamanho)
            if (!value || value.trim() === "") {
              return true;
            }
            const tamanho = value.replace(/\D/g, "").length;
            return tamanho === 10 || tamanho === 11 || "O telefone deve ter 10 ou 11 dígitos";
          },
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* Telefone"
            id="telefone"
            name="telefone"
            value={formatTelefone(value)}
            placeholder="Insira o Telefone"
            type="text"
            onChange={onChange}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "email",
      label: "E-mail",
      validation: {
        // required: "E-mail é obrigatório",
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "Insira um e-mail válido",
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <TextField
            label="* E-mail"
            id="email"
            name="email"
            value={value}
            placeholder="Insira o E-mail"
            type="text"
            onChange={onChange}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
  ];

  return (
    <form
      onSubmit={event => {
        event.preventDefault(); // Previne o comportamento padrão do formulário
        console.log("Valores reais do formulario:", getValues());

        // Validar todos os campos antes de submeter
        trigger().then(isValid => {
          if (isValid) {
            // Chama o handleSubmit do React Hook Form, que valida e envia os dados
            handleSubmit(data => {
              const payload = payloadJaSouEmpreendedor(data);
              console.log("Valores enviados para o backend:", payload);
              submit(payload);
            })();
          }
        });

        // Se você quer ver os erros de validação
        console.log("Erros de validação:", errors);
      }}
      onKeyDown={handleKeyDown}
    >
      <div>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Atividade e variação do negócio
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsAtividadeVariacao}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Gestão financeira
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsGestaoFinanceira}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Tempo de negócio e perspectivas futuras
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsTempoNegocio}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Estratégias de marketing e vendas
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsMarketingVendas}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Crescimento e desenvolvimento
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsCrescimentoDev}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Aspectos pessoais e motivações
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsAspectos}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Recursos Humanos e capacitação
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsRecursosHumanos}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Finanças e microcrédito
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsFinancas}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Dados pessoais e de contato
          </AccordionSummary>
          <AccordionDetails>
            <FormFieldsGrid
              fields={fieldsDadosContato}
              control={control}
              errors={errors}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <InformationBox
        title="Atenção:"
        message="Confira todas as respostas antes de clicar no botão 'Salvar Respostas'. Após salvar, não será permitido alterar as respostas."
      />

      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        mt={2}
      >
        <Grid
          item
          xs={12}
          sm={"auto"}
        >
          <Button
            variant="outlined"
            onClick={() => console.log("cancelar")}
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium", textTransform: "uppercase" }}
            fullWidth
          >
            Cancelar
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={"auto"}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            // onClick={() => onSubmit(getValues())}
            fullWidth
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium", textTransform: "uppercase" }}
          >
            <SaveIcon fontSize="small" />
            <Typography variant={"BUTTON TEXT"}>Salvar resposta</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

FormJaSouEmpreendedor.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submit: PropTypes.func,
};

export default FormJaSouEmpreendedor;

import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminParceiroVisualizarCurriculo from "../../components/formularios/AdminParceiroVisualizarCurriculo";
import TitlePage from "../../components/title-page/TitlePage";

const VagasCurriculo = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiros/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiros/visualizar/", text: "Vizualizar Parceiro Pendente" },
          { href: "/vagas/listar/interessadas", text: "Listar Pessoas Interessadas" },
        ]}
        currentPage="Curriculo"
      />

      <TitlePage title={"Visualizar Currículo"} />

      <AdminParceiroVisualizarCurriculo />
    </Container>
  );
};

export default VagasCurriculo;

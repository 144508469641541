import React, { useState } from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroListarParceirosAssociados from "../../components/filtros/ParceiroListarParceirosAssociados";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarParceiros = () => {
  const [informacoesModal, setInformacoesModal] = useState(false);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/listar"
        currentPage="Listar Parceiros"
      />

      <TitlePage title={"Listar Parceiros"} />

      <ParceiroListarParceirosAssociados
        informacoesModal={informacoesModal}
        setInformacoesModal={setInformacoesModal}
      />
    </Container>
  );
};

export default PageListarParceiros;

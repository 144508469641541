import React, { useCallback, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";
import { SelectAtuacaoParceiro } from "./fields/SelectAtuacaoParceiro.js";

const FormExigenciaVagaTrabalho = ({
  onFieldsChange,
  label,
  placeholder,
  list,
  visualizacao = false,
  initialValue = [],
  showInput2 = true,
  showError = false,
}) => {
  const theme = useTheme();

  const defaultFields = (initialValue ?? []).map((valueSet, index) => ({
    id: Date.now() + index,
    input1: valueSet[0] || "",
    input2: valueSet[1] || "",
    input3: valueSet[2] || "",
  }));

  if (defaultFields.length === 0) {
    defaultFields.push({
      id: Date.now(),
      input1: "",
      input2: "",
      input3: "",
    });
  }

  const [fields, setFields] = useState(defaultFields);

  const handleChange = useCallback(
    (id, value, field) => {
      setFields(prevFields => {
        const updatedFields = prevFields.map(f => (f.id === id ? { ...f, [field]: value } : f));
        onFieldsChange(updatedFields);
        return updatedFields;
      });
    },
    [onFieldsChange],
  );

  const handleAddField = () => {
    setFields(prevFields => {
      const newId = prevFields.length > 0 ? prevFields[prevFields.length - 1].id + 1 : 1;
      return [...prevFields, { id: newId, input1: "", input2: "", input3: "" }];
    });
  };

  const handleRemoveField = id => {
    if (fields.length > 1) {
      setFields(prevFields => {
        const updatedFields = prevFields.filter(f => f.id !== id);
        onFieldsChange(updatedFields);
        return updatedFields;
      });
    }
  };

  const handleCheckboxChange = (id, value) => {
    setFields(prevFields => {
      const updatedFields = prevFields.map(f => {
        if (f.id === id) {
          return { ...f, input3: f.input3 === value ? "" : value }; // Toggle logic
        }
        return f;
      });
      onFieldsChange(updatedFields);
      return updatedFields;
    });
  };

  const isMd = useResponsive("up", "sm");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, mt: 1 }}>
      <Grid
        container
        spacing={2}
        px={2}
        mt={1}
      >
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={showInput2 ? 6 : 12}
              >
                <FormGroup>
                  <SelectAtuacaoParceiro
                    idSelect={"id_" + label + "_0"}
                    nameSelect={"name_" + label + "_0"}
                    handleSelect={e => handleChange(field.id, e.target.value, "input1")}
                    list={list}
                    item={field.input1.toString()}
                    label={`${label}`}
                    placeholder={placeholder}
                    visualizacao={visualizacao}
                    error={!field.input1}
                  />
                  {!field.input1 && showError && (
                    <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>Este campo é obrigatório</Typography>
                  )}
                </FormGroup>
              </Grid>

              {/* Conditionally render input 2 */}
              {showInput2 && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormGroup>
                    <SelectAtuacaoParceiro
                      idSelect={"id_" + label + "_1"}
                      nameSelect={"name_" + label + "_1"}
                      handleSelect={e => handleChange(field.id, e.target.value, "input2")}
                      list={list}
                      item={field.input2.toString()}
                      label={"Experiência anterior"}
                      placeholder={"Escolha a experiência anterior"}
                      visualizacao={visualizacao}
                    />
                  </FormGroup>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={6}
              >
                <Stack
                  spacing={1}
                  mt={1}
                >
                  <FormGroup>
                    <Typography>* Situação</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.input3 === "D"}
                          onChange={() => handleCheckboxChange(field.id, "D")}
                          style={{ color: !field.input3 && showError ? "#FF5630" : theme.palette.primary.main }}
                          disabled={visualizacao}
                        />
                      }
                      label="Desejado"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.input3 === "E"}
                          onChange={() => handleCheckboxChange(field.id, "E")}
                          style={{ color: !field.input3 && showError ? "#FF5630" : theme.palette.primary.main }}
                          disabled={visualizacao}
                        />
                      }
                      label="Exigido"
                    />
                    {!field.input3 && showError && (
                      <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>Selecione uma opção</Typography>
                    )}
                  </FormGroup>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
              >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: isMd ? "end" : "flex-start", gap: "16px" }}>
                  <Button
                    size="large"
                    type="button"
                    variant="outlined"
                    onClick={() => handleRemoveField(field.id)}
                    disabled={fields.length === 1}
                  >
                    <DeleteOutlineIcon />
                  </Button>
                  <Button
                    size="large"
                    type="button"
                    startIcon={<AddCircleOutlineIcon />}
                    variant="outlined"
                    onClick={handleAddField}
                    disabled={field.input1.length < 1}
                  >
                    ADICIONAR ÁREA
                  </Button>
                </div>
              </Grid>
            </Grid>
            {index < fields.length - 1 && (
              <div style={{ width: "100%", borderTop: "1px solid #ccc", marginTop: "16px", marginBottom: "16px" }} />
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

FormExigenciaVagaTrabalho.propTypes = {
  onFieldsChange: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  list: PropTypes.array.isRequired,
  visualizacao: PropTypes.bool,
  showInput2: PropTypes.bool, // New prop to control visibility of input 2
  showError: PropTypes.bool,
};

export default FormExigenciaVagaTrabalho;

import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminListarHistoricoCurso from "../../components/filtros/AdminListarHistoricoCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageHistoricoCursos = () => {
  const { id } = useParams();

  const results = useQueries({
    queries: [
      {
        queryKey: ["logsCurso", id],
        queryFn: () => {
          return adminService.getLogsCurso(id);
        },
      },
      {
        queryKey: ["curso", id],
        queryFn: () => {
          return adminService.getCurso(id);
        },
      },
    ],
  });

  const [logsQuery, cursoQuery] = results;

  const log = logsQuery.data;
  const curso = cursoQuery.data;

  const isPending = logsQuery.isLoading || cursoQuery.isLoading;
  const isError = logsQuery.isError || cursoQuery.isError;

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos/pendentes", text: "Listar Cursos Pendentes" }]}
        currentPage="Histórico do curso"
      />

      <TitlePage title={"Histórico do curso"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <AdminListarHistoricoCurso
          initialData={log}
          curso={curso}
        />
      )}
    </Container>
  );
};

export default PageHistoricoCursos;

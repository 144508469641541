import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import optionsService from "../../services/options.service";
import InteresseVagaModal from "../modals/InteresseVagaModal";
import MobilizacaoVagaCidadaoModal from "../modals/MobilizacaoVagaCidadaoModal";
import DefaultTable from "../table/DefaultTable";
import { tabelaVagas } from "./dadosMockados";
import { SelectCidade } from "./fields/SelectCidade";

const termos = {
  municipio: "Município/UF",
  salario: "Salário",
  dataFinal: "Disponível até",
  quantidade: "N° de Vagas",
};

const tabelaColunas = [
  { field: "funcao", headerName: "Função" },
  { field: "tipoVaga", headerName: "Tipo de Vaga" },
];

const initialData = tabelaVagas;

const keysHidden = ["municipio", "salario", "dataFinal", "quantidade"];

const initialFilterState = {
  tipoVaga: "",
  funcao: "",
  situacao: "",
};

const ParceiroListarVagasRegiaoCidadao = () => {
  const [showInteresseVagaModal, setShowInteresseVagaModal] = useState(false);
  const isInteressado = false;
  const [showMobilizacao, setShowMobilizacao] = useState(false);
  const cidades = optionsService.cidadesJSON.filter(cidade => cidade.estado === "SP");
  const [cidade, setCidade] = useState("");
  const [selectedCidade, setSelectedCidade] = useState("");
  const navigate = useNavigate();

  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const applyFilter = () => {
    const filtered = initialData.filter(vaga => {
      return vaga.tipoVaga.includes(filter.tipoVaga) || vaga.funcao.includes(filter.funcao) || vaga.situacao.includes(filter.situacao);
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setFilteredData(initialData);
    applyFilter();
  };

  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const getTabelaActions = () => [
    {
      title: "Interesse",
      href: "",
      icon: "thumb_up",
      onClick: () => setShowInteresseVagaModal(true),
    },
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
      onClick: () => navigate("/mobilizacao/vagas/visualizar"),
    },
    {
      title: "Acesso Externo",
      href: "",
      icon: "open_in_new",
      onClick: () => setShowMobilizacao(true),
    },
  ];

  const handleSelectCidade = event => {
    setCidade(event.target.value);
    setSelectedCidade(event.target.value);
  };

  const fecharInteresseVaga = () => {
    setShowInteresseVagaModal(false);
  };

  const fecharExcluirVaga = () => {
    setShowMobilizacao(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography variant="h6">{"<Nome completo do cidadão>"}</Typography>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            mt={1}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">Vagas</InputLabel>
                <Select
                  id="tipoVaga"
                  label="Tipo de Vaga"
                  value={filter.tipoVaga}
                  onChange={e => setFilter({ ...filter, tipoVaga: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value="estagio">Estágio</MenuItem>
                  <MenuItem value="clt">CLT</MenuItem>
                  <MenuItem value="pj">PJ</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">Tipos de Vaga</InputLabel>
                <Select
                  id="funcao"
                  name="funcao"
                  label="Função"
                  value={filter.funcao}
                  onChange={e => setFilter({ ...filter, funcao: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value="Desenvolvedor Front-end">Desenvolvedor Front-end</MenuItem>
                  <MenuItem value="Desenvolvedor Back-end">Desenvolvedor Back-end</MenuItem>
                  <MenuItem value="Analista de Dados">Analista de Dados</MenuItem>
                  <MenuItem value="Gerente de Projetos">Gerente de Projetos</MenuItem>
                  <MenuItem value="Designer UX/UI">Designer UX/UI</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <SelectCidade
                idSelect={"cidade"}
                nameSelect={"cidade"}
                label="Município"
                values={cidade}
                cidades={cidades}
                cidade={selectedCidade}
                handleSelectCidade={event => handleSelectCidade(event)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                onClick={resetFilter}
                fullWidth
              >
                LIMPAR
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                onClick={applyFilter}
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                fullWidth
              >
                <SearchIcon />
                Pesquisar
              </Button>
            </Grid>
          </Grid>
        </Card>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Tabela */}
          <Box sx={{ flexGrow: 1 }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foram localizadas Vags na região!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
              termos={termos}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <MobilizacaoVagaCidadaoModal
          showModal={showMobilizacao}
          handleClose={fecharExcluirVaga}
        />
      </div>
      <div>
        <InteresseVagaModal
          showModal={showInteresseVagaModal}
          handleClose={fecharInteresseVaga}
          isConfirmado={isInteressado}
        />
      </div>
    </>
  );
};

export default ParceiroListarVagasRegiaoCidadao;

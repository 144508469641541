import React, { useState } from "react";

import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PrintIcon from "@mui/icons-material/Print";
import { Button, Container, FormControl, FormGroup, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Card, Grid } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CustomModal from "../../components/modals/CustomModal";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarCurriculo = () => {
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);

  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/vagas", text: "Vagas de trabalho" },
  ];

  const infoItems = [
    { label: "Logradouro:", value: "[endereço]" },
    { label: "Telefone:", value: "[telefone]" },
    { label: "Data de Nascimento:", value: "[data de nascimento]" },
    { label: "Estado Civil:", value: "[estado civil]" },
    { label: "E-mail:", value: "[email]" },
    { label: "Possui CNH:", value: "[possui CNH]" },
    { label: "Tipo CNH:", value: "[tipo CNH]" },
  ];

  const handleOpenModal = () => {
    setShowSendEmailModal(true);
  };

  const handleCloseModal = () => {
    setShowSendEmailModal(false);
  };

  const CardCurriculo = function () {
    return (
      <Card
        color="#ffffff"
        sx={{ borderRadius: "8px", padding: "16px", mt: "32px" }}
        style={{ transition: "1s" }}
      >
        <Typography
          variant="h5"
          sx={{ fontSize: "20", mb: "16px", fontFamily: "Rawline Regular" }}
        >
          {"Mobilização de currículo"}
        </Typography>

        <Grid
          container
          spacing={0.1}
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            mt={3}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mb: "16px", fontFamily: "Rawline Thin" }}
            >
              [nome pessoa]
            </Typography>
          </Grid>

          {infoItems.map((item, index) => (
            <Grid
              item
              container
              md={12}
              sm={12}
              xs={12}
              key={index}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mb: "10px", fontFamily: "Rawline Bold", mr: "5px" }}
              >
                {item.label}
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mb: "10px", fontFamily: "Rawline Thin" }}
              >
                {item.value}
              </Typography>
            </Grid>
          ))}

          <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "6px" }}></div>
          <Grid
            item
            container
            md={12}
            sm={12}
            xs={12}
          >
            <Grid
              item
              container
              md={2}
              sm={2}
              xs={4}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Bold", mr: "5px" }}
              >
                Escolaridade:
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={10}
              sm={10}
              xs={8}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Thin" }}
              >
                Escolaridade - [Situação Escolaridade]
              </Typography>
            </Grid>
          </Grid>

          <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "16px" }}></div>
          <Grid
            item
            container
            md={12}
            sm={12}
            xs={12}
          >
            <Grid
              item
              container
              md={2}
              sm={2}
              xs={4}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Bold", mr: "5px" }}
              >
                Cursos Técnicos:
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={10}
              sm={10}
              xs={8}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Thin" }}
              >
                Tipo do curso - [Situação do Curso] <br />
                Instituição - [Nome instituição] <br />
                Ano de conclusão - [Ano de conclusao] <br />
              </Typography>
            </Grid>
          </Grid>

          <div style={{ borderBottom: "1px solid grey", width: "100%", marginTop: "16px" }}></div>
          <Grid
            item
            container
            md={12}
            sm={12}
            xs={12}
          >
            <Grid
              item
              container
              md={2}
              sm={2}
              xs={4}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Bold", mr: "5px" }}
              >
                Experiências:
              </Typography>
            </Grid>
            <Grid
              item
              container
              md={10}
              sm={10}
              xs={8}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "16px", mt: "16px", fontFamily: "Rawline Thin" }}
              >
                Tipo da experiência - [Situação da experiência] <br />
                Instituição - [Nome instituição] <br />
                Ano de conclusão - [Ano de conclusao] <br />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  };

  const ContentModalEmail = function () {
    return (
      <div>
        <Grid container>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mb: "8px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              Remetente: [e-mail padrão de envio]
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mb: "8px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              Destintário: [e-mail do cidadão]
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "16px", mb: "8px", fontFamily: "Rawline Bold", mr: "5px" }}
            >
              Assunto: Currículo de [nome da pessoa]
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            style={{ borderBottom: "1px solid grey" }}
          />
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: "14px", mt: "8px", color: "grey", fontFamily: "Rawline Thin", mr: "5px" }}
            >
              Assunto: Currículo de [nome da pessoa]
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Visualizar currículo"
        links={linksBreadCrumb}
      />

      <CustomModal
        showModal={showPdfModal}
        handleClose={() => {
          setShowPdfModal(false);
        }}
        title={"Gerar PDF"}
        content={<CardCurriculo></CardCurriculo>}
        buttons={[]}
      />

      <CustomModal
        showModal={showConfirmEmailModal}
        handleClose={() => {
          setShowConfirmEmailModal(false);
        }}
        title={""}
        content={<ContentModalEmail></ContentModalEmail>}
        buttons={[]}
      />

      <CustomModal
        showModal={showSendEmailModal}
        handleClose={handleCloseModal}
        title={"Escolha o e-mail de envio"}
        content={
          <FormGroup variant="filled">
            <FormControl variant="filled">
              <InputLabel>E-mail do destinatário</InputLabel>
              <Select
                id="status"
                name="status"
                value={""}
                type="text"
              >
                <MenuItem
                  value="Pendente"
                  disabled
                >
                  Selecione o e-mail
                </MenuItem>
                <MenuItem value="Reprovar">Teste</MenuItem>
              </Select>
            </FormControl>
            <Grid
              item
              xs={2}
              mt={4}
              sx={{ alignSelf: "flex-end" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setShowConfirmEmailModal(true);
                }}
              >
                <Typography variant={"BUTTON TEXT"}>Encaminhar email!</Typography>
              </Button>
            </Grid>
          </FormGroup>
        }
        buttons={[]}
      />

      <TitlePage title={"Visualizar currículo"} />

      <Card
        color="#ffffff"
        sx={{ borderRadius: "8px", padding: "16px" }}
        style={{ transition: "1s" }}
      >
        <Typography
          variant="h5"
          sx={{ fontSize: "20", mb: "16px", fontFamily: "Rawline Regular" }}
        >
          {"Mobilização de currículo"}
        </Typography>
        <Grid
          spacing={1}
          container
          sx={{ justifyContent: "center", flexDirection: "column", alignItems: "center" }}
        ></Grid>

        <Grid
          container
          spacing={1}
          sx={{ mt: "5px", justifyContent: "flex-end" }}
        >
          <Grid
            item
            md={1}
            sm={1}
            xs={4}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={() => {
                setShowPdfModal(true);
              }}
              fullWidth
            >
              <PictureAsPdfIcon />
            </Button>
          </Grid>
          <Grid
            item
            md={1}
            sm={1}
            xs={4}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              <PrintIcon />
            </Button>
          </Grid>
          <Grid
            item
            md={1}
            sm={1}
            xs={4}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={handleOpenModal}
              fullWidth
            >
              <MarkAsUnreadIcon />
            </Button>
          </Grid>
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              EDITAR CURRÍCULO
            </Button>
          </Grid>
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              PERGUNTAS FREQUENTES
            </Button>
          </Grid>
          <Grid
            item
            md={3}
            sm={3}
            xs={12}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {}}
              fullWidth
            >
              VOLTAR
            </Button>
          </Grid>
        </Grid>
      </Card>
      <CardCurriculo />
    </Container>
  );
};

export default PageVisualizarCurriculo;

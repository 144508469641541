import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminAnalisarParceiroPendente from "../../components/formularios/AdminAnalisarParceiroPendente";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";
import constantsService from "../../services/constants.service";

const AnalisarParceiroPendente = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [valores, setValores] = useState(null);
  const [linkRetorno, setLinkRetorno] = useState("/parceiros/pendentes");
  const [shouldFetch, setShouldFetch] = useState(true); // Novo estado para controlar a reativação da consulta

  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresaParceira", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminService.getEmpresaParceira(id);
    },
    enabled: !!id && !valores && shouldFetch, // Adicionado 'shouldFetch'
    retry: false, // Desativar tentativas automáticas
  });

  useEffect(() => {
    if (initialData && !valores) {
      const servicos = Object.keys(initialData.tipoDeServico).filter(servico => initialData.tipoDeServico[servico]);

      const statusInicial = initialData.status;
      const status = statusInicial.includes("Novo cadastro") || statusInicial.includes("Em cadastramento") ? "" : statusInicial;
      const situacaoCadastro = statusInicial.includes("Novo cadastro") || statusInicial.includes("Em cadastramento") ? "novo" : "alterado";

      setValores({
        ...initialData,
        status,
        motivo: "",
        tipoDeServico: servicos,
        situacaoCadastro,
      });
    }
  }, [initialData, valores]);

  useEffect(() => {
    if (isError && error.response?.status === 404) {
      setShouldFetch(false); // Desativar a reativação da consulta em caso de 404
    }
  }, [isError, error]);

  const handleSubmit = async values => {
    // console.log("values: ", values);
    let files = values.anexo;

    try {
      if (files && files.length > 0) {
        const arquivos = files.map(file => ({
          nome_anexo: file.name.split(".")[0],
          extensao_anexo: file.name.split(".")[1],
          file: file.file,
        }));

        adminService
          .postInsereArquivos(arquivos)
          .then(respostas => {
            const ids = respostas.map(resposta => resposta.id);
            console.log("Uploaded file IDs:", ids);

            const empresaPayload = {
              empresa_id: id.toString(),
              status: constantsService.getCodeByStatus(values.status),
              mensagem: values.motivo,
              anexos: ids,
            };

            if (values.status == "Aprovado") {
              setLinkRetorno("/parceiros/aprovados");
            } else if (values.status == "Reprovado") {
              setLinkRetorno("/parceiros/reprovados");
            }

            adminService
              .postAtualizaStatusEmpresa(empresaPayload)
              .then(mensagem => {
                console.log("Sucesso:", mensagem);
                alert("Operação realizada com sucesso!");
                navigate(linkRetorno);
              })
              .catch(erro => {
                console.error("Erro ao atualizar status do parceiro", erro);
                alert("Erro inesperado, favor tentar novamente mais tarde");
              });
          })
          .catch(error => {
            console.error("Erro ao inserir arquivo", error);
          });
      } else {
        alert("Insira um arquivo");
        return;
      }
    } catch (erro) {
      console.error("Erro ao avaliar parceiro", erro);
    }
  };

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiros/pendentes", text: "Listar Parceiros Pendentes" }]}
        currentPage="Analisar Parceiro Pendente"
      />

      <TitlePage title="Analisar Parceiro Pendente" />

      {isPending || !valores ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <AdminAnalisarParceiroPendente
          valores={valores}
          id={id}
          onSubmit={handleSubmit}
        />
      )}

      {(isError || !id) && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default AnalisarParceiroPendente;

import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormCidadaoVisualizarVaga from "../../components/formularios/CidadaoVisualizarVaga";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarInformacoesVagas = () => {
  const { idUrlVaga } = useParams();

  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/vagas", text: "Vagas de trabalho" },
    { href: "/vagas/listar", text: "Listar vagas" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Visualizar informações da vaga"
        links={linksBreadCrumb}
      />

      <TitlePage title={`Visualizar informações da vaga ${idUrlVaga}`} />

      <FormCidadaoVisualizarVaga />
    </Container>
  );
};

export default PageVisualizarInformacoesVagas;

import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import DefaultTable from "../../components/table/DefaultTable";
import { useAuth } from "../../contexts/AuthContext";
import parceiroService from "../../services/parceiro.service";

const ParceiroUsuariosRepresentante = ({ usuarios, idEmpresa }) => {
  const { user } = useAuth();

  const [emailUsuario, setEmailUsuario] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleOpenDialog = user => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    const motivo = {
      motivo: "Representante não está mais ativo",
      id_empresa: idEmpresa,
    };
    await parceiroService.excluiRepresentanteEmpresa(selectedUser.pk_usuario, motivo);
    window.location.reload();
    handleCloseDialog();
    setLoading(false);
  };

  const handleAddRepresentante = async () => {
    setLoading(true);
    setError("");

    if (!emailUsuario.trim()) {
      setError("Por favor, informe o e-mail do representante.");
      setLoading(false);
      return;
    }

    if (!isValidEmail(emailUsuario)) {
      setError("Por favor, informe um e-mail válido.");
      setLoading(false);
      return;
    }

    try {
      await parceiroService.postRepresentanteEmpresa({
        email_representante: emailUsuario,
        id_empresa: idEmpresa,
      });
      window.location.reload();
    } catch (err) {
      setError("Representante informado não existe, favor inserir um representante válido.");
    }
    setLoading(false);
  };

  return (
    <>
      {/* Cores */}
      <Box>
        <Grid
          container
          mb={2}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm
          >
            <FormGroup>
              <TextField
                id="emailUsuario"
                name="emailUsuario"
                label="E-mail do usuário"
                placeholder="E-mail do usuário"
                value={emailUsuario}
                onChange={event => {
                  setEmailUsuario(event.target.value);
                }}
                type="text"
                fullWidth
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={"auto"}
          >
            <Button
              variant="contained"
              onClick={handleAddRepresentante}
              sx={{ gap: "8px", mt: "5px" }}
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : <AddCircleIcon />}
              <Typography
                variant={"BUTTON TEXT"}
                sx={{
                  fontSize: "12px",
                  mt: "8px",
                  mb: "8px",
                }}
              >
                {loading ? "Incluindo novo representante..." : "Incluir representante"}
              </Typography>
            </Button>
          </Grid>
          {error && (
            <Grid
              item
              xs={12}
            >
              <Typography
                color="error"
                variant="body2"
              >
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          mb={2}
        >
          <Grid
            item
            xs={12}
            sm={4}
          >
            <CircleIcon sx={{ color: "#2670E8", fontSize: "small", marginRight: "5px" }} />
            Usuário logado
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <CircleIcon sx={{ color: "#FFCF00", fontSize: "small", marginRight: "5px" }} />
            Usuário que nunca acessou o parceiro
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
          >
            <CircleIcon sx={{ color: "#00CA45", fontSize: "small", marginRight: "5px" }} />
            Usuário que já acessou o parceiro
          </Grid>
        </Grid>
      </Box>

      {/* Tabela */}
      <Box sx={{ flexGrow: 1 }}>
        <DefaultTable
          columns={[
            { field: "no_usuario", headerName: "Nome Completo" },
            { field: "ds_email", headerName: "E-mail" },
          ]}
          rows={usuarios}
          actionButtons={event => [
            {
              title: "Remover",
              icon: "delete",
              onClick: () => {
                if (user.ds_email === event.ds_email) {
                  alert("Você não pode excluir seu próprio usuário!");
                } else {
                  handleOpenDialog(event);
                }
              },
            },
          ]}
          mostrarCirculosColoridos={true}
          listaCirculosColoridos={["#2670E8", "#FFCF00", "#00CA45"]}
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Tem certeza que deseja excluir o usuário?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress
                  size={24}
                  sx={{ mr: "8px" }}
                />
                Apagando representante...
              </>
            ) : (
              "Confirmar"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ParceiroUsuariosRepresentante.propTypes = {
  usuarios: PropTypes.array.isRequired,
  idEmpresa: PropTypes.string.isRequired,
};

export default ParceiroUsuariosRepresentante;

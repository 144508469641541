import React from "react";
import { useSearchParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import ParceiroListarVagaTrabalho from "../../components/formularios/ParceiroListarVagasTrabalho";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageListarVagaTrabalho = () => {
  const [searchParams] = useSearchParams();
  // TODO: remover esse 1 default
  const id = searchParams.get("id_empresa_parceira") || "1";
  const {
    isPending,
    isError,
    data: initialData,
  } = useQuery({
    queryKey: ["vagas"],
    queryFn: () => {
      return parceiroService.getVagas(id);
    },
    retry: false,
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/parceiro", text: "Parceiro" },
          { href: "/vagas/listar", text: "Vagas" },
        ]}
        currentPage={"Listar Vagas"}
      />

      <TitlePage title={"Lista de Vagas"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <ParceiroListarVagaTrabalho initialData={initialData} />
      )}
    </Container>
  );
};

export default PageListarVagaTrabalho;

import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormCidadaoVisualizarCurso from "../../components/formularios/CidadaoVisualizarCurso";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarInformacoesCursos = () => {
  const { idUrlCurso } = useParams();

  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/cursos", text: "Cursos" },
    { href: "/cursos/listar", text: "Listar Cursos" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Visualizar informações do curso"
        links={linksBreadCrumb}
      />

      <TitlePage title={`Visualizar informações do curso ${idUrlCurso}`} />

      <FormCidadaoVisualizarCurso />
    </Container>
  );
};

export default PageVisualizarInformacoesCursos;

import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

import { capitalizarPrimeiraLetra } from "../utils";

export const SelectAtuacaoParceiro = ({
  idSelect,
  nameSelect,
  handleSelect,
  list,
  item,
  label,
  placeholder,
  visualizacao,
  errors,
  touched,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(item);

  React.useEffect(() => {
    setSelectedValue(item);
  }, [item]);

  // Melhorar a lógica de filtragem para buscar em mais campos e ser mais flexível
  const filteredList = list.filter(item => {
    const searchTermLower = searchTerm.toLowerCase().trim();
    const descricaoLower = item.descricao.toLowerCase();

    // Busca por palavras parciais e ignora acentos
    return descricaoLower
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(searchTermLower.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  });

  // Validar item antes de selecionar
  const validatedItem = filteredList.find(itemDaLista => itemDaLista.id === selectedValue) ? selectedValue : "";
  const itemSelecionado = validatedItem || "none";

  const handleSearchKeyDown = e => {
    // Previne o comportamento padrão de seleção do Select
    e.stopPropagation();
  };

  const handleChange = event => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    handleSelect(event);
  };

  const options = [
    <MenuItem
      key="search"
      style={{ backgroundColor: "white" }}
      sx={{
        p: 1,
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
    >
      <TextField
        size="small"
        fullWidth
        placeholder="Buscar..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        onClick={e => e.stopPropagation()}
        onKeyDown={handleSearchKeyDown}
        InputProps={{
          startAdornment: (
            <SearchIcon
              fontSize="small"
              sx={{ color: "action.active", mr: 1 }}
            />
          ),
          sx: {
            bgcolor: "background.paper",
            "& fieldset": {
              border: "1px solid #e0e0e0",
              "&:hover": {
                borderColor: "#bdbdbd",
              },
            },
            "& input": {
              p: "6px 8px",
            },
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: 1,
            },
          },
        }}
      />
    </MenuItem>,
    // Adiciona uma divisória após a busca
    <MenuItem
      key="divider"
      sx={{
        borderBottom: "1px solid #e0e0e0",
        p: 0,
        m: "0 8px",
      }}
    />,
    // MenuItem do placeholder
    <MenuItem
      key="none"
      value="none"
      disabled
    >
      <span style={{ color: "grey" }}>{placeholder}</span>
    </MenuItem>,
    // Lista de opções filtradas
    ...filteredList
      .sort((a, b) => a.descricao.localeCompare(b.descricao, "pt-BR"))
      .slice(0, 50)
      .map(listItem => (
        <Tooltip
          key={listItem.id}
          value={listItem.id}
          title={listItem.descricao.toLowerCase().split(" ").map(capitalizarPrimeiraLetra).join(" ")}
          placement="right"
          style={{ padding: "8px" }}
        >
          <MenuItem
            id={listItem.id}
            name={listItem.descricao}
            value={listItem.id}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "50vw",
              display: "block",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              padding: "0px",
            }}
          >
            {listItem.descricao.toLowerCase().split(" ").map(capitalizarPrimeiraLetra).join(" ")}
          </MenuItem>
        </Tooltip>
      )),
  ];

  return (
    <FormControl
      fullWidth
      label={label}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        id={idSelect}
        name={nameSelect}
        defaultValue=""
        value={itemSelecionado}
        onChange={handleChange}
        disabled={visualizacao}
        error={errors && touched}
        sx={{ width: "100%" }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "400px",
            },
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options}
      </Select>
    </FormControl>
  );
};

SelectAtuacaoParceiro.propTypes = {
  idSelect: PropTypes.string.isRequired,
  nameSelect: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  item: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  visualizacao: PropTypes.bool,
  errors: PropTypes.string,
  touched: PropTypes.bool,
};

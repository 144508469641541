import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormCurso from "../../components/formularios/FormCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroCursoUtils";

const PageDuplicarCurso = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const results = useQueries({
    queries: [
      {
        queryKey: ["curso", id],
        queryFn: () => {
          return parceiroService.getCurso(id);
        },
      },
      { queryKey: ["empresasParceiras"], queryFn: parceiroService.getEmpresasParceiras },
      { queryKey: ["trilhasCurso"], queryFn: parceiroService.getTrilhasCursos },
    ],
  });

  const [cursoQuery, empresasParceirasQuery, trilhasQuery] = results;

  const curso = cursoQuery.data;
  const listaEmpresas = empresasParceirasQuery.data;
  const listaTrilhas = trilhasQuery.data;

  const isPending = cursoQuery.isLoading || empresasParceirasQuery.isLoading || trilhasQuery.isLoading;
  const isError = cursoQuery.isError || empresasParceirasQuery.isError || trilhasQuery.isError;

  useEffect(() => {
    if (curso && listaEmpresas) {
      const ofertante = listaEmpresas.find(e => e.pk_empresa_parceira === curso.cnpjOfertante);
      curso.nomeOfertante = ofertante ? ofertante.no_fantasia : "";
    }
  }, [curso, listaEmpresas]);

  const handleSubmit = async values => {
    setIsLoading(true);
    let files = values.anexo;

    try {
      if (!files?.length > 0) {
        throw new Error("Insira um arquivo");
      }

      if (files[0].id != curso.anexo[0].id) {
        const idArquivo = curso.anexo[0].id;
        const arquivo = {
          nome_anexo: files[0].name.split(".")[0],
          extensao_anexo: files[0].name.split(".")[1],
          file: files[0].file,
        };

        const respostaArquivo = await parceiroService.postAtualizaArquivo(idArquivo, arquivo);
        const cursoPayload = transformaParaBackend(respostaArquivo.id, values);
        await parceiroService.postCadastrarCurso(cursoPayload);
      } else {
        const cursoPayload = transformaParaBackend(curso.anexo[0].id, values);
        await parceiroService.postCadastrarCurso(cursoPayload);
      }

      navigate("/cursos/listar");
    } catch (erro) {
      console.error("Erro ao duplicar curso:", erro);
      // Aqui você pode adicionar um toast/snackbar para mostrar o erro
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos", text: "Cursos" }]}
        currentPage={"Duplicar"}
      />

      <TitlePage title={"Duplicar Curso"} />

      {isPending || isLoading ? (
        <LoadingComponent
          isLoading={true}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <FormCurso
          initialValues={curso}
          listaEmpresas={listaEmpresas}
          listaTrilhas={listaTrilhas}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
};

export default PageDuplicarCurso;

import { transformarDadosQueroSerEmpreendedor } from "../utils/formulariosQueroSerEmpreendedor";
import axiosInstance from "./axiosConfig";
import API_URL from "./config";

/**
 * Recupera os dados de um cidadão usando o ID fornecido.
 * @param {number} id - O ID do cidadão a verificar.
 * @return {Promise} Uma Promessa que resolve para os dados do cidadão.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos dados do cidadão.
 */
const getCidadaoData = () => {
  return axiosInstance
    .get(API_URL + "v1/trabs/index/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service cidadao", response.data);
      return response.data;
    })
    .catch(error => {
      // console.error("Error fetching cidadao data:", error);
      throw error;
    });
};

/**
 * Envia os dados do questionário Quero Empreender.
 *
 * @param {Object} data - O objeto com as informações do formulário
 * @return {Promise} A Promise que resulta no envio dos dados
 */
const postQueroEmpreender = data => {
  return axiosInstance
    .post(API_URL + "v1/trabalhador/quero_empreender/cadastro", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Post - Quero Empreender: ", response.data);
    })
    .catch(error => {
      // console.error("Error fetching cidadao data:", error);
      throw error;
    });
};

const postJaSouEmpreendedor = data => {
  return axiosInstance
    .post(API_URL + "v1/trabalhador/ja_empreendo/cadastro", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Post - Ja sou Empreendedor: ", response.data);
    })
    .catch(error => {
      // console.error("Error fetching cidadao data:", error);
      throw error;
    });
};

/**
 * Envia os dados do questionário Quero Empreender.
 *
 * @param {number} id - O id do empreendedor
 * @return {Promise} A Promise que resulta no envio dos dados
 */
const getQueroEmpreender = id => {
  return axiosInstance
    .get(API_URL + `v1/trabalhador/quero_empreender/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      //console.log("Get - Quero Empreender: ", response.data);
      return transformarDadosQueroSerEmpreendedor(response.data);
    })
    .catch(error => {
      // console.error("Error fetching cidadao data:", error);
      if (error.response && error.response.status === 404 && error.response.data.error === "Questoes Quero Empreender não encontrado") {
        console.log("Questoes Quero Empreender não encontrado!");
        return {}; // Retorna dados vazios
      }
      throw error;
    });
};

/**
 * Recupera os dados de cursos importados.
 * @return {Promise} Uma Promessa que resolve para os dados dos cursos importados.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos dados.
 */
const getCursoImportado = () => {
  return axiosInstance
    .get(API_URL + "v1/trabalhador/curso_importado", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

/**
 * Recupera os dados de instituições de ensino.
 * @return {Promise} Uma Promessa que resolve para os dados das instituições.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos dados.
 */
const getInstituicaoEnsino = () => {
  return axiosInstance
    .get(API_URL + "v1/trabalhador/instituicao_ensino", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

/**
 * Envia os dados de escolaridade do currículo.
 *
 * @param {Object} data - Objeto contendo os dados de escolaridade
 * @return {Promise} Uma Promessa que resolve para a resposta do servidor
 * @throws {Error} Se ocorrer algum erro durante o envio dos dados
 */
const postCurriculoEscolaridade = data => {
  return axiosInstance
    .post(API_URL + "v1/trabalhador/curriculo_escolaridade/cadastro", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

const cidadaoService = {
  getCidadaoData,
  postQueroEmpreender,
  postJaSouEmpreendedor,
  getQueroEmpreender,
  getCursoImportado,
  getInstituicaoEnsino,
  postCurriculoEscolaridade, // Add the new function to the export
};

export default cidadaoService;

import { capitalizarPrimeiraLetra, formatCEP, formatCNPJ, formatCPF, formatTelefoneSemDDD } from "../components/formularios/utils";

export function formatNomeCampoAlterado(campo) {
  return ["cnpj", "cpf", "cep"].includes(campo.toLowerCase()) ? campo.toUpperCase() : capitalizarPrimeiraLetra(campo);
}

export function formatInformacoesLog(info, campo) {
  return campo == "Telefone"
    ? formatTelefoneSemDDD(info)
    : campo == "CNPJ"
      ? formatCNPJ(info)
      : campo == "CPF"
        ? formatCPF(info)
        : campo == "CEP"
          ? formatCEP(info)
          : info;
}

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormCurso from "../../components/formularios/FormCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroCursoUtils";

const PageEditarCurso = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const results = useQueries({
    queries: [
      {
        queryKey: ["curso", id],
        queryFn: () => {
          return parceiroService.getCurso(id);
        },
      },
      { queryKey: ["empresasParceiras"], queryFn: parceiroService.getEmpresasParceiras },
      { queryKey: ["trilhasCurso"], queryFn: parceiroService.getTrilhasCursos },
    ],
  });

  const [cursoQuery, empresasParceirasQuery, trilhasQuery] = results;

  const curso = cursoQuery.data;
  const listaEmpresas = empresasParceirasQuery.data;
  const listaTrilhas = trilhasQuery.data;

  const isPending = cursoQuery.isLoading || empresasParceirasQuery.isLoading || trilhasQuery.isLoading;
  const isError = cursoQuery.isError || empresasParceirasQuery.isError || trilhasQuery.isError;

  useEffect(() => {
    if (curso && listaEmpresas) {
      const ofertante = listaEmpresas.find(e => e.pk_empresa_parceira === curso.cnpjOfertante);
      curso.nomeOfertante = ofertante ? ofertante.no_fantasia : "";

      Object.keys(curso).forEach(key => {
        if (curso[key] === null) {
          curso[key] = "";
        }
      });
    }
  }, [curso, listaEmpresas]);

  const handleSubmit = async values => {
    setIsLoading(true);
    let files = values.anexo;

    try {
      if (files?.length > 0) {
        // Aceita um único arquivo
        if (files[0].id != curso.anexo[0].id) {
          const idArquivo = curso.anexo[0].id;
          const arquivo = {
            nome_anexo: files[0].name.split(".")[0],
            extensao_anexo: files[0].name.split(".")[1],
            file: files[0].file,
          };

          await parceiroService
            .postAtualizaArquivo(idArquivo, arquivo)
            .then(resposta => {
              console.log("Sucesso ao inserir arquivo:", resposta.message);
              console.log(resposta);

              const cursoPayload = transformaParaBackend(resposta.id, values);
              console.log(cursoPayload);

              parceiroService
                .atualizaCurso(id, cursoPayload)
                .then(resposta => {
                  console.log("Sucesso ao editar curso: ", resposta);
                  navigate("/cursos/listar");
                })
                .catch(erro => {
                  // TODO implementar delete de arquivo
                  console.error("Erro ao editar curso", erro);
                });
            })
            .catch(erro => {
              console.error("Erro ao inserir arquivo", erro);
            });
        } else {
          const cursoPayload = transformaParaBackend(curso.anexo[0].id, values);
          console.log(cursoPayload);

          parceiroService
            .atualizaCurso(id, cursoPayload)
            .then(resposta => {
              console.log("Sucesso ao editar curso: ", resposta);
              navigate("/cursos/listar");
            })
            .catch(erro => {
              // TODO implementar delete de arquivo
              console.error("Erro ao editar curso", erro);
            });
        }
      } else {
        console.log("Insira um arquivo");
      }
    } catch (erro) {
      console.error("Erro ao inserir curso", erro);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos", text: "Cursos" }]}
        currentPage={"Editar"}
      />

      <TitlePage title={"Editar Curso"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending || isLoading}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <FormCurso
          initialValues={curso}
          listaEmpresas={listaEmpresas}
          listaTrilhas={listaTrilhas}
          onSubmit={handleSubmit}
        />
      )}
    </Container>
  );
};

export default PageEditarCurso;

import React, { forwardRef, useImperativeHandle } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormGroup, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

import { FormFieldsGrid } from "../DynamicForm";

const valoresIniciais = {
  cursoTecnico: "",
  situacao: "",
  curso: "",
  conclusao: "",
  instituicaoEnsino: "",
};

const FormCursosTecnicoComplementar = forwardRef(({ defaultValues, onSubmit }, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: defaultValues || {
      cursosTecnicos: [valoresIniciais, valoresIniciais],
      cursosComplementares: [valoresIniciais, valoresIniciais],
    },
  });

  const {
    fields: cursosTecnicos,
    append: appendCursoTecnico,
    remove: removeCursoTecnico,
  } = useFieldArray({
    control,
    name: "cursosTecnicos",
  });

  const {
    fields: cursosComplementares,
    append: appendCursoComplementar,
    remove: removeCursoComplementar,
  } = useFieldArray({
    control,
    name: "cursosComplementares",
  });

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit(data => {
      console.log("errors form curso tecnico ->", errors);
      console.log("data form curso tecnico ->", data);
      onSubmit(data);
      return data;
    }),
    getFormValues: () => {
      const values = getValues();
      return {
        values,
        errors: Object.keys(errors).length > 0 ? errors : null,
      };
    },
  }));

  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>Fez ou está fazendo algum Curso Técnico?</p>
          <p style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
            {
              "(Curso de longa duração (mínimo 800 horas) com o objetivo de preparar o aluno na teoria e na prática para determinada atividade. Exemplo: Técnico em Enfermagem, Técnico em Confeitaria, Técnico em Informática.)"
            }
          </p>
        </Grid>
      </Grid>

      {cursosTecnicos.map((field, index) => (
        <React.Fragment key={field.id}>
          <FormFieldsGrid
            fields={[
              {
                name: `cursosTecnicos.${index}.cursoTecnico`,
                label: "* Curso técnico",
                renderComponent: ({ value, onChange }) => (
                  <>
                    <Grid
                      container
                      spacing={1}
                      sx={{ mb: 1.5 }}
                      alignItems="flex-end"
                    >
                      <Grid
                        item
                        xs={11}
                      >
                        <TextField
                          value={value}
                          onChange={onChange}
                          label="* Curso técnico"
                          placeholder="Informe seu curso técnico (ex: auxiliar de obras)"
                          type="text"
                          fullWidth
                        />
                      </Grid>
                      {index > 0 && (
                        <Grid item>
                          <FormGroup>
                            <Button
                              variant="outlined"
                              onClick={() => removeCursoTecnico(index)}
                            >
                              <DeleteOutlineIcon />
                            </Button>
                          </FormGroup>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ),
              },
            ]}
            control={control}
            errors={errors}
          />
        </React.Fragment>
      ))}

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={() => appendCursoTecnico(valoresIniciais)}
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR CURSO TÉCNICO
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          style={{ borderBottom: "1px solid gray" }}
          xs={12}
          sx={{ mt: 4, mb: 2 }}
        ></Grid>
      </Grid>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>
            Fez ou está fazendo algum curso complementar presencial ou a distância?
          </p>
          <p style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
            {
              "(Exemplo: Cursos de Inglês, Informática, Pronatec, dentre outros com menos de 800 horas de duração como confeitaria, pintura e etc.)"
            }
          </p>
        </Grid>
      </Grid>

      {cursosComplementares.map((field, index) => (
        <React.Fragment key={field.id}>
          <FormFieldsGrid
            fields={[
              {
                name: `cursosComplementares.${index}.cursoTecnico`,
                label: `${index === 0 ? "* " : ""}Curso ${index + 1}`,
                renderComponent: ({ value, onChange }) => (
                  <>
                    <Grid
                      container
                      spacing={1}
                      sx={{ mb: 1.5 }}
                      alignItems="flex-end"
                    >
                      <Grid
                        item
                        xs={11}
                      >
                        <TextField
                          value={value}
                          onChange={onChange}
                          label={`${index === 0 ? "* " : ""}Curso ${index + 1}`}
                          placeholder="Informe seu curso complementar (ex: Inglês básico)"
                          type="text"
                          fullWidth
                          error={!!errors.cursosComplementares?.[index]?.cursoTecnico}
                          helperText={errors.cursosComplementares?.[index]?.cursoTecnico?.message}
                        />
                      </Grid>
                      {index > 0 && (
                        <Grid item>
                          <FormGroup>
                            <Button
                              variant="outlined"
                              onClick={() => removeCursoComplementar(index)}
                            >
                              <DeleteOutlineIcon />
                            </Button>
                          </FormGroup>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ),
                size: { xs: 12 },
              },
              // Adicione outros campos conforme necessário
            ]}
            control={control}
            errors={errors}
          />
        </React.Fragment>
      ))}

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={() => appendCursoComplementar(valoresIniciais)}
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR CURSO COMPLEMENTAR
          </Button>
        </Grid>
      </Grid>
    </div>
  );
});

FormCursosTecnicoComplementar.displayName = "FormCursosTecnicoComplementar";

FormCursosTecnicoComplementar.propTypes = {
  defaultValues: PropTypes.shape({
    cursosTecnicos: PropTypes.arrayOf(
      PropTypes.shape({
        cursoTecnico: PropTypes.string,
        situacao: PropTypes.string,
        curso: PropTypes.string,
        conclusao: PropTypes.string,
        instituicaoEnsino: PropTypes.string,
      }),
    ),
    cursosComplementares: PropTypes.arrayOf(
      PropTypes.shape({
        cursoTecnico: PropTypes.string,
      }),
    ),
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default FormCursosTecnicoComplementar;

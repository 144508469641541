import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { Button, Grid, Stack, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { FormFieldsGrid } from "../DynamicForm";

const valoresIniciais = {
  ocupacao: "",
  dataInicio: "",
  dataFim: "",
  conclusao: "",
};

const FormExperiencias = forwardRef(({ defaultValues }, ref) => {
  const theme = useTheme();
  const [formList, setFormList] = useState(defaultValues?.experiencias || [valoresIniciais]);

  const {
    control,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {
      experiencias: [valoresIniciais],
    },
  });

  useImperativeHandle(ref, () => ({
    getFormValues: async () => {
      const isValid = await trigger();
      const values = getValues();
      return {
        values: { ...values },
        errors: isValid ? null : errors,
      };
    },
    validateForm: async () => {
      return await trigger();
    },
  }));

  const addNewForm = () => {
    console.log("Valores atuais:", getValues());
    setFormList([...formList, valoresIniciais]);
  };

  const removeForm = index => {
    const updatedFormList = formList.filter((_, i) => i !== index);
    setFormList(updatedFormList);
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ mb: 2 }}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>Tem alguma experiência de trabalho?</p>
          <p style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
            {
              "(Informe aqui todos os trabalhos em que você teve renda/salário. Por exemplo: lavador de carro, empregado doméstico ou vendedor ambulante.)"
            }
          </p>
        </Grid>
      </Grid>

      {formList.map((formValues, index) => {
        const fields = [
          {
            name: `experiencias.${index}.ocupacao`,
            label: "Tipo e ocupação",
            size: { xs: 12, sm: 4 },
          },
          {
            name: `experiencias.${index}.dataInicio`,
            label: "Primeiro mês e ano trabalhado",
            size: { xs: 12, sm: 4 },
          },
          {
            name: `experiencias.${index}.dataFim`,
            label: "Último mês e ano trabalhado",
            size: { xs: 12, sm: 4 },
          },
          {
            name: `experiencias.${index}.conclusao`,
            label: "Descrição das atividades (máximo de 300 caracteres)",
            renderComponent: ({ value, onChange }) => (
              <TextField
                label="Descrição das atividades (máximo de 300 caracteres)"
                value={value}
                onChange={onChange}
                multiline
                rows={3}
                fullWidth
              />
            ),
            size: { xs: 12 },
          },
        ];

        return (
          <Stack key={index}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
              >
                <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>{`Experiência profissional ` + (index + 1)}</p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ mt: -3 }}
              >
                <FormControlLabel
                  control={<Checkbox style={{ color: theme.palette.text.primary }} />}
                  label={
                    <span style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
                      *Se o local de trabalho é/era uma empresa ou uma instituição, marque o campo abaixo
                    </span>
                  }
                />
              </Grid>
            </Grid>

            <FormFieldsGrid
              fields={fields}
              control={control}
              errors={errors}
            />

            {index > 0 && (
              <Grid
                container
                justifyContent="flex-end"
                sx={{ mt: 2 }}
              >
                <Button
                  variant="outlined"
                  onClick={() => removeForm(index)}
                >
                  <DeleteOutline />
                </Button>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              sx={{ mt: 3, mb: 3 }}
            >
              <div style={{ borderBottom: "1px solid LightGrey" }} />
            </Grid>
          </Stack>
        );
      })}

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            onClick={addNewForm}
            sx={{
              mt: 2,
              fontSize: {
                xs: "0.75rem", // 12px para mobile
                sm: "0.875rem", // 14px (padrão) para tablet e acima
              },
              whiteSpace: "nowrap",
              minWidth: "fit-content",
            }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR EXPERIÊNCIA PROFISSIONAL
          </Button>
        </Grid>
      </Grid>
    </div>
  );
});

FormExperiencias.displayName = "FormExperiencias";

FormExperiencias.propTypes = {
  defaultValues: PropTypes.shape({
    experiencias: PropTypes.arrayOf(
      PropTypes.shape({
        ocupacao: PropTypes.string,
        dataInicio: PropTypes.string,
        dataFim: PropTypes.string,
        conclusao: PropTypes.string,
      }),
    ),
  }),
};

export default FormExperiencias;

import React, { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Container, Grid, Link, Typography, useTheme } from "@mui/material";

import imagemPrimaria from "../../assets/images/Ilustra-Admin.png";
import imagemServicos1 from "../../assets/images/servico1.png";
import imagemServicos2 from "../../assets/images/servico2.png";
import imagemServicos3 from "../../assets/images/servico3.png";
import imagemServicos4 from "../../assets/images/servico4.png";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CardPrimario from "../../components/cards/CardPrimario";
import CardServicos from "../../components/cards/CardServicos";
import CardVisaoGeral from "../../components/cards/CardVisaoGeral";
import TitlePage from "../../components/title-page/TitlePage";
import { useResponsive } from "../../hooks/use-responsive";
import adminService from "../../services/admin.service";

const PageHomeAdmin = () => {
  const [cardVisaoGeralData, setCardVisaoGeralData] = useState(null);
  const [showAllCards, setShowAllCards] = useState(false); // To toggle between showing 3 or all cards

  const theme = useTheme();
  const isXs = useResponsive("mediaOnly", "xs");

  const fetchCardVisaoGeralData = async () => {
    adminService
      .getAdminData()
      .then(data => {
        setCardVisaoGeralData(data);
      })
      .catch(error => {
        console.error("Erro ao obter dados da visão geral:", error);
      });
  };

  useEffect(() => {
    fetchCardVisaoGeralData();
  }, []);

  const handleShowMore = () => {
    setShowAllCards(true); // Show all cards when the button is clicked
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/"
        homeText="Página Inicial"
      />

      <TitlePage
        title={"Seja bem-vindo(a)"}
        mostrarVoltar={false}
      />

      <CardPrimario
        title="Administrativo"
        imageUrl={imagemPrimaria}
      />

      <Typography
        variant="h4"
        mt={3}
        mb={1}
        sx={{ fontFamily: "Rawline Bold", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Visão Geral
      </Typography>

      <Grid
        container
        gap={2}
      >
        {cardVisaoGeralData?.visao_geral_list &&
          Object.entries(cardVisaoGeralData.visao_geral_list)
            .slice(0, isXs && !showAllCards ? 3 : Object.entries(cardVisaoGeralData.visao_geral_list).length)
            .map(([title, value]) => (
              <Grid
                item
                xs={12}
                sm={"auto"}
                key={title}
              >
                <CardVisaoGeral
                  key={title}
                  title={title}
                  number={value}
                ></CardVisaoGeral>
              </Grid>
            ))}

        {!cardVisaoGeralData && (
          <>
            <CardVisaoGeral></CardVisaoGeral>
            <CardVisaoGeral></CardVisaoGeral>
            <CardVisaoGeral></CardVisaoGeral>
          </>
        )}
      </Grid>

      {isXs && !showAllCards && (
        <Button
          startIcon={<AddCircleIcon />}
          variant="text"
          fullWidth
          onClick={handleShowMore}
          sx={{ mt: 2 }}
        >
          Ver mais
        </Button>
      )}

      <Typography
        variant="h4"
        mt={3}
        mb={1}
        sx={{ fontFamily: "Rawline Bold", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Serviços ofertados
      </Typography>

      <CardServicos />

      <Typography
        variant="h4"
        mt={3}
        mb={1}
        sx={{ fontFamily: "Rawline Bold", marginTop: "40px" }}
        style={{ transition: "1s" }}
      >
        Serviços disponíveis
      </Typography>

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.visao_geral_list["Novas Vagas de Trabalho"]?.toString() || ""}
            content="Vagas de emprego"
            subContent="Disponíveis em diversas áreas"
            imageUrl={imagemServicos1}
            imageWidth={"150px"}
            imageHeight={"150px"}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.visao_geral_list["Cursos Aprovados"]?.toString() || ""}
            content="Vagas de curso"
            subContent="Disponíveis em diversas áreas"
            imageUrl={imagemServicos2}
            imageWidth={"150px"}
            imageHeight={"150px"}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.visao_geral_list["Empresas Ofertantes de Serviços Financeiros e de Pagamentos"]?.toString() || ""}
            content="Empresas ofertantes de serviços financeiros e de pagamentos"
            subContent=""
            imageUrl={imagemServicos3}
            imageWidth={"150px"}
            imageHeight={"150px"}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardPrimario
            title={cardVisaoGeralData?.visao_geral_list["Cursos para Empreender em sua Região"]?.toString() || ""}
            content="Cursos para empreender"
            subContent=""
            imageUrl={imagemServicos4}
            imageWidth={"230px"}
            imageHeight={"150px"}
          />
        </Grid>
      </Grid>

      <Box
        mt={5}
        style={{ transition: "1s" }}
        sx={{
          display: "flex",
          alignItems: "center",
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: theme.palette.border.layout,
          padding: "16px",
          paddingRight: "8px",
        }}
      >
        <InfoIcon />
        <Typography
          variant="body1"
          sx={{ ml: 1, fontFamily: "Rawline Regular" }}
          style={{ transition: "1s" }}
        >
          No caso de dúvidas e/ou problemas de acesso, clique em
          <Link
            component="a"
            href="https://www.gov.br/mds/pt-br/acesso-a-informacao/perguntas_frequentes"
            color="primary.main"
            underline="always"
            sx={{ ml: 1 }}
            target="_blank"
          >
            Perguntas Frequentes
          </Link>
          . Caso o problema persista, envie uma mensagem para o
          <Link
            component="a"
            href="https://www.gov.br/mds/pt-br/acesso-a-informacao/participacao-social/fale-conosco"
            color="primary.main"
            underline="always"
            sx={{ ml: 1 }}
            target="_blank"
          >
            Fale com MDS
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PageHomeAdmin;

import React from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminTrilha from "../../components/formularios/AdminTrilha";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageTrilhaNova = () => {
  const navigate = useNavigate();

  const initialValues = {
    dataUltimaModificacao: null,
    nome: "",
    descricao: "",
    situacao: "none",
    anexo: [],
  };

  const handleSubmit = async values => {
    let files = values.anexo;

    try {
      if (files?.length > 0) {
        // console.log("values.anexo: ", files);
        const arquivo = {
          nome_anexo: files[0].name.split(".")[0],
          extensao_anexo: files[0].name.split(".")[1],
          file: files[0].file,
        };

        // console.log("Arquivos: ", arquivos);
        adminService
          .postInsereArquivoTrilha(arquivo)
          .then(resposta => {
            console.log("Sucesso ao inserir arquivo:", resposta.message);

            const trilhaPayload = {
              nome_trilha: values.nome,
              descricao_trilha: values.descricao,
              anexo: resposta.id,
            };

            adminService
              .postTrilhaCurso(trilhaPayload)
              .then(resposta => {
                console.log("Sucesso ao inserir trilha: ", resposta);
                navigate("/trilhas");
              })
              .catch(erro => {
                console.error("Erro ao inserir trilha", erro);
              });
          })
          .catch(erro => {
            console.error("Erro ao inserir arquivo", erro);
          });
      } else {
        console.log("Insira um arquivo");
      }
    } catch (erro) {
      console.error("Erro ao inserir trilha", erro);
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/trilhas", text: "Trilhas" }]}
        currentPage="Novo"
      />

      <TitlePage title={"Novo"} />

      <AdminTrilha
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default PageTrilhaNova;

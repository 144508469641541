import { formatInformacoesLog, formatNomeCampoAlterado } from "../utils/adminEmpresaUtils";
import { transformarDadosTrilha } from "../utils/adminTrilhaUtils";
import { formatDate } from "../utils/format-time";
import { calculaVigencia, transformaCurso, transformaDetalhes } from "../utils/parceiroCursoUtils";
import transformDadosEmpresaComplementar from "../utils/parceiroEmpresaComplementarUtils";
import { transformarDadosUnicaEmpresa, transformEmpresasComDetalhes, transformEmpresasPorStatus } from "../utils/parceiroEmpresaUtils";
import { transformaDadosVaga, transformaDadosVagaSimples } from "../utils/parceiroVagaUtils";
import axiosInstance from "./axiosConfig";
import API_URL from "./config";
import constantsService from "./constants.service";

// const acessToken = localStorage.getItem("token");

/**
 * Retrieves a admin using the provided access token and ID.
 *
 * @param {number} id - The ID of the admin to retrieve
 * @return {Promise} A Promise that resolves to the data of the retrieved admin
 */
const getAdminData = () => {
  return axiosInstance
    .get(API_URL + "v1/admins/index/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Error fetching admin data:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da empresa parceira recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da empresa parceira.
 */
const getEmpresaParceira = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/empresa/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return transformarDadosUnicaEmpresa(response.data);
    })
    .catch(error => {
      console.error("Error fetching empresa parceira details:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira com os arquivos a serem recuperados.
 * @return {Promise<Object>} Uma Promessa que resolve para a lista de documentos.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos arquivos da empresa parceira.
 */
const getDocumentosEmpresaParceira = async id => {
  try {
    // Primeira chamada: Obter os dados da empresa parceira
    const resposta = await getEmpresaParceira(id);

    // Verifica se há IDs de anexos
    if (!resposta.ids_anexos || resposta.ids_anexos.length === 0) {
      console.warn("Nenhum anexo encontrado para essa empresa.");
      return []; // Se não houver anexos, retorna uma lista vazia
    }

    // Mapeia os IDs dos anexos para obter detalhes e arquivo base64
    const detalhesPromises = resposta.ids_anexos.map(async idAnexo => {
      try {
        // 1. Pega os detalhes do anexo
        const responseAnexo = await axiosInstance.get(API_URL + `v1/admins/anexo/${idAnexo}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });

        // 2. Pega o arquivo em base64
        const responseFile = await axiosInstance.get(API_URL + `v1/admins/file_anexo/${idAnexo}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          responseType: "blob",
        });

        // Retorna um objeto com os dados necessários
        return {
          dataModificacao: responseAnexo.data.updated_at,
          momento: resposta.status,
          nomeArquivo: responseAnexo.data.local.split("/")[2],
          base64: responseFile.data,
        };
      } catch (error) {
        console.error(`Erro ao buscar dados do anexo com ID: ${idAnexo}`, error);
        throw error;
      }
    });

    // Espera até que todas as promessas sejam resolvidas
    const detalhesArquivos = await Promise.all(detalhesPromises);

    return detalhesArquivos; // Retorna os detalhes dos arquivos
  } catch (error) {
    console.error("Erro ao buscar os documentos da empresa parceira:", error);
    throw error;
  }
};

/**
 * Recupera uma lista de empresas parceiras com seus detalhes.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras com seus detalhes.
 */
const getEmpresasParceiras = async () => {
  try {
    const response = await axiosInstance.get(API_URL + `v1/admins/empresas`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    // Mapear cada empresa para buscar detalhes individuais
    const empresasObj = response.data;
    const empresas = Object.values(empresasObj);

    // Map each empresa to fetch individual details
    const detalhesPromises = empresas.map(empresa => {
      return getEmpresaParceira(empresa.pk_empresa_parceira);
    });

    // Esperar por todas as chamadas de detalhes
    const detalhes = await Promise.all(detalhesPromises);

    // Combinar dados brutos com detalhes
    const empresasComDetalhes = empresas.map((empresa, index) => ({
      ...empresa,
      detalhes: detalhes[index],
    }));

    return transformEmpresasComDetalhes(empresasComDetalhes);
  } catch (error) {
    console.error("Error fetching empresas parceiras:", error);
    throw error;
  }
};

/**
 * Recupera uma lista de empresas parceiras com seus detalhes filtrando por múltiplos status.
 * TODO: provavelmente essa funcao não será usada
 *
 * @param {Array<string>} statusArray - Array de status para filtrar as empresas.
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras com seus detalhes.
 */
const getEmpresasParceirasStatus = async statusArray => {
  try {
    // Criar um array de promessas para cada status
    const statusPromises = statusArray.map(status =>
      axiosInstance
        .get(API_URL + `v1/admins/empresas/get_by_tp_registro/${status}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(response => response.data)
        .catch(error => {
          console.error(`Error fetching empresas for status ${status}:`, error);
          return {}; // Retorna objeto vazio em caso de erro
        }),
    );

    // Executar todas as chamadas em paralelo
    const responses = await Promise.all(statusPromises);

    // Combinar todos os resultados em um único array
    let todasEmpresas = [];
    responses.forEach(empresasObj => {
      if (empresasObj && Object.keys(empresasObj).length > 0) {
        const empresas = Object.values(empresasObj);
        todasEmpresas = [...todasEmpresas, ...empresas];
      }
    });

    // Map each empresa to fetch individual details
    const detalhesPromises = todasEmpresas.map(empresa =>
      getEmpresaParceira(empresa.pk_empresa_parceira).catch(error => {
        console.error(`Error fetching details for empresa ${empresa.pk_empresa_parceira}:`, error);
        return null; // Retorna null em caso de erro
      }),
    );

    // Esperar por todas as chamadas de detalhes
    const detalhes = await Promise.all(detalhesPromises);

    // Combinar dados brutos com detalhes (filtrando os nulos)
    const empresasComDetalhes = todasEmpresas
      .map((empresa, index) => {
        if (detalhes[index]) {
          return {
            ...empresa,
            detalhes: detalhes[index],
          };
        }
        return null;
      })
      .filter(empresa => empresa !== null); // Remove os nulos do resultado final

    return transformEmpresasComDetalhes(empresasComDetalhes);
  } catch (error) {
    console.error("Error fetching empresas parceiras:", error);
    return [];
  }
};

const postEmpresasParceirasStatus = async statusArray => {
  try {
    const response = await axiosInstance.post(
      API_URL + "v1/admins/empresas/get_by_tp_registro",
      { situacoes: statusArray },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
    );

    // Verificar se a resposta contém a mensagem "Nenhuma empresa encontrada com a situação informada"
    if (!response.data || response.data.message === "Nenhuma empresa encontrada com a situação informada") {
      console.log("Nenhuma empresa encontrada para as situações informadas.");
      return []; // Retornar array vazio
    }

    return transformEmpresasPorStatus(response.data);
  } catch (error) {
    console.error("Error fetching empresas for the given status array:", error);
    return [];
  }
};

/**
 * Atualiza o status de uma empresa.
 *
 * @param {object} dados - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 */
const postAtualizaStatusEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/empresa/atualiza_status`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service admin", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando status da empresa parceira:", error);
      throw error;
    });
};

/**
 * Insere arquivos no servidor (para análise de Empresas Parceiras).
 *
 * @param {Array} arquivos - Os arquivos a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a inserção dos arquivos.
 */
const postInsereArquivos = async arquivos => {
  try {
    const uploadPromises = arquivos.map(file =>
      axiosInstance.post(`${API_URL}v1/admins/anexo/insere_arquivo/`, file, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    );

    const responses = await Promise.all(uploadPromises);
    responses.forEach(response => console.log("Service admin", response.data));
    return responses.map(response => response.data);
  } catch (error) {
    console.error("Error Post Insere Arquivos:", error);
    throw new Error("Erro Post Insere Arquivos: " + error.message);
  }
};

/**
 * Insere um arquivo no servidor.
 *
 * @param {object} file - O arquivo a ser inserido.
 * @return {Promise<Object>} Uma Promessa que resolve para o arquivo inserido.
 * @throws {Error} Se ocorrer algum erro durante a inserção do arquivo.
 */
const postInsereArquivoTrilha = file => {
  return axiosInstance
    .post(API_URL + `v1/admins/anexo/insere_foto_trilha/`, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Inseri arquivo", response.data);
      // Preciso pegar o response.data.id para colocar no id da trilha
      return response.data;
    })
    .catch(error => {
      console.error("Error Post Insere Arquivo Trilha:", error);
      throw error;
    });
};

/**
 * Recupera um arquivo pelo ID.
 *
 * @param {number} id - O ID do arquivo a ser recuperado.
 * @return {Promise<Object>} Uma Promessa que resolve para o arquivo recuperado.
 * @throws {Error} Se ocorrer algum erro durante a recuperação do arquivo.
 */
const getArquivo = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/anexo/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      //console.log("Service admin", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Error obtendo arquivo:", error);
      throw error;
    });
};

/**
 * Recupera o blob do arquivo pelo ID.
 *
 * @param {number} id - O ID do arquivo a ser recuperado.
 * @return {Promise<Object>} Uma Promessa que resolve para o arquivo recuperado.
 * @throws {Error} Se ocorrer algum erro durante a recuperação do arquivo.
 */
const getArquivoDados = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/file_anexo/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      responseType: "blob",
    })
    .then(response => {
      // console.log("Service admin", response.data);
      const url = URL.createObjectURL(response.data);
      return url;
    })
    .catch(error => {
      console.error("Error obtendo dados de arquivo:", error);
      throw error;
    });
};

/**
 * Obter dados de uma empresa complementar.
 *
 * @param {number} id - ID da empresa a qual a empresa complementar é vinculada.
 * @returns {Promise<object>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const getEmpresaComplementar = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/empresa_complementar/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = transformDadosEmpresaComplementar(response.data);
      return dados;
    })
    .catch(error => {
      console.error("Error buscando empresa complementar:", error);
      throw error;
    });
};

const getEmpresaParceiraEComplementar = id => {
  return Promise.all([getEmpresaParceira(id), getEmpresaComplementar(id)])
    .then(([parceiraResponse, complementarResponse]) => {
      return {
        ...parceiraResponse,
        empresaComplementarDados: complementarResponse,
      };
    })
    .catch(error => {
      console.error("Error fetching empresa parceira e complementar:", error);
      throw error;
    });
};

/**
 * Obter dados de uma empresa complementar.
 *
 * @param {Object} dados - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const postAtualizarStatusEmpresaComplementar = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/empresa_complementar/atualiza_status`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando status da empresa complementar parceira:", error);
      throw error;
    });
};

/**
 * Obter dados de uma empresa complementar.
 *
 * @param {Object} id - ID a ser enviada no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const putAtualizarEmpresaComplementar = id => {
  // TODO: usar put em vez de POST
  return axiosInstance
    .post(API_URL + `v1/admins/empresa_complementar/atualiza/` + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando empresa complementar parceira:", error);
      throw error;
    });
};

/**
 * Cadastra uma nova trilha.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante o cadastro da trilha.
 */
const postTrilhaCurso = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/trilha_curso/cadastro/`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error cadastrando trilha:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma trilha usando o ID fornecido.
 *
 * @param {number} id - O ID da trilha a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da trilha recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da trilha.
 */
const getTrilhaCurso = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/trilha_curso/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const anexoId = response.data?.anexo;

      // Use Promise.all to wait for both requests to complete
      return Promise.all([adminService.getArquivo(anexoId), adminService.getArquivoDados(anexoId)]).then(([anexoDados, anexoURL]) => {
        const name = anexoDados?.local?.split("/").pop();

        const anexo = { ...anexoDados, url: anexoURL, name };
        const dados = { ...response.data, anexo };

        // Transform and return the combined data
        return transformarDadosTrilha(dados);
      });
    })
    .catch(error => {
      console.error("Error fetching Trilha:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma trilha usando o ID fornecido.
 *
 * @return {Promise<Object>} Uma Promessa que resolve para as trilhas cadastradas.
 * @throws {Error} Se ocorrer algum erro durante a recuperação das trilhas.
 */
const getTrilhasCurso = () => {
  return axiosInstance
    .get(API_URL + `v1/admins/trilha_curso/`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data.map(trilha => transformarDadosTrilha(trilha));
    })
    .catch(error => {
      console.error("Error fetching Trilhas:", error);
      throw error;
    });
};

/**
 * Atualiza os dados de uma trilha.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @param {number} id - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da trilha.
 */
const atualizarTrilhaCurso = (data, id) => {
  return axiosInstance
    .put(API_URL + `v1/admins/trilha_curso/atualiza/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data.message;
    })
    .catch(error => {
      console.error("Error atualizando Trilha:", error);
      throw error;
    });
};

/**
 * Atualiza o status de uma trilha.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a atualização de status da trilha.
 */
const postAtualizarStatusTrilha = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/trilha_curso/atualiza_status/`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error cadastrando trilha:", error);
      throw error;
    });
};

/**
 * Deleta uma trilha.
 *
 * @param {number} id - O id da trilha a ser deletada.
 * @param {object} motivo - O motivo da exclusão.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a deleção da trilha.
 */
const deletarTrilha = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/admins/trilha_curso/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        motivo: motivo,
      },
    })
    .then(response => {
      // console.log("Service admin", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Error deletando trilha:", error);
      throw error;
    });
};

/**
 * Recupera os representantes de uma empresa usando o ID fornecido.
 * @param {number} id - O id da empresa a verificar os representantes.
 * @return {Promise<Object>} Uma Promessa que resolve para os representantes cadastrados.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos representantes cadastrados.
 */
const getRepresentantesEmpresa = id => {
  if (id) {
    return axiosInstance
      .get(API_URL + `v1/admins/representantes/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error("Erro ao tentar obter representantes: ", error);
        throw error;
      });
  } else {
    return [];
  }
};

/**
 * Cadastra um representante a uma empresa.
 *
 * @param {object} data - Os dados a serem enviados no corpo da requisição.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante o cadastro de representante da empresa.
 */
const postRepresentanteEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/representantes/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      console.log(response.data.message);
    })
    .catch(error => {
      console.error("Erro ao cadastrar representante: ", error);
      throw error;
    });
};

/**
 * Deleta o representante de uma empresa.
 *
 * @param {number} id - O id da empresa a ter o representante deletado.
 * @param {object} dados - Os dados da empresa a ter o representante deletado.
 * @returns {Promise<string>} Uma promessa que resolve com a mensagem do servidor.
 * @throws {Error} Se ocorrer algum erro durante a deleção do representante.
 */
const excluiRepresentanteEmpresa = (id, dados) => {
  return axiosInstance
    .delete(API_URL + `v1/admins/representantes/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: dados,
    })
    .then(response => {
      console.log(response.data.message);
    })
    .catch(error => {
      console.error("Erro ao excluir representante: ", error);
      throw error;
    });
};

/**
 * Recupera os Logs de uma empresa usando o ID fornecido.
 * @param {number} id - O ID da empresa a verificar.
 * @return {Promise} Uma Promessa que resolve para os dados da empresa.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos logs da empresa.
 */
const getLogsEmpresa = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/logs/empresa/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = response.data;

      dados.forEach(element => {
        element.modificacao = formatDate(element.modificacao);
        element.campoAlterado = formatNomeCampoAlterado(element.campoAlterado.split(".").pop().split("_").pop());
        element.informacaoAnterior = formatInformacoesLog(element.informacaoAnterior, element.campoAlterado);
        element.novaInformacao = formatInformacoesLog(element.novaInformacao, element.campoAlterado);
      });

      return dados;
    })
    .catch(error => {
      console.error("Erro ao obter logs de empresa: ", error);
      throw error;
    });
};

/**
 * Recupera os Logs de uma empresa complementar usando o ID fornecido.
 * @param {number} id - O ID da empresa complementar a verificar.
 * @return {Promise} Uma Promessa que resolve para os dados da empresa complementar.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos logs da empresa complementar.
 */
const getLogsEmpresaComplementar = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/logs/empresa_complementar/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = response.data;

      dados.forEach(element => {
        element.modificacao = formatDate(element.modificacao);
        element.campoAlterado = formatNomeCampoAlterado(element.campoAlterado);
        element.informacaoAnterior = formatInformacoesLog(element.informacaoAnterior, element.campoAlterado);
        element.novaInformacao = formatInformacoesLog(element.novaInformacao, element.campoAlterado);
      });

      return dados;
    })
    .catch(error => {
      console.error("Erro ao obter logs de empresa: ", error);
      throw error;
    });
};

/**
 * Obter os dados de um curso.
 *
 * @param {Int} od - O id do curso a ser retornado.
 * @return {Promise<string>} Uma promessa que resolve para os dados do curso
 * @throws {Error} Se ocorrer algum erro durante a obtenção do curso.
 */
const getCurso = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/curso/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(async response => {
      const id = response.data.curso_id;
      const status = constantsService.getStatusByCode(response.data.tp_situacao_registro);
      const dadosCurso = transformaCurso(response.data.dados);
      const dadosDetalhes = response.data.detalhes ? transformaDetalhes(response.data.detalhes) : null;
      const vigencia = calculaVigencia(
        response.data.tp_situacao_registro,
        response.data.dados.dt_inicio_matricula,
        response.data.dados.dt_fim_matricula,
      );

      return { id, status, situacao: status, ...dadosCurso, detalhes: dadosDetalhes, vigencia };
    })
    .catch(error => {
      console.error("Erro obtendo curso:", error);
      throw error;
    });
};
/**
 * Obter a lista de cursos com detalhes das empresas parceiras.
 *
 * @return {Promise<string>} Uma promessa que resolve para os dados dos cursos cadastrados.
 * @throws {Error} Se ocorrer algum erro durante a obtenção dos cursos.
 */
const getCursosComDetalhes = async () => {
  // Cache to store fetched company data by company ID
  const empresaCache = {};

  try {
    const response = await axiosInstance.get(API_URL + `v1/admins/cursos`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    // console.log("Service parceiro", response.data);

    if (!response.data || response.data.message === "Nenhum curso encontrado.") {
      console.log("Nenhum curso encontrado");
      return [];
    }

    const info = await Promise.all(
      response.data.map(async curso => {
        const empresaId = curso.dados.fk_empresa_parceira;

        // Check if the company data is already cached
        let dadosEmpresa = empresaCache[empresaId];
        if (!dadosEmpresa) {
          // Fetch and cache the company data if not already cached
          dadosEmpresa = await getEmpresaParceira(empresaId);
          empresaCache[empresaId] = dadosEmpresa;
        }

        const id = curso.curso_id;
        const status = constantsService.getStatusByCode(curso.tp_situacao_registro);
        let dadosCurso = transformaCurso(curso.dados);
        const vigencia = calculaVigencia(curso.tp_situacao_registro, curso.dados.dt_inicio_matricula, curso.dados.dt_fim_matricula);

        dadosCurso = {
          ...dadosCurso,
          cnpj: dadosEmpresa.cnpj,
          nomeOfertante: dadosEmpresa.nomeFantasia,
        };

        return { id, status, situacao: status, ...dadosCurso, vigencia };
      }),
    );

    return info;
  } catch (error) {
    console.error("Erro obtendo cursos:", error);
    return [];
  }
};

const getCursosPorSituacao = async situacoes => {
  const empresaCache = {};

  try {
    const response = await axiosInstance.post(API_URL + `v1/admins/curso/get_by_tp_registro`, situacoes, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    // console.log("Service parceiro", response.data);

    if (!response.data || response.data.message === "Nenhum curso encontrado.") {
      console.log("Nenhum curso encontrado");
      return [];
    }

    const info = await Promise.all(
      response.data.map(async curso => {
        const empresaId = curso.dados.fk_empresa_parceira;

        // Check if the company data is already cached
        let dadosEmpresa = empresaCache[empresaId];
        if (!dadosEmpresa) {
          // Fetch and cache the company data if not already cached
          dadosEmpresa = await getEmpresaParceira(empresaId);
          empresaCache[empresaId] = dadosEmpresa;
        }

        const id = curso.curso_id;
        const status = constantsService.getStatusByCode(curso.tp_situacao_registro);
        let dadosCurso = transformaCurso(curso.dados);
        const vigencia = calculaVigencia(curso.tp_situacao_registro, curso.dados.dt_inicio_matricula, curso.dados.dt_fim_matricula);

        dadosCurso = {
          ...dadosCurso,
          cnpj: dadosEmpresa.cnpj,
          nomeOfertante: dadosEmpresa.nomeFantasia,
        };

        return { id, status, situacao: status, ...dadosCurso, vigencia };
      }),
    );

    return info;
  } catch (error) {
    console.error("Erro obtendo cursos:", error);
    return [];
  }
};

const atualizarStatusCurso = data => {
  return axiosInstance
    .post(API_URL + `v1/admins/curso/atualiza_status`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Status do curso atualizado com sucesso!", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Erro ao atualizar status do curso!", error);
      throw error;
    });
};

/**
 * Obter a lista de cursos.
 *
 * @return {Promise<string>} Uma promessa que resolve para os dados dos cursos cadastrados.
 * @throws {Error} Se ocorrer algum erro durante a obtenção dos cursos.
 */
const getCursos = async () => {
  try {
    const response = await axiosInstance.get(API_URL + `v1/admins/cursos`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.data || response.data.message === "Nenhum curso encontrado.") {
      console.log("Nenhum curso encontrado");
      return [];
    }

    const info = response.data.map(curso => {
      const id = curso.curso_id;
      const status = constantsService.getStatusByCode(curso.tp_situacao_registro);
      let dadosCurso = transformaCurso(curso.dados);
      const vigencia = calculaVigencia(curso.tp_situacao_registro, curso.dados.dt_inicio_matricula, curso.dados.dt_fim_matricula);

      return { id, status, situacao: status, ...dadosCurso, vigencia };
    });

    return info;
  } catch (error) {
    console.error("Erro obtendo cursos:", error);
    return [];
  }
};

/**
 * Exclui um curso.
 *
 * @param {number} id - O ID do curso a ser excluído.
 * @param {string} motivo - O motivo da exclusão do curso.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a exclusão do curso.
 * */
const excluirCurso = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/admins/curso/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: {
        motivo: motivo,
      },
    })
    .then(response => {
      console.log("Curso excluído com sucesso!", response.data);
      return response.data;
    })
    .catch(error => {
      console.error("Erro ao excluir curso: ", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma lista de vagas usando o ID fornecido.
 *
 * @return {Promise<Object>} Uma Promessa que resolve para as vagas cadastradas.
 * @throws {Error} Se ocorrer algum erro durante a recuperação das vagas.
 */
const getVagas = id_empresa_parceira => {
  //TODO: Pedir pro Lucas não separar por empresa
  return axiosInstance
    .get(API_URL + `v1/admins/vagas?id_empresa_parceira=${id_empresa_parceira}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      // console.log("Pegando vagas", response.data);
      const info = response.data.map(vaga => {
        return transformaDadosVagaSimples(vaga);
      });
      return info;
    })
    .catch(error => {
      console.error("Error fetching vagas:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma vaga usando o ID fornecido.
 * @param {number} id - O id da vaga.
 * @param {number} id_empresa_parceira - O id da empresa parceira.
 * @return {Promise<Object>} Uma Promessa que resolve para a vaga cadastrada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação da vaga.
 */
const getVaga = async (id, id_empresa_parceira) => {
  try {
    const response = await axiosInstance.get(API_URL + `v1/admins/vaga/${id}?id_empresa_parceira=${id_empresa_parceira}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    if (!response.data || response.data.message === "Nenhuma vaga encontrada!") {
      console.log("Nenhuma vaga encontrada!");
      return null;
    }

    const dadosEmpresa = await getEmpresaParceira(id_empresa_parceira);
    // console.log("Empresa parceira", dadosEmpresa);

    let dadosVaga = transformaDadosVaga(response.data);
    dadosVaga = {
      ...dadosVaga,
      // cnpjOfertante: dadosEmpresa.cnpj,
      cnpjOfertante: id_empresa_parceira,
      nomeOfertante: dadosEmpresa.nomeFantasia,
    };
    // console.log("Retornando vaga", dadosVaga.cnpjOfertante);
    return dadosVaga;
  } catch (error) {
    console.error("Erro ao obter vaga:", error);
    throw error;
  }
};

/**
 * Recupera os Logs de um curso usando o ID fornecido.
 * @param {number} id - O ID do curso a verificar.
 * @return {Promise} Uma Promessa que resolve para os dados da empresa.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos logs da empresa.
 */
const getLogsCurso = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/logs/curso/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      if (!response.data || response.data.message === "Nenhum log encontrado.") {
        console.log("Nenhum log encontrado.");
        return [];
      }

      const dados = response.data;
      dados.forEach(element => {
        element.modificacao = formatDate(element.modificacao);
        element.campoAlterado = formatNomeCampoAlterado(element.campoAlterado.split(".").pop().split("_").pop());
        element.informacaoAnterior = formatInformacoesLog(element.informacaoAnterior, element.campoAlterado);
        element.novaInformacao = formatInformacoesLog(element.novaInformacao, element.campoAlterado);
      });

      return dados;
    })
    .catch(error => {
      console.error("Erro ao obter logs do curso: ", error);
      throw error;
    });
};

/**
 * Recupera os Logs de status de um curso usando o ID fornecido.
 * @param {number} id - O ID do curso a verificar.
 * @return {Promise} Uma Promessa que resolve para os dados da empresa.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos logs da empresa.
 */
const getLogsStatusCurso = id => {
  return axiosInstance
    .get(API_URL + `v1/admins/logs/curso_status/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      if (!response.data || response.data.message === "Nenhum log encontrado.") {
        console.log("Nenhum log encontrado.");
        return [];
      }

      const dados = response.data;
      dados.forEach(element => {
        element.situacao = constantsService.getStatusByCode(element.situacao);
      });

      return dados;
    })
    .catch(error => {
      console.error("Erro ao obter logs do curso: ", error);
      throw error;
    });
};

const adminService = {
  getAdminData,
  getEmpresaParceira,
  getDocumentosEmpresaParceira,
  getEmpresasParceiras,
  getEmpresasParceirasStatus,
  postEmpresasParceirasStatus,
  postAtualizaStatusEmpresa,
  postInsereArquivos,
  postInsereArquivoTrilha,
  getArquivo,
  getArquivoDados,
  getEmpresaComplementar,
  postAtualizarStatusEmpresaComplementar,
  putAtualizarEmpresaComplementar,
  postTrilhaCurso,
  getTrilhaCurso,
  getTrilhasCurso,
  atualizarTrilhaCurso,
  deletarTrilha,
  postAtualizarStatusTrilha,
  getEmpresaParceiraEComplementar,
  getRepresentantesEmpresa,
  postRepresentanteEmpresa,
  excluiRepresentanteEmpresa,
  getLogsEmpresa,
  getLogsEmpresaComplementar,
  getCurso,
  getCursos,
  getCursosComDetalhes,
  getCursosPorSituacao,
  atualizarStatusCurso,
  excluirCurso,
  getVaga,
  getVagas,
  getLogsCurso,
  getLogsStatusCurso,
};

export default adminService;

import React from "react";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminListarCursosAprovados from "../../components/filtros/AdminListarCursosAprovados";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageListarCursosAprovados = () => {
  const {
    isPending,
    isError,
    data: initialData,
  } = useQuery({
    queryKey: ["cursosComDetalhesAprovados"],
    queryFn: () => {
      return adminService.getCursosPorSituacao({
        situacoes: ["APR"],
      });
    },
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb currentPage="Listar Cursos Aprovados" />

      <TitlePage title={"Listar Cursos Aprovados"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <AdminListarCursosAprovados initialData={initialData} />
      )}
    </Container>
  );
};

export default PageListarCursosAprovados;

import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormJaSouEmpreendedor from "../../components/formularios/FormJaSouEmpreendedor";
import TitlePage from "../../components/title-page/TitlePage";

const PageJaSouEmpreendedor = () => {
  const linksBreadCrumb = [
    { href: "/", text: "Administrador" },
    { href: "/microcredito", text: "Microcrédito" },
    { href: "/microcredito", text: "Acessar Microcrédito" },
  ];

  const onSubmit = payload => {
    console.log("MANDEI PRO BACK payload: ", payload);

    // TODO: criar versão do adminService
    // cidadaoService
    //   .postJaSouEmpreendedor(payload)
    //   .then(() => {
    //     window.location.reload();
    //   })
    //   .catch(error => {
    //     console.log("Erro no envio dos dados do Questionário: ", error);
    //   });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Questionário - Já sou empreendedor"
        links={linksBreadCrumb}
      />

      <TitlePage title={"Questionário Já sou Empreendedor"} />

      <div style={{ fontFamily: "Rawline Regular" }}>
        <p>
          Ao responder o questionário abaixo, o cidadão autoriza o Ministério do Desenvolvimento e Assistência Social, Família e Combate à
          Fome a enviar os seus dados e informações aos operadores de microcrédito parceiros do Acredita, visando permitir a prospecção
          direta desses parceiros sobre a sua pessoa, por qualquer meio, buscando dar efetividade à política de microcrédito produtivo
          orientado e de inclusão social e produtiva, bem como a realizar consultas para verificação da existência de restrições junto a
          instituições que controlam cadastros positivos e restritivos de crédito.
        </p>
        <p>
          Os operadores de microcrédito parceiros do Acredita podem ser bancos, cooperativas de crédito, agências de fomento, sociedades de
          crédito ao microempreendedor e à empresa de pequeno porte, organizações da sociedade civil de interesse público, pessoas jurídicas
          especializadas no apoio, no fomento ou na orientação de microcrédito produtivo orientado, instituição financeira que realiza
          operações exclusivamente por meio de sítio eletrônico ou de aplicativo{" "}
        </p>
      </div>

      <FormJaSouEmpreendedor submit={onSubmit} />
    </Container>
  );
};

export default PageJaSouEmpreendedor;

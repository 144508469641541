import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroListarVagasRegiaoCidadao from "../../components/formularios/ParceiroListarVagasRegiaoCidadao";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarVagasRegiaoCidadao = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/parceiro", text: "Parceiro" },
          { href: "/vagas/listar", text: "Vagas" },
        ]}
        currentPage={"Listar Vagas na Região do Cidadão"}
      />

      <TitlePage title={"Listar de vagas para região do cidadão"} />

      <ParceiroListarVagasRegiaoCidadao />
    </Container>
  );
};

export default PageListarVagasRegiaoCidadao;

import React, { useRef, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Card, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import CustomDatePicker from "../formularios/CustomDatePicker";
import { tabelaAcoes } from "../formularios/dadosMockados";
import RelacaoDeCursoModal from "../modals/RelacaoDeCursosModal";
import TransferList from "../transferList/TransferList";
import DynamicForm from "./DynamicForm";

const AdminPerfil = ({ editar, visualizar, valores, setValores }) => {
  const theme = useTheme();
  const formRef = useRef();

  const [modal, setModal] = useState(false);
  const [itemsTransferfList, setItemsTransferList] = useState({
    left: tabelaAcoes.map(curso => curso.nome),
    right: valores.acoes,
  });

  const handleItemsTransferListChange = updatedItems => {
    setItemsTransferList(updatedItems);
  };

  const fecharModal = () => {
    setModal(false);
    handleButtonClick();
  };

  const fields = [
    {
      name: "nome",
      label: "Nome",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="nome"
          name="nome"
          label="Nome"
          placeholder="Nome"
          value={value}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "descricao",
      label: "Descrição",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="descricao"
          name="descricao"
          label="Descrição"
          placeholder="Descrição"
          value={value}
          onChange={onChange}
          disabled={visualizar}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => (
        <>
          {editar && !visualizar && (
            <>
              <Typography variant="subtitle2">Situação</Typography>
              <FormGroup>
                <RadioGroup
                  row
                  name="situacao"
                  onChange={onChange}
                >
                  <FormControlLabel
                    value="Ativo"
                    control={
                      <Radio
                        style={{ color: theme.palette.primary.main }}
                        checked={value === "Ativo"}
                      />
                    }
                    label="Ativo"
                  />
                  <FormControlLabel
                    value="Inativo"
                    control={
                      <Radio
                        style={{ color: theme.palette.primary.main }}
                        checked={value === "Inativo"}
                      />
                    }
                    label="Inativo"
                  />
                </RadioGroup>
              </FormGroup>
            </>
          )}
          {visualizar && (
            <TextField
              fullWidth
              disabled
              id="situacao"
              name="situacao"
              value={value}
              label="Situação"
              placeholder="Situação"
              onChange={onChange}
            />
          )}
        </>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "dataCadastro",
      label: "Data do Cadastro",
      renderComponent: ({ value }) => (
        <>
          {visualizar && (
            <CustomDatePicker
              disabled
              label="Data do Cadastro"
              id="dataCadastro"
              name="dataCadastro"
              value={dayjs(value)}
              format="DD/MM/YYYY"
              onChange={valor => setValores({ ...value, ["dataCadastro"]: valor })}
            />
          )}
        </>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "perfilDisponivel",
      label: "Perfil Disponível",
      renderComponent: () => (
        <>
          {!visualizar && (
            <TransferList
              items={itemsTransferfList}
              titleLeft={"Perfil Disponível"}
              titleRight={"Perfil selecionado para o usuário"}
              onItemsChange={handleItemsTransferListChange}
            />
          )}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "acoes",
      label: "Ações associadas ao perfil",
      renderComponent: ({ value, onChange }) => (
        <>
          {visualizar && (
            <TextField
              fullWidth
              disabled
              multiline
              rows={3}
              id="acoes"
              name="acoes"
              value={value}
              label="Ações associadas ao perfil"
              placeholder="Ações associadas ao perfil"
              onChange={onChange}
            />
          )}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "motivo",
      label: "Motivo",
      renderComponent: ({ value, onChange }) => (
        <>
          {visualizar && (
            <TextField
              fullWidth
              disabled={!editar}
              multiline
              rows={3}
              id="motivo"
              name="motivo"
              value={value}
              label="Motivo"
              placeholder="Motivo"
              onChange={onChange}
            />
          )}
        </>
      ),
      size: { xs: 12 },
    },
  ];

  const handleButtonClick = () => {
    if (formRef.current) {
      // formRef.current.submitForm();
      console.log(formRef.current.getValues());
    }
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <DynamicForm
            ref={formRef}
            fields={fields}
            initialValues={valores}
          />
        </Card>
      </Stack>

      {/* Botões de salvar/cancelar */}
      <Box sx={{ flexGrow: 1, mt: "16px" }}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              sx={{ gap: "8px" }}
              style={{ fontFamily: "Rawline Medium" }}
              onClick={() => window.history.back()}
            >
              Cancelar
            </Button>
          </Grid>

          {visualizar ? (
            <>
              {!editar && (
                <>
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{ gap: "8px" }}
                      style={{ fontFamily: "Rawline Medium" }}
                      href={"/perfil/visualizar/historico-situacao/"}
                    >
                      <Typography variant={"BUTTON TEXT"}>Histórico de ativação</Typography>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      href="/perfil/editar/"
                      sx={{ gap: "8px" }}
                      style={{ fontFamily: "Rawline Medium" }}
                    >
                      <Typography variant={"BUTTON TEXT"}>Editar</Typography>
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item>
                <Button
                  variant="contained"
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  <Typography variant={"BUTTON TEXT"}>Excluir</Typography>
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button
                variant="contained"
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                onClick={() => setModal(true)}
              >
                <SaveIcon fontSize="small" />
                <Typography variant={"BUTTON TEXT"}>Salvar</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <div>
        <RelacaoDeCursoModal
          showModal={modal}
          handleClose={fecharModal}
          situacao={valores.situacao}
          cursos={valores.acoes}
        />
      </div>
    </>
  );
};

AdminPerfil.propTypes = {
  editar: PropTypes.bool,
  visualizar: PropTypes.bool,
  valores: PropTypes.object,
  setValores: PropTypes.func,
};

export default AdminPerfil;

import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import optionsService from "../../services/options.service";
import CustomDatePicker from "../formularios/CustomDatePicker";
import { dadosParceirosAprovados } from "../formularios/dadosMockados";
import { SelectCidade } from "../formularios/fields/SelectCidade";
import { SelectUF } from "../formularios/fields/SelectUF";
import TipoDeArquivoModal from "../modals/TipoDeArquivoModal";
import DefaultTable from "../table/DefaultTable";

const termos = {
  id: "ID",
  habilitacao: "Habilitação",
  email: "E-mail",
  nomePontoFocal: "Ponto focal",
  nomeResponsavel: "Nome responsável",
  uf: "UF",
  municipio: "Município",
  tipo: "Tipo de questionário respondido",
  dataResposta: "Data da resposta",
  situacao: "Situação",
  infoComplementar: "Informações complementares",
};

// Dados fictícios para teste da tabela
const initialData = dadosParceirosAprovados;

// Retornar a pesquisa vazia
const initialFilterState = {
  parceiro: "",
  cnpj: "",
  nome: "",
  uf: "",
  municipio: "",
  pontoFocal: "",
  telefone: "",
  situacao: "",
  tipo: "",
  dataCadastro: null,
  dataUltimaModificacao: null,
};

const tabelaColunas = [
  { field: "cnpj", headerName: "CNPJ" },
  { field: "razaoSocial", headerName: "Razão Social" },
  { field: "telefone", headerName: "Telefone" },
];

const keysHidden = ["nomeResponsavel", "nomePontoFocal", "uf", "municipio", "situacao", "infoComplementar"];

const AdminConsultarMPO = () => {
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);
  const [modal, setModal] = useState(false);

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  const [situacao, setSituacao] = useState({
    Aprovado: false,
    Reprovado: false,
    Pendente: false,
  });
  const [situacaoInfo, setSituacaoInfo] = useState({
    AprovadoInfo: false,
    ReprovadoInfo: false,
    PendenteInfo: false,
  });

  const handleCheckbox1 = event => {
    setSituacao({
      Aprovado: false,
      Reprovado: false,
      Pendente: false,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCheckbox2 = event => {
    setSituacaoInfo({
      AprovadoInfo: false,
      ReprovadoInfo: false,
      PendenteInfo: false,
      [event.target.name]: event.target.checked,
    });
  };

  const { Aprovado, Reprovado, Pendente } = situacao;
  const { AprovadoInfo, ReprovadoInfo, PendenteInfo } = situacaoInfo;

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });

    setSelectedUf(value);
    setSelectedMunicipio("");
    setFilter({ ...filter, ["municipio"]: "" });
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    setSelectedMunicipio(event.target.value);
  };

  const tabelaHiddenLinhas = filteredData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar Informações Complementares",
      icon: "description",
      // onClick: rowId => navigate("/parceiros/empresa-complementar/visualizar/" + rowId),
    },
    {
      title: "Analisar Informações Complementares",
      icon: "post_add",
      // onClick: rowId => navigate("/parceiros/empresa-complementar/analisar/" + rowId),
    },
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
    },
  ];

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        (!filter.dataDoCadastroInicio ||
          !filter.dataDoCadastroFim ||
          dayjs(parceiro.dataCadastro).isBetween(filter.dataDoCadastroInicio, filter.dataDoCadastroFim, "day", "[]")) &&
        (!filter.dataDaUltimaModificacaoInicio ||
          !filter.dataDaUltimaModificacaoFim ||
          dayjs(parceiro.dataUltimaModificacao).isBetween(
            filter.dataDaUltimaModificacaoInicio,
            filter.dataDaUltimaModificacaoFim,
            "day",
            "[]",
          ))
      );
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setSelectedUf("");
    setSelectedMunicipio("");
    setSituacao({ Aprovado: false, Reprovado: false, Pendente: false });
    setSituacaoInfo({ AprovadoInfo: false, ReprovadoInfo: false, PendenteInfo: false });
    applyFilter();
  };

  const handleDownloadExcelClick = () => {
    setModal(true);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            {/* CNPJ e Parceiro */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                fullWidth
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                value={filter.cnpj}
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                fullWidth
                id="parceiro"
                name="parceiro"
                label="Parceiro"
                placeholder="Parceiro"
                value={filter.parceiro}
                onChange={handleFilterChange}
              />
            </Grid>

            {/* UF e Município */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectUF
                idSelect="uf"
                nameSelect="uf"
                value={filter.uf}
                ufs={ufs}
                uf={selectedUf}
                label="* UF"
                handleSelectUf={event => handleSelectUf(event)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <SelectCidade
                idSelect="municipio"
                nameSelect="municipio"
                value={filter.municipio}
                cidades={municipios}
                cidade={selectedMunicipio}
                label="* Município"
                handleSelectCidade={event => handleSelectMunicipio(event)}
              />
            </Grid>

            {/* Situação Cadastro */}
            <Grid
              item
              container
              xs={12}

              // marginLeft={3}
              // mt={3}
            >
              <Grid
                item
                // xs={12}
              >
                <Typography variant={"body1"}>Situação do cadastro</Typography>
              </Grid>
              <Grid
                item
                container
                // xs={12}
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Aprovado}
                        name="Aprovado"
                        onChange={handleCheckbox1}
                      />
                    }
                    label="Aprovados"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="Reprovado"
                    control={
                      <Checkbox
                        checked={Reprovado}
                        name="Reprovado"
                        onChange={handleCheckbox1}
                      />
                    }
                    label="Reprovados"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="Pendente"
                    control={
                      <Checkbox
                        checked={Pendente}
                        name="Pendente"
                        onChange={handleCheckbox1}
                      />
                    }
                    label="Pendentes de Aprovação"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Data  Inicial e Final */}
            <Grid
              item
              xs={12}
              sm={6}
            >
              <CustomDatePicker
                label="Data Inicial"
                id="dataCadastro"
                name="dataCadastro"
                value={filter.dataCadastro}
                format="DD/MM/YYYY"
                onChange={valor => setFilter({ ...filter, ["dataCadastro"]: valor })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <CustomDatePicker
                label="Data Final"
                id="dataUltimaModificacao"
                name="dataUltimaModificacao"
                value={filter.dataUltimaModificacao}
                format="DD/MM/YYYY"
                onChange={valor => setFilter({ ...filter, ["dataUltimaModificacao"]: valor })}
              />
            </Grid>

            {/* Botões de pesquisa/cancelar */}
            <Grid
              item
              container
              spacing={1}
              justifyContent="flex-end"
              xs={12}
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>

            {/* Informação complementar e Situação */}
            <Grid
              item
              xs={12}
              md={6}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-infoComplementar">Informação complementar</InputLabel>
                <Select
                  id="infoComplementar"
                  name="infoComplementar"
                  value={filter.infoComplementar || ""}
                  type="text"
                  onChange={handleFilterChange}
                  labelId="label-infoComplementar"
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione uma opção
                  </MenuItem>
                  {initialData.map((usuario, index) => (
                    <MenuItem
                      key={usuario.id + index}
                      value={usuario.infoComplementar}
                    >
                      {usuario.infoComplementar}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
            >
              <Grid
                item
                xs={12}
              >
                <Typography variant={"body1"}> Situação das Informações Complementares</Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AprovadoInfo}
                      name="AprovadoInfo"
                      onChange={handleCheckbox2}
                    />
                  }
                  label="Aprovados"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ReprovadoInfo}
                      name="ReprovadoInfo"
                      onChange={handleCheckbox2}
                    />
                  }
                  label="Reprovados"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={PendenteInfo}
                      name="PendenteInfo"
                      onChange={handleCheckbox2}
                    />
                  }
                  label="Pendentes de Aprovação"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado interessados!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              termos={termos}
              actionButtons={getTabelaActions}
              outsideDownloadExcel={handleDownloadExcelClick}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      <div>
        <TipoDeArquivoModal
          showModal={modal}
          handleClose={() => setModal(false)}
          dados={filteredData}
          tipo={"Consulta"}
        />
      </div>
    </>
  );
};

export default AdminConsultarMPO;

import { format, formatDistanceToNow, getTime } from "date-fns";

// ----------------------------------------------------------------------

// Usar a formatDate logo abaixo!!!
// export function fDate(date, newFormat) {
//   const fm = newFormat || "dd MMM yyyy";

//   return date ? format(new Date(date), fm) : "";
// }

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}

// Create a utility function to format dates
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import TitlePage from "../../components/title-page/TitlePage";

const initialFilterState = {
  curso: "",
  uf: "",
  municipio: "",
  modalidade: "",
  situacao: "",
};

const PageTrilhasCurso = () => {
  const [filtros, setFiltros] = useState(initialFilterState);
  const [filtrosAplicados, setFiltrosAplicados] = useState(filtros);

  const aplicarFiltros = () => {
    setFiltrosAplicados(filtros);
  };

  const resetFilter = () => {
    setFiltros(initialFilterState);
    setFiltrosAplicados(initialFilterState);
  };

  const linksBreadCrumb = [{ href: "/", text: "Cursos" }];

  const dadosInit = [
    {
      id: 1,
      curso: "Curso 1",
      municipio: "Brasilia / DF",
      uf: "DF",
      modalidade: "Descrição do campo 01",
      situacao: "Situação curso 1",
      img: "",
    },
    {
      id: 2,
      curso: "Curso 2",
      municipio: "São Paulo / SP",
      uf: "SP",
      modalidade: "Descrição do campo 02",
      situacao: "Situação curso 1",
      img: "",
    },
    {
      id: 3,
      curso: "Curso 3",
      municipio: "Brasilia / DF",
      uf: "DF",
      modalidade: "Descrição do campo 03",
      situacao: "Situação curso 1",
      img: "",
    },
    {
      id: 4,
      curso: "Curso 4",
      municipio: "Rio de Janeiro / RJ",
      uf: "RJ",
      modalidade: "Descrição do campo 04",
      situacao: "Situação curso 1",
      img: "",
    },
  ];

  const dadosFiltrados = useMemo(() => {
    return dadosInit.filter(item =>
      Object.keys(filtrosAplicados).every(coluna => {
        const valorFiltro = filtrosAplicados[coluna];
        const valorItem = item[coluna];

        if (typeof valorFiltro === "string" && typeof valorItem === "string") {
          return valorItem.toLowerCase().includes(valorFiltro.toLowerCase());
        }

        return true;
      }),
    );
  }, [filtrosAplicados]);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Trilha de cursos em oferta"
        links={linksBreadCrumb}
      />

      <TitlePage title={"Listar Cursos"} />

      <Card>
        <Grid
          container
          spacing={2}
          sx={{ p: 2 }}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "16",
                fontFamily: "Rawline Regular",
              }}
            >
              Filtros
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            key={"curso"}
          >
            <TextField
              label={"Curso"}
              variant="filled"
              fullWidth
              value={filtros["curso"]}
              onChange={e => setFiltros({ ...filtros, ["curso"]: e.target.value })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            key={"uf"}
          >
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-uf`}>{"UF"}</InputLabel>
              <Select
                labelId={`label-uf`}
                id={"uf"}
                name={"uf"}
                value={filtros["uf"]}
                onChange={e => setFiltros({ ...filtros, ["uf"]: e.target.value })}
              >
                <MenuItem
                  value=""
                  disabled
                >
                  Selecione uma opção
                </MenuItem>
                {dadosInit.map(item => (
                  <MenuItem
                    key={`key-${item["uf"]}`}
                    value={item["uf"]}
                  >
                    {item["uf"]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            key={"municipio"}
          >
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-municipio`}>{"Município"}</InputLabel>
              <Select
                labelId={`label-municipio`}
                id={"municipio"}
                name={"municipio"}
                value={filtros["municipio"]}
                onChange={e => setFiltros({ ...filtros, ["municipio"]: e.target.value })}
              >
                <MenuItem
                  value=""
                  disabled
                >
                  Selecione uma opção
                </MenuItem>
                {dadosInit.map(item => (
                  <MenuItem
                    key={`key-${item["municipio"]}`}
                    value={item["municipio"]}
                  >
                    {item["municipio"]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            key={"modalidade"}
          >
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-modalidade`}>{"Modalidade"}</InputLabel>
              <Select
                labelId={`label-modalidade`}
                id={"modalidade"}
                name={"modalidade"}
                value={filtros["modalidade"]}
                onChange={e => setFiltros({ ...filtros, ["modalidade"]: e.target.value })}
              >
                <MenuItem value="">Selecione uma opção</MenuItem>
                {dadosInit.map(item => (
                  <MenuItem
                    key={`key-${item["modalidade"]}`}
                    value={item["modalidade"]}
                  >
                    {item["modalidade"]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            key={"situacao"}
          >
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-situacao`}>{"Situação"}</InputLabel>
              <Select
                labelId={`label-situacao`}
                id={"situacao"}
                name={"situacao"}
                value={filtros["situacao"]}
                onChange={e => setFiltros({ ...filtros, ["situacao"]: e.target.value })}
              >
                <MenuItem value="">Selecione uma opção</MenuItem>
                {dadosInit.map(item => (
                  <MenuItem
                    key={`key-${item["situacao"]}`}
                    value={item["situacao"]}
                  >
                    {item["situacao"]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px 10px" }}
          >
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                fullWidth
                onClick={() => resetFilter()}
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                type="submit"
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={aplicarFiltros}
                fullWidth
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sx={{ mt: "16px" }}
            spacing={2}
          >
            {dadosFiltrados.map(dado => (
              <Grid
                item
                md={4}
                key={dado.id}
                sx={{ mt: "16px" }}
              >
                <Link
                  to={`/cursos/trilhas/visualizar/${dado.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card>
                    {dado.img ? (
                      <CardMedia
                        sx={{ height: 140 }}
                        image={dado.img}
                        title={dado.curso}
                      />
                    ) : (
                      <IconButton
                        sx={{ height: 140, justifyContent: "center", alignItems: "center", display: "flex", width: "100%" }}
                        disableRipple
                      >
                        <ImageNotSupportedIcon fontSize="large" />
                      </IconButton>
                    )}
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                        {dado.curso}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                      >
                        {dado.modalidade}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default PageTrilhasCurso;

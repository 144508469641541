import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import parceiroService from "../../services/parceiro.service";
import AprovarOuReprovarCurso from "../modals/AprovarOuReprovarCurso";
import InativarCurso from "../modals/InativarCurso";
import DefaultTable from "../table/DefaultTable";

const tabelaColunas = [
  { field: "nomeDoCurso", headerName: "Curso" },
  { field: "nomeOfertante", headerName: "Ofertante" },
];

const keysHidden = ["cnpj", "status", "vigencia", "dataUltimaModificacao"];

const ParceiroListarCursos = ({ initialData }) => {
  const [inativarCurso, setInativarCurso] = useState(false);
  const [reprovarCurso, setReprovarCurso] = useState(false);
  const [idCurso, setIdCurso] = useState();
  const navigate = useNavigate();

  // console.log(initialData);
  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
      onClick: rowId => navigate("/cursos/visualizar/" + rowId),
    },
    {
      title: "Editar",
      href: "",
      icon: "create",
      onClick: rowId => navigate("/cursos/editar/" + rowId),
    },
    {
      title: "Duplicar",
      href: "",
      icon: "file_copy",
      onClick: rowId => navigate("/cursos/duplicar/" + rowId),
    },
    {
      title: "Interessados",
      href: "",
      icon: "person",
      onClick: rowId => navigate("/cursos/interessados/" + rowId),
    },
    {
      title: "Inativar",
      icon: "delete",
      onClick: rowId => {
        setInativarCurso(true);
        setIdCurso(rowId);
      },
    },
  ];

  const excluirCurso = async motivo => {
    await parceiroService.excluirCurso(idCurso, motivo);
    setIdCurso();
    setInativarCurso(false);
    window.location.reload();
  };

  const fecharInativarCurso = () => {
    setInativarCurso(false);
  };

  const fecharReprovarCurso = () => {
    setReprovarCurso(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Tabela */}
          <Box sx={{ flexGrow: 1 }}>
            <DefaultTable
              rows={initialData}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
              termos={{
                cnpj: "CNPJ Ofertante",
                status: "Status",
                vigencia: "Vigência",
                dataUltimaModificacao: "Data da Última Modificação",
              }}
            />
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <InativarCurso
          showModal={inativarCurso}
          handleExcluirCurso={excluirCurso}
          handleClose={fecharInativarCurso}
        />
        <AprovarOuReprovarCurso
          modalTitle="Reprovar curso"
          showModal={reprovarCurso}
          handleClose={fecharReprovarCurso}
        />
      </div>
    </>
  );
};

ParceiroListarCursos.propTypes = {
  initialData: PropTypes.array,
};

export default ParceiroListarCursos;

import React from "react";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminListarParceirosAprovados from "../../components/filtros/AdminListarParceirosAprovados";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageParceirosAprovados = () => {
  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
  } = useQuery({
    queryKey: ["empresasParceirasAprovadas"],
    queryFn: () => adminService.postEmpresasParceirasStatus(["APR"]),
    staleTime: Infinity, // Evita repeat
    retry: false,
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        homeLink="/home"
        homeText="Admin"
        currentPage="Listar Parceiros Aprovados"
      />

      <TitlePage title={"Listar Parceiros Aprovados"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : (
        <AdminListarParceirosAprovados initialData={initialData} />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageParceirosAprovados;

import React from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";

const CardHome = ({ title, content, imageUrl }) => {
  const theme = useTheme();

  const smUp = useResponsive("up", "sm");

  const imageWidth = smUp ? "300px" : "150px";
  const imageHeight = smUp ? "200px" : "100px";

  return (
    <div style={{ paddingTop: smUp ? "40px" : "0px", overflowY: "hidden", display: "flex" }}>
      <div
        style={{
          background: theme.palette.background.paper,
          color: theme.palette.text.primary,
          border: theme.palette.border.card,
          borderRadius: "0px",
          width: "100%",
          display: "flex",
          flexDirection: smUp ? "Row" : "column",
          alignItems: smUp ? "flex-end" : "flex-start",
          minHeight: "calc( " + imageHeight + " - 60px)",
        }}
      >
        <div
          style={{
            width: smUp ? "calc(100% - " + imageWidth + ")" : "100%",
            padding: "1rem",
            height: "100%",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            {title}
          </Typography>

          {content}
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <img
            alt="Imagem do Card"
            height={imageHeight}
            src={imageUrl}
            style={{
              objectFit: "fill",
              width: imageWidth,
              marginTop: smUp ? "-40px" : "0px",
              overflowY: "hidden",
            }}
          />
        </div>
      </div>
    </div>
  );
};

CardHome.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
  imageUrl: PropTypes.string,
  subContent: PropTypes.string,
};

export default CardHome;

import React from "react";

const TermoDeUso = () => {
  return (
    <>
      <h2>
        <strong>1. ACEITAÇÃO DO TERMO DE USO</strong>
      </h2>
      <p>
        O presente Termo de Uso se refere a um contrato de adesão firmado entre o usuário e o fornecedor deste serviço, o Ministério do
        Desenvolvimento e Assistência Social, Família e Combate à Fome, localizado na Esplanada dos Ministérios, bloco A 8º andar, Brasília
        – DF.
      </p>
      <p>
        O uso deste serviço, denominado <strong>Aplicativo PROGRAMA ACREDITA NO PRIMEIRO PASSO</strong>, está condicionado à aceitação dos
        termos e das políticas associadas. O usuário deverá ler tais termos e políticas, certificar-se de havê-los entendido, estar
        consciente de todas as condições estabelecidas no Termo de Uso e se comprometer a cumpri-las.
      </p>
      <p>
        Ao utilizar o serviço do aplicativo PROGRAMA ACREDITA NO PRIMEIRO PASSO, o usuário manifesta estar de acordo com relação ao conteúdo
        deste Termo de Uso e estará legalmente vinculado a todas as condições aqui previstas.
      </p>

      <h2>
        <strong>2. DEFINIÇÕES DO TERMO DE USO</strong>
      </h2>
      <p>Para os fins deste Termo de Uso, são aplicáveis as seguintes definições:</p>
      <table>
        <thead>
          <tr>
            <th>Termo</th>
            <th>Definição</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Agente público</td>
            <td>
              Todo aquele que exerce, ainda que transitoriamente ou sem remuneração, por eleição, nomeação, designação, contratação ou
              qualquer outra forma de investidura ou vínculo, mandato, cargo, emprego ou função nos órgãos e entidades da Administração
              Pública, direta e indireta.
            </td>
          </tr>
          <tr>
            <td>Agentes de Estado</td>
            <td>Inclui órgãos e entidades da Administração pública além dos seus agentes públicos.</td>
          </tr>
          <tr>
            <td>Códigos maliciosos</td>
            <td>
              São qualquer programa de computador, ou parte de um programa, construído com a intenção de provocar danos, obter informações
              não autorizadas ou interromper o funcionamento de sistemas e/ou redes de computadores.
            </td>
          </tr>
          <tr>
            <td>Sítios e aplicativos</td>
            <td>Sítios e aplicativos por meio dos quais o usuário acessa os serviços e conteúdos disponibilizados.</td>
          </tr>
          <tr>
            <td>Terceiro</td>
            <td>
              Pessoa ou entidade que não participa diretamente em um contrato, em um ato jurídico ou em um negócio, ou que, para além das
              partes envolvidas, pode ter interesse num processo jurídico.
            </td>
          </tr>
          <tr>
            <td>Internet</td>
            <td>
              Sistema constituído do conjunto de protocolos lógicos, estruturado em escala mundial para uso público e irrestrito, com a
              finalidade de possibilitar a comunicação de dados entre terminais por meio de diferentes redes.
            </td>
          </tr>
          <tr>
            <td>Beneficiário</td>
            <td>Pessoas assistidas pelo Programa PROGRAMA ACREDITA NO PRIMEIRO PASSO do Governo Federal.</td>
          </tr>
          <tr>
            <td>Usuários</td>
            <td>
              (ou &lsquo;Usuário&rsquo;, quando individualmente considerado): Todas as pessoas cadastradas no CADÚNICO que utilizarem o
              aplicativo.
            </td>
          </tr>
        </tbody>
      </table>

      <h2>
        <strong>3. ARCABOUÇO LEGAL</strong>
      </h2>
      <p>
        O arcabouço legal aplicável ao serviço do Aplicativo PROGRAMA ACREDITA NO PRIMEIRO PASSO compreende os seguintes atos legislativos e
        normativos:
      </p>
      <table>
        <thead>
          <tr>
            <th>Ato</th>
            <th>Definição</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Lei nº 12.965, de 23 de abril de 2014 - Marco Civil da Internet</td>
            <td>Estabelece princípios, garantias, direitos e deveres para o uso da Internet no Brasil.</td>
          </tr>
          <tr>
            <td>Lei nº 12.527, de 18 de novembro de 2011 - Lei de Acesso à Informação</td>
            <td>Regula o acesso a informações previsto na Constituição Federal.</td>
          </tr>
          <tr>
            <td>Lei nº 13.460, de 26 de junho de 2017</td>
            <td>Dispõe sobre participação, proteção e defesa dos direitos do usuário dos serviços públicos da administração pública.</td>
          </tr>
          <tr>
            <td>Lei nº 13.709, de 14 de agosto de 2018</td>
            <td>
              Dispõe sobre o tratamento de dados pessoais, inclusive nos meios digitais, por pessoa natural ou por pessoa jurídica de
              direito público ou privado, com o objetivo de proteger os direitos fundamentais de liberdade e de privacidade e o livre
              desenvolvimento da personalidade da pessoa natural.
            </td>
          </tr>
          <tr>
            <td>Lei nº 13.444, de 11 de maio de 2017</td>
            <td>Dispõe sobre a Identificação Civil Nacional (ICN).</td>
          </tr>
          <tr>
            <td>Decreto nº 8.777, de 11 de maio de 2016</td>
            <td>Institui a Política de Dados Abertos do Poder Executivo federal.</td>
          </tr>
          <tr>
            <td>Decreto nº 7.724, de 16 de maio de 2012</td>
            <td>
              Regulamenta a Lei no 12.527, de 18 de novembro de 2011 (Lei de Acesso à Informação), que dispõe sobre o acesso a informações
              previstas na Constituição.
            </td>
          </tr>
          <tr>
            <td>Decreto nº 7.845, de 14 de novembro de 2012</td>
            <td>
              Regulamenta procedimentos para credenciamento de segurança e tratamento de informação classificada em qualquer grau de sigilo,
              e dispõe sobre o Núcleo de Segurança e Credenciamento.
            </td>
          </tr>
          <tr>
            <td>Decreto nº 10.046, de 09 de outubro de 2019</td>
            <td>
              Dispõe sobre a governança no compartilhamento de dados no âmbito da administração pública federal e institui o Cadastro Base
              do Cidadão e o Comitê Central de Governança de Dados.
            </td>
          </tr>
          <tr>
            <td>Normas complementares do Gabinete de Segurança da Informação da Presidência (GSI/PR)</td>
            <td>
              Disciplinam a Gestão de Segurança da Informação e Comunicações na Administração Pública Federal, direta e indireta, e dá
              outras providências.
            </td>
          </tr>
          <tr>
            <td>Decreto nº 9.637, de 26 de dezembro de 2018</td>
            <td>
              Institui a Política Nacional de Segurança da Informação, dispõe sobre a governança da segurança da informação, e altera o
              Decreto nº 2.295, de 4 de agosto de 1997, que regulamenta o disposto no art. 24, caput, inciso IX, da Lei nº 8.666, de 21 de
              junho de 1993, e dispõe sobre a dispensa de licitação nos casos que possam comprometer a segurança nacional.
            </td>
          </tr>
          <tr>
            <td>Lei nº 12.737, de 30 de novembro de 2012</td>
            <td>Dispõe sobre a tipificação criminal de delitos informáticos.</td>
          </tr>
        </tbody>
      </table>

      <h2>
        <strong>4. DESCRIÇÃO DO SERVIÇO</strong>
      </h2>
      <ul>
        <li>
          Cursos em Oferta – Esta opção permite que o usuário verifique a relação de cursos de qualificação disponíveis, registrem interesse
          e se inscrevam nos cursos.
        </li>
        <li>
          Meu Currículo - Esta opção permite que o usuário elabore seu currículo para se candidatar a vagas de emprego a relação de cursos
          de qualificação disponíveis, registrem interesse e se inscrevam nos cursos.
        </li>
        <li>
          Vagas de Emprego Abertas - Esta opção permite que o usuário verifique a relação das vagas de emprego disponíveis e se candidate a
          estas vagas.
        </li>
        <li>Minhas Inscrições em vagas de Emprego: Esta opção permite que o usuário acompanhe suas inscrições em vagas de emprego.</li>
        <li>
          Cursos em oferta: Esta opção permite que o usuário verifique a relação de cursos de qualificação disponíveis, registrem interesse
          e se inscrevam nos cursos.
        </li>
        <li>
          Meus Cursos favoritos – Esta opção permite que o usuário verifique a relação de cursos de qualificação que registrou interesse.
        </li>
        <li>
          Microcrédito e incentivos: Esta opção permite que o usuário preencha o formulário quero ser empreendedor ou já sou empreendedor
          para ter acesso a microcrédito por instituições financeiras.
        </li>
      </ul>

      <h2>
        <strong>5. DIREITOS DO USUÁRIO DO SERVIÇO </strong>
      </h2>
      <p>De acordo com a Lei nº 13.460, de 26 de junho de 2017, são direitos básicos do usuário:</p>
      <ol style={{ listStyleType: "upper-roman" }}>
        <li>Participação no acompanhamento da prestação e na avaliação dos serviços;</li>
        <li>Obtenção e utilização dos serviços com liberdade de escolha entre os meios oferecidos e sem discriminação;</li>
        <li>
          Acesso e obtenção de informações relativas à sua pessoa constantes de registros ou bancos de dados, observado o disposto no inciso
          X do caput do art. 5º da Constituição Federal e na Lei nº 12.527, de 18 de novembro de 2011;
        </li>
        <li>Proteção de suas informações pessoais, nos termos da Lei nº 12.527, de 18 de novembro de 2011;</li>
        <li>Atuação integrada e sistêmica na expedição de atestados, certidões e documentos comprobatórios de regularidade; e</li>
        <li>
          Obtenção de informações precisas e de fácil acesso nos locais de prestação do serviço, assim como sua disponibilização na
          internet, especialmente sobre:
        </li>
      </ol>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>horário de funcionamento das unidades administrativas;</li>
        <li>
          serviços prestados pelo órgão ou entidade, sua localização exata e a indicação do setor responsável pelo atendimento ao público;
        </li>
        <li>acesso ao agente público ou ao órgão encarregado de receber manifestações;</li>
        <li>situação da tramitação dos processos administrativos em que figure como interessado; e</li>
        <li>
          valor das taxas e tarifas cobradas pela prestação dos serviços, contendo informações para a compreensão exata da extensão do
          serviço prestado.
        </li>
      </ol>
      <p>
        O cidadão ao usar os serviços possui os seguintes direitos, conferidos pela Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de
        Proteção de Dados Pessoais - LGPD):
      </p>
      <ul>
        <li>
          Direito de confirmação e acesso (Art. 18, I e II): direito de obter do serviço a confirmação de que os dados pessoais que lhe
          digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais tratados.
        </li>
        <li>Direito de retificação (Art. 18, III): direito de solicitar a correção de dados incompletos, inexatos ou desatualizados.</li>
        <li>
          Direito à limitação do tratamento dos dados (Art. 18, IV): direito de limitar o tratamento de seus dados pessoais, podendo exigir
          a eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei Federal n. 13.709, de 14 de
          agosto de 2018 (Lei de Proteção de Dados Pessoais - LGPD).
        </li>
        <li>
          Direito de oposição (Art. 18, § 2º): direito de, a qualquer momento, se opor ao tratamento de dados por motivos relacionados com a
          sua situação particular, com fundamento em uma das hipóteses de dispensa de consentimento ou em caso de descumprimento ao disposto
          na Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais - LGPD). A aplicabilidade deste direito
          acarretará a incompatibilidade do serviço proposto e o consequente desfazimento da relação do cidadão com o Estado.
        </li>
        <li>
          Direito de não ser submetido a decisões automatizadas (Art. 20): direito de solicitar a revisão de decisões tomadas unicamente com
          base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definir o seu
          perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade.
        </li>
      </ul>

      <h2>
        <strong>6. RESPONSABILIDADES DO USUÁRIO</strong>
      </h2>
      <p>Quais são as obrigações dos usuários que utilizam o serviço?</p>
      <p>
        O usuário se responsabiliza pela precisão e pela veracidade dos dados informados e reconhece que a inconsistência deles poderá
        implicar a impossibilidade de se utilizar o serviço do <strong>PROGRAMA ACREDITA NO PRIMEIRO PASSO</strong>.
      </p>
      <p>
        Durante a utilização do serviço, a fim de resguardar e proteger os direitos de terceiros, o usuário se compromete a fornecer somente
        seus dados pessoais, e não os de terceiros.
      </p>
      <p>
        O login e senha só poderão ser utilizados pelo usuário cadastrado. Ele se compromete em manter o sigilo da senha, que é pessoal e
        intransferível, não sendo possível, em qualquer hipótese, a alegação de uso indevido após o ato de compartilhamento.
      </p>
      <p>
        O usuário do serviço é responsável pela atualização dos seus dados pessoais e pelas consequências em caso de omissão ou erros nos
        dados fornecidos.
      </p>
      <p>
        O Usuário é responsável pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de
        quaisquer direitos de outros usuários; de terceiros, inclusive direitos de propriedade intelectual; de sigilo; e de personalidade),
        que sejam causados à Administração Pública, a qualquer outro Usuário, ou ainda a qualquer terceiro, inclusive em virtude do
        descumprimento do disposto nestes Termos de Uso e Política de Privacidade ou de qualquer ato praticado a partir de seu acesso ao
        serviço.
      </p>
      <p>
        O Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome não poderá ser responsabilizado pelos seguintes
        fatos:
      </p>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>) Equipamento infectado ou invadido por atacantes;</li>
        <li>) Equipamento avariado no momento do consumo de serviços;</li>
        <li>) Proteção do computador;</li>
        <li>) Proteção de dispositivos de smartphones;</li>
        <li>) Proteção das informações baseadas nos dispositivos dos usuários;</li>
        <li>) Abuso de uso dos computadores e dispositivos eletrônicos dos usuários;</li>
        <li>) Monitoração clandestina do computador e dispositivos eletrônicos dos usuários;</li>
        <li>) Vulnerabilidades ou instabilidades existentes nos sistemas dos usuários;</li>
        <li>) Perímetro inseguro.</li>
      </ol>
      <p>
        Em nenhuma hipótese, a Administração Pública Federal será responsável pela instalação, no equipamento do Usuário ou de terceiros, de
        códigos maliciosos (vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros que venham a ser
        criados), em decorrência da navegação na Internet pelo Usuário.
      </p>

      <h2>
        <strong>7. RESPONSABILIDADE DA ADMINISTRAÇÃO PÚBLICA</strong>
      </h2>
      <p>Quais são as responsabilidades da Administração Pública com os meus dados?</p>
      <p>
        A Administração Pública se compromete a cumprir todas as legislações inerentes ao uso correto dos dados pessoais do cidadão de forma
        a preservar a privacidade dos dados utilizados no serviço, bem como a garantir todos os direitos e garantias legais dos titulares
        dos dados. Ela também se obriga a promover, independentemente de requerimentos, a divulgação em local de fácil acesso, no âmbito de
        suas competências, de informações de interesse coletivo ou geral por eles produzidas ou custodiadas. É de responsabilidade da
        Administração Pública implementar controles de segurança para proteção dos dados pessoais dos cidadãos.
      </p>
      <p>
        A Administração Pública poderá, quanto às ordens judiciais de pedido das informações, compartilhar informações necessárias para
        investigações ou tomar medidas relacionadas a atividades ilegais, suspeitas de fraude ou ameaças potenciais contra pessoas, bens ou
        sistemas que sustentam o Serviço ou de outra forma necessárias para cumprir com obrigações legais. Caso ocorra, a Administração
        Pública notificará os titulares dos dados, salvo quando o processo estiver em segredo de justiça.
      </p>

      <h2>
        <strong>8. POLÍTICA DE PRIVACIDADE</strong>
      </h2>
      <p>
        Esta Política de Privacidade estabelecida pelo Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome e
        utilizada pelo Aplicativo PROGRAMA ACREDITA NO PRIMEIRO PASSO trata da utilização de dados pessoais.
      </p>
      <p>
        Essa Política específica faz parte de forma inerente do presente Termo de Uso, ressaltando-se que os dados pessoais mencionados por
        esse Serviço serão tratados nos termos da legislação em vigor.
      </p>
      <p>
        O usuário do aplicativo PROGRAMA ACREDITA NO PRIMEIRO PASSO poderá verificar a qualquer momento este Termo de Uso e/ou Política de
        Privacidade, através no sítio:
      </p>
      <a href="https://programaacreditanoprimeiropasso-termo-privacidade.mds.gov.br/">
        https://programaacreditanoprimeiropasso-termo-privacidade.mds.gov.br/
      </a>

      <h2>
        <strong>9. MUDANÇAS NO TERMO DE USO</strong>
      </h2>
      <p>Este Termo de Uso pode ser alterado?</p>
      <p>A presente versão deste Termo de Uso foi atualizada pela última vez em: 20/08/2024.</p>
      <p>
        O Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome se reserva o direito de modificar, a qualquer
        momento, as presentes cláusulas, especialmente para adaptá-las às evoluções do serviço, seja pela disponibilização de novas
        funcionalidades, seja pela supressão ou modificação daquelas já existentes.
      </p>
      <p>
        Qualquer alteração e/ou atualização do Termos de Uso e da Política de Privacidade passará a vigorar a partir da data de sua
        publicação no sítio do serviço e deverá ser integralmente observada pelos Usuários.
      </p>

      <h2>
        <strong>10. INFORMAÇÕES PARA CONTATO</strong>
      </h2>
      <p>
        Em caso de dúvidas relacionadas ao Aplicativo PROGRAMA ACREDITA NO PRIMEIRO PASSO, entre em contato pelo e-mail:{" "}
        <a href="mailto:cgad@mds.gov.br">cgad@mds.gov.br</a>.
      </p>

      <h2>
        <strong>11. FORO</strong>
      </h2>
      <p>
        Este Termo será regido pela legislação brasileira. Qualquer reclamação ou controvérsia com base neste Termo será dirimida
        exclusivamente pela Justiça Federal, na seção judiciária do domicílio do cidadão, por previsão do artigo 109, §§ 1º, 2º e 3º, da
        Constituição Federal.
      </p>
      <p>
        Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, todos os cidadãos têm direito a apresentar petição contra
        o controlador (Gabinete do Ministro do Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome), referente a
        seus dados pessoais, à Autoridade Nacional de Proteção de Dados (ANPD) - Art. 18, § 1º, da Lei nº 13.709, de 14 de agosto de 2018
        (Lei Geral de Proteção de Dados - LGPD) - e desde que tenha havido reclamação formal anterior não solucionada no prazo pelo
        controlador – Art. 55-J, V, Lei Geral de Proteção de Dados – LGPD.
      </p>
    </>
  );
};

export default TermoDeUso;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { tabelaVagasTrabalho } from "../formularios/dadosMockados";
import TrilhaModal from "../modals/TrilhaModal";
import DefaultTable from "../table/DefaultTable";

const termos = {
  id: "ID",
  nome: "Nome",
  // vagas: "Vagas",
  interessados: "Interessados",
  situacao: "Situação vaga",
  validadeAnuncio: "Validade",
  // dataCadastro: "Data de cadastro",
  funcao: "Função",
  tipoVaga: "Tipo de vaga",
};

// Dados fictícios para teste da tabela
// const initialData = tabelaVagasTrabalho;

// Retornar a pesquisa vazia
const initialFilterState = {
  nomeParceiro: "",
  situacaoParceiro: "",
  tipo: "",
  funcao: "",
  situacao: "",
  validade: "",
};

const tabelaColunas = [
  { field: "nomeParceiro", headerName: "Parceiro" },
  { field: "situacaoParceiro", headerName: "Situação Parceiro" },
];

const keysHidden = ["funcao", "tipoVaga", "situacao", "interessados", "validadeAnuncio"];

const AdminListarVagasTrabalho = ({ initialData }) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);
  const [title, setTitle] = useState("");
  const [modal, setModal] = useState(false);

  const applyFilter = () => {
    const filtered = initialData.filter(vaga => {
      return (
        (filter.nomeParceiro == "none" || vaga.nomeParceiro == filter.nomeParceiro) &&
        (filter.situacaoParceiro == "none" || vaga.situacaoParceiro == filter.situacaoParceiro) &&
        (filter.tipoVaga == "none" || vaga.tipoVaga == filter.tipoVaga) &&
        (filter.funcao == "none" || vaga.funcao == filter.funcao) &&
        (filter.situacao == "none" || vaga.situacao == filter.situacao) &&
        (filter.validadeAnuncio == "none" || vaga.validadeAnuncio == filter.validadeAnuncio)
      );
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setFilteredData(initialData);
    applyFilter();
  };

  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const fecharModal = () => {
    setModal(false);
  };

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      icon: "visibility",
      onClick: rowId => navigate("/vagas/visualizar/" + rowId),
    },
    {
      title: "Inativar",
      icon: "cancel",
      onClick: rowId => {
        setModal(true);
        setTitle("Inativar Vaga: " + tabelaVagasTrabalho.find(vaga => vaga.id == rowId).nome);
      },
    },
    {
      title: "Interessados",
      icon: "person",
    },
  ];

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">Filtros</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-nomeParceiro">Parceiro</InputLabel>
                <Select
                  id="nomeParceiro"
                  name="nomeParceiro"
                  value={filter.nomeParceiro}
                  labelId="label-nomeParceiro"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Parceiro
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-situacaoParceiro">Situação Parceiro</InputLabel>
                <Select
                  id="situacaoParceiro"
                  name="situacaoParceiro"
                  value={filter.situacaoParceiro}
                  labelId="label-situacaoParceiro"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Situação Parceiro
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-tipo">Tipo de Vaga</InputLabel>
                <Select
                  id="tipo"
                  name="tipo"
                  value={filter.tipo}
                  labelId="label-tipo"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Tipo de Vaga
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-funcao">Função</InputLabel>
                <Select
                  id="funcao"
                  name="funcao"
                  value={filter.funcao}
                  labelId="label-funcao"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Função
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-situacao">Situação Vaga</InputLabel>
                <Select
                  id="situacao"
                  name="situacao"
                  value={filter.situacao}
                  labelId="label-situacao"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Situação Vaga
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-validade">Validade</InputLabel>
                <Select
                  id="validade"
                  name="validade"
                  value={filter.validade}
                  labelId="label-validade"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Validade
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  LIMPAR
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <FilterAltIcon />
                  APLICAR FILTRO
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Cursos na situação aprovado!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
              termos={termos}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <TrilhaModal
          modalTitle={title}
          showModal={modal}
          handleClose={fecharModal}
        />
      </div>
    </>
  );
};

AdminListarVagasTrabalho.propTypes = {
  initialData: PropTypes.array,
  listaEmpresas: PropTypes.array,
};

export default AdminListarVagasTrabalho;

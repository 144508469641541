import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";

import { Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const FormInteresse = forwardRef(({ defaultValues, onSubmit }, ref) => {
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
    getValues,
  } = useForm({
    defaultValues: defaultValues || {
      emprego: false,
      estagio: false,
      estagioTermos: false,
      jovemAprendiz: false,
      jovemAprendizTermos: false,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const estagio = watch("estagio");
  const jovemAprendiz = watch("jovemAprendiz");

  useEffect(() => {
    if (!estagio) {
      setValue("estagioTermos", false);
    }
    trigger("estagioTermos");
  }, [estagio, setValue, trigger]);

  useEffect(() => {
    if (!jovemAprendiz) {
      setValue("jovemAprendizTermos", false);
    }
    trigger("jovemAprendizTermos");
  }, [jovemAprendiz, setValue, trigger]);

  const handleFormSubmit = data => {
    const formData = {
      ...data,
    };
    console.log("Form data:", formData);
    if (onSubmit) {
      onSubmit(formData);
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      console.log("SUBMIT FORM INTERESSE");
      handleSubmit(handleFormSubmit)();
    },
    getFormValues: async () => {
      // Trigger validation for all fields
      const isValid = await trigger();
      const values = getValues();
      const currentErrors = errors;

      return {
        values: { ...values },
        errors: isValid ? null : currentErrors,
      };
    },
  }));

  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ mb: 2 }}
        >
          <p style={{ fontFamily: "Rawline Bold", fontSize: "16px" }}>Eu tenho interesse em oportunidade de:</p>
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              mb={1}
            >
              <div style={{ border: "1px solid lightGrey", borderRadius: "5px", padding: "8px" }}>
                <FormGroup>
                  <Controller
                    name="emprego"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            style={{ color: theme.palette.text.primary }}
                          />
                        }
                        label={<span style={{ fontFamily: "Rawline Bold", fontSize: "14px" }}>Emprego</span>}
                      />
                    )}
                  />
                </FormGroup>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              mb={1}
            >
              <div style={{ border: "1px solid lightGrey", borderRadius: "5px", padding: "8px" }}>
                <FormGroup>
                  <Controller
                    name="estagio"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            style={{ color: theme.palette.text.primary }}
                          />
                        }
                        label={<span style={{ fontFamily: "Rawline Bold", fontSize: "14px" }}>Estágio</span>}
                      />
                    )}
                  />
                  <Controller
                    name="estagioTermos"
                    control={control}
                    rules={{
                      validate: value => !estagio || value || "Você deve aceitar os termos para prosseguir",
                    }}
                    render={({ field }) => (
                      <FormControlLabel
                        style={{ display: "flex", alignItems: "flex-start" }}
                        sx={{ ml: 2 }}
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            disabled={!estagio}
                            style={{ color: theme.palette.text.primary }}
                          />
                        }
                        label={
                          <span style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
                            Estou ciente que o Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome e o Programa
                            Acredita no Primeiro Passo exercem o papel de facilitador no processo de intermediação de mão de obra e não se
                            responsabiliza pela contratação do estagiário em nenhuma fase. E me enquadro em uma das seguintes situações: i)
                            estudante matriculado regularmente em instituições de educação superior, ii) de educação profissional, iii) de
                            ensino médio, iv) da educação especial ou dos v) anos finais do ensino fundamental, na modalidade profissional
                            da educação de jovens e adultos (EJA).
                          </span>
                        }
                      />
                    )}
                  />
                  {errors.estagioTermos && <FormHelperText error>{errors.estagioTermos.message}</FormHelperText>}
                </FormGroup>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <div style={{ border: "1px solid lightGrey", borderRadius: "5px", padding: "8px" }}>
                <FormGroup>
                  <Controller
                    name="jovemAprendiz"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            style={{ color: theme.palette.text.primary }}
                          />
                        }
                        label={<span style={{ fontFamily: "Rawline Bold", fontSize: "14px" }}>Jovem Aprendiz</span>}
                      />
                    )}
                  />
                  <Controller
                    name="jovemAprendizTermos"
                    control={control}
                    rules={{
                      validate: value => !jovemAprendiz || value || "Você deve aceitar os termos para prosseguir",
                    }}
                    render={({ field }) => (
                      <FormControlLabel
                        style={{ display: "flex", alignItems: "flex-start" }}
                        sx={{ ml: 2 }}
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            disabled={!jovemAprendiz}
                            style={{ color: theme.palette.text.primary }}
                          />
                        }
                        label={
                          <span style={{ fontFamily: "Rawline Regular", fontSize: "14px" }}>
                            Estou ciente que o Ministério do Desenvolvimento e Assistência Social, Família e Combate à Fome e o Programa
                            Acredita no Primeiro Passo exercem o papel de facilitador no processo de intermediação de mão de obra e não se
                            responsabiliza pela contratação do estagiário em nenhuma fase. E me enquadro em uma das seguintes situações: i)
                            estudante matriculado regularmente em instituições de educação superior, ii) de educação profissional, iii) de
                            ensino médio, iv) da educação especial ou dos v) anos finais do ensino fundamental, na modalidade profissional
                            da educação de jovens e adultos (EJA).
                          </span>
                        }
                      />
                    )}
                  />
                  {errors.jovemAprendizTermos && <FormHelperText error>{errors.jovemAprendizTermos.message}</FormHelperText>}
                </FormGroup>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </div>
  );
});

FormInteresse.displayName = "FormInteresse";

FormInteresse.propTypes = {
  defaultValues: PropTypes.shape({
    emprego: PropTypes.bool,
    estagio: PropTypes.bool,
    estagioTermos: PropTypes.bool,
    jovemAprendiz: PropTypes.bool,
    jovemAprendizTermos: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
};

export default FormInteresse;

import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminInativarEditarParceiro from "../../components/formularios/AdminInativarEditarParceiro";
import TitlePage from "../../components/title-page/TitlePage";

const PageInativarParceiro = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiros/reprovados", text: "Listar Parceiros Reprovados" }]}
        currentPage="Inativar Parceiro"
      />

      <TitlePage title={"Inativar"} />

      <AdminInativarEditarParceiro
        inativar={true}
        saveString="CONFIRMAR INATIVAÇÃO"
      />
    </Container>
  );
};

export default PageInativarParceiro;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { st_pronatec, tp_curso, tp_escolaridade, tp_modalidade, tp_turno } from "../../services/constants.js";
import optionsService from "../../services/options.service.js";
import CustomDatePicker from "../formularios/CustomDatePicker";
import { formatCNPJ, formatSite } from "../formularios/utils.js";
import FileUpload from "../inputs/fileUpload.js";
import { FormFieldsGrid } from "./DynamicForm.js";
import FormAddressSchool from "./FormAddressSchool.js";

const valoresIniciais = {
  cnpjOfertante: "",
  nomeOfertante: "",
  cargaHoraria: "",
  turno: "",
  nomeDoCurso: "",
  escolaridade: "",
  tipoDeCurso: "",
  pronatec: "",
  preRequisitos: [],
  dataMatriculaInicio: null,
  dataMatriculaFim: null,
  idadeMinima: "",
  idadeMaxima: "",
  modalidade: "",
  trilhas: [],
  link: "",
  dataCursoInicio: null,
  dataCursoFim: null,
  ementa: "",
  descricao: "",
  vigencia: "",
  dataUltimaModificacao: null,
  situacao: "",
  motivoSituacao: "",
  anexo: [],
  //detalhes: [],
};

const FormCurso = ({
  initialValues = valoresIniciais,
  visualizacao = false,
  listaEmpresas = [],
  listaTrilhas = {},
  listaPreRequisitos = [],
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [empresaSelecionada, setEmpresaSelecionada] = useState(
    initialValues.nomeOfertante ? { no_fantasia: initialValues.nomeOfertante } : null,
  );
  const [modalidadeSelecionada, setModalidadeSelecionada] = useState(initialValues.modalidade);
  const [showAddressError, setShowAddressError] = useState(false);

  const checkSiteOnline = async url => {
    try {
      const response = await optionsService.verificarSiteAtivo(url);
      return response;
    } catch (error) {
      return "O site informado não está ativo";
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onSubmit",
  });

  // Criar uma nova função para tratar o submit do form
  const onFormSubmit = async (data, e) => {
    e.preventDefault(); // Prevenir comportamento padrão

    try {
      if (modalidadeSelecionada !== "EAD") {
        setShowAddressError(true);
      }

      if (onSubmit) {
        await onSubmit(data);
      }
    } catch (error) {
      console.error("Error in form submission:", error);
    }
  };

  // Modificar a chamada do handleSubmit para capturar erros
  const handleFormSubmit = handleSubmit(onFormSubmit, formErrors => {
    console.log("Form has errors:", formErrors);
    if (formErrors) {
      return;
    }
    onFormSubmit({ ...getValues(), errors: formErrors }, { preventDefault: () => {} }); // Chamar onFormSubmit mesmo com erros
  });

  const [localFiles, setLocalFiles] = useState(initialValues.anexo);
  const handleFiles = newFiles => {
    setLocalFiles(newFiles);
    setValue("anexo", newFiles);
  };

  const handleCNPJChange = event => {
    const selectedId = event.target.value;
    // Encontrar a empresa correspondente pelo ID
    const empresa = listaEmpresas.find(e => e.pk_empresa_parceira === selectedId);
    // Atualizar o estado com a empresa selecionada
    setEmpresaSelecionada(empresa);
  };

  const fieldsPrincipal = [
    // CNPJ e Ofertante
    {
      name: "cnpjOfertante",
      label: "*CNPJ ofertante",
      validation: { required: "Por favor, selecione o CNPJ do ofertante" },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl
            fullWidth
            error={!!error}
          >
            <InputLabel>*CNPJ ofertante</InputLabel>
            <Select
              id="cnpjOfertante"
              name="cnpjOfertante"
              value={value ?? ""}
              onChange={event => {
                handleCNPJChange(event);
                onChange(event.target.value);
              }}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um CNPJ
              </MenuItem>

              {/* Mapeando empresas para criar os itens do select */}
              {listaEmpresas?.map(empresa => (
                <MenuItem
                  key={empresa.pk_empresa_parceira}
                  value={empresa.pk_empresa_parceira}
                >
                  {formatCNPJ(empresa.nu_cnpj)}
                </MenuItem>
              ))}
            </Select>
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "nomeOfertante",
      label: "* Nome do ofertante",
      renderComponent: () => (
        <TextField
          id="nomeOfertante"
          name="nomeOfertante"
          label="Nome do ofertante"
          value={empresaSelecionada?.no_fantasia || ""} // Usando optional chaining e fallback para string vazia
          disabled
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    // Carga horária e turno
    {
      name: "cargaHoraria",
      label: "*Carga horária",
      validation: {
        required: "Por favor, informe a carga horária do curso",
        max: { value: 999, message: "A carga horária deve ser menor ou igual a 999 horas" },
        min: { value: 1, message: "A carga horária deve ser maior ou igual a 1 hora" },
      },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <TextField
            id="cargaHoraria"
            name="cargaHoraria"
            label="*Carga horária"
            type="number"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
            error={!!error}
            color={error ? "error" : "primary"}
            fullWidth
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "turno",
      label: "*Turno",
      validation: { required: "Por favor, selecione o turno do curso" },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl
            fullWidth
            error={!!error}
          >
            <InputLabel>*Turno</InputLabel>
            <Select
              id="turno"
              name="turno"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um Turno
              </MenuItem>

              {Object.keys(tp_turno).map(turno => (
                <MenuItem
                  key={turno}
                  value={turno}
                >
                  {tp_turno[turno]}
                </MenuItem>
              ))}
            </Select>
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    // Nome do curso
    {
      name: "nomeDoCurso",
      label: "*Nome do curso",
      validation: { required: "Por favor, informe o nome do curso" },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <TextField
            id="nomeDoCurso"
            name="nomeDoCurso"
            label="*Nome do curso"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
            error={!!error}
            color={error ? "error" : "primary"}
            fullWidth
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12 },
    },
    // Escolaridade e tipo
    {
      name: "escolaridade",
      label: "*Escolaridade exigida",
      validation: { required: "Por favor, selecione a escolaridade exigida" },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl
            fullWidth
            error={!!error}
          >
            <InputLabel>*Escolaridade exigida</InputLabel>
            <Select
              id="escolaridade"
              name="escolaridade"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione uma Escolaridade
              </MenuItem>

              {Object.keys(tp_escolaridade).map(escolaridade => (
                <MenuItem
                  key={escolaridade}
                  value={escolaridade}
                >
                  {tp_escolaridade[escolaridade]}
                </MenuItem>
              ))}
            </Select>
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "tipoDeCurso",
      label: "*Tipo de Curso",
      validation: { required: "Por favor, selecione o tipo de curso" },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl
            fullWidth
            error={!!error}
          >
            <InputLabel>*Tipo de Curso</InputLabel>
            <Select
              id="tipoDeCurso"
              name="tipoDeCurso"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um Tipo de Curso
              </MenuItem>

              {Object.keys(tp_curso).map(curso => (
                <MenuItem
                  key={curso}
                  value={curso}
                >
                  {tp_curso[curso]}
                </MenuItem>
              ))}
            </Select>
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    // Pronatec e Pre requisitos
    {
      name: "pronatec",
      label: "Pronatec",
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Pronatec</InputLabel>
            <Select
              id="pronatec"
              name="pronatec"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione PRONATEC
              </MenuItem>

              {Object.keys(st_pronatec).map(value => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {st_pronatec[value]}
                </MenuItem>
              ))}
            </Select>
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "preRequisitos",
      label: "Pré-requisitos",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Pré-requisitos</InputLabel>
            <Select
              id="preRequisitos"
              name="preRequisitos"
              value={value ?? ""}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um curso de pré-requisito
              </MenuItem>

              {listaPreRequisitos.map(req => (
                <MenuItem
                  key={req.id}
                  value={req.id}
                >
                  {req.nomeDoCurso}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    // Inicio e Fim das Matrículas
    {
      name: "dataMatriculaInicio",
      label: "*Início matrícula",
      validation: { required: "Por favor, informe a data de início das matrículas" },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <CustomDatePicker
            id="dataMatriculaInicio"
            name="dataMatriculaInicio"
            label="*Início matrícula"
            value={value ? dayjs(value) : null}
            disabled={visualizacao}
            fullWidth
            error={!!error}
            format="DD/MM/YYYY"
            onChange={onChange}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "dataMatriculaFim",
      label: "*Fim matrícula",
      validation: { required: "Por favor, informe a data de término das matrículas" },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <CustomDatePicker
            id="dataMatriculaFim"
            name="dataMatriculaFim"
            label="*Fim matrícula"
            value={value ? dayjs(value) : null}
            disabled={visualizacao}
            fullWidth
            format="DD/MM/YYYY"
            onChange={onChange}
            error={!!error}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    // Idade mínima e máxima
    {
      name: "idadeMinima",
      label: "Idade mínima",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="idadeMinima"
          name="idadeMinima"
          label="Idade mínima"
          type="number"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "idadeMaxima",
      label: "Idade máxima",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="idadeMaxima"
          name="idadeMaxima"
          label="Idade máxima"
          type="number"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    // Modalidade e  Trilhas
    {
      name: "modalidade",
      label: "*Modalidade",
      validation: { required: "Por favor, selecione a modalidade do curso" },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
          color={error ? "error" : "primary"}
        >
          <InputLabel color={error ? "error" : "primary"}>*Modalidade</InputLabel>
          <Select
            id="modalidade"
            name="modalidade"
            value={value}
            onChange={e => {
              onChange(e);
              setModalidadeSelecionada(e.target.value);
            }}
            disabled={visualizacao}
            color={error ? "error" : "primary"}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione uma Modalidade
            </MenuItem>

            {Object.keys(tp_modalidade).map(modalidade => (
              <MenuItem
                key={modalidade}
                value={modalidade}
              >
                {tp_modalidade[modalidade]}
              </MenuItem>
            ))}
          </Select>
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </FormControl>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "trilhas",
      label: "*Trilhas",
      validation: { required: "Por favor, selecione pelo menos uma trilha" },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
        >
          <InputLabel>Trilhas</InputLabel>
          <Select
            id="trilhas"
            name="trilhas"
            multiple
            value={value}
            onChange={onChange}
            input={
              <OutlinedInput
                id="select-multiple-chip"
                label="Chip"
              />
            }
            renderValue={selected => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map(selectedId => {
                  const trilha = listaTrilhas[selectedId];
                  return (
                    <Chip
                      key={selectedId}
                      label={trilha ? trilha.nome_trilha : selectedId}
                    />
                  );
                })}
              </Box>
            )}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione as trilhas
            </MenuItem>

            {Object.entries(listaTrilhas).map(([, trilha]) => (
              <MenuItem
                key={trilha.id}
                value={trilha.id}
              >
                {trilha.nome_trilha}
              </MenuItem>
            ))}
          </Select>
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </FormControl>
      ),
      size: { xs: 12, sm: 6 },
    },
    // Inicio e Fim do Curso
    {
      name: "dataCursoInicio",
      label: "*Início do curso",
      validation: { required: "Por favor, informe a data de início do curso" },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <CustomDatePicker
            id="dataCursoInicio"
            name="dataCursoInicio"
            label="*Início do curso"
            value={value ? dayjs(value) : null}
            disabled={visualizacao}
            fullWidth
            format="DD/MM/YYYY"
            onChange={onChange}
            error={!!error}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "dataCursoFim",
      label: "*Fim do curso",
      validation: { required: "Por favor, informe a data de término do curso" },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <CustomDatePicker
            id="dataCursoFim"
            name="dataCursoFim"
            label="*Fim do curso"
            value={value ? dayjs(value) : null}
            disabled={visualizacao}
            fullWidth
            format="DD/MM/YYYY"
            onChange={onChange}
            error={!!error}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    //Link Externo
    {
      name: "link",
      label: "Link Externo",
      validation: {
        pattern: {
          value: /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*\/?$/,
          message: "Insira um formato válido (ex - www.meusite.com)",
        },
        validate: async value => {
          if (!value) {
            return true; // No value, no validation needed
          }

          const formattedUrl = formatSite(value);
          const siteCheckResult = await checkSiteOnline(formattedUrl);

          // Se o site estiver online, retorna true ou undefined (validação OK)
          if (siteCheckResult === true) {
            return true; // Validação passou, não exibe erros
          }

          // Se o site não estiver online, retorna a mensagem de erro
          return siteCheckResult; // Exibe a mensagem de erro
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <TextField
            id="link"
            name="link"
            label="Link Externo"
            value={value}
            disabled={visualizacao}
            onChange={onChange}
            fullWidth
            error={!!error}
            helperText={error?.message}
          />
        </>
      ),
      size: { xs: 12, md: 6 },
    },
  ];

  const fieldsOpcional = [
    {
      name: "detalhes",
      label: "Endereço",
      renderComponent: ({ value, onChange }) => {
        return (
          <>
            <FormAddressSchool
              name="detalhes"
              onChange={onChange}
              values={value}
              showError={showAddressError} // Passar o estado showError
              visualizacao={visualizacao}
            />
          </>
        );
      },
      size: { xs: 12 },
    },
  ];

  const fieldsFinal = [
    // Ementa e breve descrição
    {
      name: "ementa",
      label: "*Ementa",
      validation: { required: "Por favor, insira uma ementa para o curso" },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          multiline
          rows={3}
          id="ementa"
          name="ementa"
          label="*Ementa"
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
          disabled={visualizacao}
          color={error ? "error" : "primary"}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "descricao",
      label: "*Descrição",
      validation: { required: "Por favor, informe uma descrição para o curso" },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          multiline
          rows={3}
          id="descricao"
          name="descricao"
          label="*Descrição"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          error={!!error}
          helperText={error?.message}
          color={error ? "error" : "primary"}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    // Anexar Imagem
    {
      name: "anexo",
      label: "Anexar Imagem",
      validation: { required: "Por favor, faça o upload de uma imagem (jpeg, jpg, png, gif)" },
      renderComponent: ({ error }) => {
        if (!visualizacao) {
          return (
            <>
              <FileUpload
                initialValues={localFiles}
                maxFiles={1}
                fileType={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                onFieldsChange={fields => handleFiles(fields)}
                error={error}
                visualizar={visualizacao}
                showFiles={visualizacao}
              />
            </>
          );
        }
      },
      size: { xs: 12 },
    },
    // Vigência, última modificação e situação
    {
      name: "vigencia",
      label: "Vigência",
      renderComponent: ({ value, onChange }) => {
        if (visualizacao) {
          return (
            <TextField
              id="vigencia"
              name="vigencia"
              label="Vigência"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          );
        }
      },
      size: { xs: 12, sm: 4 },
    },
    {
      name: "dataUltimaModificacao",
      label: "Última modificação",
      renderComponent: ({ value }) => {
        if (visualizacao) {
          return (
            <CustomDatePicker
              id="dataUltimaModificacao"
              name="dataUltimaModificacao"
              label="Última modificação"
              value={value ? dayjs(value) : null}
              disabled={true}
              fullWidth
              format="DD/MM/YYYY"
            />
          );
        }
      },
      size: { xs: 12, sm: 4 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => {
        if (visualizacao) {
          return (
            <TextField
              id="situacao"
              name="situacao"
              label="Situação"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          );
        }
      },
      size: { xs: 12, sm: 4 },
    },
    // Motivo situação
    {
      name: "motivoSituacao",
      label: "Motivo da Situação",
      renderComponent: ({ value, onChange }) => {
        if (visualizacao) {
          return (
            <TextField
              id="motivoSituacao"
              name="motivoSituacao"
              label="Motivo da Situação"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              multiline
              fullWidth
            />
          );
        }
      },
      size: { xs: 12 },
    },
  ];

  return (
    <form
      onSubmit={handleFormSubmit}
      style={{ width: "100%" }}
    >
      {/* fieldsPrincipal */}
      <Card
        sx={{
          padding: 2,
          mt: 2,
          borderRadius: 2,
        }}
      >
        {!visualizacao && (
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h6"
              style={{ fontFamily: "Rawline Bold" }}
              sx={{ mb: 2 }}
            >
              Dados do Curso
            </Typography>
          </Grid>
        )}
        <FormFieldsGrid
          fields={fieldsPrincipal}
          control={control}
          errors={errors}
        />
      </Card>
      {/* fieldsOpcional - só mostrar se NÃO for EAD */}
      {modalidadeSelecionada && modalidadeSelecionada !== "EAD" && (
        <Card sx={{ padding: 2, mt: 2, borderRadius: 2 }}>
          <Typography
            variant="h6"
            style={{ fontFamily: "Rawline Bold" }}
          >
            Informações adicionais para modalidade presencial ou semipresencial
          </Typography>
          <FormFieldsGrid
            fields={fieldsOpcional}
            control={control}
            errors={errors}
          />
        </Card>
      )}
      {/* fieldsFinal */}
      <Card
        sx={{
          padding: 2,
          mt: 2,
          borderRadius: 2,
        }}
      >
        <FormFieldsGrid
          fields={fieldsFinal}
          control={control}
          errors={errors}
        />
      </Card>
      {/* Botoes */}
      <Grid
        container
        spacing={2}
        mt={2}
        justifyContent="flex-end"
      >
        {!visualizacao && (
          <>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => navigate("/cursos/listar")}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                type="submit"
                sx={{ gap: "8px" }}
              >
                <SaveIcon fontSize="small" />
                <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>

      {/* Debug info */}
      {/* <pre style={{ display: "none" }}>{JSON.stringify({ errors, isSubmitting }, null, 2)}</pre> */}
    </form>
  );
};

FormCurso.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
  listaEmpresas: PropTypes.array,
  listaTrilhas: PropTypes.array,
  listaPreRequisitos: PropTypes.array,
  file: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default FormCurso;

import React from "react";
import { Controller, useForm } from "react-hook-form";

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Card, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import CustomDatePicker from "../formularios/CustomDatePicker";
import { formatCNPJ } from "../formularios/utils.js";
import { FormFieldsGrid } from "./DynamicForm.js";
import ReusableSwitch from "./fields/ReusableSwitch.js";

const valoresIniciais = {
  contatadoPorOperador: false,
  dataContato: null,
  parceiroContato: "",
  emprestimoConcedido: false,
  quantidadeParceirosEmprestimo: "",
  dataEmprestimo: null,
  valorEmprestimo: "",
  parceiroEmprestimo: "",
  iniciouNegocio: false,
  dataInicioNegocio: null,
  situacaoNegocioRadio: "",
  cnpj: "",
  parceiros: [],
};

// Novo componente para renderizar linhas de parceiros
const LinhasParceiros = ({ quantidade, readOnly, control }) => {
  return Array.from({ length: quantidade }, (_, index) => (
    <Grid
      container
      spacing={2}
      key={index}
      sx={{ mt: 2 }}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="subtitle2"
          sx={{ mb: 1 }}
        >
          Parceiro {index + 1}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
      >
        <FormGroup>
          <Controller
            control={control}
            name={`parceiros.${index}.parceiroEmprestimo`}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Qual parceiro concedeu o empréstimo?"
                placeholder="Insira o nome do parceiro"
                type="text"
                inputProps={{ readOnly }}
              />
            )}
          />
        </FormGroup>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
      >
        <Controller
          control={control}
          name={`parceiros.${index}.dataEmprestimo`}
          defaultValue={null}
          render={({ field }) => (
            <CustomDatePicker
              {...field}
              label="Mês e ano do empréstimo"
              format="DD/MM/YYYY"
              inputProps={{ readOnly }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
      >
        <FormGroup>
          <Controller
            control={control}
            name={`parceiros.${index}.valorEmprestimo`}
            defaultValue=""
            render={({ field }) => (
              <TextField
                label="Valor do empréstimo"
                placeholder="R$ 0,00"
                type="text"
                value={
                  typeof field.value === "number"
                    ? field.value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : field.value
                }
                onChange={e => {
                  // Remove todos os caracteres não numéricos
                  let value = e.target.value.replace(/\D/g, "");

                  // Converte para número
                  const numeroValue = value ? Number(value) / 100 : "";

                  // Atualiza o campo com o valor numérico
                  field.onChange(numeroValue);
                }}
                inputProps={{ readOnly }}
              />
            )}
          />
        </FormGroup>
      </Grid>
    </Grid>
  ));
};

const FormInformarSituacaoContato = ({ initialValues = valoresIniciais, readOnly = false }) => {
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const contatadoPorOperador = watch("contatadoPorOperador");
  const emprestimoConcedido = watch("emprestimoConcedido");

  const handleKeyDown = event => {
    // Impedir que o formularário seja enviado ao pressionar a tecla Enter
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const fieldsDados1 = [
    {
      name: "contatadoPorOperador",
      label: "contatadoPorOperador",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">1. Você já foi contatado por algum operador de microcrédito parceiro do Acredita?</Typography>
          <ReusableSwitch
            id={"contatadoPorOperador"}
            name={"contatadoPorOperador"}
            value={value ?? false}
            onChange={onChange}
            disabled={false}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "dataContato",
      label: "Mês e ano do contato",
      validation: {
        required: contatadoPorOperador ? "Data do contato é obrigatória" : false,
      },
      renderComponent: ({ value, onChange, error }) =>
        contatadoPorOperador ? (
          <CustomDatePicker
            label="Mês e ano do contato"
            id="dataContato"
            name="dataContato"
            value={value}
            format="DD/MM/YYYY"
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
            required={true}
          />
        ) : null,
      size: { xs: 12, sm: 6 },
    },
    {
      name: "parceiroContato",
      label: "Qual parceiro que fez o contato",
      validation: {
        required: contatadoPorOperador ? "Parceiro que fez o contato é obrigatório" : false,
      },
      renderComponent: ({ value, onChange, error }) =>
        contatadoPorOperador ? (
          <FormGroup>
            <TextField
              label="Qual parceiro que fez o contato"
              id="parceiroContato"
              name="parceiroContato"
              value={value}
              placeholder="Insira o nome do parceiro que fez o contato"
              type="text"
              onChange={onChange}
              error={!!error}
              helperText={error?.message}
              inputProps={{
                readOnly: readOnly,
              }}
              sx={{
                "& .MuiFormLabel-root.Mui-error": {
                  color: "error.main",
                },
              }}
            />
          </FormGroup>
        ) : null,
      size: { xs: 12, sm: 6 },
    },
  ];

  const fieldsDados2 = [
    {
      name: "emprestimoConcedido",
      label: "emprestimoConcedido",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">
            2. Você já teve o empréstimo concedido por algum operador de microcrédito parceiro do Acredita?
          </Typography>
          <ReusableSwitch
            id={"emprestimoConcedido"}
            name={"emprestimoConcedido"}
            value={value ?? false}
            onChange={onChange}
            disabled={false}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "quantidadeParceirosEmprestimo",
      label: "De quantos parceiros obteve empréstimo?",
      renderComponent: ({ value, onChange }) =>
        emprestimoConcedido ? (
          <FormGroup>
            <TextField
              label="De quantos parceiros obteve empréstimo?"
              id="quantidadeParceirosEmprestimo"
              name="quantidadeParceirosEmprestimo"
              value={value}
              placeholder="Insira a quantidade de parceiros"
              type="number"
              onChange={e => {
                const valor = parseInt(e.target.value, 10);
                if (valor > 0 && valor < 20) {
                  onChange(valor);
                } else if (e.target.value === "") {
                  onChange("");
                }
              }}
              inputProps={{
                readOnly: readOnly,
                min: 1,
                max: 19,
                step: 1,
              }}
            />
          </FormGroup>
        ) : null,
      size: { xs: 12 },
    },
  ];

  const fieldsDados3 = [
    {
      name: "iniciouNegocio",
      label: "iniciouNegocio",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">3. Você já iniciou o seu negócio?</Typography>
          <ReusableSwitch
            id={"iniciouNegocio"}
            name={"iniciouNegocio"}
            value={value ?? false}
            onChange={onChange}
            disabled={false}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "dataInicioNegocio",
      label: "Mês e ano do início",
      validation: {
        required: watch("iniciouNegocio") ? "Data de início do negócio é obrigatória" : false,
      },
      renderComponent: ({ value, onChange, error }) =>
        watch("iniciouNegocio") ? (
          <CustomDatePicker
            label="Mês e ano do início"
            id="dataInicioNegocio"
            name="dataInicioNegocio"
            value={value}
            format="DD/MM/YYYY"
            onChange={onChange}
            error={!!error}
            helperText={error?.message}
            required={true}
          />
        ) : null,
      size: { xs: 12 },
    },
    {
      name: "situacaoNegocioRadio",
      label: "Qual a situação do negócio?",
      validation: {
        required: watch("iniciouNegocio") ? "Situação do negócio é obrigatória" : false,
      },
      renderComponent: ({ value, onChange, error }) =>
        watch("iniciouNegocio") ? (
          <>
            <Typography variant="subtitle2">Qual a situação do negócio?</Typography>
            <FormGroup>
              <RadioGroup
                name="situacaoNegocioRadio"
                onChange={onChange}
                value={value || ""}
              >
                <FormControlLabel
                  value="formal"
                  control={
                    <Radio
                      style={{
                        color: error ? theme.palette.error.main : theme.palette.primary.main,
                      }}
                      checked={value === "formal"}
                    />
                  }
                  label="Formal (MEI, CNPJ)"
                />
                <FormControlLabel
                  value="informal"
                  control={
                    <Radio
                      style={{
                        color: error ? theme.palette.error.main : theme.palette.primary.main,
                      }}
                      checked={value === "informal"}
                    />
                  }
                  label="Informal (sem registro)"
                />
              </RadioGroup>
              {error && (
                <Typography
                  variant="caption"
                  color="error"
                  sx={{ mt: 1 }}
                >
                  {error.message}
                </Typography>
              )}
            </FormGroup>
          </>
        ) : null,
      size: { sx: 12 },
    },
    {
      name: "cnpj",
      label: "CNPJ",
      validation: {
        required:
          watch("iniciouNegocio") && watch("situacaoNegocioRadio") === "formal" ? "CNPJ é obrigatório quando o negócio é formal" : false,
      },
      renderComponent: ({ value, onChange, error }) =>
        watch("iniciouNegocio") && watch("situacaoNegocioRadio") === "formal" ? (
          <FormGroup>
            <TextField
              label="CNPJ"
              id="cnpj"
              name="cnpj"
              value={value}
              placeholder="Insira o seu CNPJ"
              type="text"
              onChange={e => {
                const formattedValue = formatCNPJ(e.target.value);
                onChange(formattedValue);
              }}
              error={!!error}
              helperText={error?.message}
              inputProps={{
                readOnly: readOnly,
                maxLength: 18, // XX.XXX.XXX/XXXX-XX
              }}
            />
          </FormGroup>
        ) : null,
      size: { xs: 12 },
    },
  ];

  return (
    <form
      onSubmit={event => {
        event.preventDefault(); // Previne o comportamento padrão do formulário

        // console.log("Valores reais do formulario:", getValues());

        // Chama o handleSubmit do React Hook Form, que valida e envia os dados
        handleSubmit(data => {
          console.log("Valores validados e reais do formulário:", data);
        })();

        // Se você quer ver os erros de validação
        // console.log("Erros de validação:", errors);
      }}
      onKeyDown={handleKeyDown}
    >
      <Stack spacing={1}>
        {/* Card Passo 1 */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <FormFieldsGrid
            fields={fieldsDados1}
            control={control}
            errors={errors}
          />
        </Card>
        {/* Card Passo 2 */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <FormFieldsGrid
            fields={fieldsDados2}
            control={control}
            errors={errors}
          />
          {emprestimoConcedido && watch("quantidadeParceirosEmprestimo") > 0 && (
            <LinhasParceiros
              quantidade={watch("quantidadeParceirosEmprestimo")}
              control={control}
              readOnly={readOnly}
            />
          )}
        </Card>

        {/* Card Passo 3 */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <FormFieldsGrid
            fields={fieldsDados3}
            control={control}
            errors={errors}
          />
        </Card>

        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={"auto"}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="outlined"
              onClick={async () => {
                "";
              }}
              // loading={loading || isSubmitting}
              fullWidth
            >
              CANCELAR
            </LoadingButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={"auto"}
          >
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              onClick={async () => {
                "";
              }}
              // loading={loading || isSubmitting}
              fullWidth
            >
              <SaveIcon sx={{ mb: "4px", mr: "4px", width: "20px", height: "20px" }} />
              Salvar
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

FormInformarSituacaoContato.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  initialValues: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormInformarSituacaoContato;

import React, { useRef, useState } from "react";

import Save from "@mui/icons-material/Save";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

import RelacaoDeCursoModal from "../modals/RelacaoDeCursosModal";
import TransferList from "../transferList/TransferList";
import DynamicForm from "./DynamicForm";

const AdminIncluirCursosTrilha = ({ trilha }) => {
  const formRef = useRef();
  const [modal, setModal] = useState(false);
  const [itemsTransferfList, setItemsTransferList] = useState({
    left: ["eng da computação", "moda", "fotografia", "culinária", "musica", "artes"],
    right: trilha.cursos,
  });

  const fields = [
    {
      name: "nome",
      label: "Nome da Trilha",
      size: { xs: 12 },
    },
    {
      name: "itemsTransferList",
      label: "Cursos",
      renderComponent: () => (
        <TransferList
          items={itemsTransferfList}
          titleLeft={"Cursos cadastrados"}
          titleRight={"Cursos selecionados para trilha"}
          onItemsChange={handleItemsTransferListChange}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const handleItemsTransferListChange = updatedItems => {
    setItemsTransferList(updatedItems);
  };

  const fecharModal = () => {
    setModal(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ mb: 2 }}
          >
            Incluir cursos na trilha
          </Typography>

          <DynamicForm
            fields={fields}
            ref={formRef}
            initialValues={trilha}
          />
        </Card>

        {/* Botões de salvar/cancelar */}
        <Box sx={{ flexGrow: 1, mt: "16px" }}>
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                onClick={() => window.history.back()}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                onClick={() => {
                  setModal(true);
                  console.log(itemsTransferfList.right);
                }}
              >
                <Save />
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <RelacaoDeCursoModal
        showModal={modal}
        handleClose={fecharModal}
        situacao={trilha.situacao}
        cursos={trilha.cursos}
      />
    </>
  );
};

AdminIncluirCursosTrilha.propTypes = {
  trilha: PropTypes.object.isRequired,
};

export default AdminIncluirCursosTrilha;

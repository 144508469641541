import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircleIcon from "@mui/icons-material/Circle";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import { Box, Card, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import DefaultTable from "../../components/table/DefaultTable";
import adminService from "../../services/admin.service";
import { formatCNPJ, removeFormatCNPJ } from "./utils";

// Retornar a pesquisa vazia
const initialFilterState = {
  cnpj: "",
};

// Adicione esta função de validação de email
const isValidEmail = email => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// Adicione esta função de formatação de data para incluir horário
const formatarData = dataString => {
  if (!dataString) return "";
  const data = new Date(dataString);
  const dataFormatada = data.toLocaleDateString("pt-BR");
  const horaFormatada = data.toLocaleTimeString("pt-BR");
  return `${dataFormatada} ${horaFormatada}`;
};

const AdminUsuariosRepresentantesParceiro = ({ listaEmpresas }) => {
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(null);

  const [idEmpresa, setIdEmpresa] = useState(null);
  const [dados, setDados] = useState([]);
  const [emailUsuario, setEmailUsuario] = useState("");

  const [mensagemNaoEncontrado, setMensagemNaoEncontrado] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [error, setError] = useState("");
  const [errorAdd, setErrorAdd] = useState("");

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilter({ [name]: value });
  };

  const applyFilter = async () => {
    setLoading(true);
    setError("");
    try {
      let id = null;
      listaEmpresas.forEach(item => {
        if (item.cnpj == removeFormatCNPJ(filter.cnpj)) {
          id = item.id;
          setDados(item);
        }
      });
      if (id) {
        const representantes = await adminService.getRepresentantesEmpresa(id);
        setIdEmpresa(id);
        setFilteredData(representantes);
        setMensagemNaoEncontrado(false);
      } else {
        setFilteredData(null);
        setMensagemNaoEncontrado(true);
      }
    } catch (err) {
      setError("Erro ao buscar dados. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddRepresentante = async () => {
    if (!emailUsuario) {
      setErrorAdd("Por favor, insira um email");
      return;
    }

    if (!isValidEmail(emailUsuario)) {
      setErrorAdd("Por favor, insira um email válido");
      return;
    }

    setLoadingAdd(true);
    setErrorAdd("");
    try {
      await adminService.postRepresentanteEmpresa({
        email_representante: emailUsuario,
        id_empresa: idEmpresa,
      });
      await applyFilter();
      setEmailUsuario(""); // Limpa o campo após sucesso
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setErrorAdd("Email não cadastrado na base de usuários");
      } else {
        setErrorAdd("Erro ao adicionar representante. Tente novamente.");
      }
    } finally {
      setLoadingAdd(false);
    }
  };

  return (
    <Stack spacing={1}>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        {/* Campos para filtrar */}
        <Grid
          container
          spacing={1}
          alignItems="center"
        >
          <Grid
            item
            xs
          >
            <FormGroup>
              <TextField
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                value={formatCNPJ(filter.cnpj)}
                type="text"
                onChange={handleFilterChange}
                fullWidth
              />
              {error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 1 }}
                >
                  {error}
                </Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={applyFilter}
              sx={{
                gap: "8px",
              }}
              style={{ fontFamily: "Rawline Medium" }}
            >
              <SearchIcon />
              <Typography
                variant={"BUTTON TEXT"}
                sx={{
                  fontSize: "12px",
                  mt: "8px",
                  mb: "8px",
                }}
              >
                Buscar
              </Typography>
            </LoadingButton>
          </Grid>
        </Grid>

        {mensagemNaoEncontrado && (
          <Typography
            variant="subtitle1"
            color="error"
          >
            Nenhuma empresa encontrada
          </Typography>
        )}

        {filteredData && (
          <>
            <Grid
              container
              my={2}
            >
              <Typography variant="subtitle1">Representantes do parceiro</Typography>
            </Grid>

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    disabled
                    id="razaoSocial"
                    name="razaoSocial"
                    label="Razão Social"
                    placeholder="Razão Social"
                    value={dados.razaoSocial}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    disabled
                    id="nomefantasia"
                    name="nomefantasia"
                    label="Nome fantasia"
                    placeholder="Nome fantasia"
                    value={dados.nomeFantasia}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    disabled
                    id="dataCadastro"
                    name="dataCadastro"
                    label="Data do Cadastro"
                    placeholder="Data do Cadastro"
                    value={formatarData(dados.dataCadastro)}
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    disabled
                    id="dataUltimaModificacao"
                    name="dataUltimaModificacao"
                    label="Data da Última Modificação"
                    placeholder="Data da Última Modificação"
                    value={formatarData(dados.dataUltimaModificacao)}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            {/* Cores */}
            <Box>
              <Grid
                container
                mt={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <CircleIcon sx={{ color: "#FF7A00", fontSize: "small", marginRight: "5px" }} />
                  Usuário com a senha resetada
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <CircleIcon sx={{ color: "#FFCF00", fontSize: "small", marginRight: "5px" }} />
                  Usuário que nunca acessou o parceiro
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                >
                  <CircleIcon sx={{ color: "#00CA45", fontSize: "small", marginRight: "5px" }} />
                  Usuário que já acessou o parceiro
                </Grid>
              </Grid>
            </Box>

            {/* Adicionar Representante */}
            <Grid
              container
              spacing={1}
              alignItems="center"
              mt={2}
            >
              <Grid
                item
                md={9}
                xs={12}
              >
                <FormGroup>
                  <TextField
                    id="emailUsuario"
                    name="emailUsuario"
                    label="E-mail do usuário"
                    placeholder="E-mail do usuário"
                    value={emailUsuario}
                    onChange={event => {
                      setEmailUsuario(event.target.value);
                    }}
                    type="text"
                    fullWidth
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                md={3}
                xs={12}
              >
                <LoadingButton
                  loading={loadingAdd}
                  variant="contained"
                  onClick={handleAddRepresentante}
                  sx={{ gap: "8px", width: "100%" }}
                >
                  <AddCircleIcon />
                  <Typography
                    variant={"BUTTON TEXT"}
                    sx={{
                      fontSize: "12px",
                      mt: "8px",
                      mb: "8px",
                    }}
                  >
                    Incluir representante
                  </Typography>
                </LoadingButton>
              </Grid>
            </Grid>

            {errorAdd && (
              <Grid container>
                <Grid item>
                  <Typography
                    color="error"
                    variant="caption"
                    sx={{ mt: 1 }}
                  >
                    {errorAdd}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* Tabela */}
            <Box sx={{ flexGrow: 1, mt: 2 }}>
              <DefaultTable
                columns={[
                  { headerName: "Nome Completo", field: "no_usuario" },
                  { headerName: "E-mail", field: "ds_email" },
                ]}
                rows={filteredData}
                actionButtons={event => [
                  {
                    title: "Remover",
                    icon: "block",
                    onClick: () => {
                      if (filteredData.length <= 1) {
                        alert("A empresa deve possuir ao menos um representante!");
                      } else if (confirm("Tem certeza que deseja excluir o usuário?")) {
                        const motivo = {
                          motivo: "Exclusão por parte do admin",
                          id_empresa: idEmpresa,
                        };
                        adminService.excluiRepresentanteEmpresa(event.pk_usuario, motivo);
                        applyFilter();
                      }
                    },
                  },
                ]}
                mostrarCirculosColoridos={true}
                listaCirculosColoridos={["#FF7A00", "#FFCF00", "#00CA45"]}
              />
            </Box>
          </>
        )}
      </Card>
    </Stack>
  );
};

AdminUsuariosRepresentantesParceiro.propTypes = {
  listaEmpresas: PropTypes.array,
};

export default AdminUsuariosRepresentantesParceiro;

import React, { useRef, useState } from "react";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { valoresOSCIP, valoresSeguimentoBCB } from "../../services/constants";
import constantsService from "../../services/constants.service";
import CustomDatePicker from "../formularios/CustomDatePicker";
import DynamicForm from "./DynamicForm";
import ReusableSwitch from "./fields/ReusableSwitch";
import { SelectAtuacaoParceiro } from "./fields/SelectAtuacaoParceiro";
import FormMultipleInsertAddress from "./FormMultipleInsertAddress";
import FormMultipleInsertDouble from "./FormMultipleInsertDouble";
import FormMultipleInsertSelect from "./FormMultipleInsertSelect";

const mapInstituicoes = (instituicoes, tipo) => {
  if (!instituicoes || instituicoes.length <= 0) return [];
  return instituicoes.map(item => ({
    tipo: tipo,
    nome: item.value1 ?? item.nome,
    cnpj: (item.value2 ?? item.cnpj).replace(/\D/g, ""),
  }));
};

const valoresIniciais = {
  eOSCIP: false,
  oscips: [],
  eRegistradoBCB: false,
  seguimentoBCB: "",
  eCorrespondenteBancario: false,
  instituicoesCorrespondenteBancario: [],
  eFintech: false,
  eApoioAtividadeProdutiva: false,
  eEmpresaSimplesCredito: false,
  ePNMPO: false,
  dataDeInicio: "",
  recursosRadio: "",
  instituicoesRepassadoras: [],
  eMicrocreditoMandato: false,
  instituicoesMandatarias: [],
  abrangenciaOperacao: "",
  areaAbrangenciaLista: [],
  situacao: "",
  motivo: "",
};

const AdminParceiroFormInformacoesComplementares = ({
  initialValues = valoresIniciais,
  visualizacao = false,
  handleSubmit,
  handleCancelar,
  handleDeletar,
}) => {
  const theme = useTheme();
  const formRef = useRef();

  const [objDisable, setObjDisable] = useState({
    eOSCIP: initialValues?.eOSCIP ?? false,
    eRegistradoBCB: initialValues?.eRegistradoBCB ?? false,
    eCorrespondenteBancario: initialValues?.eCorrespondenteBancario ?? false,
    eOperadorCarteiraMicrocredito: initialValues?.eOperadorCarteiraMicrocredito ?? false,
    ePNMPO: initialValues?.ePNMPO ?? false,
  });

  // Tratar valoresOSCIP
  const optionsOSCIP = constantsService.transformDictIntoOptions(valoresOSCIP);
  const optionsSeguimentoBCB = constantsService.transformDictIntoOptions(valoresSeguimentoBCB);

  const fields = [
    {
      name: "eOSCIP",
      label: "É OSCIP?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">{"É OSCIP?"}</Typography>
          <ReusableSwitch
            id={"eOSCIP"}
            name={"eOSCIP"}
            value={value ?? false}
            onChange={newValue => {
              onChange(newValue);
              setObjDisable(prevState => ({
                ...prevState,
                eOSCIP: newValue.target.checked,
              }));
            }}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "oscips",
      label: "Lista de Finalidades",
      // TODO: remover quando backend for atualizado
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <FormMultipleInsertSelect
            initialValue={value ? value : []}
            placeHolder={"Escolha uma finalidade"}
            label={"Quais as finalidades?"}
            onFieldsChange={values => {
              handleMultiplesFinalidadeOSCIP(values);
              onChange(values);
            }}
            list={optionsOSCIP}
            visualizacao={visualizacao || !objDisable.eOSCIP}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          {!visualizacao && objDisable.eOSCIP && !formRef.current?.getValues().oscips?.includes("CRD") && (
            <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>
              É obrigatório selecionar pelo menos uma finalidade como Creditícias.
            </Typography>
          )}
        </FormGroup>
      ),
      size: { xs: 12, sm: 8 },
    },
    {
      name: "eRegistradoBCB",
      label: "É registrado(a) no Banco Central do Brasil?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É registrado(a) no Banco Central do Brasil?</Typography>
          <ReusableSwitch
            id={"eRegistradoBCB"}
            name={"eRegistradoBCB"}
            value={value ?? false}
            onChange={newValue => {
              onChange(newValue);
              setObjDisable(prevState => ({
                ...prevState,
                eRegistradoBCB: newValue.target.checked,
              }));
            }}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "seguimentoBCB",
      label: "Em qual seguimento do Sistema Financeiro Nacional?",
      // TODO: remover quando backend for atualizado
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <SelectAtuacaoParceiro
            idSelect={"seguimentoBCB"}
            nameSelect={"seguimentoBCB"}
            handleSelect={onChange}
            list={optionsSeguimentoBCB}
            item={value ?? ""}
            label="Em qual seguimento do Sistema Financeiro Nacional?"
            placeholder={"Escolha um seguimento"}
            visualizacao={visualizacao || !objDisable.eRegistradoBCB}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          {!visualizacao && objDisable.eRegistradoBCB && !formRef.current?.getValues().seguimentoBCB && (
            <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>
              Caso seja registrado(a) no Banco Central, é obrigatório selecionar um segmento bancário.
            </Typography>
          )}
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 8 },
    },
    {
      name: "eCorrespondenteBancario",
      label: "É Correspondente Bancário?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É correspondente bancário?</Typography>
          <ReusableSwitch
            id={"eCorrespondenteBancario"}
            name={"eCorrespondenteBancario"}
            value={value ?? false}
            onChange={newValue => {
              onChange(newValue);
              setObjDisable(prevState => ({
                ...prevState,
                eCorrespondenteBancario: newValue.target.checked,
              }));
            }}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "instituicoesCorrespondenteBancario",
      label: "Nome do correspondente bancário",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <FormMultipleInsertDouble
            label1="Nome do correspondente bancário"
            label2="CNPJ correspondente bancário"
            value1={"nome"}
            value2={"cnpj"}
            disabled={visualizacao || !objDisable.eCorrespondenteBancario}
            values={value}
            onFieldsChange={onChange}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "eFintech",
      label: "É uma instituição financeira que realiza operações exclusivamente por meio de sítio eletrônico ou de aplicativo?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">
              É uma instituição financeira que realiza operações exclusivamente por meio de sítio eletrônico ou de aplicativo (Fintech)?
            </Typography>
          </Grid>

          <ReusableSwitch
            id={"eFintech"}
            name={"eFintech"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "eApoioAtividadeProdutiva",
      label: "É pessoa jurídica especializada no apoio, no fomento ou na orientação a atividades produtivas?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">
              É pessoa jurídica especializada no apoio, no fomento ou na orientação a atividades produtivas?
            </Typography>
          </Grid>

          <ReusableSwitch
            id={"eApoioAtividadeProdutiva"}
            name={"eApoioAtividadeProdutiva"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "eEmpresaSimplesCredito",
      label: "É empresa Simples de Crédito?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle2">É empresa Simples de Crédito?</Typography>
          </Grid>

          <ReusableSwitch
            id={"eEmpresaSimplesCredito"}
            name={"eEmpresaSimplesCredito"}
            value={value ?? false}
            onChange={onChange}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "ePNMPO",
      label: "É operador de PNMPO?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Typography variant="subtitle2">É operador de PNMPO?</Typography>

          <ReusableSwitch
            id={"ePNMPO"}
            name={"ePNMPO"}
            value={value ?? false}
            onChange={newValue => {
              onChange(newValue);
              setObjDisable(prevState => ({
                ...prevState,
                ePNMPO: newValue.target.checked,
              }));
            }}
            disabled={visualizacao}
          />
        </>
      ),
      size: { xs: 12, sm: 6, md: 5 },
    },
    {
      name: "dataDeInicio",
      label: "Data de Início",
      // TODO: remover quando backend for atualizado
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <>
          <CustomDatePicker
            label="Data de Início"
            id="dataDeInicio"
            name="dataDeInicio"
            value={value ? dayjs(value) : null}
            format="DD/MM/YYYY"
            onChange={onChange}
            disabled={visualizacao || !objDisable.ePNMPO}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12, sm: 6, md: 7 },
    },
    {
      name: "recursosRadio",
      label: "É operador de carteira de microcrédito com que tipo de recurso?",
      validation: { required: "Obrigatório informar tipo de recurso da carteira de microcrédito" },
      renderComponent: ({ value, error, onChange }) => (
        <>
          <Typography variant="subtitle2">É operador de carteira de microcrédito com que tipo de recursos?</Typography>
          <FormGroup>
            <RadioGroup
              name="recursosRadio"
              onChange={event => {
                handleRadioChange(event);
                onChange(event.target.value);
              }}
              value={value || ""}
              disabled={visualizacao}
            >
              <FormControlLabel
                value="PRO"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "PRO"}
                    disabled={visualizacao}
                  />
                }
                label="Próprios"
              />
              <FormControlLabel
                value="TER"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "TER"}
                    disabled={visualizacao}
                  />
                }
                label="De terceiros/repasse"
              />
              <FormControlLabel
                value="PTR"
                control={
                  <Radio
                    style={{ color: theme.palette.primary.main }}
                    checked={value === "PTR"}
                    disabled={visualizacao}
                  />
                }
                label="Próprios e de terceiros/repasse"
              />
            </RadioGroup>

            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormGroup>
        </>
      ),
      size: { sx: 12 },
    },
    {
      name: "instituicoesRepassadoras",
      label: "Nome da Repassadora",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <FormMultipleInsertDouble
            label1="Nome da repassadora"
            label2="CNPJ da repassadora"
            value1={"nome"}
            value2={"cnpj"}
            disabled={visualizacao}
            values={value}
            onFieldsChange={onChange}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "eMicrocreditoMandato",
      label: "É operador de carteira de microcrédito por mandato?",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            item
            xs={12}
            mt={2}
          >
            <Typography variant="subtitle2">É operador de carteira de microcrédito por mandato?</Typography>
          </Grid>

          <ReusableSwitch
            id={"eMicrocreditoMandato"}
            name={"eMicrocreditoMandato"}
            value={value ?? false}
            onChange={newValue => {
              onChange(newValue);
              setObjDisable(prevState => ({
                ...prevState,
                eMicrocreditoMandato: newValue.target.checked,
              }));
            }}
            disabled={visualizacao}
          />
        </>
      ),
    },
    {
      name: "instituicoesMandatarias",
      label: "Nome das instituições mandatárias",
      renderComponent: ({ value, onChange, error }) => (
        <>
          <FormMultipleInsertDouble
            label1="Nome das intituições mandatárias"
            label2="CNPJ das instituições mandatárias"
            value1={"nome"}
            value2={"cnpj"}
            disabled={visualizacao || !objDisable.eMicrocreditoMandato}
            values={value}
            onFieldsChange={onChange}
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "abrangenciaOperacao",
      label: "Qual a abrangência de operação?",
      validation: { required: "Obrigatório informar a abragência de operação" },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Qual a abrangência de operação?</InputLabel>
            <Select
              id="abrangenciaOperacao"
              name="abrangenciaOperacao"
              value={value ?? ""}
              type="text"
              onChange={event => {
                handleChangeAbrangencia(event);
                onChange(event.target.value);
              }}
              disabled={visualizacao}
            >
              <MenuItem
                value=""
                disabled
              >
                Selecione uma opção
              </MenuItem>
              <MenuItem value={"NAC"}>Nacional</MenuItem>
              <MenuItem value={"EST"}>Estadual</MenuItem>
              <MenuItem value={"MUN"}>Municipal</MenuItem>
            </Select>

            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "areaAbrangenciaLista",
      label: "UF",
      renderComponent: ({ value }) => (
        <FormMultipleInsertAddress
          initialValues={value || []}
          onFieldsChange={handleMultipleInputsAddress}
          multiplesInsert={true}
          desabilitarUf={areaAbrangencia === "NAC" || !areaAbrangencia}
          desabilitarMunicipio={areaAbrangencia === "NAC" || areaAbrangencia === "EST"}
          disabled={visualizacao}
        />
      ),
      size: { xs: 12, sm: 6, md: 8 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => (
        <>
          {visualizacao && (
            <FormGroup>
              <TextField
                id="situacao"
                name="situacao"
                placeholder="Situação"
                label="Situação?"
                value={value}
                type="text"
                // onChange={handleFormChange}
                onChange={onChange}
                disabled={visualizacao}
              />
            </FormGroup>
          )}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "motivo",
      label: "Motivo",
      renderComponent: ({ value, onChange }) => (
        <>
          {visualizacao && (
            <FormGroup>
              <TextField
                multiline
                rows={4}
                id="motivo"
                name="motivo"
                label="Motivo"
                placeholder="Motivo"
                value={value}
                type="text"
                // onChange={handleFormChange}
                onChange={onChange}
                disabled={visualizacao}
              />
            </FormGroup>
          )}
        </>
      ),
      size: { xs: 12 },
    },
  ];

  const [multiplesInputAddress, setMultiplesInputAddress] = useState(initialValues?.areaAbrangenciaLista ?? []);
  const [radioValue, setRadioValue] = useState(initialValues?.recursosRadio ?? "");
  const [multiplesFinalidadeOSCIP, setMultiplesFinalidadeOSCIP] = useState(initialValues?.oscips ?? []);
  const [areaAbrangencia, setAreaAbrangencia] = useState(initialValues?.abrangenciaOperacao ?? "");

  const handleMultipleInputsAddress = values => {
    setMultiplesInputAddress(values);
  };

  const handleMultiplesFinalidadeOSCIP = values => {
    setMultiplesFinalidadeOSCIP(values);

    formRef.current.setValue("oscips", values, { shouldValidate: true });
  };

  const handleChangeAbrangencia = event => {
    const { name, value } = event.target;

    setAreaAbrangencia(value);
    formRef.current.setValue(name, value, { shouldValidate: true });
  };

  const handleRadioChange = event => {
    const { value } = event.target;

    setRadioValue(value); // Atualiza o valor localmente, se ainda precisar
    formRef.current.setValue("recursosRadio", value, { shouldValidate: true }); // Atualiza e valida o campo no formulário
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      // console.log(formRef.current.getValues());
      formRef.current.submitForm();
    }
  };

  const prepararCamposAntesDoSubmit = values => {
    // console.log("preparando os dados")
    // Tratamento para campos de Sim/Não - ReusableSwitch
    let eOSCIP = values.eOSCIP ? "SIM" : "NAO";
    let eRegistradoBCB = values.eRegistradoBCB ? "SIM" : "NAO";
    // let eCorrespondenteBancario = values.eCorrespondenteBancario ? "SIM" : "NAO";
    let eFintech = values.eFintech ? "SIM" : "NAO";
    let eApoioAtividadeProdutiva = values.eApoioAtividadeProdutiva ? "SIM" : "NAO";
    let eEmpresaSimplesCredito = values.eEmpresaSimplesCredito ? "SIM" : "NAO";
    let ePNMPO = values.ePNMPO ? "SIM" : "NAO";
    let eMicrocreditoMandato = values.eMicrocreditoMandato ? "SIM" : "NAO";

    // Tratamento para multiplos
    values.oscips = multiplesFinalidadeOSCIP;

    // Tratamento para radio
    values.recursosRadio = radioValue;

    // Tratamento para UF e Cidade - areaAbrangenciaLista
    let areaAbrangenciaLista = [];

    if (values.abrangenciaOperacao === "EST") {
      multiplesInputAddress.forEach(item => areaAbrangenciaLista.push([item.uf ?? item[0]]));
    } else if (values.abrangenciaOperacao === "MUN") {
      multiplesInputAddress.forEach(item => areaAbrangenciaLista.push([item.cidade ?? item[0], item.uf ?? item[1]]));
    }

    // Tratamento das instituicoes
    values.associadas = [
      ...mapInstituicoes(values.instituicoesCorrespondenteBancario, "CBN"),
      ...mapInstituicoes(values.instituicoesRepassadoras, "MCR"),
      ...mapInstituicoes(values.instituicoesMandatarias, "MCM"),
    ];

    const dados = {
      // Atenção: mudei isso aqui pra vir na chamada do POST
      // empresa_parceira: parseInt(id, 10),
      st_fintech: eFintech,
      st_apoio_emp_simples: eApoioAtividadeProdutiva,
      st_emp_simples: eEmpresaSimplesCredito,
      st_operador_pnmpo: ePNMPO,
      tp_microcredito_recurso: values.recursosRadio,
      tp_microcredito_mandataria: eMicrocreditoMandato,
      associadas: values.associadas,
      tp_area_abrangencia: values.abrangenciaOperacao,
      // Valores que se não estiverem setados não são mandados
      ...(values.dataDeInicio && { dt_operador_pnmpo: dayjs(values.dataDeInicio).format("YYYY-MM-DD") }),
      ...(eOSCIP && { oscips: values.oscips }),
      ...(eRegistradoBCB && { tp_bacen: values.seguimentoBCB }),
      ...(values.abrangenciaOperacao != "NAC" && { area_abrangencia_lista: areaAbrangenciaLista }),
    };

    // console.log("Dados prontos -> ", dados);

    return dados;
  };

  return (
    <>
      <DynamicForm
        ref={formRef}
        fields={fields}
        onSubmit={values => {
          const dados = prepararCamposAntesDoSubmit(values);
          handleSubmit(dados);
        }}
        initialValues={initialValues}
      />

      {/* BOTOES DE ENVIO E CANCELAMENTO */}
      {!visualizacao && (
        <Grid
          container
          spacing={1}
          mt={3}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleDeletar}
              sx={{ textTransform: "uppercase" }}
              startIcon={<DeleteOutlineIcon />}
              color={"error"}
            >
              <Typography variant="button">Excluir informações</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleCancelar}
            >
              <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              onClick={handleButtonClick}
              sx={{ gap: "8px" }}
            >
              <SaveIcon fontSize="small" />
              <Typography variant={"BUTTON TEXT"}>SALVAR INFORMAÇÕES</Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      )}

      {/* Apenas visualização */}
      {visualizacao && (
        <Grid
          container
          spacing={1}
          mt={3}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => window.history.back()}
            >
              <Typography variant={"BUTTON TEXT"}>Voltar</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

AdminParceiroFormInformacoesComplementares.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleCancelar: PropTypes.func,
  handleDeletar: PropTypes.func,
};

export default AdminParceiroFormInformacoesComplementares;

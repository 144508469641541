import { tp_vaga } from "../services/constants";
import constantsService from "../services/constants.service";

export const transformaParaBackend = values => {
  const newValues = {
    id_empresa_parceira: values.cnpjOfertante, //recebo o id da empresa
    tp_vaga: values.tipoVaga,
    nome_funcao: values.funcao,
    qt_vaga: values.quantidadeVagas,
    st_escolaridade_minima: values.escolaridadeMinima ? "SIM" : "NAO",
    vl_salario_bruto: Number(values.salario),
    ds_adicional: values.descricao,
    st_pessoas_deficiencia: values.vagaPCD ? "SIM" : "NAO",
    st_local_trabalho_parceiro: values.localTrabalhoParceiro ? "SIM" : "NAO",
    tp_escolaridade: values.escolaridadeRequerida,
    tp_turno: values.turno,
    validade: Number(values.validadeAnuncio),
    // Endereço é opcional, está sendo tratado fora
    // Benefícios é opcional, está sendo tratado fora

    // se não tiver nenhum, tem que ser enviado vazio: "areas_atuacao": []
    areas_atuacao: [
      // Profissional
      ...(values.areaAtuacaoProfissional?.map(area => ({
        tipo_atuacao: area.input3,
        tipo_vaga: "P", // Profissional
        cbo2002_ocupacao: Number(area.input1),
        cbo2002_ocupacao_anterior: Number(area.input2),
      })) || []),

      // Estudante
      ...(values.areaAtuacaoEstudante?.map(area => ({
        tipo_atuacao: area.input3,
        tipo_vaga: "E", // Estudante
        cbo2002_ocupacao: Number(area.input1),
        cbo2002_ocupacao_anterior: Number(area.input2),
      })) || []),
    ],
    // Essa parte ainda está em discussão com a equipe de informação!!!
    // se não tiver nenhum, tem que ser enviado vazio: "cursos": []
    cursos: [
      ...(values.cursoComplementar?.map(curso => ({
        id_curso: curso.input1, // ID do curso
        tipo_atuacao: curso.input3,
        tipo_vaga: "FIC", // Formação Inicial Continuada
      })) || []),

      ...(values.cursoTecnico?.map(curso => ({
        id_curso: curso.input1,
        tipo_atuacao: curso.input3,
        tipo_vaga: "CUT", // Curso Técnico
      })) || []),

      ...(values.cursoSuperior?.map(curso => ({
        id_curso: curso.input1,
        tipo_atuacao: curso.input3,
        tipo_vaga: "SUP", // Curso Superior
      })) || []),
    ],
  };

  // Adiciona endereço se `st_local_trabalho_parceiro` for "NAO"
  if (newValues.st_local_trabalho_parceiro === "NAO") {
    newValues.endereco = {
      cep: values.endereco[0].cep.replace(/[.-]/g, ""),
      logradouro: values.endereco[0].endereco,
      numero: values.endereco[0].numero || "0",
      complemento: values.endereco[0].complemento,
      bairro: values.endereco[0].bairro,
      cidade: values.endereco[0].municipio,
      uf: values.endereco[0].uf,
      // referencia: values.endereco[0].referencia ,
      referencia: "teste", // TODO: arrumar no FormAddress
    };
  }

  // Verifica benefícios opcionais e adiciona se pelo menos um foi preenchido
  const beneficios = [
    values.auxilioAlimentacao && {
      tipo: "AA",
      descricao: values.detalheAuxilioAlimentacao,
    },
    values.auxilioTransporte && {
      tipo: "AT",
      descricao: values.detalheAuxilioTransporte,
    },
    values.planoSaude && {
      tipo: "PS",
      descricao: values.detalhePlanoSaude,
    },
    values.outros && {
      tipo: "OT",
      descricao: values.detalheOutros,
    },
  ].filter(Boolean);

  if (beneficios.length > 0) {
    newValues.beneficios = beneficios;
  }

  return newValues;
};

export const transformaDadosVaga = dadosRecebidos => {
  const vaga = {
    tipoVaga: dadosRecebidos.tp_vaga,
    funcao: dadosRecebidos.nome_funcao,
    quantidadeVagas: dadosRecebidos.qt_vaga,
    validadeAnuncio: parseInt(dadosRecebidos.validade, 10),
    escolaridadeRequerida: dadosRecebidos.tp_escolaridade,
    escolaridadeMinima: dadosRecebidos.st_escolaridade_minima === "SIM",
    vagaPCD: dadosRecebidos.st_pessoas_deficiencia === "SIM",
    localTrabalhoParceiro: dadosRecebidos.st_local_trabalho_parceiro === "SIM",
    salario: dadosRecebidos.vl_salario_bruto,
    turno: dadosRecebidos.tp_turno,
    descricao: dadosRecebidos.ds_adicional,
    endereco: [
      {
        uf: dadosRecebidos.endereco?.[0]?.uf,
        municipio: dadosRecebidos.endereco?.[0]?.cidade,
        cep: dadosRecebidos.endereco?.[0]?.cep,
        endereco: dadosRecebidos.endereco?.[0]?.endereco || [],
        numero: dadosRecebidos.endereco?.[0]?.numero,
        complemento: dadosRecebidos.endereco?.[0]?.complemento,
        referencia: dadosRecebidos.endereco?.[0]?.referencia,
        bairro: dadosRecebidos.endereco?.[0]?.bairro,
      },
    ],
    auxilioAlimentacao: dadosRecebidos.beneficios?.find(b => b.tipo === "AA") ? true : false,
    detalheAuxilioAlimentacao: dadosRecebidos.beneficios?.find(b => b.tipo === "AA")?.descricao || "",
    auxilioTransporte: dadosRecebidos.beneficios?.find(b => b.tipo === "AT") ? true : false,
    detalheAuxilioTransporte: dadosRecebidos.beneficios?.find(b => b.tipo === "AT")?.descricao || "",
    planoSaude: dadosRecebidos.beneficios?.find(b => b.tipo === "PS") ? true : false,
    detalhePlanoSaude: dadosRecebidos.beneficios?.find(b => b.tipo === "PS")?.descricao || "",
    outros: dadosRecebidos.beneficios?.find(b => b.tipo === "OT") ? true : false,
    detalheOutros: dadosRecebidos.beneficios?.find(b => b.tipo === "OT")?.descricao || "",
    areaAtuacaoProfissional:
      dadosRecebidos.areas_atuacao
        ?.filter(area => area.tipo_atuacao === "D")
        .map(area => [area.cbo2002_ocupacao, "Exemplo de Experiência", "Desejado"]) || [],
    areaAtuacaoEstudante:
      dadosRecebidos.areas_atuacao
        ?.filter(area => area.tipo_atuacao === "E")
        .map(area => [area.cbo2002_ocupacao, "Outra Experiência", "Exigido"]) || [],
    cursoComplementar:
      dadosRecebidos.cursos
        ?.filter(curso => curso.tipo_curso === "Complementar")
        .map(curso => [curso.id_curso, curso.nome_curso, "Desejado"]) || [],
    cursoTecnico:
      dadosRecebidos.cursos?.filter(curso => curso.tipo_curso === "Tecnico").map(curso => [curso.id_curso, curso.nome_curso, "Exigido"]) ||
      [],
    cursoSuperior:
      dadosRecebidos.cursos
        ?.filter(curso => curso.tipo_curso === "Superior")
        .map(curso => [curso.id_curso, curso.nome_curso, "Desejado"]) || [],
  };
  return vaga;
};

export const transformaDadosVagaSimples = dadosRecebidos => {
  const vaga = {
    id: dadosRecebidos.id_trabalho_vagas,
    tipoVaga: tp_vaga[dadosRecebidos.tp_vaga] || "",
    funcao: dadosRecebidos.nome_funcao,
    quantidadeVagas: dadosRecebidos.qt_vaga,
    escolaridadeMinima: dadosRecebidos.st_escolaridade_minima === "SIM",
    salario: dadosRecebidos.vl_salario_bruto,
    descricao: dadosRecebidos.ds_adicional,
    vagaPCD: dadosRecebidos.st_pessoas_deficiencia === "SIM",
    localTrabalhoParceiro: dadosRecebidos.st_local_trabalho_parceiro === "SIM",
    escolaridadeRequerida: dadosRecebidos.tp_escolaridade,
    turno: dadosRecebidos.tp_turno,
    validadeAnuncio: parseInt(dadosRecebidos.validade, 10),
    situacao: constantsService.getStatusByCode(dadosRecebidos.tp_situacao_registro || ""),
    // TODO: pedir pro backend enviar data
    dataCadastro: new Date().toISOString().slice(0, 10),
    // TODO: pedir pro backend enviar interessados
    interessados: 99,
  };

  // console.log("vaga transformada", vaga);
  return vaga;
};

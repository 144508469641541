import React from "react";
import { Link } from "react-router-dom";

import WarningIcon from "@mui/icons-material/Warning";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const WarningBox = ({ link, action }) => {
  return (
    <Box
      sx={{
        border: "1px solid red",
        borderRadius: "5px",
        padding: "16px",
        display: "flex",
        alignItems: "flex-start",
        backgroundColor: "rgba(255, 0, 0, 0.1)",
        color: "red",
        marginTop: "16px",
        marginBottom: "16px",
      }}
    >
      <WarningIcon style={{ marginRight: "8px", color: "red" }} />
      <div>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", marginBottom: "4px", color: "darkred" }}
        >
          Atenção!
        </Typography>
        <Typography variant="body2">
          Esta página foi feita apenas para efeitos de desenvolvimento e não reflete o funcionamento pretendido pela experiência do usuário.
          Ela só está aqui para facilitar a manutenção e será removida no futuro.
        </Typography>
        {link && action && (
          <Typography
            variant="body2"
            sx={{ marginTop: "8px" }}
          >
            O uso correto dessa funcionalidade pode ser visto em{" "}
            <Link
              to={link}
              style={{ color: "red", fontWeight: "bold" }}
            >
              {link}
            </Link>{" "}
            ao realizar a ação <strong>{action}</strong>.
          </Typography>
        )}
      </div>
    </Box>
  );
};

WarningBox.propTypes = {
  link: PropTypes.string,
  action: PropTypes.string,
};

export default WarningBox;

import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import AprovarOuReprovarCurso from "../modals/AprovarOuReprovarCurso";
import InativarCurso from "../modals/InativarCurso";
import DefaultTable from "../table/DefaultTable";

const tabelaColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "telefone", headerName: "Telefone" },
  { field: "email", headerName: "E-mail" },
];

const initialFilterState = {
  cargaHoraria: "",
  modalidade: "",
  ofertante: "",
  pesquisar: "",
  situacao: "",
};

const keysHidden = ["uf", "municipio", "dataRegistro", "situacao"];

const initialData = [
  {
    id: null,
    nome: "",
    cpf: "",
    telefone: "",
    email: "",
    uf: "",
    municipio: "",
    dataRegistro: "",
    situacao: "",
    pcd: "",
    curriculo: [],
    requisitos: [],
    escolaridade: "",
  },
];

const ParceiroListarInteressados = ({ values = initialData }) => {
  const [inativarCurso, setInativarCurso] = useState(false);
  const [reprovarCurso, setReprovarCurso] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(values);

  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        parceiro.cargaHoraria.includes(filter.cargaHoraria) ||
        parceiro.modalidade.includes(filter.modalidade) ||
        parceiro.ofertante.includes(filter.ofertante) ||
        parceiro.pesquisar.includes(filter.pesquisar) ||
        parceiro.situacao.includes(filter.situacao)
      );
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const fecharInativarCurso = () => {
    setInativarCurso(false);
  };

  const fecharReprovarCurso = () => {
    setReprovarCurso(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Filtros */}
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <TextField
                fullWidth
                label="Carga Horária"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <TextField
                fullWidth
                label="Modalidade"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
            >
              <TextField
                fullWidth
                label="Ofertante"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
            >
              <TextField
                fullWidth
                label={"Pesquisar"}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
            >
              <FormControl fullWidth>
                <InputLabel>Mudar Situação dos Usuários Selecionados</InputLabel>
                <Select
                  id="situacao"
                  name="situacao"
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value="Pendente">Pendente</MenuItem>
                  <MenuItem value="Aprovado">Aprovado</MenuItem>
                  <MenuItem value="Reprovado">Reprovado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              mt={2}
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              mostrarCaixasSelecao={true}
              onSelecionadosChange={selecionados => {
                console.log("Custom onSelecionadosChange:", selecionados);
              }}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <InativarCurso
          showModal={inativarCurso}
          handleClose={fecharInativarCurso}
        />
        <AprovarOuReprovarCurso
          modalTitle="Reprovar curso"
          showModal={reprovarCurso}
          handleClose={fecharReprovarCurso}
        />
      </div>
    </>
  );
};

ParceiroListarInteressados.propTypes = {
  values: PropTypes.array,
};

export default ParceiroListarInteressados;

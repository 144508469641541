import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminParceiroVisualizarCurriculo from "../../components/formularios/AdminParceiroVisualizarCurriculo";
import TitlePage from "../../components/title-page/TitlePage";

const PageVisualizarCurriculoMobilizacao = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          {
            href: "/parceiro",
            text: "Parceiro",
          },
          {
            href: "/mobilizacao",
            text: "Mobilização",
          },
          {
            href: "/mobilizacao/curriculos/buscar",
            text: "Buscar Currículo",
          },
        ]}
        currentPage="Visualizar Currículo"
      />

      <TitlePage title={"Visualizar Currículo"} />

      <AdminParceiroVisualizarCurriculo />
    </Container>
  );
};

export default PageVisualizarCurriculoMobilizacao;

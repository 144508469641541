import constantsService from "../services/constants.service";

const mapInstituicoes = (instituicoes, tipo) => {
  if (!instituicoes) return [];

  let listInstituicoes = [];

  instituicoes.forEach(item => {
    if (item.tipo == tipo) {
      listInstituicoes.push(item);
    }
  });

  return listInstituicoes;
};

const transformDadosEmpresaComplementar = dadosRecebidos => {
  const dados = {
    eOSCIP: dadosRecebidos.oscips.length > 0 ? true : false,
    oscips: dadosRecebidos.oscips,
    eRegistradoBCB: dadosRecebidos.tp_bacen ? true : false,
    seguimentoBCB: dadosRecebidos.tp_bacen,
    eCorrespondenteBancario: mapInstituicoes(dadosRecebidos.associadas, "CBN").length > 0 ? true : false,
    instituicoesCorrespondenteBancario: mapInstituicoes(dadosRecebidos.associadas, "CBN"),
    eFintech: dadosRecebidos.st_fintech == "SIM" ? true : false,
    eApoioAtividadeProdutiva: dadosRecebidos.st_apoio_emp_simples == "SIM" ? true : false,
    eEmpresaSimplesCredito: dadosRecebidos.st_emp_simples == "SIM" ? true : false,
    ePNMPO: dadosRecebidos.st_operador_pnmpo == "SIM" ? true : false,
    dataDeInicio: dadosRecebidos.dt_operador_pnmpo,
    recursosRadio: dadosRecebidos.tp_microcredito_recurso,
    instituicoesRepassadoras: mapInstituicoes(dadosRecebidos.associadas, "MCR"),
    eMicrocreditoMandato: dadosRecebidos.tp_microcredito_mandataria == "SIM" ? true : false,
    instituicoesMandatarias: mapInstituicoes(dadosRecebidos.associadas, "MCM"),
    abrangenciaOperacao: dadosRecebidos.tp_area_abrangencia,
    areaAbrangenciaLista: dadosRecebidos.area_abrangencia_lista,
    situacao: dadosRecebidos.tp_situacao_registro ? constantsService.getStatusByCode(dadosRecebidos.tp_situacao_registro) : "",
    motivo: dadosRecebidos.motivo ? dadosRecebidos.motivo : "",
  };

  return dados;
};

export default transformDadosEmpresaComplementar;

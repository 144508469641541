import React, { useState } from "react";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminUsuariosRepresentantesParceiro from "../../components/formularios/AdminUsuariosRepresentantesParceiro";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageUsuarioRepresentanteParceiro = () => {
  const {
    isPending,
    isError,
    data: empresas,
  } = useQuery({
    queryKey: ["empresasParceiras"],
    queryFn: adminService.getEmpresasParceiras,
  });
  const [mudancaDeStatusModal, setMudancaDeStatusModal] = useState(false);
  const [informacoesModal, setInformacoesModal] = useState(false);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiros/aprovados", text: "Listar Aprovados" }]}
        currentPage="Lista de usuários representantes do parceiro"
      />

      <TitlePage title={"Lista de usuários representantes do parceiro"} />

      {isPending || !empresas ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <AdminUsuariosRepresentantesParceiro
          listaEmpresas={empresas}
          mudancaDeStatusModal={mudancaDeStatusModal}
          setMudancaDeStatusModal={setMudancaDeStatusModal}
          informacoesModal={informacoesModal}
          setInformacoesModal={setInformacoesModal}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageUsuarioRepresentanteParceiro;

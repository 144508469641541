import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { utils as XLSXUtils, writeFile as writeXLSXFile } from "xlsx";

import { useResponsive } from "../../hooks/use-responsive";
import constantsService from "../../services/constants.service";
import { formatCNPJ, formatTelefone } from "../formularios/utils.js";

const defaultColumns = [
  { field: "primeira", headerName: "Primeira coluna" },
  { field: "segunda", headerName: "Segunda coluna" },
  { field: "terceira", headerName: "Terceira coluna" },
];

const defaultTermos = constantsService.getAllTermos();

const formatValue = (key, value) => {
  if (key.startsWith("data")) {
    return dayjs(value).format("DD/MM/YYYY");
  } else if (key.startsWith("cnpj")) {
    return formatCNPJ(value);
  } else if (key.startsWith("telefone")) {
    return formatTelefone(value);
  } else {
    return value;
  }
};

export default function DefaultTable({
  rows = [],
  columns = defaultColumns,
  hiddenRows = [],
  actionButtons = undefined,
  notFoundText = "Nenhum registro encontrado",
  termos = defaultTermos,
  mostrarCaixasSelecao = false,
  onSelecionadosChange = selecionados => {
    console.log("Selected IDs:", selecionados);
  },
  mostrarCirculosColoridos = false,
  listaCirculosColoridos = [],
  outsideDownloadExcel = undefined,
  rowsPage = 5,
}) {
  const theme = useTheme();
  const mdDown = useResponsive("down", "md");

  const [expandedRows, setExpandedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [selecionados, setSelecionados] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [rowsPerPage, setRowsPerPage] = useState(rowsPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSort = columnKey => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  const handleArrowClick = globalIndex => {
    const newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(globalIndex)) {
      newExpandedRows.splice(newExpandedRows.indexOf(globalIndex), 1);
    } else {
      newExpandedRows.push(globalIndex);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleDownloadCSV = data => {
    if (data.length === 0) {
      console.error("Sem dados para download");
      return;
    }

    // Extract all unique keys from the data
    const allColumns = Array.from(new Set(data.flatMap(row => Object.keys(row))));
    const csvDataHead = allColumns.map(key => (termos[key] ? termos[key] : key)).join(";") + "\n";

    const csvDataBody = data
      .map(row =>
        allColumns
          .map(column => {
            const value = row[column];
            if (Array.isArray(value)) {
              return value.join("; ");
            } else if (typeof value === "object" && value !== null) {
              return JSON.stringify(value);
            } else {
              return value !== undefined ? value : "";
            }
          })
          .join(";"),
      )
      .join("\n");

    const csvData = csvDataHead + csvDataBody;

    // Add BOM to ensure UTF-8 encoding
    const bom = "\uFEFF";
    const blob = new Blob([bom + csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "dados_tabela.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownloadExcelClick = () => {
    if (outsideDownloadExcel) {
      console.log("Original download excel was overloaded");
      outsideDownloadExcel(rows);
    } else {
      handleDownloadExcel(rows);
    }
  };

  const handleDownloadExcel = data => {
    const worksheet = XLSXUtils.json_to_sheet(data);
    const workbook = XLSXUtils.book_new();

    XLSXUtils.book_append_sheet(workbook, worksheet, "Dados Tabela");

    writeXLSXFile(workbook, "dados_tabela.xlsx");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Lista de Dados Tabela",
    onAfterPrint: () => console.log("Printing completed"),
  });

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecionados = rows.map(row => row.id);
      setSelecionados(newSelecionados);
      onSelecionadosChange(newSelecionados);
    } else {
      setSelecionados([]);
      onSelecionadosChange([]);
    }
  };

  const handleSelectClick = (event, id) => {
    const selectedIndex = selecionados.indexOf(id);
    let newSelecionados = [];

    if (selectedIndex === -1) {
      newSelecionados = newSelecionados.concat(selecionados, id);
    } else if (selectedIndex === 0) {
      newSelecionados = newSelecionados.concat(selecionados.slice(1));
    } else if (selectedIndex === selecionados.length - 1) {
      newSelecionados = newSelecionados.concat(selecionados.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelecionados = newSelecionados.concat(selecionados.slice(0, selectedIndex), selecionados.slice(selectedIndex + 1));
    }

    setSelecionados(newSelecionados);
    onSelecionadosChange(newSelecionados);
  };

  const getActionButtonsForRow = objRow => {
    if (typeof actionButtons === "function") {
      return actionButtons(objRow);
    }
    return actionButtons;
  };

  const sortedRows = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...rows].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      return sorted;
    }
    return rows;
  }, [rows, sortConfig]);

  const sortedHiddenRows = React.useMemo(() => {
    const indexMap = new Map(rows.map((row, index) => [row.id, index]));
    return sortedRows.reduce((acc, row, index) => {
      const originalIndex = indexMap.get(row.id);
      if (originalIndex !== undefined && hiddenRows[originalIndex]) {
        acc[index] = hiddenRows[originalIndex];
      }
      return acc;
    }, {});
  }, [sortedRows, hiddenRows, rows]);

  const componentRef = useRef();
  const tableRef = useRef();
  const lgUp = useResponsive("up", "sm");
  return (
    <TableContainer
      component={Paper}
      ref={tableRef}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.length > 0 &&
              columns.map((column, index) => (
                <TableCell
                  key={index}
                  onClick={() => handleSort(column.field)}
                >
                  <div style={{ display: "flex", cursor: "pointer" }}>
                    {column.headerName}
                    {sortConfig.key === column.field ? (
                      sortConfig.direction === "asc" ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    ) : (
                      <UnfoldMoreIcon style={{ color: "grey" }} />
                    )}
                  </div>
                </TableCell>
              ))}
            <TableCell align="right">
              <Box
                display="flex"
                justifyContent="flex-end"
                gap={1}
              >
                {mostrarCaixasSelecao && (
                  <Checkbox
                    indeterminate={selecionados.length > 0 && selecionados.length < rows.length}
                    checked={rows.length > 0 && selecionados.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "Marcar/Desmarcar todos" }}
                    style={{ color: theme.palette.text.disabled }}
                  />
                )}
                <Tooltip title="Imprimir tabela">
                  <IconButton
                    color="primary"
                    sx={{
                      border: "1px solid",
                      borderRadius: "8px",
                      backgroundColor: theme.palette.text.inverter,
                    }}
                    pl={1}
                    onClick={() => handlePrint()}
                  >
                    <span className="material-symbols-outlined">print</span>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download da tabela em CSV">
                  <IconButton
                    color="primary"
                    sx={{
                      border: "1px solid",
                      borderRadius: "8px",
                      backgroundColor: theme.palette.text.inverter,
                    }}
                    pl={1}
                    onClick={() => handleDownloadCSV(rows)}
                  >
                    <span className="material-symbols-outlined">csv</span>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Download da tabela em EXCEL">
                  <IconButton
                    color="primary"
                    sx={{
                      border: "1px solid",
                      borderRadius: "8px",
                      backgroundColor: theme.palette.text.inverter,
                    }}
                    onClick={handleDownloadExcelClick}
                  >
                    <span className="material-symbols-outlined">data_table</span>
                  </IconButton>
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        {rows && rows.length !== 0 ? (
          <>
            {rows.length > 0 &&
              sortedRows.slice(rowsPerPage * (page - 1), rowsPerPage * page).map((objRow, localIndex) => {
                const globalIndex = rowsPerPage * (page - 1) + localIndex;
                const circleColor = listaCirculosColoridos[globalIndex] || "gray";
                const actionButtonsForRow = getActionButtonsForRow(objRow);

                return (
                  <TableBody key={globalIndex + "_tbody"}>
                    <TableRow key={globalIndex + "_TableRow"}>
                      {/* Verifica quais valores em rows estao presentes no array de columns, para exibir somente os valores explicitados */}
                      {columns
                        // eslint-disable-next-line no-prototype-builtins
                        .filter(column => objRow.hasOwnProperty(column.field))
                        .sort((a, b) => columns.indexOf(a) - columns.indexOf(b))
                        .map((column, colIndex) => (
                          <TableCell
                            key={globalIndex + "_" + column.field}
                            sx={column.sxRowProps}
                          >
                            {/* Verifica se tem circulos coloridos para mostrá-los */}
                            {colIndex === 0 && mostrarCirculosColoridos && (
                              <CircleIcon sx={{ color: circleColor, fontSize: "small", marginRight: "5px" }} />
                            )}
                            {/* {column.field.startsWith("data") ? dayjs(objRow[column.field]).format("DD/MM/YYYY") : objRow[column.field]} */}
                            {formatValue(column.field, objRow[column.field])}
                          </TableCell>
                        ))}
                      <TableCell align="right">
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          gap={1}
                        >
                          {/* Verifica se tem action buttons para mostrá-los */}
                          {actionButtonsForRow && (
                            <ActionButtons
                              id={objRow.id}
                              actions={actionButtonsForRow.map(action => ({
                                ...action,
                                // onClick: action.onClick ? () => action.onClick(objRow.id) : undefined,
                                onClick: action.onClick ? (objRow.id ? () => action.onClick(objRow.id) : action.onClick) : undefined,
                              }))}
                            />
                          )}
                          {/* Verifica se possui hiddenRows para exibir o dropDown */}
                          {hiddenRows.length > 0 && (
                            <Tooltip title="Detalhes do item">
                              <IconButton
                                color="primary"
                                onClick={() => handleArrowClick(globalIndex)}
                              >
                                {expandedRows.includes(globalIndex) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </Tooltip>
                          )}
                          {/* Verifica se deve mostrar a caixa de seleção */}
                          {mostrarCaixasSelecao && (
                            <Checkbox
                              checked={selecionados.indexOf(objRow.id) !== -1}
                              onChange={event => handleSelectClick(event, objRow.id)}
                              inputProps={{ "aria-label": `Select row ${globalIndex}` }}
                              style={{ color: theme.palette.text.disabled }}
                            />
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                    {hiddenRows.length > 0 && expandedRows.includes(globalIndex) && (
                      <TableRow key={globalIndex + "_HiddenRows"}>
                        <TableCell
                          colSpan={10}
                          style={{ backgroundColor: theme.palette.grey[200] }}
                        >
                          <Grid
                            container
                            spacing={2}
                          >
                            {/* TABELA NORMAL */}
                            {/* Itera sobre o object de uma posicao do array */}
                            {Object.entries(sortedHiddenRows[globalIndex]).map(([key, value], subIndex) => (
                              <React.Fragment key={globalIndex + "_HiddenRows_fragment" + subIndex}>
                                <Grid
                                  key={globalIndex + "_HiddenRows_grid" + subIndex}
                                  item
                                  md={3}
                                  xs={6}
                                >
                                  <a style={{ fontFamily: "Rawline Bold" }}>{termos[key] ? termos[key] : key}</a>
                                  <p style={{ fontFamily: "Rawline Medium" }}>{formatValue(key, value)}</p>
                                </Grid>
                                {((mdDown && (subIndex + 1) % 2 === 0) || (!mdDown && (subIndex + 1) % 4 === 0)) && (
                                  <Grid
                                    item
                                    xs={12}
                                    key={globalIndex + "_divider" + subIndex}
                                  >
                                    <div style={{ borderBottom: "1px solid", borderColor: theme.palette.grey[600] }}></div>
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                );
              })}
          </>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={5}>{notFoundText}</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>

      {/* PAGINACAO*/}
      {lgUp ? (
        <div
          style={{
            borderTop: "1px solid #d3d3d3",
            padding: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "15px", width: "45%" }}>
            <a>itens exibidos: </a>
            <FormControl variant="outlined">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage} // Define o valor inicial
                onChange={event => setRowsPerPage(event.target.value)}
                variant="outlined"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <a>de {rows.length}</a>
          </div>
          <div style={{ flex: 1, display: "flex" }}>
            <Pagination
              page={page}
              count={rows.length > 0 ? Math.ceil(rows.length / rowsPerPage) : 1}
              color="primary"
              onChange={handlePageChange}
              sx={{
                "& .MuiPaginationItem-root": {
                  "&.Mui-selected": {
                    color: theme.palette.text.inverter + " !important",
                  },
                }, // Aplica a cor apenas ao item selecionado
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            borderTop: "1px solid #d3d3d3",
            padding: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ flex: 1, display: "flex", marginBottom: "16px" }}>
            <Pagination
              page={page}
              count={rows.length > 0 ? Math.ceil(rows.length / rowsPerPage) : 1}
              color="primary"
              onChange={handlePageChange}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "15px", width: "100%", justifyContent: "center" }}>
            <a>itens exibidos: </a>
            <FormControl variant="outlined">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rowsPerPage} // Define o valor inicial
                onChange={event => setRowsPerPage(event.target.value)}
                variant="outlined"
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <a>de {rows.length}</a>
          </div>
        </div>
      )}

      {/* CRIANDO TEMPLATE DA TABELA DE IMPRESSA */}
      <div style={{ display: "none" }}>
        <TableContainer
          component={Paper}
          ref={componentRef}
        >
          <Table>
            <TableHead>
              <TableRow>
                {columns.length > 0 &&
                  columns.map((objColumn, index) => (
                    <TableCell
                      sx={objColumn.sxProps}
                      key={index + "_" + objColumn.headerName + "_template"}
                    >
                      {objColumn.headerName}
                    </TableCell>
                  ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {rows.length != 0 ? (
              <>
                {rows.length > 0 &&
                  rows.map((objRow, index) => (
                    <TableBody key={index + "_tbody" + "_template"}>
                      <TableRow key={index + "_TableRow" + "_template"}>
                        {/* Verifica quais valores em rows estao presentes no array de columns, para exibir somente os valores explicitados */}
                        {columns
                          // eslint-disable-next-line no-prototype-builtins
                          .filter(column => objRow.hasOwnProperty(column.field))
                          .sort((a, b) => columns.indexOf(a) - columns.indexOf(b))
                          .map(column => (
                            <TableCell key={index + "_" + column.field + "_template"}>
                              {/* {column.field.startsWith("data") ? dayjs(objRow[column.field]).format("DD/MM/YYYY") : objRow[column.field]} */}
                              {formatValue(column.field, objRow[column.field])}
                            </TableCell>
                          ))}
                        {/* Verifica se possui hiddenRows para exibir o dropDown */}
                        {hiddenRows.length > 0 && (
                          <TableCell align="right">
                            <Tooltip title="Detalhes do item">
                              <IconButton
                                color="primary"
                                onClick={() => handleArrowClick(index)}
                              >
                                {expandedRows.includes(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                      {hiddenRows.length > 0 && (
                        <TableRow
                          key={index + "_HiddenRows" + "_template"}
                          style={{ borderBottom: "2px solid grey" }}
                        >
                          <TableCell
                            colSpan={10}
                            style={{ backgroundColor: theme.palette.grey[200] }}
                          >
                            <Grid>
                              <Grid
                                container
                                spacing={2}
                              >
                                {/* TABELA HIDDEN */}
                                {/* Itera sobre o object de uma posicao do array */}
                                {Object.entries(hiddenRows[index]).map(([key, value], subIndex) => (
                                  <React.Fragment key={index + "_HiddenRows_fragment" + subIndex}>
                                    <Grid
                                      key={index + "_HiddenRows_grid" + subIndex}
                                      item
                                      xs={4}
                                    >
                                      <a style={{ fontFamily: "Rawline Bold" }}>{termos[key] ? termos[key] : key}</a>
                                      <p style={{ fontFamily: "Rawline Medium" }}>{formatValue(key, value)}</p>
                                    </Grid>

                                    {(subIndex + 1) % 3 === 0 && (
                                      <Grid
                                        item
                                        xs={12}
                                        key={index + "_divider" + subIndex}
                                      >
                                        <div style={{ borderBottom: "1px solid", borderColor: theme.palette.grey[600] }}></div>
                                      </Grid>
                                    )}
                                  </React.Fragment>
                                ))}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  ))}
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={5}>{notFoundText}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </TableContainer>
  );
}

// Add prop types validation
DefaultTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  hiddenRows: PropTypes.array,
  actionButtons: PropTypes.func,
  notFoundText: PropTypes.string,
  termos: PropTypes.object,
  mostrarCaixasSelecao: PropTypes.bool,
  onSelecionadosChange: PropTypes.func,
  mostrarCirculosColoridos: PropTypes.bool,
  listaCirculosColoridos: PropTypes.array,
  outsideDownloadExcel: PropTypes.func,
  rowsPage: PropTypes.number,
};

const ActionButtons = ({ actions, id }) => {
  return (
    <>
      {actions.map((action, index) => (
        <Tooltip
          key={index}
          title={action.title}
        >
          <IconButton
            color="primary"
            href={action.href || undefined}
            onClick={
              action.onClick
                ? id
                  ? () => action.onClick(id)
                  : action.onClick
                : () => console.log(`onClick not defined for ${action.title}`)
            }
            sx={{
              border: "1px solid",
              borderRadius: "8px",
            }}
          >
            {/* TODO mudar para material symbols ? */}
            {/* <span className="material-symbols-outlined">{action.icon}</span> */}
            <span className="material-icons">{action.icon}</span>
          </IconButton>
        </Tooltip>
      ))}
    </>
  );
};

ActionButtons.propTypes = {
  id: PropTypes.number,
  actions: PropTypes.array,
};

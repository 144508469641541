import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";

import AprovarOuReprovarCurso from "../modals/AprovarOuReprovarCurso";
import InativarCurso from "../modals/InativarCurso";
import DefaultTable from "../table/DefaultTable";
import { tabelaInteressados } from "./dadosMockados";

const tabelaColunas = [
  { field: "situacao", headerName: "Situação" },
  { field: "pcd", headerName: "PCD" },
  { field: "nome", headerName: "Nome" },
  { field: "email", headerName: "E-mail" },
];

const initialData = tabelaInteressados;

const initialFilterState = {
  cargaHoraria: "",
  modalidade: "",
  ofertante: "",
  pesquisar: "",
  situacao: "",
};

const keysHidden = ["curriculo", "requisitos"];

const ParceiroListarInteressadosVagaTrabalho = () => {
  const [inativarCurso, setInativarCurso] = useState(false);
  const [reprovarCurso, setReprovarCurso] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        parceiro.cargaHoraria.includes(filter.cargaHoraria) ||
        parceiro.modalidade.includes(filter.modalidade) ||
        parceiro.ofertante.includes(filter.ofertante) ||
        parceiro.pesquisar.includes(filter.pesquisar) ||
        parceiro.situacao.includes(filter.situacao)
      );
    });
    setFilteredData(filtered);
  };

  const getTabelaActions = () => [
    {
      title: "Ativar",
      href: "",
      icon: "thumb_up",
    },
  ];

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const fecharInativarCurso = () => {
    setInativarCurso(false);
  };

  const fecharReprovarCurso = () => {
    setReprovarCurso(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography variant="h6">Currículos encontrados para a vaga {"<tipo de vaga> <nome da vaga>"}</Typography>
          <Typography
            variant="h6"
            mt={1}
          >
            Quantidade de currículos com o perfil {"<quantidade>"}
          </Typography>
          {/* Filtros */}
          <Grid
            container
            spacing={2}
            mt={1}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl fullWidth>
                <InputLabel>Atendem aos critérios da vaga</InputLabel>
                <Select
                  id="requisitos"
                  name="requisitos"
                  value={filter.requisitos}
                  onChange={e => setFilter({ ...filter, pcd: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value={"Sim"}>Sim</MenuItem>
                  <MenuItem value={"Não"}>Não</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl fullWidth>
                <InputLabel>É PCD?</InputLabel>
                <Select
                  id="pcd"
                  name="pcd"
                  value={filter.pcd}
                  onChange={e => setFilter({ ...filter, pcd: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                  <MenuItem value={"Sim"}>Sim</MenuItem>
                  <MenuItem value={"Não"}>Não</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
              mt={2}
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foram localizadas vagas!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              mostrarCaixasSelecao={false}
              onSelecionadosChange={selecionados => {
                console.log("Custom onSelecionadosChange:", selecionados);
              }}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <InativarCurso
          showModal={inativarCurso}
          handleClose={fecharInativarCurso}
        />
        <AprovarOuReprovarCurso
          modalTitle="Reprovar curso"
          showModal={reprovarCurso}
          handleClose={fecharReprovarCurso}
        />
      </div>
    </>
  );
};

export default ParceiroListarInteressadosVagaTrabalho;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ThemeProvider } from "@emotion/react";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Card, FormGroup, Grid, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";

import adminService from "../../services/admin.service";
import ErroCarregamentoModal from "../errors/ErrorModal";
import { formatCNPJ, formatTelefone } from "../formularios/utils.js";
import LoadingComponent from "../loading/LoadingComponent";
import ErroModal from "../modals/ErroModal";
import FinanceiroModal from "../modals/FinanceiroModal";

const initialCores = {
  0: "naoSelecionado",
  1: "naoSelecionado",
  2: "naoSelecionado",
  3: "naoSelecionado",
  4: "naoSelecionado",
  5: "naoSelecionado",
  6: "naoSelecionado",
};

const AdminInativarEditarParceiro = ({ inativar, saveString }) => {
  const navigate = useNavigate();
  const [valores, setValores] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cores, setCores] = useState(initialCores);
  const [financeiroModal, setFinanceiroModal] = useState(false);
  const [erroModal, setErroModal] = useState(false);
  let { id } = useParams();

  const handleErroClose = () => {
    setErroModal(false);
    navigate("/parceiros/pendentes");
  };

  const theme = useTheme();
  const isDark = theme.palette.mode == "dark";

  const tema = createTheme({
    palette: {
      naoSelecionado: {
        main: isDark ? "#02b1c4" : "#FFFFFF",
        dark: isDark ? "#017a87" : "#D9D9D9",
      },
      selecionado: {
        main: isDark ? "#64a367" : "#8AE38F",
        dark: isDark ? "#4d7d50" : "#77C77B",
      },
    },
  });

  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresaParceira", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminService.getEmpresaParceira(id);
    },
    enabled: !!id,
  });

  useEffect(() => {
    setIsLoading(true);
    if (initialData) {
      const servicos = Object.keys(initialData.tipoDeServico).filter(servico => initialData.tipoDeServico[servico]);
      const cores = Object.keys(initialData.tipoDeServico).map(servico => {
        if (initialData.tipoDeServico[servico]) {
          return "selecionado";
        }
        return "naoSelecionado";
      });

      cores.forEach((index, valor) => setCores({ ...cores, [index]: valor }));

      setValores({
        ...initialData,
        motivo: "",
        tipoDeServico: servicos,
      });
    }
    setIsLoading(false);
  }, [initialData, id]);

  // if (isLoading || isPending) {
  //   return <div>Carregando...</div>;
  // } else if (!valores) {
  //   return <div>Parceiro não encontrado</div>;
  // } else if (isError) {
  //   console.error("Erro: " + error);
  //   return <div>Erro na consulta</div>;
  // }

  const selectServico = event => {
    const { id, name } = event.target;
    if (inativar) {
      return;
    }
    if (valores["tipoDeServico"].includes(name)) {
      setValores({ ...valores, ["tipoDeServico"]: valores["tipoDeServico"].filter(nome => nome !== name) });
      setCores({ ...cores, [id]: "naoSelecionado" });
    } else {
      if (name == "FPG") {
        setFinanceiroModal(true);
      } else {
        setValores({ ...valores, ["tipoDeServico"]: valores["tipoDeServico"].concat([name]) });
        setCores({ ...cores, [id]: "selecionado" });
      }
    }
  };

  const handleChanges = event => {
    const { name, value } = event.target;
    setValores({ ...valores, [name]: value });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const editarParceiro = {
      empresa_id: JSON.parse(localStorage.getItem("analisarID")).toString(),
      status: "INA",
      mensagem: valores.motivo,
    };
    if (valores.motivo.length > 0) {
      return adminService
        .postAtualizaStatusEmpresa(editarParceiro)
        .then(mensagem => {
          console.log("Sucesso:", mensagem);
          alert("Operação realizada com sucesso!");
        })
        .catch(erro => {
          console.error(" ", erro);
          setErroModal(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    alert("O motivo precisa ser fornecido");
    setIsLoading(false);
    return;
  };

  const handleFinanceiroClose = () => {
    setFinanceiroModal(false);
  };

  const handleFinanceiroContinuar = () => {
    setValores({ ...valores, ["tipoDeServico"]: valores["tipoDeServico"].concat(["FPG"]) });
    setCores({ ...cores, [4]: "selecionado" });
    setFinanceiroModal(false);
  };

  if (isLoading || isPending) {
    return (
      <LoadingComponent
        isLoading={isPending}
        fullScreen={false}
      />
    );
  } else if (!valores) {
    if (!isError) {
      console.error("Erro: " + error);
      return <div>Erro na consulta</div>;
    }
    return <div>Parceiro não encontrado</div>;
  } else if (isError) {
    console.error("Erro de carregamento: " + error);
    return <ErroCarregamentoModal isShow={true} />;
  }

  return (
    <>
      <Stack spacing={2}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Grid
            spacing={2}
            container
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle1">Dados</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="email"
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                  value={valores.email}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="cnpj"
                  name="cnpj"
                  label="CNPJ"
                  placeholder="CNPJ"
                  value={formatCNPJ(valores.cnpj) || ""}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="razaoSocial"
                  name="razaoSocial"
                  label="Razão Social"
                  placeholder="Razão Social"
                  value={valores.razaoSocial}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  placeholder="Nome Fantasia"
                  value={valores.nomeFantasia}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="cep"
                  name="cep"
                  label="CEP"
                  placeholder="CEP"
                  value={valores.cep}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="endereco"
                  name="endereco"
                  label="Logradouro"
                  placeholder="Logradouro"
                  value={valores.endereco}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="numero"
                  name="numero"
                  label="Número"
                  placeholder="Número"
                  value={valores.numero}
                  type="number"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  placeholder="Complemento"
                  value={valores.complemento}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  placeholder="Bairro"
                  value={valores.bairro}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="uf"
                  name="uf"
                  label="UF"
                  placeholder="UF"
                  value={valores.uf}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="cidade"
                  name="cidade"
                  label="Município"
                  placeholder="Município"
                  value={valores.cidade}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  placeholder="Telefone"
                  value={formatTelefone(valores.telefone) || ""}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="site"
                  name="site"
                  label="Site"
                  placeholder="Site"
                  value={valores.site}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="redesSociais"
                  name="redesSociais"
                  label="Redes Sociais"
                  placeholder="Redes Sociais"
                  value={valores.redesSociais}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Card>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle1">
                Representante
                <Tooltip
                  title="Representante da empresa"
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="nomeResponsavel"
                  name="nomeResponsavel"
                  label="Nome"
                  placeholder="Nome"
                  value={valores.nomeResponsavel}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  placeholder="CPF"
                  value={valores.cpf}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="telefoneResponsavel"
                  name="telefoneResponsavel"
                  label="Telefone"
                  placeholder="Telefone"
                  value={valores.telefoneResponsavel}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="ufResponsavel"
                  name="ufResponsavel"
                  label="UF"
                  placeholder="UF"
                  value={valores.ufResponsavel}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="cidadeResponsavel"
                  name="cidadeResponsavel"
                  label="Município"
                  placeholder="Município"
                  value={valores.cidadeResponsavel}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Card>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle1">
                Ponto Focal
                <Tooltip
                  title="Ponto Focal da empresa"
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="nomePontoFocal"
                  name="nomePontoFocal"
                  label="Nome"
                  placeholder="Nome"
                  value={valores.nomePontoFocal}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="emailPontoFocal"
                  name="emailPontoFocal"
                  label="E-mail"
                  placeholder="E-mail"
                  value={valores.emailPontoFocal}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="telefonePontoFocal"
                  name="telefonePontoFocal"
                  label="Telefone"
                  placeholder="Telefone"
                  value={valores.telefonePontoFocal}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="areaAtuacaoParceiro"
                  name="areaAtuacaoParceiro"
                  label="Área de Atuação do Parceiro"
                  placeholder="Área de Atuação do Parceiro"
                  value={valores.areaAtuacaoParceiro}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormGroup>
                <TextField
                  disabled={inativar}
                  id="naturezaJuridica"
                  name="naturezaJuridica"
                  label="Natureza Jurídica"
                  placeholder="Natureza Jurídica"
                  value={valores.naturezaJuridica}
                  type="text"
                  onChange={handleChanges}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Card>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography
            variant="subtitle2"
            mb={2}
          >
            Que tipo de serviço essa instituição irá ofertar?
          </Typography>
          <ThemeProvider theme={tema}>
            <Grid container>
              <Grid
                item
                xs={2.8}
              >
                <Button
                  id={0}
                  name="VEP"
                  variant="contained"
                  color={cores[0]}
                  onClick={selectServico}
                >
                  Vaga de Emprego
                </Button>
              </Grid>
              <Grid
                item
                xs={2.7}
              >
                <Button
                  id={1}
                  name="VET"
                  variant="contained"
                  color={cores[1]}
                  onClick={selectServico}
                >
                  Vaga de Estágio
                </Button>
              </Grid>
              <Grid
                item
                xs={3.6}
              >
                <Button
                  id={2}
                  name="VJA"
                  variant="contained"
                  color={cores[2]}
                  onClick={selectServico}
                >
                  Vaga de Jovem Aprendiz
                </Button>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <Button
                  id={3}
                  name="CUR"
                  variant="contained"
                  color={cores[3]}
                  onClick={selectServico}
                >
                  Cursos
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              mt={1}
            >
              <Grid
                item
                xs={4.3}
              >
                <Button
                  id={4}
                  name="FPG"
                  variant="contained"
                  color={cores[4]}
                  onClick={selectServico}
                >
                  Financeiros e de Pagamentos
                </Button>
              </Grid>
              <Grid
                item
                xs={3.7}
              >
                <Button
                  id={5}
                  name="MPU"
                  variant="contained"
                  color={cores[5]}
                  onClick={selectServico}
                >
                  Mobilização de Público
                </Button>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Button
                  id={6}
                  name="MPA"
                  variant="contained"
                  color={cores[6]}
                  onClick={selectServico}
                >
                  Mobilização de Parceiro
                </Button>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Card>

        <>
          {inativar ? (
            <Card
              color="#ffffff"
              sx={{
                borderRadius: "8px",
                padding: "16px",
                mt: "24px",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                >
                  <FormGroup>
                    <TextField
                      multiline
                      rows={3}
                      id="motivo"
                      name="motivo"
                      label="* Motivo da exclusão"
                      placeholder="Motivo da exclusão"
                      value={valores.motivo}
                      type="text"
                      onChange={handleChanges}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Card>
          ) : (
            ""
          )}
        </>
        <Grid
          container
          spacing={1}
          mt={3}
          justifyContent="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={"auto"}
          >
            <Button
              variant="outlined"
              onClick={() => window.history.back()}
            >
              <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={"auto"}
          >
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ gap: "8px" }}
            >
              <SaveIcon fontSize="small" />
              <Typography variant={"BUTTON TEXT"}>{saveString}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <div>
        <FinanceiroModal
          showModal={financeiroModal}
          handleContinuar={() => handleFinanceiroContinuar()}
          handleClose={() => handleFinanceiroClose()}
        />
      </div>
      <div>
        <ErroModal
          showModal={erroModal}
          handleClose={handleErroClose}
        />
      </div>
    </>
  );
};

AdminInativarEditarParceiro.propTypes = {
  inativar: PropTypes.bool.isRequired,
  saveString: PropTypes.string.isRequired,
};

export default AdminInativarEditarParceiro;

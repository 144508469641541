import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormGroup, Grid, Skeleton, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import optionsService from "../../services/options.service.js";
import { SelectCidade } from "./fields/SelectCidade.js";
import { SelectUF } from "./fields/SelectUF.js";
import { formatCEP } from "./utils.js";

let defaultValues = [
  {
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    uf: "",
    municipio: "",
    referencia: "",
  },
];

const FormAddress = ({
  onChange,
  values = defaultValues,
  allowMultiple = false,
  showReferencia = false,
  readOnly = false,
  showError = false,
}) => {
  const [formValues, setFormValues] = useState(values);
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState(values.map(v => v.uf));
  const [selectedMunicipio, setSelectedMunicipio] = useState(values.map(v => v.municipio));
  const [isLoadingCEP, setIsLoadingCEP] = useState(values.map(() => false));
  const [errors, setErrors] = useState(
    values.map(() => ({
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      uf: "",
      municipio: "",
      complemento: "",
    })),
  );

  useEffect(() => {
    const fetchUfs = async () => {
      try {
        const ufsData = await optionsService.getAllUFs();
        setUfs(ufsData);
      } catch (error) {
        console.error("Erro ao obter UFs:", error);
      }
    };
    fetchUfs();
  }, []);

  useEffect(() => {
    // Carrega as cidades para cada UF inicial
    values.forEach((value, index) => {
      if (value.uf) {
        fetchCidadesByUf(value.uf, cidades => {
          setMunicipios(prevMunicipios => {
            const updatedMunicipios = [...prevMunicipios];
            updatedMunicipios[index] = cidades;
            return updatedMunicipios;
          });
        });
      }
    });
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = (index, event) => {
    const { name, value } = event.target;

    // console.log("VALUE -> ", value);

    setFormValues(prevFormValues => {
      const newFormValues = [...prevFormValues];
      newFormValues[index] = {
        ...newFormValues[index],
        [name]: value,
        municipio: "",
      };
      onChange(newFormValues);
      return newFormValues;
    });

    setSelectedUf(prevSelectedUf => {
      const updatedSelectedUf = [...prevSelectedUf];
      updatedSelectedUf[index] = value;
      return updatedSelectedUf;
    });

    fetchCidadesByUf(value, cidades => {
      setMunicipios(prevMunicipios => {
        const updatedMunicipios = [...prevMunicipios];
        updatedMunicipios[index] = cidades;
        return updatedMunicipios;
      });
    });
  };

  const handleSelectMunicipio = (index, event) => {
    const { name, value } = event.target;
    setFormValues(prevFormValues => {
      const newFormValues = [...prevFormValues];
      newFormValues[index] = {
        ...newFormValues[index],
        [name]: value,
      };
      // console.log(`nes form - `, newFormValues);
      onChange(newFormValues);
      return newFormValues;
    });

    setSelectedMunicipio(prevSelectedMunicipio => {
      const updatedSelectedMunicipio = [...prevSelectedMunicipio];
      updatedSelectedMunicipio[index] = value;
      return updatedSelectedMunicipio;
    });

    // console.log("updatedSelectedMunicipio - ", selectedMunicipio);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index][name] = value;

    setFormValues(newFormValues);
    validateField(index, name, value);
    onChange(newFormValues);
  };

  const handleChangeCEP = async (index, event) => {
    const { value } = event.target;
    const newFormValues = [...formValues];
    newFormValues[index].cep = value;

    setFormValues(newFormValues);
    validateField(index, "cep", value);

    if (value.replace(/\D/g, "").length === 8) {
      try {
        setIsLoadingCEP(prev => {
          const newLoading = [...prev];
          newLoading[index] = true;
          return newLoading;
        });

        const dados = await optionsService.verificarCEP(value);
        if (dados) {
          newFormValues[index] = {
            ...newFormValues[index],
            uf: dados.uf,
            endereco: dados.logradouro,
            bairro: dados.bairro,
            complemento: dados.complemento || "",
            municipio: dados.localidade,
          };

          validateField(index, "uf", dados.uf);
          validateField(index, "endereco", dados.logradouro);
          validateField(index, "bairro", dados.bairro);
          validateField(index, "municipio", dados.localidade);

          const updatedSelectedUf = [...selectedUf];
          updatedSelectedUf[index] = dados.uf;
          const updatedSelectedMunicipio = [...selectedMunicipio];
          updatedSelectedMunicipio[index] = dados.localidade;
          setSelectedUf(updatedSelectedUf);
          setSelectedMunicipio(updatedSelectedMunicipio);
          setFormValues(newFormValues);
          onChange(newFormValues);
          fetchCidadesByUf(dados.uf, cidades => {
            const updatedMunicipios = [...municipios];
            updatedMunicipios[index] = cidades;
            setMunicipios(updatedMunicipios);
          });
        }
      } catch (error) {
        console.error("Erro obtendo CEP", error);
        const newErrors = [...errors];
        newErrors[index].cep = "CEP não encontrado";
        setErrors(newErrors);
      } finally {
        setIsLoadingCEP(prev => {
          const newLoading = [...prev];
          newLoading[index] = false;
          return newLoading;
        });
      }
    }
  };

  const addNewAddressForm = () => {
    if (allowMultiple) {
      setFormValues([
        ...formValues,
        {
          cep: "",
          endereco: "",
          numero: "",
          complemento: "",
          bairro: "",
          uf: "",
          municipio: "",
          referencia: "",
        },
      ]);
    }
  };

  const removeAddressForm = index => {
    if (allowMultiple && formValues.length > 1) {
      const newFormValues = formValues.filter((_, i) => i !== index);
      setFormValues(newFormValues);
      onChange(newFormValues);
    }
  };

  // Adicionar novo useEffect para validação inicial
  useEffect(() => {
    if (showError) {
      formValues.forEach((address, index) => {
        // Validar cada campo do endereço, incluindo explicitamente o municipio
        validateField(index, "cep", address.cep);
        validateField(index, "endereco", address.endereco);
        validateField(index, "numero", address.numero);
        validateField(index, "bairro", address.bairro);
        validateField(index, "uf", address.uf);
        validateField(index, "municipio", address.municipio);
      });
    }
  }, [showError, formValues]); // Adicionando formValues como dependência

  const validateField = (index, name, value) => {
    const newErrors = [...errors];

    if (!newErrors[index]) {
      newErrors[index] = {};
    }

    switch (name) {
      case "cep":
        newErrors[index].cep = !value ? "CEP é obrigatório" : "";
        break;
      case "endereco":
        newErrors[index].endereco = !value || value.trim() === "" ? "Endereço é obrigatório" : "";
        break;
      case "numero":
        newErrors[index].numero = !value || value.trim() === "" ? "Número é obrigatório" : "";
        break;
      case "bairro":
        newErrors[index].bairro = !value || value.trim() === "" ? "Bairro é obrigatório" : "";
        break;
      case "uf":
        newErrors[index].uf = !value || value.trim() === "" ? "UF é obrigatória" : "";
        break;
      case "municipio":
        newErrors[index].municipio = !value || value.trim() === "" ? "Município é obrigatório" : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  // Funções auxiliares para erro
  const hasError = (index, fieldName) => {
    return showError && errors[index]?.[fieldName] ? true : false;
  };

  const getErrorMessage = (index, fieldName) => {
    return showError ? errors[index]?.[fieldName] || "" : "";
  };

  return (
    <>
      {formValues.map((address, index) => (
        <Grid
          container
          spacing={2}
          key={index}
        >
          {allowMultiple && (
            <Grid
              item
              xs={12}
              mb={-2}
            >
              <Typography
                variant="h5"
                sx={{ fontSize: "12", mb: "16px", fontFamily: "Rawline Regular", color: "grey" }}
              >
                Endereço {index + 1}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              <TextField
                label="* CEP"
                id={`cep-${index}`}
                name="cep"
                value={formatCEP(address.cep)}
                onChange={e => handleChangeCEP(index, e)}
                inputProps={{ maxLength: 10 }}
                disabled={readOnly}
                error={hasError(index, "cep")}
                helperText={getErrorMessage(index, "cep")}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              {isLoadingCEP[index] ? (
                <Skeleton
                  variant="rectangular"
                  height={56}
                />
              ) : (
                <TextField
                  label="* Endereço"
                  id={`endereco-${index}`}
                  name="endereco"
                  value={address.endereco}
                  onChange={e => handleChange(index, e)}
                  disabled={readOnly}
                  error={hasError(index, "endereco")}
                  helperText={getErrorMessage(index, "endereco")}
                />
              )}
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
          >
            <FormGroup>
              {isLoadingCEP[index] ? (
                <Skeleton
                  variant="rectangular"
                  height={56}
                />
              ) : (
                <TextField
                  label="* Número"
                  id={`numero-${index}`}
                  name="numero"
                  type="number"
                  value={address.numero}
                  onChange={e => handleChange(index, e)}
                  disabled={readOnly}
                  error={hasError(index, "numero")}
                  helperText={getErrorMessage(index, "numero")}
                />
              )}
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              {isLoadingCEP[index] ? (
                <Skeleton
                  variant="rectangular"
                  height={56}
                />
              ) : (
                <TextField
                  label="* Complemento"
                  id={`complemento-${index}`}
                  name="complemento"
                  value={address.complemento}
                  onChange={e => handleChange(index, e)}
                  disabled={readOnly}
                  error={hasError(index, "complemento")}
                  helperText={getErrorMessage(index, "complemento")}
                />
              )}
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              {isLoadingCEP[index] ? (
                <Skeleton
                  variant="rectangular"
                  height={56}
                />
              ) : (
                <TextField
                  label="* Bairro"
                  id={`bairro-${index}`}
                  name="bairro"
                  value={address.bairro}
                  onChange={e => handleChange(index, e)}
                  disabled={readOnly}
                  error={hasError(index, "bairro")}
                  helperText={getErrorMessage(index, "bairro")}
                />
              )}
            </FormGroup>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              {isLoadingCEP[index] ? (
                <Skeleton
                  variant="rectangular"
                  height={56}
                />
              ) : (
                <>
                  <SelectUF
                    idSelect={`uf-${index}`}
                    nameSelect="uf"
                    value={address.uf}
                    ufs={ufs}
                    uf={selectedUf[index]}
                    label="* UF"
                    handleSelectUf={e => handleSelectUf(index, e)}
                    visualizacao={readOnly}
                    errors={String(!!hasError(index, "uf"))}
                    touched={!!hasError(index, "uf")}
                  />
                  {!!hasError(index, "municipio") && (
                    <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{getErrorMessage(index, "uf")}</Typography>
                  )}
                </>
              )}
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormGroup>
              {isLoadingCEP[index] ? (
                <Skeleton
                  variant="rectangular"
                  height={56}
                />
              ) : (
                <>
                  <SelectCidade
                    idSelect={`municipio-${index}`}
                    nameSelect="municipio"
                    value={address.municipio}
                    cidades={municipios[index] || []}
                    cidade={selectedMunicipio[index]}
                    label="* Município"
                    handleSelectCidade={e => handleSelectMunicipio(index, e)}
                    visualizacao={readOnly}
                    errors={String(!!hasError(index, "municipio"))}
                    touched={!!hasError(index, "municipio")}
                    color={hasError(index, "municipio") ? "error" : "primary"}
                  />
                  {!!hasError(index, "municipio") && (
                    <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{getErrorMessage(index, "municipio")}</Typography>
                  )}
                </>
              )}
            </FormGroup>
          </Grid>

          {showReferencia && (
            <Grid
              item
              xs={12}
            >
              <FormGroup>
                <TextField
                  label="Referência"
                  id={`referencia-${index}`}
                  name="referencia"
                  value={address.referencia}
                  onChange={e => handleChange(index, e)}
                  disabled={readOnly}
                  error={hasError(index, "referencia")}
                  helperText={getErrorMessage(index, "referencia")}
                />
              </FormGroup>
            </Grid>
          )}

          {allowMultiple && !readOnly && (
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "16px" }}
            >
              <Button
                aria-label="delete"
                variant="outlined"
                onClick={() => removeAddressForm(index)}
                color="primary"
                disabled={index === 0 || formValues.length === 1}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          )}
        </Grid>
      ))}
      {allowMultiple && !readOnly && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={addNewAddressForm}
        >
          Adicionar Novo Endereço
        </Button>
      )}
    </>
  );
};

FormAddress.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
  allowMultiple: PropTypes.bool,
  showReferencia: PropTypes.bool,
  readOnly: PropTypes.bool,
  showError: PropTypes.bool,
};

export default FormAddress;

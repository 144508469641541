import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";

import InformationBox from "../../components/boxes/InformationBox";
import WarningBox from "../../components/boxes/WarningBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import AdminParceiroFormInformacoesComplementares from "../../components/formularios/AdminParceiroFormInformacoesComplementares";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageInformacoesComplementares = () => {
  const { id } = useParams();

  const handleSubmit = async values => {
    const dados = {
      empresa_parceira: parseInt(id, 10),
      ...values,
    };
    // console.log("dados", dados);
    return parceiroService
      .postCadastrarEmpresaComplementar(dados)
      .then(mensagem => {
        console.log("Sucesso:", mensagem);
        // setConfirmacaoModal(true);
        alert("Operação realizada com sucesso!");
      })
      .catch(erro => {
        console.error(" ", erro);
        // setErroModal(true);
        alert("Erro ao realizar operação!");
      })
      .finally(() => {
        // setSubmitting(false);
        // setLoading(false);
      });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/", text: "Parceiro" }]}
        currentPage={"Informações Complementares"}
      />

      <TitlePage title={"Informações Complementares"} />

      <WarningBox
        link="/empresa/cadastro"
        action="clicar no checkbox Financeiros e Pagamentos"
      />

      {/* Aviso na página */}
      <InformationBox
        title="Atenção:"
        message={
          <>
            <p>
              Para ser avaliada a opção de oferta do tipo de serviço {'"Financeiros e de Pagamentos"'} é necessário responder a todas as
              perguntas desta tela e salvar as informações clicando no botão {'"Salvar informações"'}.
            </p>
            <p>
              Declaro, sob as penas da lei, que os dados e informações aqui prestados são verídicos e que a institução dispõe dos
              correspondentes documentos de comprovação estando-os à disposição para o caso de verificações se necessário for.
            </p>
            <p>
              Caros Parceiros de Serviços Financeiros e de Pagamentos, é necessário também cadastrar filiais em cada UF para que ocorra o
              match com o Cidadão daquela microrregião.
            </p>
          </>
        }
      />

      <AdminParceiroFormInformacoesComplementares handleSubmit={handleSubmit} />
    </Container>
  );
};

export default PageInformacoesComplementares;

import { formatCPF, formatTelefone } from "../components/formularios/utils";

export function payloadQueroSerEmpreendedor(formData) {
  return {
    perguntas: {
      // '1. Você deseja abrir seu próprio negócio?
      st_pergunta_01: formData.desejaAbrirNegocio ? "SIM" : "NAO",
      // '2. É a primeira vez que você vai abrir o seu próprio negócio?
      st_pergunta_02: formData.primeiraVezAbrirNegocio ? "SIM" : "NAO",
      // '3. Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?
      ds_pergunta_03: encodeBooleansToBits(formData),
      // '4. O seu futuro negócio está mais próximo de qual dos setores abaixo?
      ds_pergunta_04: formData.setoresRadio,
      // '5. O seu futuro negócio será em qual situação?
      ds_pergunta_05: formData.situacaoFuturaRadio,
      // 'Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
      st_pergunta_06: formData.jaParticipouCurso ? "SIM" : "NAO",
      // '7. Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?
      st_pergunta_07: formData.gostariaParticiparCurso ? "SIM" : "NAO",
      ds_motivo_pergunta_07: formData.gostariaParticiparCursoMotivo || "",
      // '8. Você gostaria de receber orientação sobre como administrar seu negócio?
      st_pergunta_08: formData.gostariaReceberOrientacao ? "SIM" : "NAO",
      ds_motivo_pergunta_08: formData.gostariaReceberOrientacaoMotivo || "",
      // '9. Você tem necessidade de um financiamento/empréstimo de microcrédito?
      st_pergunta_09: formData.necessidadeFinanciamento ? "SIM" : "NAO",
      ds_motivo_pergunta_09: formData.necessidadeFinanciamentoMotivo || "",
      // '10. Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?
      st_pergunta_10: formData.conheceEmprestimos ? "SIM" : "NAO",
      // Sobre você
      no_completo: formData.nomeCompleto,
      dt_nascimento: formData.dataNascimento,
      nu_rg: formData.rg,
      nu_cpf: formData.cpf.replace(/\D/g, ""),
      tp_genero: formData.generoRadio,
      tp_escolaridade: formData.escolaridade,
      endereco: {
        cep: formData.enderecoCompleto?.[0]?.cep?.replace(/\D/g, "") || "",
        logradouro: formData.enderecoCompleto?.[0]?.endereco || "",
        numero: formData.enderecoCompleto?.[0]?.numero || "0",
        complemento: formData.enderecoCompleto?.[0]?.complemento || "",
        bairro: formData.enderecoCompleto?.[0]?.bairro || "",
        uf: formData.enderecoCompleto?.[0]?.uf || "",
        cidade: formData.enderecoCompleto?.[0]?.municipio || "",
        telefone: formData.telefone?.replace(/\D/g, "") || "",
      },
      ds_email: formData.email,
    },
    cursos:
      formData.jaParticipouCurso === "SIM"
        ? formData.jaParticipouCursoDetalhes.map(curso => ({
            no_instituicao: curso.value1,
            no_curso: curso.value2,
          }))
        : [],
  };
}

/**
 * encodeBooleansToBits:
 * Esta função recebe um objeto contendo vários valores booleanos (true/false) e os converte em uma string compacta de bits.
 * Cada booleano é representado por um caractere: "1" para true e "0" para false.
 * O objetivo é economizar espaço ao armazenar múltiplos valores booleanos em uma única string.
 *
 * Exemplo:
 * Entrada: {
 *   checkDesempregado: true,
 *   checkSonho: false,
 *   checkQueroSerPatrao: true,
 *   checkQueroTerExperienciaEmpreender: false,
 *   checkQueroEmpreenderNovamente: true,
 *   checkProjetoFamiliar: false,
 *   checkEmpregado: true,
 * }
 * Saída: "1010101"
 */
function encodeBooleansToBits(data) {
  const booleans = [
    data.checkDesempregado,
    data.checkSonho,
    data.checkQueroSerPatrao,
    data.checkQueroTerExperienciaEmpreender,
    data.checkQueroEmpreenderNovamente,
    data.checkProjetoFamiliar,
    data.checkEmpregado,
  ];

  // Create a string with "1" for true and "0" for false
  return booleans.map(bool => (bool ? "1" : "0")).join("");
}

/**
 * decodeBitsToBooleans:
 * Esta função faz o processo inverso, pegando uma string de bits ("1" e "0") e convertendo-a de volta para um objeto com os valores booleanos originais.
 * Cada caractere da string é interpretado como um booleano: "1" para true e "0" para false.
 *
 * Exemplo:
 * Entrada: "1010101"
 * Saída: {
 *   checkDesempregado: true,
 *   checkSonho: false,
 *   checkQueroSerPatrao: true,
 *   checkQueroTerExperienciaEmpreender: false,
 *   checkQueroEmpreenderNovamente: true,
 *   checkProjetoFamiliar: false,
 *   checkEmpregado: true,
 * }
 */
function decodeBitsToBooleans(bitString) {
  const booleans = bitString.split("").map(bit => bit === "1");

  return {
    checkDesempregado: booleans[0],
    checkSonho: booleans[1],
    checkQueroSerPatrao: booleans[2],
    checkQueroTerExperienciaEmpreender: booleans[3],
    checkQueroEmpreenderNovamente: booleans[4],
    checkProjetoFamiliar: booleans[5],
    checkEmpregado: booleans[6],
  };
}

export const transformarDadosQueroSerEmpreendedor = dadosRecebidos => {
  return {
    // '1. Você deseja abrir seu próprio negócio?
    desejaAbrirNegocio: dadosRecebidos.st_pergunta_01 === "SIM",

    // '2. É a primeira vez que você vai abrir o seu próprio negócio?
    primeiraVezAbrirNegocio: dadosRecebidos.st_pergunta_02 === "SIM",

    // '3. Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?
    ...decodeBitsToBooleans(dadosRecebidos.ds_pergunta_03),

    // '4. O seu futuro negócio está mais próximo de qual dos setores abaixo?
    setoresRadio: dadosRecebidos.ds_pergunta_04,

    // '5. O seu futuro negócio será em qual situação?
    situacaoFuturaRadio: dadosRecebidos.ds_pergunta_05,

    // 'Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
    jaParticipouCurso: dadosRecebidos.st_pergunta_06 === "SIM",

    // '7. Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos para melhor gerir o seu negócio?
    gostariaParticiparCurso: dadosRecebidos.st_pergunta_07 === "SIM",
    gostariaParticiparCursoMotivo: dadosRecebidos.ds_motivo_pergunta_07,

    // '8. Você gostaria de receber orientação sobre como administrar seu negócio?
    gostariaReceberOrientacao: dadosRecebidos.st_pergunta_08 === "SIM",
    gostariaReceberOrientacaoMotivo: dadosRecebidos.ds_motivo_pergunta_08,

    // '9. Você tem necessidade de um financiamento/empréstimo de microcrédito?
    necessidadeFinanciamento: dadosRecebidos.st_pergunta_09 === "SIM",
    necessidadeFinanciamentoMotivo: dadosRecebidos.ds_motivo_pergunta_09,

    // '10. Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de pagamento do empréstimo?
    conheceEmprestimos: dadosRecebidos.st_pergunta_10 === "SIM",

    // Sobre você
    nomeCompleto: dadosRecebidos.no_completo,
    dataNascimento: dadosRecebidos.dt_nascimento,
    rg: dadosRecebidos.nu_rg,
    cpf: formatCPF(dadosRecebidos.nu_cpf),
    generoRadio: dadosRecebidos.tp_genero,
    escolaridade: dadosRecebidos.tp_escolaridade,

    enderecoCompleto: [
      {
        cep: dadosRecebidos.fk_endereco?.[0]?.nu_cep || "",
        endereco: dadosRecebidos.fk_endereco?.[0]?.ds_logradouro || "",
        numero: dadosRecebidos.fk_endereco?.[0]?.nu_logradouro || "0",
        complemento: dadosRecebidos.fk_endereco?.[0]?.ds_complemento || "",
        bairro: dadosRecebidos.fk_endereco?.[0]?.no_bairro || "",
        uf: dadosRecebidos.fk_endereco?.[0]?.uf || "",
        municipio: dadosRecebidos.fk_endereco?.[0]?.cidade || "",
      },
    ],

    telefone: formatTelefone((dadosRecebidos.fk_telefone?.[0]?.nu_ddd || "") + (dadosRecebidos.fk_telefone?.[0]?.nu_telefone || "")),
    email: dadosRecebidos.ds_email || "",

    // Cursos array, mapping over any `cursos` if present
    jaParticipouCursoDetalhes: (dadosRecebidos.cursos || []).map(curso => ({
      value1: curso.no_instituicao,
      value2: curso.no_curso,
    })),
  };
};

import React from "react";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import ParceiroListarCursos from "../../components/formularios/ParceiroListarCursos";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageListarCursos = () => {
  const {
    isPending,
    isError,
    data: initialData,
  } = useQuery({
    queryKey: ["cursosComDetalhes"],
    queryFn: parceiroService.getCursosComDetalhes,
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/cursos", text: "Cursos" },
        ]}
        currentPage="Listar Cursos Pendentes"
      />

      <TitlePage title={"Listar Cursos Pendentes"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <ParceiroListarCursos initialData={initialData} />
      )}
    </Container>
  );
};

export default PageListarCursos;

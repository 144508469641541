import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Card,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

import optionsService from "../../services/options.service";
import AprovarOuReprovarCurso from "../modals/AprovarOuReprovarCurso";
import InativarCurso from "../modals/InativarCurso";
import DefaultTable from "../table/DefaultTable";
import { tabelaCursosPendentes } from "./dadosMockados";
import { SelectCidade } from "./fields/SelectCidade";
import { SelectUF } from "./fields/SelectUF";

const tabelaColunas = [
  { field: "nome", headerName: "Curso" },
  { field: "ofertante", headerName: "Ofertante" },
  { field: "vagas", headerName: "Vagas" },
];

const initialData = tabelaCursosPendentes;

const keysHidden = ["modalidade", "municipio", "uf"];

const initialFilterState = {
  tipoVaga: "",
  funcao: "",
  situacao: "",
};

const ParceiroMobilizacaoBuscarCursos = () => {
  const navigate = useNavigate();
  const [inativarCurso, setInativarCurso] = useState(false);
  const [reprovarCurso, setReprovarCurso] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState(filter.uf);
  const [selectedMunicipio, setSelectedMunicipio] = useState(filter.municipio);

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;

    setFilter({ ...filter, [name]: value });
    setFilter({ ...filter, ["cidade"]: "" });

    setSelectedUf(value);
    setSelectedMunicipio("");
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setSelectedMunicipio(event.target.value);
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    const filtered = initialData.filter(vaga => {
      return vaga.tipoVaga.includes(filter.tipoVaga) || vaga.funcao.includes(filter.funcao) || vaga.situacao.includes(filter.situacao);
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setFilteredData(initialData);
    applyFilter();
  };

  const tabelaHiddenLinhas = initialData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar",
      href: "",
      icon: "visibility",
      onClick: () => navigate("/mobilizacao/cursos/visualizar"),
    },
    {
      title: "Interessados",
      href: "",
      icon: "description",
      onClick: () => navigate("/cursos/interessados"),
    },
  ];

  const fecharInativarCurso = () => {
    setInativarCurso(false);
  };

  const fecharReprovarCurso = () => {
    setReprovarCurso(false);
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography
            variant="h5"
            mb={2}
          >
            Cursos em oferta
          </Typography>
          <Typography variant="h6">Filtro</Typography>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            mt={1}
          >
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
            >
              <TextField
                id="nome"
                label="Nome do Curso"
                value={filter.nome}
                onChange={e => setFilter({ ...filter, nome: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">Modalidade</InputLabel>
                <Select
                  id="modalidade"
                  name="modalidade"
                  label="Modalidade"
                  value={filter.funcao}
                  onChange={e => setFilter({ ...filter, funcao: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
            >
              <FormControl fullWidth>
                <InputLabel id="select-label">Turno</InputLabel>
                <Select
                  id="turno"
                  name="turno"
                  label="Turno"
                  value={filter.turno}
                  onChange={e => setFilter({ ...filter, turno: e.target.value })}
                >
                  <MenuItem
                    value=""
                    disabled
                  >
                    Selecione
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
            >
              <SelectUF
                idSelect="ufSelect"
                nameSelect="ufSelect"
                uf={selectedUf}
                ufs={ufs}
                label="UF"
                value={filter.uf}
                handleSelectUf={event => handleSelectUf(event)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={10}
            >
              <SelectCidade
                idSelect="municipioSelect"
                nameSelect="municipioSelect"
                cidade={selectedMunicipio}
                cidades={municipios}
                label="Município"
                value={filter.municipio}
                handleSelectCidade={event => handleSelectMunicipio(event)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <TextField
                id="ofertante"
                name="ofertante"
                label="Ofertante"
                value={filter.ofertante}
                onChange={e => setFilter({ ...filter, ofertante: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
            >
              <TextField
                id="quantidade"
                name="quantidade"
                label="Quantidade de Vagas"
                value={filter.quantidade}
                onChange={e => setFilter({ ...filter, turno: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              display={"flex"}
              justifyContent={"flex-start"}
            >
              <FormGroup>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                  }}
                >
                  Em minha microrregião
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <Typography>Não</Typography>
                  <Switch
                    checked={filter.pcd === "Sim"}
                    onChange={e => setFilter({ ...filter, pcd: e.target.checked ? "Sim" : "Não" })}
                  />
                  <Typography>Sim</Typography>
                </Stack>
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                onClick={resetFilter}
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                fullWidth
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                onClick={applyFilter}
                sx={{ gap: "8px" }}
                style={{ fontFamily: "Rawline Medium" }}
                fullWidth
              >
                <SearchIcon />
                Pesquisar
              </Button>
            </Grid>
          </Grid>
        </Card>
        {/* Tabela */}
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Cursos na situação pendente de aprovação!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      {/* Modais */}
      <div>
        <InativarCurso
          showModal={inativarCurso}
          handleClose={fecharInativarCurso}
        />
        <AprovarOuReprovarCurso
          modalTitle="Reprovar curso"
          showModal={reprovarCurso}
          handleClose={fecharReprovarCurso}
        />
      </div>
    </>
  );
};

export default ParceiroMobilizacaoBuscarCursos;

import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import ParceiroListarInteressados from "../../components/formularios/ParceiroListarInteressados";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageRelacaoInteressados = () => {
  const { id } = useParams();

  const {
    isPending,
    isError,
    data: listaInteressados,
  } = useQuery({
    queryKey: ["listaInteressados"],
    queryFn: async () => {
      // const curso = await parceiroService.getCurso(id);
      // const empresa = await parceiroService.getEmpresaData(curso.cnpjOfertante);
      const data = {
        curso_id: parseInt(id, 10),
        // filtros: {
        //   carga_horaria: curso.cargaHoraria,
        //   modalidade: curso.modalidade,
        //   cnpj: empresa.cnpj,
        //   ofertante: empresa.nomeFantasia,
        // },
      };
      return parceiroService.postRelacaoInteressadosCurso(data);
    },
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/cursos", text: "Cursos" },
        ]}
        currentPage="Listar Interessados"
      />

      <TitlePage title={"Listar Interessados"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <ParceiroListarInteressados values={listaInteressados} />
      )}
    </Container>
  );
};

export default PageRelacaoInteressados;

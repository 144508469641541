import React, { useRef, useState } from "react";

import { FormGroup, Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";

import DynamicForm from "../formularios/DynamicForm";
import FileUpload from "../inputs/fileUpload";
import CustomModal from "./CustomModal";

const AprovarOuReprovarCursoModal = ({ modalTitle, showModal, handleAprovarInativar, handleClose }) => {
  const [files, setFiles] = useState([]);
  const formRef = useRef();

  const handleFilesChange = values => {
    // Dummy console log só para não reclamar do files
    console.log(files);
    setFiles(values);
  };

  const onSubmit = data => {
    handleAprovarInativar(data.motivo, files);
  };

  const fields = [
    {
      name: "motivo",
      label: "Motivo",
      renderComponent: () => (
        <>
          <Grid
            item
            xs={12}
          >
            <FormGroup>
              <TextField
                multiline
                id="motivo"
                name="motivo"
                rows={3}
                label="Motivo"
                placeholder="Motivo"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FileUpload
              onFieldsChange={handleFilesChange}
              fieldLabel={"Anexar Imagem"}
            />
          </Grid>
        </>
      ),
      size: { sm: 12, md: 12 },
    },
  ];

  const modalContent = (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <DynamicForm
        styleForm={{ width: "100%" }}
        fields={fields}
        ref={formRef}
        onSubmit={onSubmit}
      />
    </Grid>
  );

  const handleSalvar = () => {
    formRef.current.submitForm();
  };

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: "HISTÓRICO DO CURSO", onClick: handleClose, variant: "outlined" },
    { label: "VISUALIZAR CURSO", onClick: handleClose, variant: "outlined" },
    { label: "VISUALIZAR OFERTANTE", onClick: handleClose, variant: "outlined" },
    { label: "SALVAR", onClick: handleSalvar, variant: "contained", icon: "save" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="md"
    />
  );
};

AprovarOuReprovarCursoModal.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleAprovarInativar: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
};

export default AprovarOuReprovarCursoModal;

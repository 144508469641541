import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminListarVagasTrabalho from "../../components/filtros/AdminListarVagasTrabalho";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageListarVagasTrabalho = () => {
  // Criar um estado para armazenar os novos dados de vagas
  const [novosDadosVaga, setNovosDadoVaga] = useState([]);
  const [isLoadingDados, setIsLoadingDados] = useState(true);

  const results = useQueries({
    queries: [
      {
        queryKey: ["vagas"],
        queryFn: () => {
          return adminService.getVagas(1);
        }, //todo: não enviar estático
      },
      {
        queryKey: ["empresasParceiras"],
        queryFn: adminService.getEmpresasParceiras,
      },
    ],
  });

  const [vagasQuery, empresasQuery] = results;

  const vagas = vagasQuery.data;
  const empresas = empresasQuery.data;

  const isPending = vagasQuery.isLoading || empresasQuery.isLoading || isLoadingDados;
  const isError = vagasQuery.isError || empresasQuery.isError;

  useEffect(() => {
    if (vagas && empresas) {
      const ofertante = empresas.find(e => e.id === 1); //todo: não enviar estático
      const dadosAtualizados = vagas.map(empresa => ({
        ...empresa,
        nomeParceiro: ofertante ? ofertante.nomeFantasia : "", // Adicionando o campo nomeParceiro
        situacaoParceiro: ofertante ? ofertante.situacao : "", // Adicionando o campo situacaoParceiro
      }));
      setNovosDadoVaga(dadosAtualizados);
      setIsLoadingDados(false);
    }
  }, [vagas, empresas]);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "", text: "Vagas" }]}
        currentPage="Listar"
      />

      <TitlePage title={"Listar"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <AdminListarVagasTrabalho
          initialData={novosDadosVaga}
          listaEmpresas={empresas}
        />
      )}
    </Container>
  );
};

export default PageListarVagasTrabalho;

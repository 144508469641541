import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import optionsService from "../../../services/options.service.js";
import FinanceiroModal from "../../modals/FinanceiroModal.js";
import { FormFieldsGrid, FormFieldsNoGrid } from "../DynamicForm.js";
import { SelectAtuacaoParceiro } from "../fields/SelectAtuacaoParceiro.js";
import { SelectCidade } from "../fields/SelectCidade.js";
import { SelectNaturezaJuridica } from "../fields/SelectNaturezaJuridica.js";
import { SelectUF } from "../fields/SelectUF.js";
import FormMultipleInsert from "../FormMultipleInsert.js";
import { formatCEP, formatCNPJ, formatCPF, formatSite, formatTelefone, validarCNPJ, validarCPF } from "../utils.js";

const FormInscricaoParceiro = ({
  visualizacao,
  initialValues,
  onSubmit,
  hasInformacoesComplementares,
  handleInformacoesComplementares,
}) => {
  const formRef = useRef();
  const theme = useTheme();

  const [financeiroModal, setFinanceiroModal] = useState(false);
  const [atuacoes, setAtuacoes] = useState([]);
  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [cidadesRepresentante, setCidadesRepresentante] = useState([]);

  const [temDadosRepresentante, setTemDadosRepresentante] = useState(false);

  const situacaoEmpresa = initialValues.situacao;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors, isSubmitted },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  // UseEffect para obter os valores iniciais
  useEffect(() => {
    setTemDadosRepresentante(
      !!(
        initialValues.nomeRepresentante &&
        initialValues.cpfRepresentante &&
        initialValues.telefoneRepresentante &&
        initialValues.ufRepresentante &&
        initialValues.cidadeRepresentante
      ),
    );
  }, [initialValues]);

  // UseEffect para obter as UFs
  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  // UseEffect para obter as Atuações
  useEffect(() => {
    const fetchAtuacoes = async () => {
      await optionsService
        .getAreasAtuacao()
        .then(atuacoesData => {
          setAtuacoes(atuacoesData);
        })
        .catch(error => {
          console.error("Erro ao obter Atuações:", error);
        });
    };
    fetchAtuacoes();
  }, []);

  // UseEffect para marcar o checkbox de informações complementares
  useEffect(() => {
    updateCheckFinanceiro(); // Atualiza o valor do checkbox quando hasInformacoesComplementares mudar
  }, [hasInformacoesComplementares]);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    if (ufSigla === undefined || ufSigla == "") {
      //Caso nao tenha ufSigla nao faz req
      return;
    }

    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error, " - ufSigla => ", ufSigla);
    }
  };

  const handleChangeCNPJ = async event => {
    const { value } = event.target;
    setValue("cnpj", value);

    if (!validarCNPJ(value)) {
      resetCNPJFields();
      return;
    }

    try {
      // Atenção, os dados aqui vem da API do optionsService e não do nosso backend
      const dados = await optionsService.verificarCNPJ(value);
      // console.log("DADOS CNPJ -> ", dados);

      setValue("razaoSocial", dados.razao_social);
      setValue("nomeFantasia", dados.nome_fantasia ? dados.nome_fantasia : dados.razao_social);
      setValue("naturezaJuridica", dados.natureza_juridica);
      setValue("naturezaJuridicaCodigo", dados.codigo_natureza_juridica.toString());
      setValue("porte", dados.porte);

      // TODO: na nova API será recuperado porte também
      // setValue("tipo", dados.tipo);
    } catch (error) {
      console.error("Erro ao obter CNPJ", error);
      resetCNPJFields();
    }
  };

  const resetCNPJFields = () => {
    setValue("razaoSocial", "");
    setValue("nomeFantasia", "");
    setValue("naturezaJuridica", "");
    setValue("naturezaJuridicaCodigo", "");
    setValue("porte", "");
  };

  const handleChangeCEP = async event => {
    const { value } = event.target;
    setValue("cep", value);

    if (value.replace(/\D/g, "").length == 8) {
      optionsService
        .verificarCEP(value)
        .then(dados => {
          if (dados) {
            // console.log("Dados -> ", dados);
            return fetchCidadesByUf(dados.uf, setCidades).then(() => {
              setValue("uf", dados.uf);
              setValue("cidade", dados.localidade);
              setValue("endereco", dados.logradouro);
              setValue("bairro", dados.bairro);
              setValue("complemento", dados.complemento);
            });
          }
        })
        .catch(error => {
          console.error("Erro obtendo CEP", error);
        });
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;

    if (name === "uf") {
      setValue("uf", value);
      setValue("cidade", "");
      fetchCidadesByUf(value, setCidades);
    } else if (name === "ufRepresentante") {
      setValue("ufRepresentante", value);
      setValue("cidadeRepresentante", "");
      fetchCidadesByUf(value, setCidadesRepresentante);
    }
  };

  const handleFinanceiro = () => {
    if (hasInformacoesComplementares) {
      // Tenho informações complementares, então eu posso abrir o modal de financeiro
      handleInformacoesComplementares();

      // Adiciona um atraso de 2 segundos antes de chamar updateCheckFinanceiro para manter o checkbox marcado
      setTimeout(() => {
        updateCheckFinanceiro();
      }, 2000);
    } else {
      // Não tenho informações complementares, então eu posso abrir o modal de financeiro
      setFinanceiroModal(true);
    }
  };

  const handleFinanceiroClose = () => {
    setFinanceiroModal(false);
    updateCheckFinanceiro();
  };

  const handleFinanceiroContinuar = () => {
    handleFinanceiroClose();
    handleInformacoesComplementares();
  };

  const updateCheckFinanceiro = () => {
    setValue("checkFinanceiro", hasInformacoesComplementares);
  };

  const checkSiteOnline = async url => {
    try {
      const response = await optionsService.verificarSiteAtivo(url);
      return response;
    } catch (error) {
      console.error("Error checking site:", error);
      return "O site informado não está ativo";
    }
  };

  const isValidURL = url => {
    const urlPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)*$/i;
    return urlPattern.test(url);
  };

  const handleKeyDown = event => {
    // Impedir que o formularário seja enviado ao pressionar a tecla Enter
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  //Validacao de erro dos checkboxs

  // Observe todos os campos de checkbox de uma vez
  const checkboxValues = watch([
    "checkVagaEmprego",
    "checkVagaEstagio",
    "checkVagaJovem",
    "checkCursos",
    "checkFinanceiro",
    "checkMobilidadePublico",
    "checkMobilidadeParceiro",
  ]);

  // Verifique se pelo menos um checkbox está marcado
  const isAnyCheckboxChecked = checkboxValues.some(Boolean);

  const fieldsDados = [
    {
      name: "email",
      label: "E-mail",
      renderComponent: ({ value }) => (
        <FormGroup>
          <TextField
            label="E-mail"
            disabled
            id="email"
            name="email"
            value={value}
            placeholder="Insira seu E-mail aqui"
            type="text"
            InputProps={{
              endAdornment: (
                <Tooltip
                  title="E-mail do representante da empresa"
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              ),
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12 },
    },
    {
      name: "cnpj",
      label: "* CNPJ",
      validation: {
        required: "CNPJ é obrigatório",
        validate: {
          tamanhoValido: value => {
            if (!value || value.replace(/\D/g, "").length !== 14) {
              return "O CNPJ deve ter 14 dígitos";
            }
            return true;
          },
          cnpjValido: value => {
            if (!validarCNPJ(value)) {
              return "O CNPJ informado não é válido";
            }
            return true;
          },
        },
      },
      renderComponent: ({ value, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* CNPJ"
            id="cnpj"
            name="cnpj"
            value={formatCNPJ(value)}
            placeholder="Insira o CNPJ"
            type="text"
            onChange={handleChangeCNPJ}
            onBlur={onBlur}
            inputProps={{ maxLength: 18 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 4, lg: 4 },
    },
    {
      name: "razaoSocial",
      label: "Razão Social",
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="Razão Social"
            id="razaoSocial"
            name="razaoSocial"
            value={value}
            placeholder="Insira a Razão Social"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 4, lg: 4 },
    },
    {
      name: "nomeFantasia",
      label: "Nome Fantasia",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="Nome Fantasia"
            id="nomeFantasia"
            name="nomeFantasia"
            value={value}
            placeholder="Insira o Nome Fantasia"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 4, lg: 4 },
    },
    {
      name: "cep",
      label: "* CEP",
      validation: {
        required: "CEP é obrigatório",
        validate: { tamanhoValido: value => value.replace(/\D/g, "").length === 8 || "O CEP deve ter 8 dígitos" },
      },
      renderComponent: ({ value, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* CEP"
            id="cep"
            name="cep"
            value={formatCEP(value)}
            placeholder="Insira o CEP"
            type="text"
            onChange={handleChangeCEP}
            onBlur={onBlur}
            inputProps={{ maxLength: 10 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "endereco",
      label: "* Endereço",
      validation: { required: "Endereço é obrigatório" },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Endereço"
            id="endereco"
            name="endereco"
            value={value}
            placeholder="Insira o Endereço"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "numero",
      label: "Número",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="Número"
            id="numero"
            name="numero"
            value={value}
            placeholder="Insira o Número"
            type="number"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "complemento",
      label: "Complemento",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="Complemento"
            id="complemento"
            name="complemento"
            value={value || ""}
            placeholder="Insira o Complemento"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "bairro",
      label: "* Bairro",
      validation: { required: "Bairro é obrigatório" },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Bairro"
            id="bairro"
            name="bairro"
            value={value}
            placeholder="Insira o Bairro"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "uf",
      label: "* UF",
      validation: { required: "UF é obrigatório" },
      renderComponent: ({ value, onBlur, error }) => {
        if (cidades.length === 0) fetchCidadesByUf(value, setCidades);
        return (
          <FormGroup>
            <SelectUF
              idSelect={"uf"}
              nameSelect={"uf"}
              ufs={ufs}
              uf={value}
              handleSelectUf={handleSelectUf}
              onBlur={onBlur}
              visualizacao={visualizacao}
              errors={error?.message}
              touched={true}
            />
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormGroup>
        );
      },
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "cidade",
      label: "* Cidade",
      validation: { required: "Cidade é obrigatória" },
      renderComponent: ({ value, onChange, onBlur, error }) => {
        return (
          <FormGroup>
            <SelectCidade
              idSelect={"cidade"}
              nameSelect={"cidade"}
              cidades={cidades.length > 0 ? cidades : []}
              cidade={cidades.length > 0 ? value : ""}
              handleSelectCidade={onChange}
              onBlur={onBlur}
              visualizacao={visualizacao}
              errors={error?.message}
              touched={true}
            />
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormGroup>
        );
      },
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "telefone",
      label: "* Telefone",
      validation: {
        required: "Telefone é obrigatório",
        validate: {
          tamanhoValido: value => {
            const tamanho = value.replace(/\D/g, "").length;
            return tamanho === 10 || tamanho === 11 || "O telefone deve ter 10 ou 11 dígitos";
          },
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Telefone"
            id="telefone"
            name="telefone"
            value={formatTelefone(value)}
            placeholder="Insira o Telefone"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "site",
      label: "Site",
      validation: {
        pattern: {
          value: /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[\w-]*)*\/?$/,
          message: "Insira um formato válido (ex - www.meusite.com)",
        },
        validate: async value => {
          if (!value) {
            return true; // No value, no validation needed
          }

          const formattedUrl = formatSite(value);
          const siteCheckResult = await checkSiteOnline(formattedUrl);

          // Se o site estiver online, retorna true ou undefined (validação OK)
          if (siteCheckResult === true) {
            return true; // Validação passou, não exibe erros
          }

          // Se o site não estiver online, retorna a mensagem de erro
          return siteCheckResult; // Exibe a mensagem de erro
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="Site"
            id="site"
            name="site"
            value={value || ""}
            onChange={onChange}
            placeholder="Insira o Site"
            type="text"
            onBlur={onBlur}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "redesSociais",
      label: "Redes Sociais",
      validation: {
        validate: value => {
          // console.log("Redes Sociais", value);
          if (!value || value.length === 0 || value.every(url => url === "")) {
            return true; // Validation passes when the field is empty or contains only empty strings
          }
          const invalidUrls = value.filter(url => url !== "" && !isValidURL(url));
          if (invalidUrls.length > 0) {
            // console.log("Invalid URLs", invalidUrls);
            return "A rede social necessita ter um formato de site válido (ex: https://www.minharede.com)";
          }
          return true;
        },
      },
      renderComponent: ({ value, onChange, error }) => {
        // console.log("Erro antes de renderComponente", error);
        return (
          <FormGroup>
            <FormMultipleInsert
              initialValues={value}
              placeHolder={"Insira a Rede Social"}
              label={"Redes Sociais"}
              disabled={visualizacao}
              onFieldsChange={onChange}
              errors={[error?.message]}
              touched={[true]}
            />
          </FormGroup>
        );
      },
      size: { xs: 12, sm: 12 },
    },
  ];

  const fieldsRepresentante = [
    {
      name: "nomeRepresentante",
      label: "Nome",
      validation: {
        required: "Nome do Representante é obrigatório",
      },
      renderComponent: ({ value }) => (
        <FormGroup>
          <TextField
            label="* Nome"
            id="nomeRepresentante"
            name="nomeRepresentante"
            value={value}
            placeholder="Insira o Nome"
            type="text"
            disabled
          />
        </FormGroup>
      ),

      size: { xs: 12, sm: 8, md: 9, lg: 9 },
    },
    {
      name: "cpfRepresentante",
      label: "CPF",
      validation: {
        required: "CPF do Representante é obrigatório",
        validate: {
          tamanhoValido: value => {
            if (!value || value.replace(/\D/g, "").length !== 11) {
              return "O CPF deve ter 11 dígitos";
            }
            return true;
          },
          cpfValido: value => {
            if (!validarCPF(value)) {
              return "O CPF informado não é válido";
            }
            return true;
          },
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* CPF"
            id="cpfRepresentante"
            name="cpfRepresentante"
            value={formatCPF(value)}
            placeholder="Insira o CPF"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{ maxLength: 14 }}
            disabled={visualizacao || temDadosRepresentante}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4, md: 3, lg: 3 },
    },
    {
      name: "telefoneRepresentante",
      label: "Telefone",
      validation: {
        required: "Telefone do Representante é obrigatório",
        validate: {
          tamanhoValido: value => {
            const tamanho = value.replace(/\D/g, "").length;
            return tamanho === 10 || tamanho === 11 || "O telefone deve ter 10 ou 11 dígitos";
          },
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Telefone"
            id="telefoneRepresentante"
            name="telefoneRepresentante"
            value={formatTelefone(value)}
            placeholder="Insira o Telefone"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao || temDadosRepresentante}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "ufRepresentante",
      label: "ufRepresentante",
      validation: { required: "UF do Representante é obrigatório" },
      renderComponent: ({ value, onBlur, error }) => {
        if (cidadesRepresentante.length === 0) fetchCidadesByUf(value, setCidadesRepresentante);
        return (
          <FormGroup>
            <SelectUF
              idSelect={"ufRepresentante"}
              nameSelect={"ufRepresentante"}
              ufs={ufs}
              uf={value}
              handleSelectUf={handleSelectUf}
              onBlur={onBlur}
              visualizacao={visualizacao || temDadosRepresentante}
              errors={error?.message}
              touched={true}
            />
            {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
          </FormGroup>
        );
      },
      size: { xs: 12, sm: 4, md: 4, lg: 4 },
    },
    {
      name: "cidadeRepresentante",
      label: "cidade",
      validation: { required: "Cidade do Representante é obrigatório" },
      renderComponent: ({ value, onChange, onBlur, error }) => {
        if (cidadesRepresentante.length >= 1)
          return (
            <FormGroup>
              <SelectCidade
                idSelect={"cidadeRepresentante"}
                nameSelect={"cidadeRepresentante"}
                cidades={cidadesRepresentante}
                cidade={value || ""}
                handleSelectCidade={onChange}
                onBlur={onBlur}
                visualizacao={visualizacao || temDadosRepresentante}
                errors={error?.message}
                touched={true}
              />
              {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
            </FormGroup>
          );
      },
      size: { xs: 12, sm: 4, md: 4, lg: 4 },
    },
  ];

  const fieldsPontoFocal = [
    {
      name: "nomePontoFocal",
      label: "Nome",
      validation: { required: "Nome do ponto focal é obrigatório" },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Nome"
            id="nomePontoFocal"
            name="nomePontoFocal"
            value={value}
            placeholder="Insira o Nome"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 4, lg: 4 },
    },
    {
      name: "telefonePontoFocal",
      label: "Telefone",
      validation: {
        required: "Telefone do ponto focal é obrigatório",
        validate: {
          tamanhoValido: value => {
            const tamanho = value.replace(/\D/g, "").length;
            return tamanho === 10 || tamanho === 11 || "O telefone deve ter 10 ou 11 dígitos";
          },
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Telefone"
            id="telefonePontoFocal"
            name="telefonePontoFocal"
            value={formatTelefone(value)}
            placeholder="Insira o telefone"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{ maxLength: 15 }}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 4, lg: 4 },
    },
    {
      name: "emailPontoFocal",
      label: "E-mail",
      validation: {
        required: "E-mail do ponto focal é obrigatório",
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "Insira um e-mail válido",
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* E-mail"
            id="emailPontoFocal"
            name="emailPontoFocal"
            value={value}
            placeholder="Insira o E-mail"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 4, lg: 4 },
    },
  ];

  const fieldsAreaAtuacao = [
    {
      name: "areaAtuacao",
      label: "areaAtuacao",
      validation: { required: "Área de Atuação é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <SelectAtuacaoParceiro
            idSelect={"areaAtuacao"}
            nameSelect={"areaAtuacao"}
            handleSelect={onChange}
            list={atuacoes}
            item={value}
            label={"* Área de Atuação"}
            placeholder={"Selecione a Área de Atuação"}
            visualizacao={visualizacao}
            errors={error?.message}
            touched={true}
          />
          {error && (
            <Typography
              sx={{
                color: "#FF5630",
                fontSize: "12px",
                ml: "12px",
              }}
            >
              {error.message}
            </Typography>
          )}
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "naturezaJuridicaCodigo",
      label: "Natureza Jurídica",
      validation: { required: "Natureza Jurídica é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormGroup>
          <SelectNaturezaJuridica
            idSelect={"naturezaJuridicaCodigo"}
            nameSelect={"naturezaJuridicaCodigo"}
            handleSelect={onChange}
            // Esse select recebe o código para buscar da lista pré-populada
            item={value}
            label="* Natureza Jurídica"
            placeholder={"Insira um CNPJ"}
            visualizacao={visualizacao}
            errors={error?.message}
            touched={true}
          />
          {error && (
            <Typography
              sx={{
                color: "#FF5630",
                fontSize: "12px",
                ml: "12px",
              }}
            >
              {error.message}
            </Typography>
          )}
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "naturezaJuridica",
      label: "Natureza Jurídica",
      hidden: true,
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            id="naturezaJuridica"
            name="naturezaJuridica"
            type="hidden"
            // Aqui vamos guardar o texto da natureza juridica pq temos que mandar para o backend
            value={value}
            onChange={onChange}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "tipo",
      label: "* Tipo",
      validation: {
        required: "Tipo é obrigatório",
        maxLength: {
          value: 100,
          message: "O tamanho máximo é 100 caracteres",
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Tipo"
            id="tipo"
            name="tipo"
            value={value}
            placeholder="Insira um tipo"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
    {
      name: "porte",
      label: "Porte",
      validation: {
        required: "Porte é obrigatório",
        maxLength: {
          value: 100,
          message: "O tamanho máximo é 100 caracteres",
        },
      },
      renderComponent: ({ value, onChange, onBlur, error }) => (
        <FormGroup>
          <TextField
            label="* Porte"
            id="porte"
            name="porte"
            value={value}
            placeholder="Insira um porte"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            disabled={visualizacao}
            error={!!error}
            helperText={error?.message}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 6, lg: 6 },
    },
  ];

  const fieldsCheckbox = [
    {
      name: "checkVagaEmprego",
      label: "Vagas de Emprego",
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkVagaEmprego"
                name="checkVagaEmprego"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography>
                Vagas de Emprego &nbsp;
                <Tooltip
                  title="Para oferta de vaga(s) formal(is) de emprego com carteira assinada."
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            }
            sx={{
              border: "solid 1px",
              borderRadius: "4px",
              borderColor: "#CCCCCC",
              margin: "4px 24px 4px 4px",
              pr: "10px",
            }}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkVagaEstagio",
      label: "Vagas de Estágio",
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkVagaEstagio"
                name="checkVagaEstagio"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography>
                Vagas de Estágio &nbsp;
                <Tooltip
                  title="Para oferta de vaga(s) de estágio (nível médio e superior)."
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            }
            sx={{
              border: "solid 1px",
              borderRadius: "4px",
              borderColor: "#CCCCCC",
              margin: "4px 24px 4px 4px",
              pr: "10px",
            }}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkVagaJovem",
      label: "Vagas de Jovem Aprendiz",
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkVagaJovem"
                name="checkVagaJovem"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography>
                Vagas de Jovem Aprendiz &nbsp;
                <Tooltip
                  title="Para oferta de vaga(s) de trabalho para Jovem Aprendiz (14 a 24 anos)."
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            }
            sx={{
              border: "solid 1px",
              borderRadius: "4px",
              borderColor: "#CCCCCC",
              margin: "4px 24px 4px 4px",
              pr: "10px",
            }}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkCursos",
      label: "Cursos",
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkCursos"
                name="checkCursos"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography>
                Cursos &nbsp;
                <Tooltip
                  title="Para oferta de cursos (presenciais ou a distância) para o público do Programa Acredita no Primeiro Passo/Cadastro Único."
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            }
            sx={{
              border: "solid 1px",
              borderRadius: "4px",
              borderColor: "#CCCCCC",
              margin: "4px 24px 4px 4px",
              pr: "10px",
            }}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkFinanceiro",
      label: "Financeiros e Pagamentos",
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkFinanceiro"
                name="checkFinanceiro"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled, cursor: situacaoEmpresa === "Pendente" ? "not-allowed" : "pointer" }}
                disabled={visualizacao || situacaoEmpresa === "Pendente"}
              />
            }
            onClick={() => {
              if (situacaoEmpresa !== "Pendente") {
                handleFinanceiro();
              }
            }}
            label={
              <Typography
                sx={{
                  cursor: situacaoEmpresa === "Pendente" ? "not-allowed" : "pointer",
                }}
              >
                Financeiros e Pagamentos &nbsp;
                <Tooltip
                  title="Para instituições que operam com oferta de microcrédito produtivo orientado para empreendedores formais ou informais."
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            }
            sx={{
              border: "solid 1px",
              borderRadius: "4px",
              borderColor: "#CCCCCC",
              margin: "4px 24px 4px 4px",
              pr: "10px",
              backgroundColor: situacaoEmpresa === "Pendente" ? "#e0e0e0" : "transparent",
              "&:hover": {
                backgroundColor: situacaoEmpresa === "Pendente" ? "#e0e0e0" : theme.palette.action.hover,
              },
              cursor: situacaoEmpresa === "Pendente" ? "not-allowed" : "pointer",
              color: situacaoEmpresa === "Pendente" ? theme.palette.text.disabled : theme.palette.text.primary,
            }}
          />
          {situacaoEmpresa === "Pendente" && (
            <Typography
              variant="caption"
              color="error"
              sx={{ mt: 1, display: "flex", alignItems: "center", ml: "4px", cursor: "default" }}
            >
              Atenção: parceiro pendente
              <Tooltip
                title="É necessário aguardar a aprovação do administrador antes de editar informações financeiras."
                placement="right"
              >
                <HelpIcon
                  color="error"
                  sx={{ width: "15px", height: "15px", ml: 0.5, cursor: "pointer" }}
                />
              </Tooltip>
            </Typography>
          )}
          {situacaoEmpresa !== "Pendente" && hasInformacoesComplementares && (
            <Typography
              variant="subtitle2"
              sx={{ mt: 1, ml: "4px" }}
            >
              <Link
                component="button"
                onClick={handleInformacoesComplementares}
                color="primary.main"
                underline="always"
              >
                Editar informações complementares
              </Link>
            </Typography>
          )}
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkMobilidadePublico",
      label: "Mobilização de Público",
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkMobilidadePublico"
                name="checkMobilidadePublico"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography>
                Mobilização de Público &nbsp;
                <Tooltip
                  title="Para instituições com capacidade para mobilizar público e oferecer os serviços do Programa Acredita no Primeiro Passo no seu município."
                  placement="right"
                >
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            }
            sx={{
              border: "solid 1px",
              borderRadius: "4px",
              borderColor: "#CCCCCC",
              margin: "4px 24px 4px 4px",
              pr: "10px",
            }}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "checkMobilidadeParceiro",
      label: "Mobilização de Parceiro",
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="checkMobilidadeParceiro"
                name="checkMobilidadeParceiro"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography>
                Mobilização de Parceiro &nbsp;
                <Tooltip title="Para associações, confederações, cooperativas, grupos, e entidades com potencial para articular a adesão de novos integrantes da Rede.">
                  <InfoIcon
                    color="primary"
                    sx={{ width: "15px", height: "15px" }}
                  />
                </Tooltip>
              </Typography>
            }
            sx={{
              border: "solid 1px",
              borderRadius: "4px",
              borderColor: "#CCCCCC",
              margin: "4px 24px 4px 4px",
              pr: "10px",
            }}
          />
        </FormControl>
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsNormas = [
    {
      name: "toggleCienteNormas",
      label:
        "Declaro, para os devidos fins, estar ciente e conforme com todos os termos, cláusulas, condições e normas da LEI Nº 14.995, DE 10 DE OUTUBRO DE 2024, e manifesto o interesse em me credenciar como INTEGRANTE DA REDE DE PARCEIROS DO DESENVOLVIMENTO SOCIAL.",
      validation: {
        required: "Você precisa concordar com as normas",
      },
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="toggleCienteNormas"
                name="toggleCienteNormas"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography variant="body1">
                Declaro, para os devidos fins, estar ciente e conforme com todos os termos, cláusulas, condições e normas da{" "}
                <Link href="#">LEI Nº 14.995, DE 10 DE OUTUBRO DE 2024</Link>, e manifesto o interesse em me credenciar como INTEGRANTE DA
                REDE DE PARCEIROS DO DESENVOLVIMENTO SOCIAL.
              </Typography>
            }
          />
          {error && <div style={{ color: "#FF5630" }}>{error.message}</div>}
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "toggleCienteGratuito",
      label:
        "Declaro, para os devidos fins, que estou ciente que todos os serviços ofertados por esta Instituição ao público inscrito no Programa Acredita no Primeiro Passo serão gratuitos.",
      validation: {
        required: "Você precisa informar que está ciente da condição",
      },
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                id="toggleCienteGratuito"
                name="toggleCienteGratuito"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                disabled={visualizacao}
              />
            }
            label={
              <Typography variant="body1">
                Declaro, para os devidos fins, que estou ciente que todos os serviços ofertados por esta Instituição ao público inscrito no
                Programa Acredita no Primeiro Passo serão gratuitos.
              </Typography>
            }
            sx={{ mt: "24px" }}
          />
          {error && <div style={{ color: "#FF5630" }}>{error.message}</div>}
        </FormControl>
      ),
      size: { xs: 12 },
    },
  ];

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={handleKeyDown}
      disabled={visualizacao}
    >
      <Stack spacing={2}>
        {/* Card de dados pessoais */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <Typography
            variant="h5"
            sx={{ marginBottom: "8px", fontFamily: "Rawline Bold", textTransform: "uppercase" }}
          >
            Dados pessoais
          </Typography>

          <FormFieldsGrid
            fields={fieldsDados}
            control={control}
            errors={errors}
          />
        </Card>
        {/* Card de representante */}
        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <Typography
            variant="h5"
            sx={{
              marginBottom: "8px",
              fontFamily: "Rawline Bold",
              textTransform: "uppercase",
            }}
          >
            Representante{" "}
            <Tooltip
              title="Responsável pelo cadastramento da instituição na rede de parceiros no portal Acredita"
              placement="right"
            >
              <InfoIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Tooltip>
          </Typography>

          <FormFieldsGrid
            fields={fieldsRepresentante}
            control={control}
            errors={errors}
          />
        </Card>

        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <Typography
            variant="h5"
            sx={{
              marginBottom: "8px",
              fontFamily: "Rawline Bold",
              textTransform: "uppercase",
            }}
          >
            Ponto focal{" "}
            <Tooltip
              title="Responsável operacional pelos serviços ofertados em parceria com o Programa Acredita no Primeiro Passo"
              placement="right"
            >
              <InfoIcon
                color="primary"
                sx={{ width: "15px", height: "15px" }}
              />
            </Tooltip>
          </Typography>

          <FormFieldsGrid
            fields={fieldsPontoFocal}
            control={control}
            errors={errors}
          />
        </Card>

        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <Typography
            variant="h5"
            sx={{ marginBottom: "8px", fontFamily: "Rawline Bold", textTransform: "uppercase" }}
          >
            Área de atuação{" "}
          </Typography>

          <FormFieldsGrid
            fields={fieldsAreaAtuacao}
            control={control}
            errors={errors}
          />
        </Card>

        <Card
          color="#ffffff"
          sx={{ borderRadius: "8px", padding: "16px" }}
          style={{ transition: "1s" }}
        >
          <Typography
            variant="h6"
            sx={{ marginBottom: "8px", fontFamily: "Rawline Bold", textTransform: "uppercase" }}
          >
            * Quais tipos de serviço essa instituição irá ofertar?
          </Typography>

          <FormFieldsNoGrid
            fields={fieldsCheckbox}
            control={control}
            errors={errors}
          />
          {isSubmitted && !isAnyCheckboxChecked && (
            <Typography style={{ color: "#FF5630", fontSize: "0.75rem" }}>Necessário escolher no mínimo um tipo de serviço</Typography>
          )}
        </Card>

        {!visualizacao && (
          <FormFieldsNoGrid
            fields={fieldsNormas}
            control={control}
            errors={errors}
          />
        )}

        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => window.history.back()}
            >
              <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              // onClick={() => onSubmit(getValues())}
              fullWidth
              sx={{ gap: "8px" }}
            >
              <SaveIcon fontSize="small" />
              <Typography variant={"BUTTON TEXT"}>SALVAR INFORMAÇÕES</Typography>
            </LoadingButton>
          </Grid>
        </Grid>

        <FinanceiroModal
          showModal={financeiroModal}
          handleContinuar={handleFinanceiroContinuar}
          handleClose={handleFinanceiroClose}
        />
      </Stack>
    </form>
  );
};

FormInscricaoParceiro.propTypes = {
  visualizacao: PropTypes.bool,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  hasInformacoesComplementares: PropTypes.bool,
  handleInformacoesComplementares: PropTypes.func,
};

export default FormInscricaoParceiro;

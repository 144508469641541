import React, { useEffect, useRef, useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import cidadaoService from "../../services/cidadao.service";
import { escolaridadeBanco, st_curso } from "../../services/constants.js";
import FormCursosTecnicoComplementar from "./cidadaoEditarCurriculo/FormCursosTecnicoComplementar";
import FormDadosPessoais from "./cidadaoEditarCurriculo/FormDadosPessoais";
import FormEscolaridade from "./cidadaoEditarCurriculo/FormEscolaridade";
import FormExperiencias from "./cidadaoEditarCurriculo/FormExperiencias";
import FormInteresse from "./cidadaoEditarCurriculo/FormInteresse";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CidadaoTabs() {
  const [value, setValue] = useState(0);
  const formInteresseRef = useRef(null);
  const formCursosTecnicoComplementarRef = useRef(null);
  const formDadosPessoaisRef = useRef(null);
  const formEscolaridadeRef = useRef(null);
  const formExperienciasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    dadosPessoais: {},
    escolaridade: {},
    cursos: {},
    experiencias: {},
    interesse: {},
  });
  // ... existing refs ...

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openModal, setOpenModal] = useState(false);
  const [savedData, setSavedData] = useState(null);

  const [cursos, setCursos] = useState([]);
  const [instituicoes, setInstituicoes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cursosResponse, instituicoesResponse] = await Promise.all([
          cidadaoService.getCursoImportado(),
          cidadaoService.getInstituicaoEnsino(),
        ]);
        setCursos(cursosResponse);
        setInstituicoes(instituicoesResponse);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchData();
  }, []);

  const handleFormDataChange = (section, data) => {
    setFormData(prevData => ({
      ...prevData,
      [section]: data,
    }));
  };

  const handleChange = async (event, newValue) => {
    // Salva os dados do formulário atual antes de mudar de aba
    let currentFormData;

    switch (value) {
      case 0:
        currentFormData = await formDadosPessoaisRef.current?.getFormValues();
        if (currentFormData) handleFormDataChange("dadosPessoais", currentFormData);
        break;
      case 1:
        currentFormData = await formEscolaridadeRef.current?.getFormValues();
        if (currentFormData) handleFormDataChange("escolaridade", currentFormData);
        break;
      case 2:
        currentFormData = await formCursosTecnicoComplementarRef.current?.getFormValues();
        if (currentFormData) handleFormDataChange("cursos", currentFormData);
        break;
      case 3:
        currentFormData = await formExperienciasRef.current?.getFormValues();
        if (currentFormData) handleFormDataChange("experiencias", currentFormData);
        break;
      case 4:
        currentFormData = await formInteresseRef.current?.getFormValues();
        if (currentFormData) handleFormDataChange("interesse", currentFormData);
        break;
      default:
        break;
    }

    // Atualiza a aba atual
    setValue(newValue);
  };

  const handleNext = async () => {
    setValue(prevValue => Math.min(prevValue + 1, 4));
  };

  const handlePrevious = () => {
    if (value > 0) {
      setValue(prevValue => prevValue - 1);
    }
    console.log("VALORES DO FORM VALUES -> ", formData);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);

      // Coleta dados de todos os formulários
      const dadosPessoaisData = await formDadosPessoaisRef.current?.getFormValues();
      const escolaridadeData = await formEscolaridadeRef.current?.getFormValues();
      const cursosData = await formCursosTecnicoComplementarRef.current?.getFormValues();
      const experienciasData = await formExperienciasRef.current?.getFormValues();
      const interesseData = await formInteresseRef.current?.getFormValues();

      // Atualiza o formData com todos os dados coletados
      const dadosCompletos = {
        dadosPessoais: dadosPessoaisData,
        escolaridade: escolaridadeData,
        cursos: cursosData,
        experiencias: experienciasData,
        interesse: interesseData,
      };

      console.log("FORM COMPLETO -> ", dadosCompletos);

      if (value === 1 && !escolaridadeData?.errors) {
        try {
          const escolaridades = escolaridadeData.values.escolaridades;
          const savedItems = [];

          for (const escolaridade of escolaridades) {
            const response = await cidadaoService.postCurriculoEscolaridade(escolaridade);
            savedItems.push({
              nivelEscolar: escolaridade.tipo_escolaridade,
              situacao: escolaridade.situacao_curso,
              curso: escolaridade.curso,
              instituicao: escolaridade.instituicao,
              anoConclusao: escolaridade.ano_conclusao,
              id: response.id,
            });
          }

          setSavedData({
            items: savedItems,
          });
          setOpenModal(true);
          setSnackbarMessage("Dados de escolaridade salvos com sucesso!");
          setSnackbarSeverity("success");
        } catch (error) {
          console.error("Erro ao salvar dados:", error);
          setSnackbarMessage("Erro ao salvar os dados. Tente novamente.");
          setSnackbarSeverity("error");
          setOpenModal(true);
        }
      }
    } catch (error) {
      console.error("Erro ao salvar os dados:", error);
      setSnackbarMessage("Erro ao salvar os dados. Tente novamente.");
      setSnackbarSeverity("error");
      setOpenModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
          }}
          style={{ transition: "1s" }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
            >
              <Tab
                label="1. Dados pessoais"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="2. Escolaridade"
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="3. Cursos"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="Experiência"
                {...a11yProps(3)}
                sx={{
                  color: value === 3 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
              <Tab
                label="Interesse"
                {...a11yProps(4)}
                sx={{
                  color: value === 4 ? "primary.main" : "text.primary",
                  fontFamily: "Rawline Light",
                }}
              />
            </Tabs>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
          >
            <FormDadosPessoais
              ref={formDadosPessoaisRef}
              defaultValues={formData.dadosPessoais.values}
              onSubmit={data => console.log("FormDadosPessoais data submitted:", data)}
            />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
          >
            <FormEscolaridade
              ref={formEscolaridadeRef}
              defaultValues={formData.escolaridade.values}
              onSubmit={data => console.log("FormEscolaridade data submitted:", data)}
            />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
          >
            <FormCursosTecnicoComplementar
              ref={formCursosTecnicoComplementarRef}
              defaultValues={formData.cursos.values}
              onSubmit={data => console.log("FormCursosTecnicoComplementar data submitted:", data)}
            />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={3}
          >
            <FormExperiencias
              ref={formExperienciasRef}
              defaultValues={formData.experiencias.values}
              onSubmit={data => console.log("FormExperiencias data submitted:", data)}
            />
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={4}
          >
            <FormInteresse
              ref={formInteresseRef}
              defaultValues={formData.interesse.values}
              onSubmit={data => console.log("FormInteresse data submitted:", data)}
            />
          </CustomTabPanel>
        </Card>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: "10px 10px" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handlePrevious}
          disabled={value === 0}
        >
          ANTERIOR
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleNext}
          disabled={value === 4}
        >
          PRÓXIMO
        </Button>
        <Button
          variant="contained"
          startIcon={isLoading ? null : <SaveIcon />}
          onClick={handleSave}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress
                size={20}
                color="inherit"
                sx={{ mr: 1 }}
              />
              Salvando...
            </>
          ) : (
            "Salvar"
          )}
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{snackbarSeverity === "success" ? "Escolaridade(s) Salva(s) com Sucesso!" : "Erro ao Salvar"}</DialogTitle>
        <DialogContent>
          {savedData && savedData.items && (
            <>
              {savedData.items.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ mt: index === 0 ? 0 : 4 }}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        variant="h6"
                        sx={{ mb: 2 }}
                      >
                        Escolaridade {index + 1}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <TextField
                        fullWidth
                        label="Escolaridade / Grau de instrução"
                        value={escolaridadeBanco[item.nivelEscolar] || ""}
                        disabled
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <TextField
                        fullWidth
                        label="Situação do curso"
                        value={st_curso[item.situacao] || ""}
                        disabled
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <TextField
                        fullWidth
                        label="Curso"
                        value={cursos.find(c => c.id_curso === item.curso)?.curso || ""}
                        disabled
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <TextField
                        fullWidth
                        label="Ano de Conclusão"
                        value={item.anoConclusao}
                        disabled
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Instituição de ensino"
                        value={instituicoes.find(i => i.id_instituicao === item.instituicao)?.instituicao || ""}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  {index < savedData.items.length - 1 && (
                    <Grid
                      item
                      xs={12}
                      sx={{ mt: 3, mb: 3 }}
                    >
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import VagaTrabalho from "../../components/formularios/VagaTrabalho";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroVagaUtils";

const PageEditarVagaTrabalho = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  // TODO: remover esse 1 default
  const idEmpresaParceira = searchParams.get("id_empresa_parceira") || "1";

  // Garantir que ambas as consultas sejam feitas simultaneamente e os arrays não sejam undefined
  const results = useQueries({
    queries: [
      {
        queryKey: ["empresasParceiras"],
        queryFn: parceiroService.getEmpresasParceiras,
        enabled: !!id,
      },
      {
        queryKey: ["ocupacoes"],
        queryFn: parceiroService.getOcupacoesCB2002,
        enabled: !!id,
      },
      {
        queryKey: ["vagaTrabalho", id],
        queryFn: () => parceiroService.getVaga(id, idEmpresaParceira),
        enabled: !!id,
      },
    ],
  });

  // Desestruturação dos resultados das queries
  const [empresasQuery, ocupacoesQuery, vagaQuery] = results;

  // Dados carregados com sucesso
  const empresas = empresasQuery.data;
  // Atenção: está com uma limitação de 10 valores apenas, pois são mais de 2700 registros
  const ocupacoes = ocupacoesQuery.data;
  // Dados do vaga
  const vaga = vagaQuery.data;

  // Variaveis de estado
  const isError = empresasQuery.isError || ocupacoesQuery.isError || vagaQuery.isError;
  const isPending = empresasQuery.isPending || ocupacoesQuery.isPending || vagaQuery.isPending;

  const handleSubmit = values => {
    const vagaPayload = transformaParaBackend({ id_empresa_parceira: idEmpresaParceira, ...values });

    return parceiroService
      .atualizaVaga(id, vagaPayload)
      .then(resposta => {
        console.log("Sucesso ao editar vaga: ", resposta.data);
        navigate(`/vagas/listar?id_empresa_parceira=${idEmpresaParceira}`);
      })
      .catch(erro => {
        console.error("Erro ao cadastrar vaga", erro);
      });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          {
            href: "/",
            text: "Página Inicial",
          },
          {
            href: "/parceiro",
            text: "Parceiro",
          },
        ]}
        currentPage={"Editar Vaga de Trabalho"}
      />

      <TitlePage title={"Dados da vaga"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <VagaTrabalho
          initialValues={vaga}
          onSubmit={handleSubmit}
          listaEmpresas={empresas}
          listaAreaAtuacao={ocupacoes}
        />
      )}
    </Container>
  );
};

export default PageEditarVagaTrabalho;

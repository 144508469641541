import React, { useRef } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReplyIcon from "@mui/icons-material/Reply";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Button, Card, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import DynamicForm from "./DynamicForm";

const FormCidadaoVisualizarVaga = ({ values, readOnly = false }) => {
  const formRef = useRef();

  const fields = [
    {
      name: "ofertante",
      label: "Ofertante",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Ofertante"
            id="ofertante"
            name="ofertante"
            value={values}
            placeholder="Empresa responsável pela vaga"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "salario",
      label: "Salário",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Salário"
            id="salario"
            name="salario"
            value={values}
            placeholder="Remuneração da vaga"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "vagas",
      label: "Vagas",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Vagas"
            id="vagas"
            name="vagas"
            value={values}
            placeholder="Quantidade de vagas disponíveis"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "funcao",
      label: "Função",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Função"
            id="funcao"
            name="funcao"
            value={values}
            placeholder=""
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "areaAtuacao",
      label: "Área de atuação",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Área de atuação"
            id="areaAtuacao"
            name="areaAtuacao"
            value={values}
            placeholder=""
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "areaEstudante",
      label: "Área do estudante",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Área do estudante"
            id="areaEstudante"
            name="areaEstudante"
            value={values}
            placeholder=""
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "local",
      label: "Local",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Local"
            id="local"
            name="local"
            value={values}
            placeholder=""
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "descricao",
      label: "Descrição",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Descrição"
            id="descricao"
            name="descricao"
            value={values}
            placeholder=""
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "necessario",
      label: "É necessário",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="É necessário"
            id="necessario"
            name="necessario"
            value={values}
            placeholder=""
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
            multiline
            maxRows={4}
            minRows={4}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "beneficios",
      label: "Benefícios",
      renderComponent: ({ values, onChange }) => (
        <FormGroup>
          <TextField
            label="Benefícios"
            id="beneficios"
            name="beneficios"
            value={values}
            placeholder=""
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
            multiline
            maxRows={4}
            minRows={4}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 12 },
    },
  ];

  return (
    <Stack>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
        }}
        style={{ transition: "1s" }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: "20",
            mb: "16px",
            fontFamily: "Rawline Regular",
          }}
        >
          Informações da vaga
        </Typography>
        <DynamicForm
          ref={formRef}
          fields={fields}
          initialValues={values}
        />

        {/* buttons */}
        <Grid
          spacing={1}
          container
          sx={{
            mt: "16px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Button
              size="large"
              type="submit"
              fullWidth
              startIcon={<ThumbUpIcon />}
              variant="outlined"
            >
              TENHO INTERESSE
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Button
              size="large"
              type="submit"
              fullWidth
              startIcon={<CancelIcon />}
              variant="outlined"
            >
              RETIRAR INTERESSE
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Button
              size="large"
              type="submit"
              fullWidth
              variant="outlined"
            >
              CANCELAR
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Button
              size="large"
              type="submit"
              fullWidth
              startIcon={<ReplyIcon />}
              variant="outlined"
            >
              ENCAMINHAR CURRICULO
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
          >
            <Button
              size="large"
              type="submit"
              fullWidth
              startIcon={<OpenInNewIcon />}
              variant="outlined"
            >
              ACESSO EXTERNO
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
};

FormCidadaoVisualizarVaga.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  values: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormCidadaoVisualizarVaga;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CardVisualizarCurso from "../../components/cards/CardVisualizarCurso";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageCursosVisualizar = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const results = useQueries({
    queries: [
      {
        queryKey: ["curso", id],
        queryFn: () => {
          return adminService.getCurso(id);
        },
        retry: false,
      },
      {
        queryKey: ["empresasParceiras"],
        queryFn: adminService.getEmpresasParceiras,
        retry: false,
      },
    ],
  });

  const [cursoQuery, empresasParceirasQuery] = results;

  const curso = cursoQuery.data;
  const listaEmpresas = empresasParceirasQuery.data;

  const isPending = cursoQuery.isLoading || empresasParceirasQuery.isLoading || isLoading;
  const isError = cursoQuery.isError || empresasParceirasQuery.isError;

  useEffect(() => {
    if (curso && listaEmpresas) {
      const ofertante = listaEmpresas.find(e => e.pk_empresa_parceira === curso.cnpjOfertante);
      curso.cnpj = ofertante ? ofertante.cnpj : "";
      curso.nomeOfertante = ofertante ? ofertante.no_fantasia : "";
      setIsLoading(false);
    }
  }, [curso, listaEmpresas]);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiros/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiros/visualizar/" + id, text: "Informações do Parceiro" },
        ]}
        currentPage="Visualizar Curso"
      />

      <TitlePage title={"Visualizar Curso"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <CardVisualizarCurso initialData={curso} />
      )}
    </Container>
  );
};

export default PageCursosVisualizar;

import React, { useEffect, useRef, useState } from "react";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Button, Card, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import optionsService from "../../services/options.service.js";
import DynamicForm from "./DynamicForm";
import { SelectCidade } from "./fields/SelectCidade.js";
import { SelectUF } from "./fields/SelectUF.js";
import { formatTelefone } from "./utils.js";

const FormCidadaoVisualizarCurso = ({ valores, readOnly = false }) => {
  const formRef = useRef();

  const initialValues = valores
    ? valores
    : {
        ofertante: "Empresas Alfas",
        curso: "Dev full-stack",
        vigencia: "",
        modalide: "",
        tipo: "",
        pronatec: "",
        turno: "",
        caragaHoraria: "",
        vagas: "",
        idadeMinina: "",
        idadeMaxima: "",
        inicioCurso: "",
        fimCurso: "",
        escolaridadeMinima: "",
        enderecoCurso: "",
        telefoneContato: "",
        requisito: "",
        linkExterno: "",
        ementa: "",
        breveDescricao: "",
      };

  const [values, setValues] = useState(initialValues);

  // Preparação para UFS e Municípios
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });

    setSelectedUf(value);
    setSelectedMunicipio("");
    setValues({ ...values, ["municipio"]: "" });
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setSelectedMunicipio(event.target.value);
  };

  const fields = [
    {
      name: "ofertante",
      label: "Ofertante",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Ofertante"
            id="ofertante"
            name="ofertante"
            value={value}
            placeholder="Empresa responsável pela vaga"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "curso",
      label: "Curso",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Curso"
            id="curso"
            name="curso"
            value={value}
            placeholder="Curso ofertado"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "vigencia",
      label: "Vigência",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Vigência"
            id="vigencia"
            name="vigencia"
            value={value}
            placeholder="Vigência do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "modalidade",
      label: "Modalidade",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Modalidade"
            id="modalidade"
            name="modalidade"
            value={value}
            placeholder="Modalidade do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "tipo",
      label: "tipo",
      renderComponent: ({ value, onChange }) => (
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="label-tipo">Tipo</InputLabel>
          <Select
            labelid={`label-tipo`}
            id="tipo"
            label="Tipo"
            name="tipo"
            value={value}
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          >
            <MenuItem
              value=""
              disabled
            >
              Selecione uma opção
            </MenuItem>
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "pronatec",
      label: "Pronatec",
      renderComponent: ({ value, onChange }) => (
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="label-pronatec">Pronatec</InputLabel>
          <Select
            labelid={`label-pronatec`}
            id="pronatec"
            name="pronatec"
            value={value}
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          >
            <MenuItem
              value=""
              disabled
            >
              Selecione uma opção
            </MenuItem>
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "abrangencia",
      label: "Abrangência",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            fullWidth
            id="abrangencia"
            name="abrangencia"
            label="Abrangência"
            placeholder="Abrangência"
            value={value}
            onChange={onChange}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "uf",
      label: "uf",
      renderComponent: ({ value }) => (
        <SelectUF
          idSelect="uf"
          nameSelect="uf"
          value={value}
          ufs={ufs}
          uf={selectedUf}
          label="UF"
          visualizacao={readOnly}
          handleSelectUf={event => handleSelectUf(event)}
        />
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "municipio",
      label: "Município",
      renderComponent: ({ value }) => (
        <SelectCidade
          idSelect="municipio"
          nameSelect="municipio"
          value={value}
          cidades={municipios}
          cidade={selectedMunicipio}
          label="Município"
          visualizacao={readOnly}
          handleSelectCidade={event => handleSelectMunicipio(event)}
        />
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "turno",
      label: "Turno",
      renderComponent: ({ value, onChange }) => (
        <FormControl style={{ width: "100%" }}>
          <InputLabel id="label-turno">Turno</InputLabel>
          <Select
            labelid={`label-turno`}
            idSelect="turno"
            nameSelect="turno"
            value={value}
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          >
            <MenuItem
              value=""
              disabled
            >
              Selecione uma opção
            </MenuItem>
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "cargaHoraria",
      label: "Carga Horária",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Carga Horária"
            id="cargaHoraria"
            name="cargaHoraria"
            value={value}
            placeholder="Carga horária do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "vagas",
      label: "Vagas",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Vagas"
            id="vagas"
            name="vagas"
            value={value}
            placeholder="Número de vagas do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "idadeMinima",
      label: "Idade Mínima",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Idade Mínima"
            id="idadeMinima"
            name="idadeMinima"
            value={value}
            placeholder="Idade mínima para o curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 3 },
    },
    {
      name: "idadeMaxima",
      label: "Idade Máxima",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Idade Máxima"
            id="idadeMaxima"
            name="idadeMaxima"
            value={value}
            placeholder="Idade máxima para o curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 3 },
    },
    {
      name: "inicioCurso",
      label: "Início do Curso",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Início do Curso"
            id="inicioCurso"
            name="inicioCurso"
            value={value}
            placeholder="Data de início do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 3 },
    },
    {
      name: "fimCurso",
      label: "Fim do Curso",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Fim do Curso"
            id="fimCurso"
            name="fimCurso"
            value={value}
            placeholder="Data de término do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 3 },
    },
    {
      name: "escolaridadeMinima",
      label: "Escolaridade Mínima",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Escolaridade Mínima"
            id="escolaridadeMinima"
            name="escolaridadeMinima"
            value={value}
            placeholder="Escolaridade mínima para o curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "enderecoCurso",
      label: "Endereço do Curso",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Endereço do Curso"
            id="enderecoCurso"
            name="enderecoCurso"
            value={value}
            placeholder="Endereço do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "telefoneContato",
      label: "Telefone de Contato",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Telefone de Contato"
            id="telefoneContato"
            name="telefoneContato"
            value={formatTelefone(value)}
            placeholder="Telefone para contato"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "requisito",
      label: "Requisitos",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Requisitos"
            id="requisito"
            name="requisito"
            value={value}
            placeholder="Requisitos para o curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "linkExterno",
      label: "Link Externo",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Link Externo"
            id="linkExterno"
            name="linkExterno"
            value={value}
            placeholder="Link externo do curso"
            type="text"
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "ementa",
      label: "Ementa",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <TextField
            label="Ementa"
            id="ementa"
            name="ementa"
            value={value}
            placeholder="Ementa do curso"
            multiline
            rows={4}
            onChange={onChange}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { xs: 12, sm: 12 },
    },
    {
      name: "breveDescricao",
      label: "Breve Descrição",
      renderComponent: ({ value, onChange }) => (
        <Grid
          sx={{
            mb: "16px",
          }}
        >
          <FormGroup>
            <TextField
              label="Breve Descrição"
              id="breveDescricao"
              name="breveDescricao"
              value={value}
              placeholder="Breve descrição do curso"
              multiline
              rows={4}
              onChange={onChange}
              inputProps={{
                readOnly: readOnly,
              }}
            />
          </FormGroup>
        </Grid>
      ),
      size: { xs: 12, sm: 12 },
    },
  ];

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "20",
              mb: "16px",
              fontFamily: "Rawline Regular",
            }}
          >
            Informações do curso
          </Typography>

          <DynamicForm
            ref={formRef}
            fields={fields}
            initialValues={valores}
          />

          <Grid
            item
            container
            justifyContent="flex-end"
            spacing={1}
          >
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                type="submit"
                fullWidth
                variant="outlined"
              >
                CANCELAR
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                type="submit"
                fullWidth
                startIcon={<ThumbUpIcon />}
                variant="contained"
              >
                TENHO INTERESSE NESTE CURSO
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Stack>
    </>
  );
};

FormCidadaoVisualizarCurso.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  valores: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormCidadaoVisualizarCurso;

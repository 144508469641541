import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormCurso from "../../components/formularios/FormCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroCursoUtils";

const PageNovoCurso = () => {
  const navigate = useNavigate();

  const results = useQueries({
    queries: [
      {
        queryKey: ["empresasParceiras"],
        queryFn: parceiroService.getEmpresasParceiras,
      },
      {
        queryKey: ["trilhas"],
        queryFn: parceiroService.getTrilhasCursos,
      },
      { queryKey: ["cursos"], queryFn: parceiroService.getCursos },
    ],
  });

  // Desestruturação dos resultados das queries
  const [empresasQuery, trilhasQuery, cursosQuery] = results;
  const [isLoading, setIsLoading] = useState(false);

  // Dados carregados com sucesso
  const empresas = empresasQuery.data;
  const trilhas = trilhasQuery.data;
  const cursos = cursosQuery.data;

  // Variaveis de estado
  const isError = empresasQuery.isError || trilhasQuery.isError || cursosQuery.isError;
  const isPending = empresasQuery.isPending || trilhasQuery.isPending || cursosQuery.isPending;

  const handleSubmit = async values => {
    console.log("ATENÇÃO: o form está deixando chegar no handleSubmit mesmo com erros de validação quando envio dados de endereço");
    setIsLoading(true);
    let files = values.anexo;

    try {
      if (files?.length > 0) {
        // Aceita um único arquivo
        const arquivo = {
          nome_anexo: files[0].name.split(".")[0],
          extensao_anexo: files[0].name.split(".")[1],
          file: files[0].file,
        };

        // console.log("Arquivos: ", arquivos);
        parceiroService
          .postInsereArquivo(arquivo)
          .then(resposta => {
            console.log("Sucesso ao inserir arquivo:", resposta.message);

            const cursoPayload = transformaParaBackend(resposta.id, values);
            console.log(cursoPayload);

            parceiroService
              .postCadastrarCurso(cursoPayload)
              .then(resposta => {
                console.log("Sucesso ao cadastrar curso: ", resposta);
                navigate("/cursos/listar");
              })
              .catch(erro => {
                // TODO implementar delete de arquivo
                console.error("Erro ao cadastrar curso", erro);
              });
          })
          .catch(erro => {
            console.error("Erro ao inserir arquivo", erro);
          });
      } else {
        console.log("Insira um arquivo");
      }
    } catch (erro) {
      console.error("Erro ao inserir curso", erro);
    } finally {
      setIsLoading(false);
    }
  };

  if (isError) {
    console.error("Erro de carregamento");
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos", text: "Cursos" }]}
        currentPage={"Novo"}
      />

      <TitlePage title={"Novo curso"} />

      {isPending || isLoading ? (
        <LoadingComponent
          isLoading={isPending || isLoading}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <FormCurso
          listaEmpresas={empresas}
          listaTrilhas={trilhas}
          listaPreRequisitos={cursos}
          onSubmit={handleSubmit}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageNovoCurso;

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import cidadaoService from "../../../services/cidadao.service";
import { escolaridadeBanco, st_curso } from "../../../services/constants.js";
import { FormFieldsGrid } from "../DynamicForm.js";

const FormEscolaridade = forwardRef(({ defaultValues }, ref) => {
  const [cursos, setCursos] = useState([]);
  const [instituicoes, setInstituicoes] = useState([]);
  const [escolaridades, setEscolaridades] = useState(
    defaultValues?.escolaridades || [
      {
        tipo_escolaridade: "",
        situacao_curso: "",
        curso: "",
        ano_conclusao: "",
        instituicao: "",
      },
    ],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [cursosResponse, instituicoesResponse] = await Promise.all([
          cidadaoService.getCursoImportado(),
          cidadaoService.getInstituicaoEnsino(),
        ]);
        setCursos(cursosResponse);
        setInstituicoes(instituicoesResponse);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchData();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: { escolaridades },
    mode: "onBlur",
  });

  const handleFormSubmit = data => {
    console.log("Form data:", data);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      return handleSubmit(handleFormSubmit)();
    },
    getFormValues: () => {
      console.log("errors - ", errors);
      return new Promise(resolve => {
        handleSubmit(
          data => {
            resolve({ values: data, errors: null });
          },
          errors => {
            resolve({ values: getValues(), errors });
          },
        )();
      });
    },
  }));

  const addEscolaridade = () => {
    setEscolaridades([
      ...escolaridades,
      {
        tipo_escolaridade: "",
        situacao_curso: "",
        curso: "",
        ano_conclusao: "",
        instituicao: "",
      },
    ]);
  };

  const removeEscolaridade = index => {
    const newEscolaridades = escolaridades.filter((_, i) => i !== index);
    setEscolaridades(newEscolaridades);
  };

  const escolaridadeFields = [
    {
      name: "tipo_escolaridade",
      label: "Escolaridade / Grau de instrução",
      validation: { required: "Escolaridade é obrigatória" },
      renderComponent: ({ value, onChange, fieldArrayIndex }) => (
        <FormControl
          fullWidth
          error={!!errors?.escolaridades?.[fieldArrayIndex]?.tipo_escolaridade?.message}
        >
          <InputLabel>{`Escolaridade / Grau de instrução`}</InputLabel>
          <Select
            value={value || ""}
            onChange={onChange}
            label={`Escolaridade / Grau de instrução`}
          >
            <MenuItem value="">Selecione a Escolaridade</MenuItem>
            {Object.entries(escolaridadeBanco).map(([value, label]) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
          {errors && (
            <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>
              {errors?.escolaridades?.[fieldArrayIndex]?.tipo_escolaridade?.message || ""}
            </Typography>
          )}
        </FormControl>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "situacao_curso",
      label: "Situação do curso",
      validation: { required: "Situação é obrigatória" },
      renderComponent: ({ value, onChange, fieldArrayIndex }) => (
        <FormControl
          fullWidth
          error={!!errors?.escolaridades?.[fieldArrayIndex]?.situacao_curso?.message}
        >
          <InputLabel>{`Situação do curso`}</InputLabel>
          <Select
            value={value || ""}
            onChange={onChange}
            label={`Situação do curso`}
          >
            <MenuItem value="">Selecione a Situação do curso</MenuItem>
            {Object.entries(st_curso).map(([value, label]) => (
              <MenuItem
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
          {errors && (
            <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>
              {errors?.escolaridades?.[fieldArrayIndex]?.situacao_curso?.message}
            </Typography>
          )}
        </FormControl>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "curso",
      label: "Curso",
      validation: { required: "Curso é obrigatório" },
      renderComponent: ({ value, onChange, fieldArrayIndex }) => (
        <FormControl
          fullWidth
          error={!!errors?.escolaridades?.[fieldArrayIndex]?.curso?.message}
        >
          <InputLabel>{`Curso`}</InputLabel>
          <Select
            value={value ?? ""}
            onChange={onChange}
            label={`Curso `}
          >
            <MenuItem value="">Selecione o Curso</MenuItem>
            {cursos.map(curso => (
              <MenuItem
                key={curso.id_curso}
                value={curso.id_curso}
              >
                {curso.curso}
              </MenuItem>
            ))}
          </Select>
          {errors && (
            <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>
              {errors?.escolaridades?.[fieldArrayIndex]?.curso?.message}
            </Typography>
          )}
        </FormControl>
      ),
      size: { xs: 12, sm: 4 },
    },
    {
      name: "ano_conclusao",
      label: "Conclusão",
      validation: {
        required: "O ano de conclusão é obrigatório",
        pattern: {
          value: /^\d{4}$/,
          message: "O ano deve conter 4 dígitos",
        },
        min: {
          value: 2000,
          message: "Ano inválido insira anos a partir de 1900",
        },
        max: {
          value: new Date().getFullYear(),
          message: "O ano não pode ser maior que o atual",
        },
      },
      renderComponent: ({ value, onChange, fieldArrayIndex }) => {
        return (
          <TextField
            value={value ?? ""}
            onChange={onChange}
            label={`Conclusão`}
            inputProps={{
              maxLength: 4,
              pattern: "[0-9]*",
              inputMode: "numeric",
            }}
            error={!!errors?.escolaridades?.[fieldArrayIndex]?.ano_conclusao?.message}
            helperText={errors?.escolaridades?.[fieldArrayIndex]?.ano_conclusao?.message || ""}
            fullWidth
            type="number"
            placeholder="Insira o ano de conclusão do curso"
          />
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "instituicao",
      label: "Instituição de ensino",
      validation: { required: "A instituição é obrigatória" },
      renderComponent: ({ value, onChange, fieldArrayIndex }) => (
        <FormControl
          fullWidth
          error={!!errors?.escolaridades?.[fieldArrayIndex]?.instituicao?.message}
        >
          <InputLabel>{`Instituição de ensino`}</InputLabel>
          <Select
            value={value ?? ""}
            onChange={onChange}
            label={`Instituição de ensino`}
          >
            <MenuItem value="">Selecione a Instituição</MenuItem>
            {instituicoes.map(inst => (
              <MenuItem
                key={inst.id_instituicao}
                value={inst.id_instituicao}
              >
                {inst.instituicao}
              </MenuItem>
            ))}
          </Select>
          {errors && (
            <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>
              {errors?.escolaridades?.[fieldArrayIndex]?.instituicao?.message}
            </Typography>
          )}
        </FormControl>
      ),
      size: { xs: 12, sm: 6 },
    },
  ];

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {escolaridades.map((escolaridade, index) => (
        <React.Fragment key={index}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 2 }}
              >
                Escolaridade
              </Typography>
              {index > 0 && (
                <Button
                  color="inherit"
                  onClick={() => removeEscolaridade(index)}
                  sx={{ mb: 2, color: "gray" }}
                >
                  <DeleteIcon />
                </Button>
              )}
            </Grid>
          </Grid>
          <FormFieldsGrid
            fields={escolaridadeFields.map(f => ({
              ...f,
              name: `escolaridades.${index}.${f.name}`,
              renderComponent: props => f.renderComponent({ ...props, fieldArrayIndex: index }),
            }))}
            control={control}
            errors={errors}
          />
          <Grid
            item
            xs={12}
            sx={{ mt: 3, mb: 3 }}
          >
            <div style={{ borderBottom: "1px solid LightGrey" }}></div>
          </Grid>
        </React.Fragment>
      ))}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={addEscolaridade}
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR ESCOLARIDADE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

FormEscolaridade.displayName = "FormEscolaridade";

FormEscolaridade.propTypes = {
  defaultValues: PropTypes.shape({
    escolaridades: PropTypes.arrayOf(
      PropTypes.shape({
        tipo_escolaridade: PropTypes.string,
        situacao_curso: PropTypes.string,
        curso: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ano_conclusao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        instituicao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
};

export default FormEscolaridade;

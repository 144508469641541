import React, { useEffect, useState } from "react";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import VagaTrabalho from "../../components/formularios/VagaTrabalho";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroVagaUtils";

const filterDataByStatus = (data, statuses) => {
  return data.filter(item => statuses.includes(item.status));
};

const PageNovaVagaTrabalho = () => {
  const [empresasHabilitadas, setEmpresasHabilitadas] = useState([]);

  // Garantir que ambas as consultas sejam feitas simultaneamente e os arrays não sejam undefined
  const results = useQueries({
    queries: [
      {
        queryKey: ["empresasParceiras"],
        queryFn: parceiroService.getEmpresasParceiras,
      },
      {
        queryKey: ["ocupacoes"],
        queryFn: parceiroService.getOcupacoesCB2002,
      },
    ],
  });

  // Desestruturação dos resultados das queries
  const [empresasQuery, ocupacoesQuery] = results;

  // Dados carregados com sucesso
  const empresas = empresasQuery.data;
  // Atenção: está com uma limitação de 10 valores apenas, pois são mais de 2700 registros
  const ocupacoes = ocupacoesQuery.data;

  // Variaveis de estado
  const isError = empresasQuery.isError || ocupacoesQuery.isError;
  const isPending = empresasQuery.isPending || ocupacoesQuery.isPending;

  useEffect(() => {
    if (empresas) {
      setEmpresasHabilitadas(
        filterDataByStatus(Array.isArray(empresas) ? empresas : [], ["Novo cadastro", "Em cadastramento", "Aprovado"]),
      );
    }
  }, [empresas]);

  const handleSubmit = values => {
    const vagaPayload = transformaParaBackend(values);

    return parceiroService
      .postCadastrarVaga(vagaPayload)
      .then(resposta => {
        console.log("Sucesso ao cadastrar vaga: ", resposta);
        window.location.href = "/vagas/listar";
      })
      .catch(erro => {
        console.error("Erro ao cadastrar vaga", erro);
      });
  };

  if (isError) {
    console.error("Erro de carregamento");
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          {
            href: "/",
            text: "Página Inicial",
          },
          {
            href: "/parceiro",
            text: "Parceiro",
          },
        ]}
        currentPage={"Cadastrar Vaga de Trabalho"}
      />

      <TitlePage title={"Dados da vaga"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <VagaTrabalho
          onSubmit={handleSubmit}
          listaEmpresas={empresasHabilitadas}
          listaAreaAtuacao={ocupacoes}
        />
      )}

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default PageNovaVagaTrabalho;

import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CustomDatePicker from "../../components/formularios/CustomDatePicker";
import CustomModal from "../../components/modals/CustomModal";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";

const initialData = [
  {
    id: 1,
    vaga: "Empresa Alpha",
    municipio: "Brasilia / DF",
    salario: "Descrição do campo 01",
    "Disponivel até": "Descrição do campo 01",
    nDeVagas: "Descrição do campo 01",
    Natureza: "Descrição do campo 01",
  },
  {
    id: 2,
    vaga: "Empresa Beta",
    municipio: "Belo Horizonte / MG",
    salario: "Descrição do campo 02",
    "Disponivel até": "Descrição do campo 02",
    nDeVagas: "Descrição do campo 02",
    Natureza: "Descrição do campo 02",
  },
  {
    id: 3,
    vaga: "Empresa Omega",
    municipio: "Rio de Janeiro / RJ",
    salario: "Descrição do campo 03",
    "Disponivel até": "Descrição do campo 03",
    nDeVagas: "Descrição do campo 03",
    Natureza: "Descrição do campo 03",
  },
];

const initialFilterState = {
  tipoVaga: "",
  salario: "",
  nDeVagas: "",
  dataDisponivel: null,
};

const keysHidden = ["salario", "Disponivel até", "nDeVagas", "Natureza"];

const PageListarVagas = () => {
  const navigate = useNavigate();

  const [showRemoverInteresseDeVaga, setShowRemoverInteresseDeVaga] = useState(false);
  const [showIncluirInteresseDeVaga, setShowIncluirInteresseDeVaga] = useState(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState(initialData);

  const linksBreadCrumb = [
    { href: "/", text: "Cidadão" },
    { href: "/vagas", text: "Vagas de trabalho" },
  ];

  const headerTabela = [
    { field: "vaga", headerName: "Vaga" },
    { field: "municipio", headerName: "Município/UF" },
  ];

  const tabelaHiddenLinhas = useMemo(
    () =>
      (Array.isArray(filteredData) ? filteredData : []).map(row =>
        keysHidden.reduce((acc, key) => {
          acc[key] = row[key];
          return acc;
        }, {}),
      ),
    [filteredData, keysHidden],
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar Informações da vaga",
      href: "",
      icon: "visibility",
      onClick: () => {
        navigate("/vagas/visualizar/idVaga");
      },
    },
    {
      title: "Estou interessado nessa vaga",
      href: "",
      icon: "thumb_up",
      onClick: () => {
        setShowIncluirInteresseDeVaga(true);
      },
    },
    {
      title: "Não estou interessado nessa vaga",
      href: "",
      icon: "thumb_down",
      onClick: () => {
        setShowRemoverInteresseDeVaga(true);
      },
    },
    {
      title: "Abrir em uma nova aba",
      href: "",
      icon: "open_in_new",
      onClick: "",
    },
  ];

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      // const matchesTextFilter =
      //   filter.tipoDeServico?.length === 0 ||
      //   Object.keys(parceiro.tipoDeServico)
      //     .map(servico => {
      //       return parceiro.tipoDeServico[servico] == true && filter.tipoDeServico.includes(servico);
      //     })
      //     .includes(true);

      // const matchesDateFilter =
      //   (!filter.dataCadastroInicio ||
      //     !filter.dataCadastroFim ||
      //     dayjs(parceiro.dataCadastro).isBetween(filter.dataCadastroInicio, filter.dataCadastroFim, "day", "[]"));

      return parceiro;
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const ContentModalRemoverInteresseVaga = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ContentModalIncluirInteresseVaga = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Listar vagas"
        links={linksBreadCrumb}
      />

      <CustomModal
        showModal={showRemoverInteresseDeVaga}
        handleClose={() => {
          setShowRemoverInteresseDeVaga(false);
        }}
        title={"Confirmar a remoção do seu interesse na vaga"}
        content={<ContentModalRemoverInteresseVaga />}
        buttons={[]}
      />

      <CustomModal
        showModal={showIncluirInteresseDeVaga}
        handleClose={() => {
          setShowIncluirInteresseDeVaga(false);
        }}
        title={"Confirmar seu interesse na vaga"}
        content={<ContentModalIncluirInteresseVaga />}
        buttons={[]}
      />

      <TitlePage title={"Listar Vagas"} />

      <Card>
        <Grid
          container
          spacing={2}
          sx={{ p: 2 }}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "16",
                fontFamily: "Rawline Regular",
              }}
            >
              Filtros
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <FormControl
              variant="filled"
              sx={{ minWidth: "100%" }}
            >
              <InputLabel id="tipoVaga-label">Tipo de vaga</InputLabel>
              <Select
                labelId="tipoVaga-label"
                id="tipoVaga"
                name="tipoVaga"
                value={filter.tipoVaga}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Escolha o tipo de curso</em>
                </MenuItem>
                <MenuItem value={0}>Vagas ofertadas para o meu perfil</MenuItem>
                <MenuItem value={1}>Vagas disponíveis em minha região</MenuItem>
                <MenuItem value={2}>Vagas que encaminhei currículo</MenuItem>
                <MenuItem value={3}>Vagas que informei ter interesse</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              label="Salário"
              id="salario"
              name="salario"
              value={filter.salario}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
          >
            <CustomDatePicker
              label="Disponível até"
              id="dataDisponivel"
              name="dataDisponivel"
              value={filter.dataDisponivel}
              format="DD/MM/YYYY"
              onChange={valor => setFilter({ ...filter, ["dataDisponivel"]: valor })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              label="Número de vagas"
              id="nDeVagas"
              name="nDeVagas"
              value={filter.nDeVagas}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px 10px" }}
          >
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                onClick={resetFilter}
                fullWidth
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                type="submit"
                startIcon={<SearchIcon />}
                onClick={applyFilter}
                variant="contained"
                fullWidth
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ mt: "16px" }}
          >
            <DefaultTable
              columns={headerTabela}
              rows={initialData}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default PageListarVagas;

import React, { useEffect, useRef } from "react";
import { useState } from "react";

import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Card, FormControl, FormGroup, Grid, TextField, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useAuth } from "../../contexts/AuthContext.js";
import { st_escolaridade, tp_como_acessar_web, tp_estado_civil, tp_raca, tp_sexo } from "../../services/constants.js";
import optionsService from "../../services/options.service.js";
import CustomDatePicker from "../formularios/CustomDatePicker";
import ConfirmacaoModal from "../modals/ConfirmacaoModal.js";
import ErroModal from "../modals/ErroModal.js";
import DynamicForm from "./DynamicForm.js";
import ReusableSwitch from "./fields/ReusableSwitch.js";
import { SelectCidade } from "./fields/SelectCidade.js";
import { SelectUF } from "./fields/SelectUF.js";
import { formatTelefone } from "./utils.js";

const FormEditarPerfilCidadao = ({
  loading,
  handleSubmit,
  confirmacaoModal,
  setConfirmacaoModal,
  erroModal,
  setErroModal,
  values,
  readOnly = false,
}) => {
  const { user } = useAuth();
  const formRef = useRef();

  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();

  //========================================>  <================================================
  //VARIAVEIS UTILIZADAS NO FORM  - Primeiramente deve setar os valores iniciais
  //========================================>  <================================================
  const initialValues = values
    ? values
    : {
        email: user.ds_email,
        nome: "",
        nomePai: "",
        nomeMae: "",
        emailAdicional: "",
        telefone: "",
        estadoCivil: "",
        escolaridade: "",
        cnpj: "",
        uf: "",
        municipio: "",
        corRaca: "",
        genero: "",
        faixaRenda: "",
        acessoInternet: "",
        negocioProprio: false,
        pcd: false,
        situacaoRua: false,
        trabalhandoEstagiando: false,
        lerEscrever: false,
        buscaEmpregoEstagio: false,
      };

  const fields = [
    {
      name: "",
      label: "",
      renderComponent: () => (
        <Typography
          variant="h5"
          sx={{
            fontSize: "20",
            mb: "16px",
            fontFamily: "Rawline Regular",
          }}
        >
          Meu perfil
        </Typography>
      ),
      size: { sm: 12, md: 12 },
    },
    {
      name: "nome",
      label: "Nome",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="Nome "
            id="nome"
            name="nome"
            value={value}
            placeholder="Insira o seu Nome completo"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { sm: 6, md: 6 },
    },
    {
      name: "email",
      label: "* E-mail",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="* E-mail"
            id="email"
            name="email"
            value={value}
            placeholder="Insira seu E-mail aqui"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            // error={errors.email && touched.email}
            // helperText={errors.email && touched.email && errors.email}
          ></TextField>
        </FormGroup>
      ),
      size: { sm: 6, md: 6 },
    },
    {
      name: "nomePai",
      label: "* Nome do pai",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="* Nome do Pai"
            id="nomePai"
            name="nomePai"
            value={value}
            placeholder="Insira o Nome Completo do seu Pai"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
              readOnly: readOnly,
            }}
            // error={errors.nomePai && touched.nomePai}
            // helperText={errors.nomePai && touched.nomePai && errors.nomePai}
          />
        </FormGroup>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "nomeMae",
      label: "* Nome da Mãe",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="* Nome da Mãe"
            id="nomeMae"
            name="nomeMae"
            value={value}
            placeholder="Insira o Nome Completo da sua Mãe"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
              readOnly: readOnly,
            }}
            // error={errors.nomeMae && touched.nomeMae}
            // helperText={errors.nomeMae && touched.nomeMae && errors.nomeMae}
          />
        </FormGroup>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "emailAdicional",
      label: "* E-mail Adicional",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="* E-mail Adicional"
            id="emailAdicional"
            name="emailAdicional"
            value={value}
            placeholder="Insira seu E-mail Adicional aqui"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            // error={errors.email && touched.email}
            // helperText={errors.email && touched.email && errors.email}
          ></TextField>
        </FormGroup>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "telefone",
      label: "Telefone",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="Telefone"
            id="telefone"
            name="telefone"
            value={formatTelefone(value)}
            placeholder="Insira o Telefone"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{
              maxLength: 15,
              readOnly: readOnly,
            }}
          />
        </FormGroup>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "dataNascimento",
      label: "* Data de Nascimento",
      renderComponent: () => (
        <CustomDatePicker
          label="* Data de Nascimento"
          id="dataNascimento"
          name="dataNascimento"
          placeholder="Insira a Data de Nascimento"
          onChange={event => handleDataNascimetoChange(event)}
          // slotProps={{
          //   textField: {
          //     fullWidth: true,
          //     error: errors.dataNascimento && touched.dataNascimento,
          //     helperText: errors.dataNascimento && touched.dataNascimento && errors.dataNascimento,
          //   },
          // }}
        />
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "estadoCivil",
      label: "* Estado Civil",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel id="estadoCivilLabel">* Estado Civil</InputLabel>
          <Select
            labelid="estadoCivilLabel"
            id="estadoCivil"
            name="estadoCivil"
            value={value}
            onChange={onChange}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione o Estado Civil
            </MenuItem>
            {Object.keys(tp_estado_civil).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_estado_civil[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "escolaridade",
      label: "Escolaridade / Grau de Instrução",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel>Escolaridade / Grau de Instrução</InputLabel>
          <Select
            label="Escolaridade / Grau de Instrução"
            id="escolaridade"
            name="escolaridade"
            value={value}
            placeholder="Insira sua Escolaridade (ex: Ensino Médio Incompleto)"
            onChange={onChange}
            type="text"
          >
            <MenuItem
              key={""}
              name={""}
            >
              Selecione a Escolaridade
            </MenuItem>
            {Object.keys(st_escolaridade).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {st_escolaridade[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { sm: 6, md: 6 },
    },
    {
      name: "cnpj",
      label: "CNPJ de Microempreendedor Individual (MEI)",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="CNPJ de Microempreendedor Individual (MEI)"
            id="cnpj"
            name="cnpj"
            value={value}
            placeholder="Insira um CNPJ Válido"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormGroup>
      ),
      size: { sm: 6, md: 6 },
    },
    {
      name: "uf",
      label: "* UF",
      renderComponent: ({ value, onBlur }) => (
        <FormGroup>
          <SelectUF
            idSelect={"uf"}
            nameSelect={"uf"}
            value={value}
            ufs={ufs}
            uf={selectedUf}
            handleSelectUf={event => handleSelectUf(event)}
            onBlur={onBlur}
            readOnly={readOnly}
            // errors={errors.uf}
            // touched={touched.uf}
          />
          {/* {errors.uf && touched.uf && errors.uf && (
                        <Typography
                          sx={{
                            color: "#FF5630",
                            fontSize: "12px",
                            ml: "12px",
                          }}
                        >
                          {errors.uf}
                        </Typography>
                      )} */}
        </FormGroup>
      ),
      size: { sm: 3, md: 3 },
    },
    {
      name: "municipio",
      label: "* Município",
      renderComponent: ({ value, onBlur }) => (
        <FormGroup>
          <SelectCidade
            idSelect={"municipio"}
            nameSelect={"municipio"}
            value={value}
            cidades={cidades}
            cidade={selectedCidade}
            handleSelectCidade={event => handleSelectCidade(event)}
            onBlur={onBlur}
            readOnly={readOnly}
            // errors={errors.cidade}
            // touched={touched.cidade}
          />
          {/* {errors.cidade && touched.cidade && errors.cidade && (
                        <Typography
                          sx={{
                            color: "#FF5630",
                            fontSize: "12px",
                            ml: "12px",
                          }}
                        >
                          {errors.cidade}
                        </Typography>
                      )} */}
        </FormGroup>
      ),
      size: { sm: 3, md: 3 },
    },
    {
      name: "corRaca",
      label: "Cor ou Raça",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel id="corRaca">Cor ou Raça</InputLabel>
          <Select
            labelid="corRaca"
            id="corRaca"
            name={"corRaca"}
            value={value}
            label="Cor ou Raça"
            onChange={onChange}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione uma Cor/Raça
            </MenuItem>
            {Object.keys(tp_raca).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_raca[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { sm: 6, md: 6 },
    },
    {
      name: "genero",
      label: "Gênero",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel id="generoLabel">Gênero</InputLabel>
          <Select
            labelid="generoLabel"
            id="genero"
            name="genero"
            value={value}
            label="Gênero"
            onChange={onChange}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione o Gênero
            </MenuItem>
            {Object.keys(tp_sexo).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_sexo[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "faixaRenda",
      label: "Faixa de Renda",
      renderComponent: ({ value, onChange, onBlur }) => (
        <FormGroup>
          <TextField
            label="Faixa de Renda"
            id="faixaRenda"
            name="faixaRenda"
            value={value}
            placeholder="Insira uma Faixa de Renda"
            type="text"
            onChange={onChange}
            onBlur={onBlur}
          />
        </FormGroup>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "acessoInternet",
      label: "Como Geralmente Você Acessa a Internet?",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel id="acessoInternetLabel">Como Geralmente Você Acessa a Internet?</InputLabel>
          <Select
            labelid="acessoInternetLabel"
            id="acessoInternet"
            name="acessoInternet"
            value={value}
            onChange={onChange}
          >
            <MenuItem
              key={""}
              value={""}
            >
              Selecione uma Opção
            </MenuItem>
            {Object.keys(tp_como_acessar_web).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_como_acessar_web[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { sm: 4, md: 4 },
    },
    {
      name: "negocioProprio",
      label: "Tem Interesse em Abrir um Negócio Próprio?",
      renderComponent: ({ value }) => (
        <>
          <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Tem interesse em abrir um negócio próprio?</p>
          <ReusableSwitch
            id={"negocioProprio"}
            name={"negocioProprio"}
            value={value}
            onChange={event => handleSwitchChange(event)}
            disabled={false}
          />
        </>
      ),
      size: { sm: 12, md: 12 },
    },
    {
      name: "trabalhandoEstagiando",
      label: "Você Atualmente está Trabalhando ou Estagiando?",
      renderComponent: ({ value }) => (
        <>
          <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Você atualmente está trabalhando ou estagiando?</p>
          <ReusableSwitch
            id={"trabalhandoEstagiando"}
            name={"trabalhandoEstagiando"}
            value={value}
            onChange={event => handleSwitchChange(event)}
            disabled={false}
          />
        </>
      ),
      size: { sm: 12, md: 12 },
    },
    {
      name: "pcd",
      label: "PCD?",
      renderComponent: ({ value }) => (
        <>
          <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>PCD?</p>
          <ReusableSwitch
            id={"pcd"}
            name={"pcd"}
            value={value}
            onChange={event => handleSwitchChange(event)}
            disabled={false}
          />
        </>
      ),
      size: { sm: 12, md: 12 },
    },
    {
      name: "lerEscrever",
      label: "Sabe ler e escrever?",
      renderComponent: ({ value }) => (
        <>
          <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Sabe ler e escrever?</p>
          <ReusableSwitch
            id={"lerEscrever"}
            name={"lerEscrever"}
            value={value}
            onChange={event => handleSwitchChange(event)}
            disabled={false}
          />
        </>
      ),
      size: { sm: 12, md: 12 },
    },
    {
      name: "situacaoRua",
      label: "Você se encontra em situação de rua?",
      renderComponent: ({ value }) => (
        <>
          <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Você se encontra em situação de rua?</p>
          <ReusableSwitch
            id={"situacaoRua"}
            name={"situacaoRua"}
            value={value}
            onChange={event => handleSwitchChange(event)}
            disabled={false}
          />
        </>
      ),
      size: { sm: 12, md: 12 },
    },
    {
      name: "buscaEmpregoEstagio",
      label: "Você está buscando emprego ou estágio?",
      renderComponent: ({ value }) => (
        <>
          <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Você está buscando emprego ou estágio?</p>
          <ReusableSwitch
            id={"buscaEmpregoEstagio"}
            name={"buscaEmpregoEstagio"}
            value={value}
            onChange={event => handleSwitchChange(event)}
            disabled={false}
          />
        </>
      ),
      size: { sm: 12, md: 12 },
    },
  ];

  const [ufs, setUfs] = useState([]);
  const [cidades, setCidades] = values
    ? useState(optionsService.cidadesJSON.filter(municipio => municipio.estado === values.uf))
    : useState([]);
  const [selectedUf, setSelectedUf] = values ? useState(values.uf) : useState("");
  const [selectedCidade, setSelectedCidade] = values ? useState(values.municipio) : useState("");

  //========================================>  <================================================
  //HANDLES (SET VALUES) DIFERENTES UTILIZADOS NO FORM  - Caso necessite fazer um novo handle utilize o setFielValue, seguir o exemplo do handleSwitch
  //========================================>  <================================================
  const handleSwitchChange = event => {
    const { name, checked } = event.target;
    formRef.current.setValue(name, checked);
  };

  const handleDataNascimetoChange = event => {
    const date = new Date(event.$d);
    const formattedDate = date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    formRef.current.setValue("dataNascimento", formattedDate);
  };

  const handleConfirmacaoClose = () => {
    setConfirmacaoModal(false);
  };

  const handleErroClose = () => {
    setErroModal(false);
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    formRef.current.setValue(name, value);

    if (name === "uf") {
      setSelectedUf(value);
      setSelectedCidade("");
      formRef.current.setValue("municipio", "");
      fetchCidadesByUf(event.target.value, setCidades);
    }
  };

  const handleSelectCidade = event => {
    const { name, value } = event.target;
    formRef.current.setValue("municipio", value);

    if (name === "municipio") {
      setSelectedCidade(event.target.value);
    }
  };

  //========================================>  <================================================
  //FETCH PARA PREENCHER OPCOES NOS INPUTS - Caso necessite fazer um fetch para mostrar options
  //========================================>  <================================================
  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      // formRef.current.submitForm();
      console.log(formRef.current.getValues());
    }
  };

  return (
    <>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
        }}
        style={{ transition: "1s" }}
      >
        <DynamicForm
          fields={fields}
          ref={formRef}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
        />
      </Card>
      <Grid
        container
        spacing={1}
        sx={{ mt: "5px", justifyContent: "flex-end" }}
      >
        <Grid
          item
          md={2}
          sm={4}
          xs={12}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ border: "solid 1px", borderRadius: "24px" }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
        >
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            onClick={handleButtonClick}
            fullWidth
            loading={loading}
            sx={{ borderRadius: "24px" }}
          >
            {loading && <span className="spinner-border spinner-border-sm"></span>}
            <SaveIcon sx={{ mb: "4px", mr: "4px", width: "20px", height: "20px" }} />
            Salvar Informações
          </LoadingButton>
          {/* TODO: trocar essa modal por apenas um alert */}
          <ConfirmacaoModal
            showModal={confirmacaoModal || false}
            handleClose={handleConfirmacaoClose}
          />
          <ErroModal
            showModal={erroModal || false}
            handleClose={handleErroClose}
          />
        </Grid>
      </Grid>
    </>
  );
};

FormEditarPerfilCidadao.propTypes = {
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmacaoModal: PropTypes.bool,
  setConfirmacaoModal: PropTypes.func,
  setErroModal: PropTypes.func,
  erroModal: PropTypes.bool,
  erroDoUsuarioModal: PropTypes.bool,
  values: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FormEditarPerfilCidadao;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal.js";
import AdminVisualizarParceiroPendente from "../../components/filtros/AdminVisualizarParceiroPendente.js";
import { formatCPF } from "../../components/formularios/utils.js";
import LoadingComponent from "../../components/loading/LoadingComponent.js";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service.js";

// Segunda Tabela
// TODO: Pegar do Back
const tabelaVagasLinhas = [];

const tabelaVagasHiddenLinhas = tabelaVagasLinhas.map(({ salario, dataCadastro, vigencia }) => ({
  salario,
  dataCadastro,
  vigencia,
}));

// Terceira Tabela
// TODO: Pegar do Back
const tabelaCursosLinhas = [];

const tabelaCursosHiddenLinhas = tabelaCursosLinhas.map(({ vagas, interessados, situacao, status }) => ({
  vagas,
  interessados,
  situacao,
  status,
}));

// Quarta Tabela
// TODO: Pegar do Back
const tabelaPublicoMobilizadoLinhas = [
  // {
  //   id: 1,
  //   nome: "Empresa Alpha",
  //   cnpj: "12.345.678/0001-99",
  //   nomeResponsavel: "Ana Silva",
  //   uf: "SP",
  //   cidade: "São Paulo",
  //   vagasCadastradas: 10,
  //   candidatosInteressados: 50,
  //   curriculosCadastrados: 40,
  //   pessoasInteressadasEmCurso: 20,
  // },
  // {
  //   id: 2,
  //   nome: "Empresa Beta",
  //   cnpj: "98.765.432/0001-88",
  //   nomeResponsavel: "Bruno Souza",
  //   uf: "RJ",
  //   cidade: "Rio de Janeiro",
  //   vagasCadastradas: 8,
  //   candidatosInteressados: 30,
  //   curriculosCadastrados: 25,
  //   pessoasInteressadasEmCurso: 15,
  // },
  // {
  //   id: 3,
  //   nome: "Empresa Gamma",
  //   cnpj: "23.456.789/0001-77",
  //   nomeResponsavel: "Carla Pereira",
  //   uf: "MG",
  //   cidade: "Belo Horizonte",
  //   vagasCadastradas: 12,
  //   candidatosInteressados: 40,
  //   curriculosCadastrados: 35,
  //   pessoasInteressadasEmCurso: 18,
  // },
];

const tabelaPublicoMobilizadoHiddenLinhas = tabelaPublicoMobilizadoLinhas.map(
  ({ uf, cidade, vagasCadastradas, candidatosInteressados, curriculosCadastrados, pessoasInteressadasEmCurso }) => ({
    uf,
    cidade,
    vagasCadastradas,
    candidatosInteressados,
    curriculosCadastrados,
    pessoasInteressadasEmCurso,
  }),
);

const VisualizarParceiroPendente = () => {
  const [valores, setValores] = useState();
  const [tabelaRepresentantesLinhas, setTabelaRepresentanteLinhas] = useState([]);

  const { id } = useParams();
  const parceiroID = id;

  const results = useQueries({
    queries: [
      // Dados da Empresa
      { queryKey: ["empresaParceira"], enabled: !!parceiroID, queryFn: () => adminService.getEmpresaParceira(id) },
      // Primeira Tabela
      { queryKey: ["representantes"], enabled: !!parceiroID, queryFn: () => adminService.getRepresentantesEmpresa(id) },
    ],
  });

  // Desestruturação dos resultados das queries
  const [empresaQuery, representantesQuery] = results;

  // Dados carregados com sucesso
  const parceiroEncontrado = empresaQuery.data;
  const tabela1 = representantesQuery.data;

  // Variáveis de estado
  const isError = representantesQuery.isError || empresaQuery.isError;
  const isPending = representantesQuery.isLoading || empresaQuery.isLoading;

  // const initialData = dadosParceirosPendentes;

  useEffect(() => {
    if (parceiroEncontrado) {
      //TODO: colocar a página AdminVisualizarParceiroPendente escolher os dados que ela precisa
      // console.log(parceiroEncontrado);
      setValores({
        ...parceiroEncontrado,
      });
    }

    if (tabela1) {
      // TODO: ajustar isso quando receber os dados do service
      // console.log(tabela1);
      const tabela = Array.isArray(tabela1) ? tabela1 : [];
      setTabelaRepresentanteLinhas(
        tabela.map(linha => {
          return {
            nome: linha.no_usuario,
            email: linha.ds_email,
            cpf: formatCPF(linha.nu_cpf),
          };
        }),
      );
    }
  }, [parceiroEncontrado, tabela1]);

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiros/pendentes", text: "Listar Parceiros Pendentes" }]}
        currentPage="Visualizar Parceiro Pendente"
      />
      <TitlePage title="Visualizar Parceiro Pendente" />
      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
          widescreen={true}
        />
      ) : (
        <AdminVisualizarParceiroPendente
          tabelaRepresentantesLinhas={tabelaRepresentantesLinhas}
          tabelaVagasLinhas={tabelaVagasLinhas}
          tabelaVagasHiddenLinhas={tabelaVagasHiddenLinhas}
          tabelaCursosLinhas={tabelaCursosLinhas}
          tabelaCursosHiddenLinhas={tabelaCursosHiddenLinhas}
          tabelaPublicoMobilizadoLinhas={tabelaPublicoMobilizadoLinhas}
          tabelaPublicoMobilizadoHiddenLinhas={tabelaPublicoMobilizadoHiddenLinhas}
          valores={valores}
        />
      )}
      {isError && <ErroCarregamentoModal isShow={true} />};
    </Container>
  );
};

export default VisualizarParceiroPendente;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { useResponsive } from "../../hooks/use-responsive";
import AuthService from "../../services/auth.service";
import { FormFieldsGrid } from "../formularios/DynamicForm";

const ChangePassword = () => {
  const navigate = useNavigate();
  const mdUp = useResponsive("up", "md");

  const token = useParams().token;

  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });

  const [showPassword, setShowPassword] = useState({
    novaSenha: false,
    confirmNovaSenha: false,
  });

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { novaSenha: "", confirmNovaSenha: "" } });

  // Watch for changes in the password field
  const password = watch("novaSenha");

  // Update password requirements in real-time
  useEffect(() => {
    setPasswordRequirements({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[^a-zA-Z0-9]/.test(password),
    });
  }, [password]);

  const resetSenha = () => {
    AuthService.postChangePassword(token, getValues().novaSenha)
      .then(response => {
        alert(response.message);
      })
      .catch(error => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        alert(resMessage);
      })
      .finally(() => {
        navigate("/parceiro_login");
        //TODO: Colocar a pagina de redirecionamento adequada
      });
  };

  // Verifica se a senha tem os requisitos
  const validatePassword = value => {
    const hasUpperCaseLetters = /[A-Z]/.test(value);
    const hasLowerCaseLetters = /[a-z]/.test(value);
    const hasNumbers = /[0-9]/.test(value);
    const hasSpecialChars = /[^a-zA-Z0-9]/.test(value);

    if (!hasUpperCaseLetters) {
      return "Deve conter pelo menos uma letra maiúscula.";
    }
    if (!hasLowerCaseLetters) {
      return "Deve conter pelo menos uma letra minúscula.";
    }
    if (!hasNumbers) {
      return "Deve conter pelo menos um número.";
    }
    if (!hasSpecialChars) {
      return "Deve conter pelo menos um caracter especial.";
    }

    return true;
  };

  // Verifica se os campos de senha são iguais
  const validarIgualdade = value => {
    if (getValues().novaSenha != value) {
      return "As senhas devem ser iguais";
    }
    return true;
  };

  const handleTogglePasswordVisibility = field => {
    setShowPassword(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const fields = [
    // Senha
    {
      name: "novaSenha",
      label: "Nova senha",
      validation: {
        required: "Campo de senha é obrigatório",
        minLength: { value: 8, message: "A senha deve ter pelo menos 8 caracteres" },
        validate: validatePassword,
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="novaSenha"
          name="novaSenha"
          label="Nova senha"
          fullWidth
          type={showPassword.novaSenha ? "text" : "password"}
          placeholder="Nova senha"
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility("novaSenha")}
                  edge="end"
                >
                  {showPassword.novaSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
      size: { xs: 12 },
    },
    //Confirmação
    {
      name: "confirmNovaSenha",
      label: "Confirmar senha",
      validation: {
        validate: validarIgualdade,
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="confirmNovaSenha"
          name="confirmNovaSenha"
          label="Confirmar senha"
          fullWidth
          type={showPassword.confirmNovaSenha ? "text" : "password"}
          placeholder="Confirmar senha"
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility("confirmNovaSenha")}
                  edge="end"
                >
                  {showPassword.confirmNovaSenha ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ),
      size: { xs: 12 },
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        p: 2,
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: 1 }}
      >
        <Card
          sx={{
            p: mdUp ? 5 : 2,
          }}
        >
          <Typography
            mb={2}
            variant="h3"
          >
            Alteração de senha
          </Typography>

          <form onSubmit={handleSubmit(resetSenha)}>
            <FormFieldsGrid
              fields={fields}
              control={control}
              errors={errors}
            />

            {/* Enhanced Password requirements feedback */}
            <Box sx={{ mt: 1, mb: 2, backgroundColor: "background.paper", borderRadius: 1 }}>
              <List dense>
                {Object.entries(passwordRequirements).map(([key, value]) => (
                  <ListItem
                    key={key}
                    disableGutters
                    sx={{ py: 0.05 }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      {value ? (
                        <CheckCircleOutlineIcon
                          color="success"
                          fontSize="small"
                        />
                      ) : (
                        <ErrorOutlineIcon
                          color="error"
                          fontSize="small"
                        />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          color={value ? "success.main" : "error.main"}
                        >
                          {key === "length" && "Mínimo de 8 caracteres"}
                          {key === "uppercase" && "Pelo menos uma letra maiúscula"}
                          {key === "lowercase" && "Pelo menos uma letra minúscula"}
                          {key === "number" && "Pelo menos um número"}
                          {key === "special" && "Pelo menos um caractere especial"}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>

            <Grid
              container
              spacing={1}
              mt={2}
              mb={2}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
              >
                <LoadingButton
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{ gap: "8px" }}
                >
                  <Typography variant={"BUTTON TEXT"}>SALVAR ALTERAÇÕES</Typography>
                </LoadingButton>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate("/parceiro_login")}
                >
                  CANCELAR
                </Button>
              </Grid>
            </Grid>
          </form>

          <center>
            <Typography variant="caption">
              Caso o problema persista, entre em contato conosco pelo e-mail{" "}
              <Link
                component="a"
                //href=
                // Incluir o redirecionamento adequado
                color="primary.main"
                underline="always"
                sx={{ ml: 1 }}
              >
                cgad@mds.gov.br
              </Link>{" "}
            </Typography>
          </center>
        </Card>
      </Stack>
    </Box>
  );
};

export default ChangePassword;

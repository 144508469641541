import React from "react";

import { Card, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { formatCNPJ } from "../../components/formularios/utils.js";
import { tp_curso, tp_escolaridade, tp_modalidade, tp_turno } from "../../services/constants.js";

const CardVisualizarCurso = ({ initialData: values }) => {
  // console.log(values);
  return (
    <Card
      color="#ffffff"
      sx={{
        borderRadius: "8px",
        padding: "16px",
        mt: "24px",
      }}
    >
      <Stack spacing={1}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="h6">{values.nomeDoCurso}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormGroup>
              <TextField
                disabled
                id="cnpj"
                name="cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                value={formatCNPJ(values.cnpj) || ""}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormGroup>
              <TextField
                disabled
                id="ofertante"
                name="ofertante"
                label="Ofertante"
                placeholder="Ofertante"
                value={values.nomeOfertante}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="modalidade"
                name="modalidade"
                label="Modalidade"
                placeholder="Modalidade"
                value={tp_modalidade[values.modalidade]}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={3}
          >
            <FormGroup>
              <TextField
                disabled
                id="tipo"
                name="tipo"
                label="Tipo"
                placeholder="Tipo"
                value={tp_curso[values.tipoDeCurso]}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={1.5}
          >
            <FormGroup>
              <TextField
                disabled
                id="pronatec"
                name="pronatec"
                label="Pronatec"
                placeholder="Pronatec"
                value={values.pronatec}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.25}
          >
            <FormGroup>
              <TextField
                disabled
                id="inicioPreMatricula"
                name="inicioPreMatricula"
                label="Início pré-matrícula"
                placeholder="Início pré-matrícula"
                value={values.dataMatriculaInicio}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.25}
          >
            <FormGroup>
              <TextField
                disabled
                id="fimPreMatricula"
                name="fimPreMatricula"
                label="Fim pré-matrícula"
                placeholder="Fim pré-matrícula"
                value={values.dataMatriculaFim}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="turno"
                name="turno"
                label="Turno"
                placeholder="Turno"
                value={tp_turno[values.turno]}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="vagas"
                name="vagas"
                label="Vagas"
                placeholder="Vagas"
                value={values.vagas}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="cargaHoraria"
                name="cargaHoraria"
                label="Carga Horária"
                placeholder="Carga Horária"
                value={values.cargaHoraria}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="idadeMinima"
                name="idadeMinima"
                label="Idade Mínima"
                placeholder="Idade Mínima"
                value={values.idadeMinima}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="idadeMaxima"
                name="idadeMaxima"
                label="Idade Máxima"
                placeholder="Idade Máxima"
                value={values.idadeMaxima}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <FormGroup>
              <TextField
                disabled
                id="escolaridadeMinima"
                name="escolaridadeMinima"
                label="Escolaridade Mínima"
                placeholder="Escolaridade Mínima"
                value={tp_escolaridade[values.escolaridade]}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="abrangencia"
                name="abrangencia"
                label="Abrangência"
                placeholder="Abrangência"
                value={values.abrangencia}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="uf"
                name="uf"
                label="UF"
                placeholder="UF"
                value={values.uf}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="municipio"
                name="municipio"
                label="Município"
                placeholder="Município"
                value={values.municipio}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="inicioCurso"
                name="inicioCurso"
                label="Início do Curso"
                placeholder="Início do Curso"
                value={values.inicioCurso}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={2.4}
          >
            <FormGroup>
              <TextField
                disabled
                id="fimCurso"
                name="fimCurso"
                label="Fim do Curso"
                placeholder="Fim do Curso"
                value={values.fimCurso}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={6}
          >
            <FormGroup>
              <TextField
                disabled
                id="requisito"
                name="requisito"
                label="Requisito"
                placeholder="Requisito"
                value={values.requisito}
                type="text"
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormGroup>
              <TextField
                disabled
                id="linkExterno"
                name="linkExterno"
                label="Link Externo do Curso"
                placeholder="Link externo do Curso"
                value={values.linkExterno}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <FormGroup>
              <TextField
                disabled
                multiline
                rows={3}
                id="ementa"
                name="ementa"
                label="Ementa"
                placeholder="Ementa"
                value={values.ementa}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <FormGroup>
              <TextField
                disabled
                multiline
                rows={3}
                id="descricao"
                name="descricao"
                label="Breve descrição"
                placeholder="Breve descrição"
                value={values.descricao}
                type="text"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

CardVisualizarCurso.propTypes = {
  initialData: PropTypes.object,
};

export default CardVisualizarCurso;

import React, { useRef } from "react";

import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import DynamicForm from "../formularios/DynamicForm";
import CustomModal from "./CustomModal";

const AtivarVagaTrabalhoModal = ({ values, showModal, handleAtivarVaga, handleClose }) => {
  const formRef = useRef();

  const modalTitle = `Ativar vaga ${values.nome}`;
  const subtitle = `Situação ${values.situacao}`;
  const fields = [
    {
      name: "motivo",
      label: "Motivo",
      renderComponent: ({ value, onChange }) => (
        <>
          <Grid
            container
            spacing={2}
            display={"flex"}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Typography variant={"h6"}>{subtitle}</Typography>
            </Grid>
            <Grid
              item
              sm={12}
              md={12}
            >
              <TextField
                label="Motivo"
                value={value}
                onChange={onChange}
                rows={3}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </>
      ),
      size: { sm: 12, md: 12 },
    },
  ];

  const modalContent = (
    <Box>
      <DynamicForm
        fields={fields}
        ref={formRef}
      />
    </Box>
  );

  const handleAtivar = () => {
    handleAtivarVaga();
  };

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: "CONFIRMAR ATIVAÇÃO", onClick: handleAtivar, variant: "contained", icon: "save" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="md"
    />
  );
};

AtivarVagaTrabalhoModal.propTypes = {
  values: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleAtivarVaga: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AtivarVagaTrabalhoModal;

import React, { useRef, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { Box, Card, CardActions, CardContent, CardMedia, IconButton, InputAdornment, Snackbar, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const FileUpload = ({
  initialValues = [],
  fieldLabel = "Selecione os arquivos",
  maxFiles,
  onFieldsChange,
  fileType = [],
  showFiles = false,
  visualizar = false,
  error,
}) => {
  const [files, setFiles] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);
  const theme = useTheme();

  const handleChange = e => {
    const newFiles = Array.from(e.target.files).slice(0, maxFiles ? maxFiles - files.length : undefined);

    // console.log("NEW FILES -> ", newFiles);

    if (fileType.length > 1) {
      const validFiles = newFiles.filter(file => fileType.includes(file.type));

      if (validFiles.length !== newFiles.length) {
        setErrorMessage("");
        setErrorMessage("Alguns arquivos têm um tipo inválido. Os tipos permitidos são: " + fileType.join(", "));

        setTimeout(() => {
          setErrorMessage("");
        }, 10000);

        return;
      }
    }

    const mappedFiles = newFiles.map(file => ({
      name: file.name,
      base64: "",
      file,
    }));

    // console.log("mapped files -", mappedFiles);
    const updatedFiles = [];

    mappedFiles.forEach(fileObj => {
      const reader = new FileReader();
      reader.onloadend = () => {
        fileObj.base64 = reader.result;
        fileObj.preview = reader.result; // Add this line to store the preview

        // Atualiza a lista localmente
        updatedFiles.push(fileObj);

        // Verifica se todos os arquivos foram processados
        if (updatedFiles.length === mappedFiles.length) {
          setFiles(prevFiles => [...prevFiles, ...updatedFiles]);
          onFieldsChange && onFieldsChange([...files, ...updatedFiles]);
        }
      };
      reader.readAsDataURL(fileObj.file);
    });
  };

  const handleTextFieldClick = () => {
    if (!maxFiles || files.length < maxFiles) {
      fileInputRef.current.click();
    } else {
      setErrorMessage(`Você pode carregar apenas ${maxFiles === 1 ? "1 arquivo" : `${maxFiles} arquivos`}`);

      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
    }
  };

  const handleDelete = fileName => {
    const updatedFiles = files.filter(file => file.name !== fileName);
    setFiles(updatedFiles);
    onFieldsChange && onFieldsChange(updatedFiles);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, mt: 2 }}>
      <input
        type="file"
        hidden
        ref={fileInputRef}
        onChange={handleChange}
        multiple
        accept={Array.isArray(fileType) && fileType.length > 0 ? fileType.join(",") : undefined}
        style={{ cursor: "pointer" }}
        disabled={visualizar}
      />

      <Snackbar
        open={errorMessage != ""}
        autoHideDuration={6000}
        style={{ zIndex: 10 }}
        message={errorMessage}
      />

      <TextField
        label={fieldLabel}
        onClick={visualizar ? undefined : handleTextFieldClick}
        sx={{ input: { cursor: "pointer" } }}
        error={!!error}
        helperText={error?.message}
        color={error ? "error" : "primary"}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={visualizar ? undefined : handleTextFieldClick}
                disabled={visualizar}
              >
                <UploadFileRoundedIcon sx={{ color: error ? "error.main" : "darkgray" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        disabled={visualizar}
      />

      <Box sx={{ width: "100%", mt: 2 }}>
        {files.map(file => (
          <Card
            key={file.name}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "8px",
              boxShadow: "none",
            }}
          >
            <CardContent>
              <Typography
                style={{ textDecoration: "underline", fontFamily: "Rawline Medium" }}
                variant="body2"
                color="primary"
              >
                {file.name}
              </Typography>
            </CardContent>
            <CardActions>
              <IconButton
                onClick={() => handleDelete(file.name)}
                sx={{
                  border: "1px solid",
                  borderColor: theme.palette.primary.main,
                  width: "36px",
                  height: "36px",
                  borderRadius: "5px",
                }}
                disabled={visualizar}
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>

      {showFiles && (
        <Box
          sx={{
            width: "100%",
            mt: 2,
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "flex-start",
          }}
        >
          {files.map(file => (
            <Card
              sx={{
                width: 200, // Definindo uma largura fixa
                flexShrink: 0, // Evita que os cards encolham
                p: 2,
              }}
              key={file.name}
            >
              <CardMedia
                sx={{ height: 140 }} // Ajustando a altura para manter a proporção
                image={file.preview || file.url || file.base64} // Modified this line
                title={file.name}
              />
              <Typography
                sx={{ mt: 1 }}
                noWrap
              >
                {file.name}
              </Typography>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};

FileUpload.propTypes = {
  initialValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      base64: PropTypes.string,
      file: PropTypes.instanceOf(File),
      url: PropTypes.string,
    }),
  ),
  fieldLabel: PropTypes.string,
  maxFiles: PropTypes.number,
  onFieldsChange: PropTypes.func,
  showFiles: PropTypes.bool,
  visualizar: PropTypes.bool,
  fileType: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.object,
  helperText: PropTypes.string,
};

export default FileUpload;

import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import DynamicForm from "../formularios/DynamicForm";
// import ReCAPTCHA from "./recaptcha";

const LoginForm = ({ loading, recaptchaRef, handleSubmit, handleTermoDeUsoShow, handleAvisoDePrivacidadeShow, userRota }) => {
  const theme = useTheme();
  const formRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  const label = userRota == "/parceiro" ? "E-mail" : "Username";

  const fields = [
    {
      name: "username",
      label: label,
      validation: {
        required: userRota == "/parceiro" ? "Campo de e-mail é obrigatório" : "Campo de nome de usuário é obrigatório",

        minLength: { value: 5, message: "Nome de usuário deve ter pelo menos 6 caracteres" },
        ...(userRota === "/parceiro" && {
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "O e-mail deve ser válido",
          },
        }),
      },
      size: { xs: 12 },
    },
    {
      name: "password",
      label: "Senha",
      validation: {
        required: "Senha é obrigatória",
        minLength: { value: 5, message: "A senha deve ter pelo menos 6 caracteres" },
      },
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <>
          <TextField
            id="password"
            name="password"
            label="Senha"
            fullWidth
            InputLabelProps={value ? { shrink: true } : null}
            type={showPassword ? "text" : "password"}
            placeholder="Senha"
            value={value ?? ""}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={!!error}
            helperText={error?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {userRota == "/parceiro" && (
            <Typography variant="subtitle2">
              <Link
                component="a"
                href="/forgot_password"
                color="primary.main"
                underline="always"
                sx={{ ml: 1 }}
              >
                Esqueceu sua senha?
              </Link>
            </Typography>
          )}
        </>
      ),
      size: { xs: 12 },
    },
    {
      name: "toggle",
      label: "Toggle",
      validation: { required: "Você precisa aceitar os termos e condições" },
      renderComponent: ({ value, onChange, onBlur, ref, error }) => (
        <FormControl
          error={!!error}
          component="fieldset"
          fullWidth
          style={{ marginTop: 16 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="toggle"
                name="toggle"
                checked={value ?? false}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                style={{ color: theme.palette.text.disabled }}
                data-testid="termos-e-condicoes"
              />
            }
            label={
              <Typography variant="body1">
                Estou ciente e concordo com o
                <Link
                  component="a"
                  href="#"
                  onClick={handleTermoDeUsoShow}
                  color={theme.palette.primary.main}
                  underline="always"
                  sx={{ mx: 1 }}
                >
                  Termo de Uso
                </Link>
                e
                <Link
                  component="a"
                  href="#"
                  onClick={handleAvisoDePrivacidadeShow}
                  color={theme.palette.primary.main}
                  underline="always"
                  sx={{ mx: 1 }}
                >
                  Aviso de Privacidade
                </Link>
              </Typography>
            }
          />
          <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
      ),
      size: { xs: 12 },
    },
    {
      name: "token",
      label: "token",
      renderComponent: ({ onChange }) => (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LfH51EpAAAAAHhmahtgrjoaaKrO3n-hn9eCDGsv"
          onChange={onChange}
          data-testid="recaptcha"
        />
      ),
      size: { xs: 12 },
    },
  ];

  const handleButtonClick = () => {
    if (formRef.current) {
      // console.log(formRef.current.getValues());
      formRef.current.submitForm();
    }
  };

  return (
    <div>
      <DynamicForm
        ref={formRef}
        fields={fields}
        onSubmit={handleSubmit}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="button"
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
        loading={loading}
        sx={{ mt: 2 }}
        data-testid="login-submit"
      >
        {loading && <span className="spinner-border spinner-border-sm"></span>}
        Login
      </LoadingButton>
    </div>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  recaptchaRef: PropTypes.object,
  loading: PropTypes.bool,
  handleAvisoDePrivacidadeShow: PropTypes.func,
  handleTermoDeUsoShow: PropTypes.func,
  userRota: PropTypes.string,
};

export default LoginForm;

import React, { useCallback, useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";
import optionsService from "../../services/options.service";
import ButtonSecondary from "../buttons/ButtonSecondary";

const fetchUfs = async () => {
  const response = await optionsService.getAllUFs();
  return response;
};

const FormMultipleInsertAddress = ({
  onFieldsChange,
  multiplesInsert,
  desabilitarUf,
  desabilitarMunicipio,
  initialValues = [],
  disabled,
}) => {
  const defaultFields =
    (initialValues ?? []).length > 0
      ? initialValues.map((value, index) => ({
          id: Date.now() + index,
          uf: value.length === 1 ? value[0] : value[1],
          cidade: value.length > 1 ? value[0] : "",
        }))
      : [{ id: Date.now(), uf: "", cidade: "" }];

  const [fields, setFields] = useState(defaultFields);
  const [cidades, setCidades] = useState({});

  const { data: ufs = [] } = useQuery({
    queryKey: ["ufs"],
    queryFn: fetchUfs,
  });

  const isXs = useResponsive("down", "sm");

  // Function to fetch cities based on UF
  const fetchCidadesByUf = async ufSigla => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidades(prevState => ({ ...prevState, [ufSigla]: cidadesData })); // Update state with fetched cities
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  useEffect(() => {
    const initializeCities = async () => {
      const initialUfs = initialValues.map(value => (value.length === 1 ? value[0] : value[1]));
      const uniqueInitialUfs = [...new Set(initialUfs)];

      for (const uf of uniqueInitialUfs) {
        await fetchCidadesByUf(uf);
      }
    };

    initializeCities();
  }, [initialValues]);

  const handleAddField = useCallback(() => {
    const newFields = [...fields, { id: Date.now(), uf: "", cidade: "" }];
    setFields(newFields);
    onFieldsChange(newFields.map(field => ({ uf: field.uf, cidade: field.cidade })));
  }, [fields, onFieldsChange]);

  const handleRemoveField = useCallback(
    id => {
      const newFields = fields.filter(field => field.id !== id);
      setFields(newFields);
      onFieldsChange(newFields.map(field => ({ uf: field.uf, cidade: field.cidade })));
    },
    [fields, onFieldsChange],
  );

  const handleChange = useCallback(
    async (id, newValue, fieldKey) => {
      const newFields = fields.map(field => (field.id === id ? { ...field, [fieldKey]: newValue } : field));
      setFields(newFields);
      onFieldsChange(newFields.map(field => ({ uf: field.uf, cidade: field.cidade })));

      if (fieldKey === "uf") {
        if (!cidades[newValue]) {
          await fetchCidadesByUf(newValue);
        }
      }
    },
    [fields, onFieldsChange, cidades],
  );

  const allFieldsFilled = () => {
    return fields.every(field => field.uf.trim() !== "");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, mt: 1 }}>
      <Grid
        container
        spacing={2}
        px={2}
      >
        {fields.map((field, index) => (
          <Grid
            container
            spacing={2}
            key={field.id}
          >
            <Grid
              item
              xs={disabled ? 6 : 5}
              sx={{ mt: 1 }}
            >
              <FormControl fullWidth>
                <InputLabel id={`uf-label-${index}`}>UF</InputLabel>
                <Select
                  labelId={`uf-label-${index}`}
                  id={`id_uf_${index}`}
                  label="UF"
                  value={field.uf}
                  onChange={e => handleChange(field.id, e.target.value, "uf")}
                  disabled={desabilitarUf || disabled}
                >
                  {ufs.map(uf => (
                    <MenuItem
                      key={uf.sigla}
                      value={uf.sigla}
                    >
                      {uf.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={disabled ? 6 : 5}
              sx={{ mt: 1 }}
            >
              <FormControl fullWidth>
                <InputLabel id={`cidade-label-${index}`}>Município</InputLabel>
                <Select
                  labelId={`cidade-label-${index}`}
                  id={`id_cidade_${index}`}
                  value={field.cidade}
                  onChange={e => handleChange(field.id, e.target.value, "cidade")}
                  disabled={!field.uf || desabilitarMunicipio || disabled}
                >
                  {(cidades[field.uf] || []).map(cidade => (
                    <MenuItem
                      key={cidade.id}
                      value={cidade.nome}
                    >
                      {cidade.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {index === 0 && allFieldsFilled() && multiplesInsert && !disabled && (
              <Grid
                item
                xs={2}
                sx={{ mt: 1 }}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
              >
                <ButtonSecondary
                  title={isXs ? "" : "adicionar"}
                  icon={<AddCircleIcon />}
                  onClick={handleAddField}
                />
              </Grid>
            )}
            {index !== 0 && !disabled && (
              <Grid
                item
                xs={2}
                sx={{ mt: 1 }}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end" }}
              >
                <ButtonSecondary
                  title={isXs ? "" : "remover"}
                  icon={<RemoveCircleIcon />}
                  onClick={() => handleRemoveField(field.id)}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

FormMultipleInsertAddress.propTypes = {
  onFieldsChange: PropTypes.func.isRequired,
  multiplesInsert: PropTypes.bool,
  desabilitarUf: PropTypes.bool,
  desabilitarMunicipio: PropTypes.bool,
  initialValues: PropTypes.array,
  disabled: PropTypes.bool,
};

export default FormMultipleInsertAddress;

import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

export const SelectUF = ({ idSelect, nameSelect, ufs, uf, label = "* UF", handleSelectUf, errors, touched, visualizacao }) => {
  // Validar UF antes de selecionar
  const validatedUf = ufs.find(ufItem => ufItem.sigla === uf) ? uf : "";
  const ufSelecionada = validatedUf || "none";

  const options = ufs.map(ufItem => (
    <MenuItem
      key={ufItem.sigla}
      value={ufItem.sigla}
    >
      {ufItem.nome} ({ufItem.sigla})
    </MenuItem>
  ));

  return (
    <FormControl
      fullWidth
      key={idSelect + "_formControl"}
    >
      <InputLabel key={idSelect + "_label"}>{label}</InputLabel>
      <Select
        key={idSelect}
        id={idSelect}
        name={nameSelect}
        value={ufSelecionada}
        placeholder="Selecione uma UF"
        onChange={handleSelectUf}
        error={errors && touched}
        disabled={visualizacao}
      >
        <MenuItem
          key="none"
          value="none"
          disabled
        >
          <span style={{ color: errors && touched ? "#FF5630" : "grey" }}>Selecione uma UF</span>
        </MenuItem>
        {options}
      </Select>
    </FormControl>
  );
};

SelectUF.propTypes = {
  idSelect: PropTypes.string.isRequired,
  nameSelect: PropTypes.string.isRequired,
  ufs: PropTypes.array.isRequired,
  uf: PropTypes.string,
  label: PropTypes.string,
  handleSelectUf: PropTypes.func.isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  visualizacao: PropTypes.bool,
};

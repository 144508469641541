import React from "react";
import { useForm } from "react-hook-form";

import { Box, Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import { FormFieldsGrid } from "../formularios/DynamicForm";
import CustomModal from "./CustomModal";

const ExcluirVagaTrabalhoModal = ({ values, showModal, handleClose, handleExcluirVaga }) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      motivo: "",
    },
  });

  const modalTitle = `Excluir vaga ${values.nome}`;
  const subtitle = `Situação ${values.situacao}`;

  const fields = [
    {
      name: "motivo",
      label: "Motivo",
      validation: { required: "Informe o motivo da exclusão" },
      renderComponent: ({ value, onChange, error }) => (
        <Grid
          container
          spacing={2}
          display={"flex"}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
          >
            <Typography variant={"h6"}>{subtitle}</Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
          >
            <TextField
              label="Motivo"
              value={value}
              onChange={onChange}
              rows={3}
              fullWidth
              multiline
              error={!!error}
              helperText={error?.message}
              color={error ? "error" : "primary"}
            />
          </Grid>
        </Grid>
      ),
      size: { sm: 12, md: 12 },
    },
  ];

  const modalContent = (
    <Box>
      <FormFieldsGrid
        fields={fields}
        control={control}
        errors={errors}
      />
    </Box>
  );

  const onSubmit = data => {
    if (!data.motivo || data.motivo.trim() === "") {
      setError("motivo", { message: "Informe o motivo da exclusão" });
      return;
    }
    handleExcluirVaga(data.motivo);
  };

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    { label: "CONFIRMAR EXCLUSÃO", onClick: handleSubmit(onSubmit), variant: "contained", icon: "save" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      justifyButton="fex-end"
      maxWidth="md"
    />
  );
};

ExcluirVagaTrabalhoModal.propTypes = {
  values: PropTypes.object.isRequired,
  handleExcluirVaga: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ExcluirVagaTrabalhoModal;

import dayjs from "dayjs";

export function transformaDetalhes(detalhes) {
  let novosDetalhes = [];

  if (detalhes) {
    detalhes.forEach(detalhe => {
      const value = {
        cep: detalhe.endereco.nu_cep,
        endereco: detalhe.endereco.ds_logradouro,
        numero: detalhe.endereco.nu_logradouro,
        complemento: detalhe.endereco.ds_complemento,
        bairro: detalhe.endereco.no_bairro,
        uf: detalhe.endereco.uf,
        municipio: detalhe.endereco.cidade,
        telefone: detalhe.telefone.nu_ddd + detalhe.telefone.nu_telefone,
        vagas: detalhe.qt_vagas,
        abrangencia: detalhe.tp_abrangencia,
        dataInicioCurso: detalhe.dt_curso_inicio,
        dataFinalCurso: detalhe.dt_curso_fim,
      };
      novosDetalhes.push(value);
    });
  }

  return novosDetalhes;
}

export function calculaVigencia(situacao_registro, inicio_matricula, fim_matricula) {
  const hoje = dayjs(new Date().toDateString());
  const inicio = inicio_matricula;
  const fim = fim_matricula;

  if (situacao_registro === "CLD") {
    return "Cancelado";
  }

  if (hoje.isBefore(inicio)) {
    return "Inscrições Em Breve";
  }

  if (hoje.isAfter(fim)) {
    return "Encerrado";
  }

  return "Inscrições Abertas";
}

export function transformaCurso(curso) {
  const dados = {
    preRequisitos: curso.fk_curso_pre_requisito || [], // provavel que precise arrumar
    cnpjOfertante: curso.fk_empresa_parceira,
    // nomeOfertante: curso.nomeFantasia,
    cargaHoraria: curso.carga_horaria,
    turno: curso.tipo_turno,
    nomeDoCurso: curso.nome_curso,
    escolaridade: curso.tipo_escolaridade,
    tipoDeCurso: curso.tipo_curso,
    pronatec: curso.st_pronatec,
    dataMatriculaInicio: curso.dt_inicio_matricula,
    dataMatriculaFim: curso.dt_fim_matricula,
    idadeMinima: curso.idade_minima,
    idadeMaxima: curso.idade_maxima,
    modalidade: curso.tipo_modalidade,
    trilhas: curso.trilhas_curso,
    link: curso.link_externo ? curso.link_externo : "",
    ementa: curso.ementa,
    descricao: curso.breve_descricao,
    dataCriacao: curso.dh_criacao,
    dataUltimaModificacao: curso.dh_atualizacao,
    dataCursoInicio: curso.dt_curso_inicio,
    dataCursoFim: curso.dt_curso_fim,
    //vigencia: calculaVigencia(curso.situacao, curso.dt_inicio_matricula, curso.dt_fim_matricula),
    // situacao: curso.situacao,
    // motivoSituacao: curso.motivo,
  };

  // if (curso.tipo_modalidade && curso.tipo_modalidade != "EAD") {
  //   dados = {
  //     ...dados,
  //     detalhes: transformaDetalhes(curso.detalhes),
  //     dataCursoInicio: curso.detalhes[0].dt_curso_inicio,
  //     dataCursoFim: curso.detalhes[0].dt_curso_fim,
  //   };
  // }

  return dados;
}

export function transformaParaBackend(idAnexo, values) {
  // Checando se tem endereço
  const enderecoValues = values.detalhes && values.modalidade !== "EAD" ? values.detalhes[0] : null;

  const newValues = {
    dados: {
      fk_anexos_foto: idAnexo,
      fk_empresa_parceira: values.cnpjOfertante, //recebo o id da empresa
      carga_horaria: values.cargaHoraria,
      nome_curso: values.nomeDoCurso,
      st_pronatec: values.pronatec,
      dt_inicio_matricula: values.dataMatriculaInicio,
      dt_inicio: values.dataCursoInicio,
      dt_fim: values.dataCursoFim,
      ementa: values.ementa,
      breve_descricao: values.descricao,
      tipo_curso: values.tipoDeCurso,
      tipo_modalidade: values.modalidade,
      tipo_escolaridade: values.escolaridade,
      tipo_turno: values.turno,
      trilhas_curso: values.trilhas,

      // Campos opcionais
      ...(values.preRequisitos && { fk_curso_pre_requisito: values.preRequisitos }),
      ...(values.dataMatriculaFim && { dt_fim_matricula: values.dataMatriculaFim }),
      ...(values.idadeMinima && { idade_minima: values.idadeMinima }),
      ...(values.idadeMaxima && { idade_maxima: values.idadeMaxima > 99 ? 99 : values.idadeMaxima }),
      ...(values.link && { link_externo: values.link.startsWith("http") ? values.link : `https://${values.link}` }),
    },

    // Detalhes tem que ser enviado vazio se nao tiver
    detalhes: enderecoValues
      ? {
          endereco: {
            cep: enderecoValues.cep.replace(/[.-]/g, ""),
            numero: enderecoValues.numero || "0",
            complemento: enderecoValues.complemento || "",
            bairro: enderecoValues.bairro,
            logradouro: enderecoValues.endereco,
            uf: enderecoValues.uf,
            cidade: enderecoValues.municipio,
            telefone: enderecoValues.telefone,
          },
          dt_curso_inicio: enderecoValues.dataInicioCurso,
          dt_curso_fim: enderecoValues.dataFinalCurso,
          qt_vagas: enderecoValues.vagas,
          tp_abrangencia: enderecoValues.abrangencia,
        }
      : [],
  };

  return newValues;
}

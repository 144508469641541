import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroListarInteressadosVagaTrabalho from "../../components/formularios/ParceiroListarInteressadosVagaTrabalho";
import TitlePage from "../../components/title-page/TitlePage";

const PageListarInteressadosVagaTrabalho = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/", text: "Página Inicial" },
          { href: "/parceiro", text: "Parceiro" },
          { href: "/vagas/listar", text: "Vagas" },
        ]}
        currentPage={"Listar Interessados"}
      />

      <TitlePage title={"Lista de Interessados em Vagas de Trabalho"} />

      <ParceiroListarInteressadosVagaTrabalho />
    </Container>
  );
};

export default PageListarInteressadosVagaTrabalho;

import { utils as XLSXUtils, writeFile as writeXLSXFile } from "xlsx";

export const GerarExcelAutomatizado = (dados, termos, headerConfig, nomeArquivo = "Planilha Padrão") => {
  // Process data to match headerConfig order
  const dadosOrganizados = dados.map(row => {
    const rowData = [];
    const processRowData = (headerObj, parentKey = "") => {
      Object.entries(headerObj).forEach(([header, subHeaderList]) => {
        const fullHeader = parentKey ? `${parentKey} - ${header}` : header;

        if (Array.isArray(subHeaderList)) {
          if (subHeaderList.length > 0) {
            subHeaderList.forEach(subHeader => {
              const key = Object.keys(termos).find(k => termos[k] === subHeader);
              const value = row[key] || "";
              rowData.push(value);
            });
          } else {
            const key = Object.keys(termos).find(k => termos[k] === header);
            const value = row[key] || "";
            rowData.push(value);
          }
        } else {
          // Recursion for subheaders that are objects
          processRowData(subHeaderList, fullHeader);
        }
      });
    };

    processRowData(headerConfig);
    return rowData;
  });

  // Create the headers with empty spaces for correct merging
  const mainHeaders = [];
  const subHeaders = [];
  const thirdLevelHeaders = [];

  // Process headers and create arrays for three levels
  for (const key in headerConfig) {
    if (Object.prototype.hasOwnProperty.call(headerConfig, key)) {
      // Usando Object.prototype.hasOwnProperty
      mainHeaders.push(key);
      const value = headerConfig[key];

      if (Array.isArray(value) && value.length > 0) {
        subHeaders.push(...Array(mainHeaders.length - subHeaders.length - 1).fill(""));
        subHeaders.push(...value);
        thirdLevelHeaders.push(...Array(mainHeaders.length - thirdLevelHeaders.length).fill(""));
        mainHeaders.push(""); // Adiciona espaço vazio após chave com filhos
      } else if (typeof value === "object" && value !== null) {
        // ADICIONEI O -1 EM AMBAS
        subHeaders.push(...Array(mainHeaders.length - subHeaders.length - 1).fill(""));
        thirdLevelHeaders.push(...Array(mainHeaders.length - thirdLevelHeaders.length - 1).fill(""));
        let isFirstSubkey = true;

        for (const subkey in value) {
          if (Object.prototype.hasOwnProperty.call(value, subkey)) {
            // Mesma verificação para sub-objeto
            subHeaders.push(subkey);

            if (!isFirstSubkey) {
              mainHeaders.push("");
            }
            isFirstSubkey = false;

            if (Array.isArray(value[subkey]) && value[subkey].length > 0) {
              const emptySpace = Math.max(mainHeaders.length - thirdLevelHeaders.length - 1, 0);
              const tamanhoSubkey = Math.max(value[subkey].length - 1, 1);
              thirdLevelHeaders.push(...Array(emptySpace).fill(""));
              thirdLevelHeaders.push(...value[subkey]);
              subHeaders.push(...Array(tamanhoSubkey).fill(""));
              mainHeaders.push(...Array(tamanhoSubkey).fill(""));
            } else {
              thirdLevelHeaders.push("");
            }
          }
        }
      } else {
        subHeaders.push("");
        thirdLevelHeaders.push("");
      }
    }
  }

  const remainingSubHeadersSpace = Math.max(mainHeaders.length - subHeaders.length, 0);
  const remainingThirdLevelHeadersSpace = Math.max(mainHeaders.length - thirdLevelHeaders.length, 0);

  subHeaders.push(...Array(remainingSubHeadersSpace).fill(""));
  thirdLevelHeaders.push(...Array(remainingThirdLevelHeadersSpace).fill(""));

  // Generate worksheet data
  const worksheetData = [mainHeaders, subHeaders, thirdLevelHeaders, ...dadosOrganizados];
  const worksheet = XLSXUtils.aoa_to_sheet(worksheetData);

  // Merge cells as needed
  const merges = [];
  const createMerges = (headerObj, level = 0, startCol = 0) => {
    let colOffset = startCol;

    Object.keys(headerObj).forEach(header => {
      const subHeaderList = headerObj[header];

      if (Array.isArray(subHeaderList)) {
        if (subHeaderList.length > 0) {
          let hasThirdLevel = false;

          // Verifica se algum subHeader tem terceiro nível
          subHeaderList.forEach(subHeader => {
            if (typeof subHeader === "object" && !Array.isArray(subHeader) && Object.keys(subHeader).length > 0) {
              hasThirdLevel = true;
            }
          });

          // Mescla para o nível atual se houver terceiro nível
          merges.push({
            s: { r: level, c: colOffset },
            e: { r: level, c: colOffset + subHeaderList.length - 1 },
          });

          if (!hasThirdLevel) {
            // Se não houver terceiro nível, mescla até o terceiro nível
            subHeaderList.forEach(() => {
              merges.push({
                s: { r: level + 1, c: colOffset },
                e: { r: 2, c: colOffset },
              });
              colOffset += 1;
            });
          } else {
            colOffset += subHeaderList.length;
          }
        } else {
          // Mescla as células quando não há subcabeçalhos no próximo nível
          merges.push({
            s: { r: level, c: colOffset },
            e: { r: 2, c: colOffset }, // Mescla até o terceiro nível
          });
          colOffset += 1;
        }
      } else {
        const subHeadersCount = Object.keys(subHeaderList).reduce((count, key) => {
          const subItem = subHeaderList[key];
          if (Array.isArray(subItem)) {
            return count + (subItem.length > 0 ? subItem.length : 1);
          } else {
            return count + Object.keys(subItem).length;
          }
        }, 0);

        // Mescla o cabeçalho principal com subcabeçalhos
        merges.push({
          s: { r: level, c: colOffset },
          e: { r: level, c: colOffset + subHeadersCount - 1 },
        });

        // Chama recursivamente para tratar subcabeçalhos
        createMerges(subHeaderList, level + 1, colOffset);
        colOffset += subHeadersCount;
      }
    });

    return colOffset;
  };
  createMerges(headerConfig);

  worksheet["!merges"] = merges;

  // Create a new workbook
  const workbook = XLSXUtils.book_new();
  XLSXUtils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a file
  writeXLSXFile(workbook, nomeArquivo + ".xlsx");
};

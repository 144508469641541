import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import adminService from "../../services/admin.service";
import constantsService from "../../services/constants.service";
import ModalTituloSubTitulo from "../modals/ModalTituloSubTitulo";
import DefaultTable from "../table/DefaultTable";

// Retornar a pesquisa vazia
const initialFilterState = {
  situacao: "none",
  origem: "none",
};

const tabelaColunas = [
  { field: "nome", headerName: "Nome" },
  { field: "cursos", headerName: "Cursos Cadastrados" },
];

const keysHidden = ["situacao", "origem", "dataUltimaModificacao"];

const AdminListarTrilhas = ({ initialData }) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState([]);
  const [modal, setModal] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [motivo, setMotivo] = useState("");
  const [status, setStatus] = useState("");
  const [deletar, setDeletar] = useState(false);
  const [id, setId] = useState();
  const [erroMotivo, setErroMotivo] = useState(false);

  useEffect(() => {
    if (initialData) {
      setFilteredData(initialData);
    }
  }, [initialData]);

  const tabelaHiddenLinhas = filteredData.map(row =>
    keysHidden.reduce((acc, key) => {
      acc[key] = row[key];
      return acc;
    }, {}),
  );

  const fecharModal = () => {
    setModal(false);
    setMotivo("");
  };

  const handleMotivoChange = e => {
    setMotivo(e.target.value);
    setErroMotivo(e.target.value === "");
  };

  const submit = () => {
    if (motivo.length > 0) {
      let request;

      if (deletar) {
        request = adminService.deletarTrilha(id, motivo);
      } else {
        request = adminService.postAtualizarStatusTrilha({
          trilha_id: id.toString(),
          status: status,
          mensagem: motivo,
        });
      }

      request
        .then(() => {
          fecharModal();
          window.location.reload();
          alert("Operação realizada com sucesso!");
        })
        .catch(erro => {
          if (deletar) {
            console.error("Erro ao deletar Trilha: ", erro);
          } else {
            console.error("Erro ao atualizar Trilha: ", erro);
          }
        });
    } else {
      alert("Motivo é obrigatório");
    }
  };

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      return (
        (!filter.dataDoCadastroInicio ||
          !filter.dataDoCadastroFim ||
          dayjs(parceiro.dataCadastro).isBetween(filter.dataDoCadastroInicio, filter.dataDoCadastroFim, "day", "[]")) &&
        (!filter.dataDaUltimaModificacaoInicio ||
          !filter.dataDaUltimaModificacaoFim ||
          dayjs(parceiro.dataUltimaModificacao).isBetween(
            filter.dataDaUltimaModificacaoInicio,
            filter.dataDaUltimaModificacaoFim,
            "day",
            "[]",
          ))
      );
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    applyFilter();
  };

  const getTabelaActions = row => {
    const botoes = [
      {
        title: "Visualizar",
        icon: "visibility",
        onClick: rowId => navigate("/trilhas/visualizar/" + JSON.stringify(rowId)),
      },
      {
        title: "Editar",
        icon: "edit",
        onClick: rowId => navigate("/trilhas/editar/" + JSON.stringify(rowId)),
      },
      {
        title: "Adicionar",
        icon: "add_circle",
        onClick: rowId => navigate("/trilhas/incluir/" + JSON.stringify(rowId)),
      },
    ];

    if (row?.situacao != "Aprovado") {
      botoes.push({
        title: "Ativar",
        icon: "check_circle",
        onClick: rowId => {
          setModal(true);
          setStatus("APR");
          setId(rowId);
          setTitulo("Ativar trilha");
        },
      });
    }
    if (row?.situacao == "Aprovado") {
      botoes.push({
        title: "Inativar",
        icon: "remove_circle",
        onClick: rowId => {
          setModal(true);
          setStatus("INA");
          setId(rowId);
          setTitulo("Inativar trilha");
        },
      });
    }

    botoes.push({
      title: "Excluir",
      icon: "delete",
      onClick: rowId => {
        setModal(true);
        setTitulo("Excluir trilha");
        setId(rowId);
        setDeletar(true);
      },
    });

    return botoes;
  };

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">Filtros</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-situacao">Situação</InputLabel>
                <Select
                  id="situacao"
                  name="situacao"
                  value={filter.situacao}
                  labelId="label-situacao"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Situação
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="label-origem">Origem</InputLabel>
                <Select
                  id="origem"
                  name="origem"
                  value={filter.origem}
                  labelId="label-origem"
                >
                  <MenuItem value="none">Origem</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={"auto"}
              >
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                  fullWidth
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizada Trilha!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
              termos={constantsService.getAllTermos()}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>

      {/* Modais */}
      <div>
        <ModalTituloSubTitulo
          titulo={titulo}
          subTitulo={"Motivo"}
          texto={""}
          motivo={motivo}
          erroMotivo={erroMotivo}
          handleMotivoChange={handleMotivoChange}
          showModal={modal}
          handleSubmit={submit}
          handleClose={fecharModal}
        />
      </div>
    </>
  );
};

AdminListarTrilhas.propTypes = {
  initialData: PropTypes.array,
  setServicosModal: PropTypes.func,
  servicosModal: PropTypes.bool,
};

export default AdminListarTrilhas;

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import { Button, Card, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import CustomDatePicker from "../../components/formularios/CustomDatePicker";
import { SelectCidade } from "../../components/formularios/fields/SelectCidade";
import { SelectUF } from "../../components/formularios/fields/SelectUF";
import CustomModal from "../../components/modals/CustomModal";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";
import optionsService from "../../services/options.service";

const initialFilterState = {
  curso: "",
  modalidade: "",
  nDeVagas: "",
  uf: "",
  municipio: "",
  dataInicio: null,
  dataFim: null,
  tipoCurso: "",
};

const keysHidden = ["modalidade", "dataInicio", "nDeVagas", "tipoCurso"];

const PageListarCursos = () => {
  const navigate = useNavigate();

  const [showRemoverInteresseDeCurso, setShowRemoverInteresseDeCurso] = useState(false);
  const [showIncluirInteresseDeCurso, setShowIncluirInteresseDeCurso] = useState(false);

  const [filtros, setFiltros] = useState(initialFilterState);
  const [filtrosAplicados, setFiltrosAplicados] = useState(filtros);

  // Preparação para UFS e Municípios
  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    setFiltros({ ...filtros, [name]: value });

    setSelectedUf(value);
    setSelectedMunicipio("");
    setFiltros({ ...filtros, ["municipio"]: "" });
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    setFiltros({ ...filtros, [name]: value });
    setSelectedMunicipio(event.target.value);
  };

  const aplicarFiltros = () => {
    setFiltrosAplicados(filtros);
  };

  const linksBreadCrumb = [{ href: "/", text: "Cursos" }];

  const headerTabela = [
    { field: "curso", headerName: "Curso" },
    { field: "municipio", headerName: "Município/UF" },
  ];

  const dadosTabela = [
    {
      id: 1,
      curso: "Curso 1",
      municipio: "Brasilia / DF",
      uf: "DF",
      modalidade: "Descrição do campo 01",
      dataInicio: new Date("2024-09-12").toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }),
      "tipo de curso": "tipo de curso campo 01",
    },
    {
      id: 2,
      curso: "Curso 2",
      municipio: "São Paulo / SP",
      uf: "SP",
      modalidade: "Descrição do campo 02",
      dataInicio: new Date("2024-10-20").toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }),
      "tipo de curso": "tipo de curso campo 02",
    },
    {
      id: 1,
      curso: "Curso 3",
      municipio: "Rio de Janeiro / RJ",
      uf: "RJ",
      modalidade: "Descrição do campo 03",
      dataInicio: new Date("2024-09-30").toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }),
      "tipo de curso": "tipo de curso campo 03",
    },
  ];

  const dadosFiltrados = useMemo(() => {
    return dadosTabela.filter(item =>
      Object.keys(filtros).every(coluna => {
        const valorFiltro = filtros[coluna];
        const valorItem = item[coluna];

        if (coluna === "dataInicio" && valorFiltro instanceof Date) {
          // Convertendo a string em objeto Date
          let partesData = valorItem.split("/");
          let dia = partesData[0];
          let mes = partesData[1] - 1;
          let ano = partesData[2];
          let itemDate = new Date(ano, mes, dia);

          const filtroDia = valorFiltro.getDate();
          const filtroMes = valorFiltro.getMonth();
          const filtroAno = valorFiltro.getFullYear();

          const itemDia = itemDate.getDate();
          const itemMes = itemDate.getMonth();
          const itemAno = itemDate.getFullYear();

          return filtroDia === itemDia && filtroMes === itemMes && filtroAno === itemAno;
        }

        // Verifica se o valor do filtro e do item são strings antes de aplicar toLowerCase()
        if (typeof valorFiltro === "string" && typeof valorItem === "string") {
          return valorItem.toLowerCase().includes(valorFiltro.toLowerCase());
        }

        // Se não forem strings, não aplica toLowerCase() e retorna true para manter o item no filtro
        return true;
      }),
    );
  }, [filtrosAplicados]);

  const tabelaHiddenLinhas = useMemo(
    () =>
      (Array.isArray(dadosFiltrados) ? dadosFiltrados : []).map(row =>
        keysHidden.reduce((acc, key) => {
          acc[key] = row[key];
          return acc;
        }, {}),
      ),
    [dadosFiltrados, keysHidden],
  );

  const getTabelaActions = () => [
    {
      title: "Visualizar Informações do curso",
      href: "",
      icon: "visibility",
      onClick: () => {
        //navigate("/cursos/listar-cursos/visualizar-informacoes-do-curso/idCurso");
        navigate("/cursos/visualizar/idCurso");
      },
    },
    {
      title: "Estou interessado nesse curso",
      href: "",
      icon: "thumb_up",
      onClick: () => {
        setShowIncluirInteresseDeCurso(true);
      },
    },
    {
      title: "Não estou interessado nesse curso",
      href: "",
      icon: "thumb_down",
      onClick: () => {
        setShowRemoverInteresseDeCurso(true);
      },
    },
  ];

  const handleChange = event => {
    const { name, value } = event.target;
    setFiltros({ ...filtros, [name]: value });
  };

  const ContentModalRemoverInteresseCurso = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ContentModalIncluirInteresseCurso = function () {
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "flex-end", flexDirection: "row", alignItems: "flex-end", gap: 2 }}
      >
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="outlined"
          >
            NÃO
          </Button>
        </Grid>
        <Grid
          item
          xs={3}
          md={1}
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
          >
            SIM
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Listar cursos"
        links={linksBreadCrumb}
      />

      <CustomModal
        showModal={showRemoverInteresseDeCurso}
        handleClose={() => {
          setShowRemoverInteresseDeCurso(false);
        }}
        title={"Confirmar a remoção do seu interesse no curso"}
        content={<ContentModalRemoverInteresseCurso />}
        buttons={[]}
      />

      <CustomModal
        showModal={showIncluirInteresseDeCurso}
        handleClose={() => {
          setShowIncluirInteresseDeCurso(false);
        }}
        title={"Confirmar seu interesse no curso"}
        content={<ContentModalIncluirInteresseCurso />}
        buttons={[]}
      />

      <TitlePage title={"Listar Cursos"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "16",
                fontFamily: "Rawline Regular",
              }}
            >
              Filtros
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <TextField
              fullWidth
              id="curso"
              name="curso"
              label="Curso"
              placeholder="Curso"
              value={filtros.curso}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <TextField
              fullWidth
              id="modalidade"
              name="modalidade"
              label="Modalidade"
              placeholder="Modalidade"
              value={filtros.modalidade}
              onChange={handleChange}
            />
          </Grid>
          {/* UF E Município */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <SelectUF
              idSelect="uf"
              nameSelect="uf"
              value={filtros.uf}
              ufs={ufs}
              uf={selectedUf}
              label="UF"
              handleSelectUf={event => handleSelectUf(event)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <SelectCidade
              idSelect="municipio"
              nameSelect="municipio"
              value={filtros.municipio}
              cidades={municipios}
              cidade={selectedMunicipio}
              label="Município"
              handleSelectCidade={event => handleSelectMunicipio(event)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <CustomDatePicker
              label="Início do Período"
              id="dataInicio"
              name="dataInicio"
              value={filtros.dataInicio}
              format="DD/MM/YYYY"
              onChange={valor => setFiltros({ ...filtros, ["dataInicio"]: valor })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <CustomDatePicker
              label="Fim do Período"
              id="dataFim"
              name="dataFim"
              value={filtros.dataFim}
              format="DD/MM/YYYY"
              onChange={valor => setFiltros({ ...filtros, ["dataFim"]: valor })}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-tipoCurso`}>Tipo de curso</InputLabel>
              <Select
                labelid={`label-tipoCurso}`}
                id="tipoCurso"
                name="tipoCurso"
                value={filtros.tipoCurso}
                onChange={handleChange}
              >
                <MenuItem value="">Selecione uma opção</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
          >
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-nDeVagas`}>Número de Vagas</InputLabel>
              <Select
                labelid={`label-nDeVagas}`}
                id="nDeVagas"
                name="nDeVagas"
                value={filtros.nDeVagas}
                onChange={handleChange}
              >
                <MenuItem value="">Selecione uma opção</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            container
            spacing={1}
            xs={12}
            justifyContent="flex-end"
          >
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                fullWidth
              >
                CANCELAR
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={"auto"}
            >
              <Button
                size="large"
                startIcon={<SearchIcon />}
                variant="contained"
                onClick={aplicarFiltros}
                fullWidth
              >
                PESQUISAR
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ mt: "16px" }}
          >
            <DefaultTable
              columns={headerTabela}
              rows={dadosFiltrados}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default PageListarCursos;

import React from "react";
import { useParams } from "react-router-dom";

import { Card, Container, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";

import InformationBox from "../../components/boxes/InformationBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroUsuariosRepresentante from "../../components/formularios/ParceiroUsuariosRepresentante";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import parceiroService from "../../services/parceiro.service";

const PageUsuariosParceiro = () => {
  const { id } = useParams();
  const { isPending, data: usuarios } = useQuery({
    queryKey: ["usuarios"],
    queryFn: () => parceiroService.getRepresentantesEmpresa(id),
  });

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/", text: "Parceiro" }]}
        currentPage="Usuário do Parceiro"
      />

      <TitlePage title="Usuários do Parceiro" />

      {/* Aviso na página */}
      <Grid container>
        <Grid
          item
          align="left"
          xs={12}
          mb={2}
        >
          <InformationBox
            title=""
            message="Usuários listados em amarelo ainda não acessaram o sistema. Após o primeiro acesso o vínculo é confirmado e seu nome aparecerá
            em verde."
          />
        </Grid>
      </Grid>

      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <Typography
            variant="h4"
            mb={3}
            sx={{ fontFamily: "Rawline Bold" }}
            style={{ transition: "1s" }}
          >
            Lista de Usuários Representantes do Parceiro
          </Typography>
          {isPending || !usuarios ? (
            <LoadingComponent
              isLoading={isPending}
              fullScreen={false}
              widescreen={true}
            />
          ) : (
            <ParceiroUsuariosRepresentante
              usuarios={usuarios}
              idEmpresa={id}
            />
          )}
        </Card>
      </Stack>
    </Container>
  );
};

export default PageUsuariosParceiro;

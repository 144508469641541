import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { escolaridadeBanco, tp_turno, tp_vaga, validadeVaga } from "../../services/constants";
import CustomDatePicker from "../formularios/CustomDatePicker";
import { formatCNPJ } from "../formularios/utils.js";
import { FormFieldsGrid } from "./DynamicForm";
import FormAddress from "./FormAddress.js";
import FormExigenciaVagaTrabalho from "./FormExigenciaVagaTrabalho";

const valoresIniciais = {
  tipoVaga: "",
  cnpj: "",
  nomeOfertante: "",
  funcao: "",
  quantidadeVagas: "",
  validadeAnuncio: "",
  escolaridadeRequerida: "",
  escolaridadeMinima: false,
  vagaPCD: false,
  localTrabalhoParceiro: true,
  salario: "",
  turno: "",
  descricao: "",
  endereco: [
    {
      uf: "",
      municipio: "",
      cep: "",
      endereco: "",
      numero: "",
      complemento: "",
      referencia: "",
      bairro: "",
    },
  ],
  auxilioAlimentacao: false,
  auxilioTransporte: false,
  planoSaude: false,
  outros: false,
  detalheAuxilioAlimentacao: "",
  detalheAuxilioTransporte: "",
  detalhePlanoSaude: "",
  detalheOutros: "",
  areaAtuacaoProfissional: [],
  areaAtuacaoEstudante: [],
  cursoComplementar: [],
  cursoTecnico: [],
  cursoSuperior: [],
};

const formatarMoeda = valor => {
  if (!valor) return "";

  // Remove tudo que não for número
  valor = valor.replace(/\D/g, "");

  // Limitar o valor máximo
  if (Number(valor) > 999999) {
    valor = "999999";
  }

  // Converte para número e divide por 100 para considerar os centavos
  const numero = Number(valor) / 100;

  // Formata o número como moeda
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(numero);
};

const removerFormatacaoMoeda = valor => {
  if (!valor) return "";
  return valor.replace(/\D/g, "");
};

const fakeListaCursos = {
  complementar: [
    { id: "1", descricao: "Administração" },
    { id: "2", descricao: "Engenharia" },
    { id: "3", descricao: "Informática" },
    { id: "5", descricao: "Saúde" },
  ],
  tecnico: [
    { id: "1", descricao: "Administração" },
    { id: "2", descricao: "Engenharia" },
    { id: "3", descricao: "Informática" },
    { id: "5", descricao: "Saúde" },
  ],
  superior: [
    { id: "1", descricao: "Administração" },
    { id: "2", descricao: "Engenharia" },
    { id: "3", descricao: "Informática" },
    { id: "5", descricao: "Saúde" },
  ],
};

const VagaTrabalho = ({
  initialValues = valoresIniciais,
  visualizacao,
  isAdmin = false,
  onSubmit,
  listaEmpresas = [],
  listaAreaAtuacao = [],
  listaCursos = fakeListaCursos,
}) => {
  const formRef = useRef();
  const theme = useTheme();
  const navigate = useNavigate();

  const [empresaSelecionada, setEmpresaSelecionada] = useState(
    initialValues ? listaEmpresas.find(e => e.no_fantasia === initialValues.nomeOfertante) : "",
  );
  const [tipoVagaSelecionada, setTipoVagaSelecionada] = useState(initialValues?.tipoVaga || "");
  const [mostrarEnderecoAlternativo, setMostrarEnderecoAlternativo] = useState(!initialValues?.localTrabalhoParceiro);

  const {
    control,
    handleSubmit,
    setValue,
    // getValues,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: initialValues,
    mode: "onBlur", // or "onSubmit", "onChange", depending on when you want validation to trigger
  });

  // console.log(getValues());

  const handleCNPJChange = event => {
    const selectedId = event.target.value;
    // Encontrar a empresa correspondente pelo ID
    const empresa = listaEmpresas.find(e => e.pk_empresa_parceira === selectedId);
    // Atualizar o estado com a empresa selecionada
    setEmpresaSelecionada(empresa);
  };

  const fields = [
    {
      name: "tipoVaga",
      label: " * Tipo de Vaga",
      validation: {
        required: "Tipo de vaga é obrigatório",
      },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
        >
          <InputLabel error={!!error}>* Tipo de vaga</InputLabel>
          <Select
            id="tipoVaga"
            name="tipoVaga"
            value={value}
            error={!!error}
            onChange={e => {
              onChange(e);
              setTipoVagaSelecionada(e.target.value);
              setValue("areaAtuacaoProfissional", []);
              setValue("areaAtuacaoEstudante", []);
              setValue("cursoComplementar", []);
              setValue("cursoTecnico", []);
              setValue("cursoSuperior", []);
            }}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione um Tipo
            </MenuItem>
            {Object.keys(tp_vaga).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_vaga[value]}
              </MenuItem>
            ))}
          </Select>
          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "cnpjOfertante",
      label: "* CNPJ ofertante",
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => {
        return (
          <FormControl
            fullWidth
            error={!!error}
          >
            <InputLabel error={!!error}>* CNPJ ofertante</InputLabel>
            <Select
              id="cnpjOfertante"
              name="cnpjOfertante"
              value={value ?? ""}
              onChange={event => {
                handleCNPJChange(event);
                onChange(event.target.value);
              }}
              disabled={visualizacao}
              error={!!error}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um CNPJ
              </MenuItem>

              {/* Mapeando empresas para criar os itens do select */}
              {listaEmpresas?.map(empresa => (
                <MenuItem
                  key={empresa.pk_empresa_parceira}
                  value={empresa.pk_empresa_parceira}
                >
                  {formatCNPJ(empresa.nu_cnpj)}
                </MenuItem>
              ))}
            </Select>
            {!!error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "nomeOfertante",
      label: "Nome do ofertante",
      renderComponent: () => (
        <TextField
          id="nomeOfertante"
          name="nomeOfertante"
          label="Nome do ofertante"
          value={empresaSelecionada ? empresaSelecionada.no_fantasia : ""}
          disabled
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "funcao",
      label: "* Nome da Função",
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="funcao"
          name="funcao"
          label="* Nome da Função"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          error={!!error}
          helperText={error?.message}
          fullWidth
          InputLabelProps={{
            style: error ? { color: "#FF5630" } : undefined,
          }}
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "quantidadeVagas",
      label: "* Quantidade de Vagas",
      validation: {
        required: "Este campo é obrigatório",
        max: {
          value: 999,
          message: "O valor deve ser menor ou igual a 999",
        },
        min: {
          value: 1,
          message: "O valor deve ser maior ou igual a 1",
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="quantidadeVagas"
          name="quantidadeVagas"
          label="* Quantidade de Vagas"
          type="number"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          error={!!error}
          helperText={error?.message}
          fullWidth
          InputLabelProps={{
            style: error ? { color: "#FF5630" } : undefined,
          }}
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "validadeAnuncio",
      label: "* Validade do Anúncio",
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
        >
          <InputLabel>* Validade do Anúncio</InputLabel>
          <Select
            id="validadeAnuncio"
            name="validadeAnuncio"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione a Validade
            </MenuItem>
            {Object.keys(validadeVaga).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {validadeVaga[value]}
              </MenuItem>
            ))}
          </Select>
          {!!error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "escolaridadeRequerida",
      label: "* Escolaridade Requerida",
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
        >
          <InputLabel>* Escolaridade Requerida</InputLabel>
          <Select
            id="escolaridadeRequerida"
            name="escolaridadeRequerida"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione uma Escolaridade
            </MenuItem>
            {Object.keys(escolaridadeBanco).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {escolaridadeBanco[value]}
              </MenuItem>
            ))}
          </Select>
          {!!error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "salario",
      label: tipoVagaSelecionada === "EMP" ? "* Salário" : "* Bolsa Auxílio",
      validation: {
        required: "Este campo é obrigatório",
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="salario"
          name="salario"
          label={tipoVagaSelecionada === "EMP" ? "* Salário" : "* Bolsa Auxílio"}
          value={formatarMoeda(value)}
          onChange={e => {
            const valorNumerico = removerFormatacaoMoeda(e.target.value);
            onChange(valorNumerico);
          }}
          disabled={visualizacao}
          fullWidth
          inputProps={{
            inputMode: "numeric",
          }}
          InputLabelProps={{
            style: error ? { color: "#FF5630" } : undefined,
          }}
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "turno",
      label: "* Turno",
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <FormControl
          fullWidth
          error={!!error}
        >
          <InputLabel>* Turno</InputLabel>
          <Select
            id="turno"
            name="turno"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione um turno
            </MenuItem>
            {Object.keys(tp_turno).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_turno[value]}
              </MenuItem>
            ))}
          </Select>
          {!!error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => (
        <>
          {isAdmin && (
            <TextField
              id="situacao"
              name="situacao"
              label="Situação"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          )}
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "dataResposta",
      label: "Data de Resposta",
      renderComponent: ({ value, onChange }) => (
        <>
          {isAdmin && (
            <CustomDatePicker
              label="Data da Resposta"
              id="dataResposta"
              name="dataResposta"
              value={value}
              format="DD/MM/YYYY"
              onChange={onChange}
              disabled={visualizacao}
            />
          )}
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "interessados",
      label: "Interessados",
      renderComponent: ({ value, onChange }) => (
        <>
          {isAdmin && (
            <TextField
              id="interessados"
              name="interessados"
              label="Interessados"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          )}
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "escolaridadeMinima",
      label: "Escolaridade mínima exigida?",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          control={
            <Checkbox
              id="escolaridadeMinima"
              name="escolaridadeMinima"
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Escolaridade mínima exigida?"
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "descricao",
      label: "* Descrição/Informações Adicionais",
      validation: { required: "Este campo é obrigatório" },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="descricao"
          name="descricao"
          label="* Descrição/Informações Adicionais"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          rows={4}
          fullWidth
          multiline
          InputLabelProps={{
            style: error ? { color: "#FF5630" } : undefined,
          }}
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "vagaPCD",
      label: "Essa vaga é direcionada para pessoas com deficiência?",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          control={
            <Checkbox
              name="vagaPCD"
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Essa vaga é direcionada para pessoas com deficiência?"
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsEndereco = [
    {
      name: "localTrabalhoParceiro",
      label: "O local de trabalho será o mesmo cadastrado no logradouro do parceiro?",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          control={
            <Checkbox
              id="localTrabalhoParceiro"
              name="localTrabalhoParceiro"
              checked={value ?? true}
              onChange={e => {
                onChange(e);
                setMostrarEnderecoAlternativo(!e.target.checked);
              }}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="O local de trabalho será o mesmo cadastrado no logradouro do parceiro?"
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "endereco",
      label: "Endereço",
      renderComponent: ({ value, onChange }) => (
        <>
          {mostrarEnderecoAlternativo && (
            <>
              <Typography
                variant="h6"
                sx={{ mb: 1 }}
              >
                Outro Logradouro
              </Typography>

              <FormAddress
                values={value}
                showReferencia={true}
                onChange={onChange}
              />
            </>
          )}
        </>
      ),
      size: { xs: 12, sm: 12 },
    },
  ];

  const fieldsBeneficios = [
    {
      name: "auxilioAlimentacao",
      label: "Auxílio Alimentação",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"auxilioAlimentacao"}
          name={"auxilioAlimentacao"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Auxílio Alimentação"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalheAuxilioAlimentacao",
      label: "Datalhes do Auxílio Alimentação",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalheAuxilioAlimentacao"
          name="detalheAuxilioAlimentacao"
          label="Detalhes do Auxílio Alimentação"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
    {
      name: "auxilioTransporte",
      label: "Auxílio Transporte",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"auxilioTransporte"}
          name={"auxilioTransporte"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Auxílio Transporte"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalheAuxilioTransporte",
      label: "Datalhes do Auxílio Transporte",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalheAuxilioTransporte"
          name="detalheAuxilioTransporte"
          label="Detalhes do Auxílio Transporte"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
    {
      name: "planoSaude",
      label: "Plano de Saúde",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"planoSaude"}
          name={"planoSaude"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Plano Saúde"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalhePlanoSaude",
      label: "Datalhes do Plano Saúde",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalhePlanoSaude"
          name="detalhePlanoSaude"
          label="Detalhes do Plano de Saúde"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
    {
      name: "outros",
      label: "Outros",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"outros"}
          name={"outros"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Outros"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalheOutros",
      label: "Outros Detalhes",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalheOutros"
          name="detalheOutros"
          label="Outros Detalhes"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
  ];

  const fieldsAreaAtuacao = [
    {
      name: "areaAtuacaoProfissional",
      label: "*Informe a(s) Área(s) de Atuação do Profissional",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleAreaAtuacaoProfissional}
          label={"*Informe a(s) Área(s) de Atuação do Profissional"}
          placeholder={"Escolha a área de atuação do profissional"}
          list={listaAreaAtuacao}
          initialValue={value}
          visualizacao={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsAreaEstudante = [
    {
      name: "areaAtuacaoEstudante",
      label: "*Informe a(s) Área(s) de Atuação do Estudante",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleAreaAtuacaoEstudante}
          label={"*Informe a(s) Área(s) de Atuação do Estudante"}
          placeholder={"Escolha a área de atuação do estudante"}
          list={listaAreaAtuacao}
          initialValue={value}
          visualizacao={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsCursoComplementar = [
    {
      name: "cursoComplementar",
      label: "*Algum Curso Complementar é Exigido ou Desejável? (Caso sim adicione um ou mais itens)",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleCursoComplementar}
          label={"*Algum Curso Complementar é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
          placeholder={"Escolha o curso complementar"}
          list={listaCursos.complementar}
          initialValue={value}
          visualizacao={visualizacao}
          showInput2={false}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsCursoTecnico = [
    {
      name: "cursoTecnico",
      label: "*Algum Curso Técnico é Exigido ou Desejável? (Caso sim adicione um ou mais itens)",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleCursoTecnico}
          label={"*Algum Curso Técnico é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
          placeholder={"Escolha o curso técnico"}
          list={listaCursos.tecnico}
          initialValue={value}
          visualizacao={visualizacao}
          showInput2={false}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const fieldsCursoSuperior = [
    {
      name: "cursoSuperior",
      label: "*Algum Curso de Nível Superior é Exigido ou Desejável? (Caso sim adicione um ou mais itens)",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleCursoSuperior}
          label={"*Algum Curso de Nível Superior é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
          placeholder={"Escolha o curso de nível superior"}
          list={listaCursos.superior}
          initialValue={value}
          visualizacao={visualizacao}
          showInput2={false}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const handleAreaAtuacaoProfissional = values => {
    setValue("areaAtuacaoProfissional", values);
  };

  const handleAreaAtuacaoEstudante = values => {
    setValue("areaAtuacaoEstudante", values);
  };

  const handleCursoComplementar = values => {
    setValue("cursoComplementar", values);
  };

  const handleCursoTecnico = values => {
    setValue("cursoTecnico", values);
  };

  const handleCursoSuperior = values => {
    setValue("cursoSuperior", values);
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      disabled={visualizacao}
    >
      <Stack spacing={2}>
        <Card
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
        >
          <FormFieldsGrid
            fields={fields}
            control={control}
            errors={errors}
          />
        </Card>

        <Card
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
        >
          <FormFieldsGrid
            fields={fieldsEndereco}
            control={control}
            errors={errors}
          />
        </Card>

        <Card
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">Benefícios</Typography>
          <FormFieldsGrid
            fields={fieldsBeneficios}
            control={control}
            errors={errors}
          />
        </Card>

        {/* Área de Atuação Profissional - mostrar apenas para Vaga de Emprego (tipo 1) */}
        {tipoVagaSelecionada === "EMP" && (
          <Card sx={{ padding: 2, borderRadius: 2 }}>
            <Typography variant="h6">*Informe a(s) Área(s) de Atuação do Profissional</Typography>
            <FormFieldsGrid
              fields={fieldsAreaAtuacao}
              control={control}
              errors={errors}
            />
          </Card>
        )}

        {/* Área de Atuação do Estudante - mostrar apenas para Estágio (tipo 2) ou Jovem Aprendiz (tipo 3) */}
        {(tipoVagaSelecionada === "EST" || tipoVagaSelecionada === "APR") && (
          <Card sx={{ padding: 2, borderRadius: 2 }}>
            <Typography variant="h6">*Informe a(s) Área(s) de Atuação do Estudante</Typography>
            <FormFieldsGrid
              fields={fieldsAreaEstudante}
              control={control}
              errors={errors}
            />
          </Card>
        )}

        {/* Curso Complementar - mostrar apenas quando tipoVaga for selecionado */}
        {tipoVagaSelecionada && (
          <Card sx={{ padding: 2, borderRadius: 2 }}>
            <Typography variant="h6">Curso Complementar</Typography>
            <FormFieldsGrid
              fields={fieldsCursoComplementar}
              control={control}
              errors={errors}
            />
          </Card>
        )}

        {/* Curso Técnico - mostrar apenas quando tipoVaga for selecionado e for EMP */}
        {tipoVagaSelecionada === "EMP" && (
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">Algum Curso Técnico é Exigido ou Desejável? (Caso sim adicione um ou mais itens)</Typography>
            <FormFieldsGrid
              fields={fieldsCursoTecnico}
              control={control}
              errors={errors}
            />
          </Card>
        )}

        {/* Curso Superior - mostrar apenas quando tipoVaga for selecionado e for EMP ou EST */}
        {(tipoVagaSelecionada === "EMP" || tipoVagaSelecionada === "EST") && (
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">Algum Curso de Nível Superior é Exigido ou Desejável? (Caso sim adicione um ou mais itens)</Typography>
            <FormFieldsGrid
              fields={fieldsCursoSuperior}
              control={control}
              errors={errors}
            />
          </Card>
        )}
      </Stack>
      {/* Botões */}
      <Grid
        container
        mt={1}
        spacing={1}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => navigate("/vagas/listar")}
            fullWidth
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ gap: "8px" }}
            loading={isSubmitting}
          >
            <SaveIcon fontSize="small" />
            <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

VagaTrabalho.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onSubmit: PropTypes.func,
  listaEmpresas: PropTypes.array,
  listaAreaAtuacao: PropTypes.array,
  listaCursos: PropTypes.object, //será um array depois
};

export default VagaTrabalho;

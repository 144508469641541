import React from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQueries } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import AdminListarHistoricoAlteracoesCurso from "../../components/filtros/AdminListarHistoricoAlteracoesCurso";
import LoadingComponent from "../../components/loading/LoadingComponent";
import TitlePage from "../../components/title-page/TitlePage";
import adminService from "../../services/admin.service";

const PageHistoricoAlteracoesCurso = () => {
  const { id } = useParams();

  const results = useQueries({
    queries: [
      {
        queryKey: ["logsStatusCurso", id],
        queryFn: () => {
          return adminService.getLogsStatusCurso(id);
        },
      },
      {
        queryKey: ["curso", id],
        queryFn: () => {
          return adminService.getCurso(id);
        },
      },
    ],
  });

  const [logsQuery, cursoQuery] = results;

  const log = logsQuery.data;
  const curso = cursoQuery.data;

  const isPending = logsQuery.isLoading || cursoQuery.isLoading;
  const isError = logsQuery.isError || cursoQuery.isError;

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/cursos/pendentes", text: "Listar Cursos Pendentes" },
          { href: "/cursos/pendentes/analisar/", text: "Analisar" },
        ]}
        currentPage="Histórico de Análise"
      />

      <TitlePage title={"Histórico de Análise"} />

      {isPending ? (
        <LoadingComponent
          isLoading={isPending}
          fullScreen={false}
        />
      ) : isError ? (
        <ErroCarregamentoModal isShow={true} />
      ) : (
        <AdminListarHistoricoAlteracoesCurso
          initialData={log}
          curso={curso}
        />
      )}
    </Container>
  );
};

export default PageHistoricoAlteracoesCurso;

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Grid, Typography } from "@mui/material";

const FormMicrocreditoMPO = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      mb={2}
      mt={2}
    >
      <Grid
        item
        md={6}
        sm={6}
        xs={6}
      >
        <Button
          size="large"
          type="submit"
          variant="outlined"
          onClick={() => console.log("Clique!")}
          fullWidth
        >
          JÁ SOU EMPREENDEDOR
        </Button>
      </Grid>
      <Grid
        item
        md={6}
        sm={6}
        xs={6}
      >
        <Button
          size="large"
          type="submit"
          variant="contained"
          onClick={() => navigate("questionario")}
          fullWidth
        >
          QUERO SER EMPREENDEDOR
        </Button>
      </Grid>
      <Grid item>
        <Box
          mt={5}
          style={{ transition: "1s" }}
          sx={{
            display: "flex",
            alignItems: "center",
            background: "background.paper",
            color: "text.primary",
            border: "border.layout",
            padding: "16px",
            paddingRight: "8px",
          }}
        >
          <InfoIcon />
          <Typography
            variant="body1"
            sx={{ ml: 1, fontFamily: "Rawline Regular" }}
            style={{ transition: "1s" }}
          >
            Ainda tem dúvidas?{" "}
            <Link
              component="a"
              href="/faq"
              color="primary.main"
              underline="always"
              sx={{ ml: 1, fontFamily: "Rawline Bold" }}
            >
              Clique aqui e acesse nossas Perguntas Frequentes
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormMicrocreditoMPO;

import React from "react";

import PropTypes from "prop-types";

import AdminParceiroFormInformacoesComplementares from "../../components/formularios/AdminParceiroFormInformacoesComplementares";
import CustomModal from "./CustomModal";

const InformacoesComplementaresModal = ({ showModal, handleClose, handleSubmit, handleDeletar, initialValues }) => {
  const modalTitle = "Serviço Financeiros e Pagamentos";

  const modalContent = (
    <AdminParceiroFormInformacoesComplementares
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleCancelar={handleClose}
      handleDeletar={handleDeletar}
    />
  );

  const modalButtons = [];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="lg"
    />
  );
};

InformacoesComplementaresModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleContinuar: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDeletar: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  setCheckFinanceiro: PropTypes.func,
};

export default InformacoesComplementaresModal;

import React, { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { Card, InputAdornment, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function TransferList({ items, onItemsChange, titleLeft, titleRight }) {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(items.left);
  const [right, setRight] = useState(items.right);
  const [searchTermLeft, setSearchTermLeft] = useState("");
  const [searchTermRight, setSearchTermRight] = useState("");

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const isXs = useResponsive("down", "md");

  useEffect(() => {
    onItemsChange({ left, right });
  }, [left, right]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items, searchTerm, setSearchTerm, title) => {
    const filteredItems = items.filter(value => `List item ${value + 1}`.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
      <>
        <div style={{ border: "1px solid #e8e8e8", textAlign: "center", borderRadius: "5px", marginBottom: "8px", padding: "4px" }}>
          <Typography
            variant="h5"
            mt={2}
            sx={{ fontFamily: "Rawline Bold", margin: "0px" }}
            style={{ transition: "1s" }}
          >
            {title}
          </Typography>
        </div>
        <Card sx={{ width: "100%", height: 230, overflow: "auto", p: 2 }}>
          <TextField
            id="input-with-icon-textfield"
            label="Pesquisar"
            fullWidth
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <List
            dense
            component="div"
            role="list"
          >
            {filteredItems.map(value => {
              const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItemButton
                  key={value}
                  role="listitem"
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={` ${value}`}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Card>
      </>
    );
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={5}
      >
        {customList(left, searchTermLeft, setSearchTermLeft, titleLeft)}
      </Grid>
      <Grid
        item
        md={2}
        style={{ display: "flex", height: isXs ? "auto" : 230, width: "100%" }}
      >
        <Grid
          spacing={1}
          container
          style={{
            padding: isXs ? "1rem" : "0",
            display: "flex",
            justifyContent: "center",
            flexDirection: isXs ? "row" : "column",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              {isXs ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowRightIcon />}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              {isXs ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              {isXs ? <KeyboardArrowUpIcon /> : <KeyboardArrowLeftIcon />}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              {isXs ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowLeftIcon />}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        md={5}
        xs={12}
        item
      >
        {customList(right, searchTermRight, setSearchTermRight, titleRight)}
      </Grid>
    </Grid>
  );
}

TransferList.propTypes = {
  items: PropTypes.object,
  onItemsChange: PropTypes.func,
  titleRight: PropTypes.string,
  titleLeft: PropTypes.string,
};

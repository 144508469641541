import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ParceiroBuscarCurriculoRede from "../../components/formularios/ParceiroBuscarCurriculoRede";
import TitlePage from "../../components/title-page/TitlePage";

const PageBuscarCurriculoRede = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro", text: "Parceiro" },
          { href: "/vagas/listar", text: "Vagas" },
        ]}
        currentPage={"Buscar Currículos"}
      />

      <TitlePage title={"Vagas em oferta"} />

      <ParceiroBuscarCurriculoRede />
    </Container>
  );
};

export default PageBuscarCurriculoRede;

import React from "react";
import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import PageAcessoMicrocreditoMPO from "../../pages/cidadao/PageAcessoMicrocreditoMPO";
import PageAcessoMicrocreditoMPOAprovado from "../../pages/cidadao/PageAcessoMicrocreditoMPOAprovado";
import PageCurriculo from "../../pages/cidadao/PageCurriculo";
import PageEditarCurriculo from "../../pages/cidadao/PageEditarCurriculo";
import PageEditarPerfil from "../../pages/cidadao/PageEditarPerfilCidadao";
import PageHomeCidadao from "../../pages/cidadao/PageHomeCidadao";
import PageInformarSituacaoContato from "../../pages/cidadao/PageInformarSituacaoContato";
import PageListarConsultaParceiros from "../../pages/cidadao/PageListarConsultaParceiros";
import PageListarCursos from "../../pages/cidadao/PageListarCursos";
import PageListarVagas from "../../pages/cidadao/PageListarVagas";
import PageMicrocreditoMPO from "../../pages/cidadao/PageMicrocreditoMPO";
import PageMicrocreditoMPOQuestionario from "../../pages/cidadao/PageMicrocreditoMPOQuestionario";
import PagePerguntasFrequentesEmpreendedorismo from "../../pages/cidadao/PagePerguntasFrequentesEmpreendedorismo";
import PageQuestionarioJaSouEmpreendedor from "../../pages/cidadao/PageQuestionarioJaSouEmpreendedor";
import PageQuestionarioQueroSerEmpreendedor from "../../pages/cidadao/PageQuestionarioQueroSerEmpreendedor";
import PageTrilhasCurso from "../../pages/cidadao/PageTrilhasCurso";
import PageVisualizarCurriculo from "../../pages/cidadao/PageVisualizarCurriculo";
import PageVisualizarInformacoesCursos from "../../pages/cidadao/PageVisualizarInformacoesCursos";
import PageVisualizarInformacoesVagas from "../../pages/cidadao/PageVisualizarInformacoesVagas";
import PageVisualizarTrilhaCurso from "../../pages/cidadao/PageVisualizarTrilhaCurso";
import FAQ from "../../pages/FAQ";
import NotFound from "../../pages/NotFound";
import PageComponents from "../../pages/PageComponents";
import PageComponentsDefaultTable from "../../pages/PageComponentsDefaultTable";
import PageComponentsForm from "../../pages/PageComponentsForm";
import Profile from "../../pages/Profile";

const queryClient = new QueryClient();

const CidadaoRoutes = () => (
  <QueryClientProvider client={queryClient}>
    <Routes>
      <Route
        path="/"
        element={<PageHomeCidadao />}
      />
      <Route
        path="/home"
        element={<PageHomeCidadao />}
      />
      <Route
        path="/profile"
        element={<Profile />}
      />
      <Route
        path="/editar-perfil"
        element={<PageEditarPerfil />}
      />

      {/* Vagas */}
      <Route
        path="/vagas/curriculo"
        element={<PageCurriculo />}
      />
      <Route
        path="/vagas/curriculo/editar"
        element={<PageEditarCurriculo />}
      />
      <Route
        path="/vagas/curriculo/visualizar"
        element={<PageVisualizarCurriculo />}
      />
      <Route
        path="/vagas/listar"
        element={<PageListarVagas />}
      />
      <Route
        path="/vagas/visualizar/:id?"
        element={<PageVisualizarInformacoesVagas />}
      />

      {/* Cursos */}
      <Route
        path="/cursos/listar"
        element={<PageListarCursos />}
      />
      <Route
        path="/cursos/visualizar/:id?"
        element={<PageVisualizarInformacoesCursos />}
      />
      <Route
        path="/cursos/trilhas"
        element={<PageTrilhasCurso />}
      />
      <Route
        path="/cursos/trilhas/visualizar/:id?"
        element={<PageVisualizarTrilhaCurso />}
      />

      {/* Empreendedorismo / Microcrédito */}
      <Route
        path="/empreendedorismo/microcredito/credito-mpo"
        element={<PageMicrocreditoMPO />}
      />
      <Route
        path="/empreendedorismo/microcredito/credito-mpo/questionario"
        element={<PageMicrocreditoMPOQuestionario />}
      />
      <Route
        path="/empreendedorismo/microcredito/acesso-credito-mpo"
        element={<PageAcessoMicrocreditoMPO />}
      />
      <Route
        path="/empreendedorismo/microcredito/acesso-credito-mpo/aprovado"
        element={<PageAcessoMicrocreditoMPOAprovado />}
      />
      <Route
        path="/empreendedorismo/microcredito/informar-situacao"
        element={<PageInformarSituacaoContato />}
      />
      <Route
        path="/empreendedorismo/microcredito/perguntas-frequentes"
        element={<PagePerguntasFrequentesEmpreendedorismo />}
      />
      <Route
        path="/empreendedorismo/microcredito/quero-ser-empreendedor"
        element={<PageQuestionarioQueroSerEmpreendedor />}
      />
      <Route
        path="/empreendedorismo/microcredito/ja-sou-empreendedor"
        element={<PageQuestionarioJaSouEmpreendedor />}
      />
      <Route
        path="/empreendedorismo/mobilizacao/parceiros-operadores"
        element={<PageListarConsultaParceiros />}
      />

      {/* Components */}
      <Route
        path="/components"
        element={<PageComponents />}
      />
      <Route
        path="/components-default-table"
        element={<PageComponentsDefaultTable />}
      />
      <Route
        path="/components-form"
        element={<PageComponentsForm />}
      />
      <Route
        path="/faq"
        element={<FAQ />}
      />
      <Route
        path="*"
        element={<NotFound />}
      />

      {/* Auth routes should be home */}
      <Route
        path="/parceiro_login"
        element={<PageHomeCidadao />}
      />
      <Route
        path="/admin_login"
        element={<PageHomeCidadao />}
      />
      <Route
        path="/cidadao_login"
        element={<PageHomeCidadao />}
      />
      <Route
        path="/login"
        element={<PageHomeCidadao />}
      />
      <Route
        path="/register"
        element={<PageHomeCidadao />}
      />
    </Routes>
  </QueryClientProvider>
);

export default CidadaoRoutes;

import React from "react";

import { Box, Card, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

import DefaultTable from "../../components/table/DefaultTable";

const tabelaColunas = [
  { field: "data", headerName: "Data" },
  { field: "situacao", headerName: "Situação" },
  { field: "usuario", headerName: "Usuário" },
  { field: "motivo", headerName: "Motivo" },
];

const AdminListarHistoricoAlteracoesCurso = ({ initialData, curso }) => {
  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          <Typography variant="subtitle2">Histórico de alterações do curso: {curso.nomeDoCurso}</Typography>
          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "24px" }}>
            <DefaultTable
              rows={initialData}
              notFoundText={"Não foi localizado histórico!"}
              columns={tabelaColunas}
            />
          </Box>
        </Card>
      </Stack>
    </>
  );
};

AdminListarHistoricoAlteracoesCurso.propTypes = {
  initialData: PropTypes.array,
  curso: PropTypes.object,
};

export default AdminListarHistoricoAlteracoesCurso;

import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";

import FileUpload from "../../components/inputs/fileUpload";
import DynamicForm from "../formularios/DynamicForm";
import CustomModal from "./CustomModal";

const InativarCurso = ({ showModal, handleInativarCurso, handleClose, idCurso = null }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const formRef = useRef();
  const handleFilesChange = values => {
    // Dummy console log só para não reclamar do files
    console.log(files);
    setFiles(values);
  };

  const fields = [
    {
      name: "motivo",
      label: "Motivo",
      validation: { required: "Por favor, informe um motivo para a exclusão do curso" },
      renderComponent: ({ value, onChange, error }) => (
        <Grid item>
          <TextField
            multiline
            id="motivo"
            name="motivo"
            rows={3}
            label="Motivo"
            placeholder="Motivo"
            value={value}
            onChange={onChange}
            error={!!error}
            color={error ? "error" : "primary"}
            fullWidth
          />
          {!!error && <Typography sx={{ color: "#FF5630", fontSize: "12px", ml: "12px" }}>{error.message}</Typography>}
        </Grid>
      ),
      sx: { sm: 12, md: 12 },
    },
    {
      name: "file",
      label: "Anexar Imagem",
      renderComponent: () => (
        <Grid item>
          <FileUpload
            onFieldsChange={handleFilesChange}
            fieldLabel={"Anexar Imagem"}
            fullWidth
          />
        </Grid>
      ),
      sx: { sm: 12, md: 12 },
    },
  ];

  const modalTitle = "Inativar Curso";
  const onSubmit = data => {
    handleInativarCurso(data.motivo, files);
  };

  const modalContent = (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <DynamicForm
        styleForm={{ width: "100%" }}
        fields={fields}
        ref={formRef}
        onSubmit={onSubmit}
      />
    </Grid>
  );

  const handleSalvar = () => {
    formRef.current.submitForm();
  };
  const handleVisualizar = () => {
    const navigateURL = idCurso ? `/cursos/visualizar/${idCurso}` : `/cursos`;
    navigate(navigateURL);
  };

  const modalButtons = [
    { label: "CANCELAR", onClick: handleClose, variant: "outlined" },
    // { label: "VISUALIZAR OFERTANTE", onClick: handleClose, variant: "outlined" },
    { label: "VISUALIZAR CURSO", onClick: handleVisualizar, variant: "outlined" },
    { label: "SALVAR", onClick: handleSalvar, variant: "contained", icon: "save" },
  ];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
    />
  );
};

InativarCurso.propTypes = {
  idCurso: PropTypes.number,
  idOfertante: PropTypes.number,
  showModal: PropTypes.bool.isRequired,
  handleInativarCurso: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
};

export default InativarCurso;
